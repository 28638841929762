import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ClientActionType } from '@travauxlib/shared/src/types';

import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { Section } from './Section';

import { useValidateAction } from '../api/useValidateAction';
import { sections } from '../utils/constants';

export const GuidesSection: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { validateAction } = useValidateAction();

  useEffect(() => {
    validateAction({ projectUuid: uuid!, actionType: ClientActionType.SeeGuides });
    EspaceClientTracking.onEspaceClientGuidesViewed();
  }, []);

  return (
    <div className="flex flex-col gap-md">
      <Section section={sections['offre-hemea']} />
      <Section section={sections['commencer-son-projet']} />
      <Section section={sections['suivre-son-projet']} />
    </div>
  );
};
