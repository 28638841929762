import React from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useContacts } from 'features/EspaceClient3/api/useContacts';

import { MyAdviserInfo } from '../components/MyAdviserInfo';

type OwnProps = {
  projectUuid: string;
};

type Props = OwnProps & {
  handleClose: () => void;
};

export const MyAdviserModal: React.FC<Props> = ({ projectUuid, handleClose }) => {
  const { isLoading, contacts } = useContacts();
  const contact = contacts[projectUuid];

  return (
    <ModalContent validateAction={{ label: 'Fermer', onClick: handleClose }}>
      {isLoading ? <Loader /> : <MyAdviserInfo contact={contact} />}
    </ModalContent>
  );
};

export const useOpenMyAdviserModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(MyAdviserModal, {
      title: 'Mon conseiller hemea',
      closable: true,
      ...props,
    });
};
