import React from 'react';

import { Prestations } from 'features/Estimateur/features/Result/components/Prestations';
import { Answer, Estimation } from 'features/Estimateur/types';
import { heightEstimateur } from 'features/Estimateur/utils/heightEstimateur';

import { DesktopColumn } from './DesktopColumn';

import { Details } from '../Details';
import { Summary } from '../Summary';

interface Props {
  estimation: Estimation;
  organizationName?: string;
  submitAnswer: (answer: Answer, index?: number) => Promise<unknown>;
  submitEstimation: (estimation: Estimation) => Promise<unknown>;
  deleteAnswer: (answerIndex: number) => Promise<unknown>;
  printUrl?: string;
  isClientEstimateur: boolean;
  toggleRecallModal: () => void;
  openLeadModal: () => void;
}

export const Desktop: React.FC<Props> = (props: Props) => {
  const {
    submitAnswer,
    submitEstimation,
    deleteAnswer,
    estimation,
    organizationName,
    printUrl,
    isClientEstimateur,
    toggleRecallModal,
    openLeadModal,
  } = props;

  return (
    <div className="flex items-start relative overflow-hidden">
      <DesktopColumn height={heightEstimateur} smallWidth={18.5} width={20} left>
        <Prestations
          estimation={estimation}
          submitAnswer={submitAnswer}
          deleteAnswer={deleteAnswer}
        />
      </DesktopColumn>
      <div
        style={{ maxHeight: heightEstimateur }}
        className="overflow-auto grow px-sm md-desktop:px-lg pt-sm"
      >
        <Details
          isClientEstimateur={isClientEstimateur}
          estimation={estimation}
          submitEstimation={submitEstimation}
        />
      </div>
      <DesktopColumn height={heightEstimateur}>
        {estimation.result && (
          <Summary
            locations={estimation.context.locations}
            printUrl={printUrl}
            estimationResult={estimation.result}
            organizationName={organizationName}
            toggleRecallModal={toggleRecallModal}
            googleMapsAddress={estimation.googleMapsAddress}
            isClientEstimateur={isClientEstimateur}
            openLeadModal={openLeadModal}
          />
        )}
      </DesktopColumn>
    </div>
  );
};
