import React from 'react';

import ArchitectureOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ArchitectureOutline.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import ProjectIcon from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { EtapeProjet } from '@travauxlib/shared/src/types';

const preChantierAndConsultationStep = {
  icon: <Quote />,
  title: 'Consultation entreprises',
};

const configurationByProjectStep: { [k in EtapeProjet]?: any } = {
  [EtapeProjet.Etude]: {
    icon: <ProjectIcon />,
    title: 'Étude du projet',
  },
  [EtapeProjet.Archi]: {
    icon: <ArchitectureOutline />,
    title: 'Conception architecte',
  },
  // TODO: Vu avec Eng et Jalil, l'avant chantier n'a que peu d'interet de presentation mais peut presenter encore un
  // TODO: interet dans le backend, il est donc retiré de la presentation mais conservé dans le back avant qu'on decide
  // TODO: Quoi en faire de maniere sereine
  [EtapeProjet.Pro]: preChantierAndConsultationStep,
  [EtapeProjet.Devis]: preChantierAndConsultationStep,
  [EtapeProjet.AvantChantier]: preChantierAndConsultationStep,
};

type ProjectInfoProps = {
  projectStep: EtapeProjet;
  projectShortAddress?: string;
};

export const ProjectInfo: React.FC<ProjectInfoProps> = ({ projectStep, projectShortAddress }) => (
  <div className="flex justify-between items-center">
    <div className="flex">
      <div className="p-xxs">
        <ColoredCircle variant="secondary" size="lg">
          {configurationByProjectStep[projectStep]?.icon ?? <></>}
        </ColoredCircle>
      </div>
      <div className="p-xxs flex flex-col justify-center">
        <div className="text-ds-b1 font-bold">
          {configurationByProjectStep[projectStep]?.title ?? <>&nbsp;</>}
        </div>
        <div className="mt-xxs text-ds-b2 text-neutral-600">
          {projectShortAddress ?? <>&nbsp;</>}
        </div>
      </div>
    </div>
    <IconButtonLink to="etapes">
      <ChevronRight />
    </IconButtonLink>
  </div>
);
