import _partition from 'lodash/partition';
import _sum from 'lodash/sum';

import { DevisLocation, TypeLocation } from '@travauxlib/shared/src/types';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

type LocationConfig = {
  typeRatio?: 'ratioChambre' | 'ratioTotal';
  value: number;
  min?: number;
  max?: number;
};

const getConfigs = (isStudio: boolean): { [K in TypeLocation]: LocationConfig } => ({
  Chambre: {
    typeRatio: 'ratioChambre',
    value: 1,
  },
  'Pièce principale': {
    typeRatio: 'ratioChambre',
    value: 1,
  },
  'Salle à manger': {
    typeRatio: 'ratioChambre',
    value: 1,
  },
  Séjour: {
    typeRatio: 'ratioChambre',
    value: 1.9,
  },
  Salon: {
    typeRatio: 'ratioChambre',
    value: 1.9,
  },
  Bureau: {
    typeRatio: 'ratioChambre',
    value: 0.8,
  },
  Dressing: {
    typeRatio: 'ratioChambre',
    value: isStudio ? 0.125 : 0.25,
  },
  Autre: {
    typeRatio: 'ratioChambre',
    value: isStudio ? 0.25 : 0.5,
  },
  Cuisine: {
    typeRatio: 'ratioTotal',
    value: 0.1,
  },
  Dégagement: {
    typeRatio: 'ratioTotal',
    value: 0.04,
    min: 1,
    max: isStudio ? 10 : 15,
  },
  Entrée: {
    typeRatio: 'ratioTotal',
    value: 0.06,
    min: 1,
    max: 15,
  },
  "Salle d'eau": {
    typeRatio: 'ratioTotal',
    value: 0.085,
    min: 2,
    max: 10,
  },
  'Salle de bain': {
    typeRatio: 'ratioTotal',
    value: 0.085,
    min: 4,
    max: 15,
  },
  WC: {
    value: 1,
  },
  Terrasse: {
    value: 0,
  },
});

const computeSurfaceForRatio = (
  reference: number,
  { value, min = -Infinity, max = Infinity }: LocationConfig,
): number => roundToTwoDecimals(Math.min(Math.max(value * reference, min), max));

export const distributeSurfaceAcrossLocations = (
  locations: DevisLocation[],
  surfaceTotale: number = 0,
): DevisLocation[] => {
  const isStudio = locations.some(({ typeLocation }) => typeLocation === 'Pièce principale');

  const allConfigs = getConfigs(isStudio);

  const locationsWithConfigs = locations.map(location => ({
    location,
    config: allConfigs[location.typeLocation],
  }));

  const [globalLocations, chambreLocations] = _partition(
    locationsWithConfigs,
    ({ config }) => config.typeRatio !== 'ratioChambre',
  );

  const knownSurface = _sum(
    globalLocations.map(({ config }) =>
      config.typeRatio === 'ratioTotal'
        ? computeSurfaceForRatio(surfaceTotale, config)
        : config.value,
    ),
  );
  const chambrePonderation = _sum(chambreLocations.map(({ config: { value } }) => value));

  const surfaceChambre = roundToTwoDecimals((surfaceTotale - knownSurface) / chambrePonderation);

  const newLocations = locationsWithConfigs.map(({ location, config }) => {
    const surface = config.typeRatio
      ? computeSurfaceForRatio(
          config.typeRatio === 'ratioTotal' ? surfaceTotale : surfaceChambre,
          config,
        )
      : config.value;

    return { ...location, surface };
  });
  const computedSurfaceTotale = _sum(newLocations.map(location => location.surface));

  if (computedSurfaceTotale !== surfaceTotale) {
    let indexToChange =
      newLocations.findIndex(({ typeLocation }) =>
        ['Salon', 'Séjour', 'Pièce principale'].includes(typeLocation),
      ) || newLocations.length - 1;
    if (indexToChange === -1) {
      indexToChange = newLocations.length - 1;
    }
    newLocations[indexToChange].surface = roundToTwoDecimals(
      newLocations[indexToChange].surface - computedSurfaceTotale + surfaceTotale,
    );
  }
  return newLocations;
};
