import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Meeting, Person } from '../types';

type ApiReturn = { meetings?: Meeting[]; persons?: { [k: string]: Person } };

export const useProjectMeetings = (projectToken: string): { isLoading: boolean } & ApiReturn => {
  const { isLoading, data } = useQuery<ApiReturn>({
    queryKey: ['PROJECT_MEETINGS', projectToken],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/project/${projectToken}/meetings`),
  });

  return { meetings: data?.meetings, persons: data?.persons, isLoading };
};
