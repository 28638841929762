export enum Consent {
  AdStorage = 'ad_storage',
  AnalyticsStorage = 'analytics_storage',
  PersonalizationStorage = 'personalization_storage',
}

export enum ConsentValue {
  Granted = 'granted',
  Denied = 'denied',
}

export enum ConsentEvent {
  Consents = 'consents',
}
