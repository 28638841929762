import { IconSizes } from '../../ColoredCircle';
import { IconIllustrationSize } from '../types';

export const iconBackgroundSizeMapper: { [K in IconIllustrationSize]: IconSizes } = {
  sm: 'sm',
  md: 'lg',
  lg: 'xxl',
};

export const illustrationSizeMapper: { [K in IconIllustrationSize]: string } = {
  sm: 'w-[5rem] h-[5rem]',
  md: 'w-[7.5rem] h-[7.5rem]',
  lg: 'w-[15rem] h-[15rem]',
};
