import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const InsufficientFunds: React.FC<Props> = ({ isOpen, handleClose }) => (
  <FeedbackMessages
    isOpen={isOpen}
    handleClose={handleClose}
    title="Fonds insuffisants"
    message={
      <div className="text-neutral-600 text-ds-b2">
        Vous n’avez pas assez de fonds sur votre compte séquestre pour débloquer des fonds sur votre
        chantier. Faite un virement bancaire pour provisionner votre compte afin de pouvoir libérer
        des fonds.
      </div>
    }
    variant="warning"
    validateAction={{ label: 'Fermer', onClick: handleClose }}
    centered
  />
);
