import React from 'react';

import { Form } from 'react-final-form';

import Amex from '@travauxlib/shared/src/components/DesignSystem/assets/Amex.svg?react';
import CarteBancaire from '@travauxlib/shared/src/components/DesignSystem/assets/CarteBancaire.svg?react';
import MasterCard from '@travauxlib/shared/src/components/DesignSystem/assets/MasterCard.svg?react';
import Visa from '@travauxlib/shared/src/components/DesignSystem/assets/Visa.svg?react';

import { useSubmitFactureCheckout } from 'features/Factures/api/useSubmitFactureCheckout';

import { FacturePaymentCardFormContent } from './FacturePaymentCardFormContent';

type FormValues = {
  email: string;
  name: string;
  acceptConditions: boolean;
  token: string;
};

type FacturePaymentCardFormProps = {
  email: string;
  name: string;
  token: string;
  onPaymentCompleted: () => void;
};

export const FacturePaymentCardForm: React.FC<FacturePaymentCardFormProps> = ({
  email,
  name,
  token,
  onPaymentCompleted,
}) => {
  const { handleSubmit } = useSubmitFactureCheckout(onPaymentCompleted);

  return (
    <div className="flex flex-col w-full p-sm mb-sm">
      <div className="flex flex-col w-full">
        <div className="flex w-full px-sm justify-between items-center hover:cursor-pointer">
          Carte bancaire
          <div className="flex">
            <Amex className="mr-xxs" />
            <Visa />
            <div className="mx-xxs">
              <CarteBancaire />
            </div>
            <MasterCard />
          </div>
        </div>

        <div className="px-sm pt-sm">
          <div className="mb-xs">Cartes acceptées : CB, Visa, Mastercard</div>
          <Form<FormValues>
            initialValues={{
              email,
              name,
              acceptConditions: false,
              token,
            }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <FacturePaymentCardFormContent
                  submitButtonLabel="Régler avec"
                  submitting={submitting}
                />
              </form>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};
