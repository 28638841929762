import {
  NumberInputStepConfig,
  OnboardingEventProperty,
  ProjectQuestion,
  QuestionType,
  VariableSurfaceStepConfig,
} from 'features/OnboardingSolide/types';

export const surfaceStep = ({
  title,
  helperText,
  imageUrl,
  avis,
}: VariableSurfaceStepConfig): NumberInputStepConfig => ({
  id: ProjectQuestion.Surface,
  type: QuestionType.NumberInput,
  title,
  helperText,
  label: 'Surface',
  suffix: 'm²',
  imageUrl,
  avis,
  eventKey: OnboardingEventProperty.ProjectSurface,
});
