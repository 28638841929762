import React from 'react';

import classNames from 'classnames';
import Dropzone from 'react-dropzone';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { formatFileSize } from '@travauxlib/shared/src/utils/format';

import { DropZoneError } from './DropZoneError';
import { DropZoneInitial } from './DropZoneInitial';
import { DropZoneProgress } from './DropZoneProgress';
import { FileDropzonePrivateProps, FileDropzonePublicProps } from './types';

export const DEFAULT_SIZE_LIMIT = 8000000;

const RawFileDropzoneContainer: React.FC<{
  disabled?: boolean;
  dragInProgress?: boolean;
  small?: boolean;
  hasErrors?: boolean;
  children: React.ReactNode;
}> = ({ children, disabled, dragInProgress, small, hasErrors }) => (
  <div
    className={classNames(
      'border min-h-3xl border-dashed bg-neutral-100 rounded-xs',
      small ? 'p-sm' : 'sm-desktop:min-h-[80px] sm-desktop:p-md p-[22px]',
      disabled
        ? 'border-neutral-300 cursor-not-allowed'
        : 'border-neutral-600  hover:border-primary active:border-primary',
      dragInProgress ? 'border-[2px] m-[-1px] bg-neutral-200' : 'border',
      { '!border-error-800 !pb-xs': hasErrors },
    )}
  >
    {children}
  </div>
);

const errorMessages = (): { [key: string]: string } => ({
  'file-too-large': `Fichier trop gros - ${formatFileSize(DEFAULT_SIZE_LIMIT)} maximum`,
  'file-invalid-type': `Fichier invalide - type de fichiers non autorisé`,
  'file-too-small': 'Fichier trop petit',
  'too-many-files': "Trop de fichiers d'un coup",
});

export const FileDropzone: React.FC<FileDropzonePrivateProps & FileDropzonePublicProps> = ({
  progress,
  handleUploadFile,
  multiple = false,
  hasErrors,
  onClickRetry,
  sizeLimit,
  disabled,
  small = false,
  id = 'dropzone',
}) => {
  const [dragInProgress, setDragInProgress] = React.useState<boolean>(false);
  const onDrop = (droppedFiles: File[]): Promise<any> => handleUploadFile(droppedFiles);

  const disableDrop = !!progress || hasErrors || disabled;

  return (
    <Dropzone
      disabled={disableDrop}
      multiple={multiple}
      onDrop={onDrop}
      onDragEnter={() => setDragInProgress(true)}
      onDragLeave={() => setDragInProgress(false)}
      onDropRejected={events => {
        const errors = events.flatMap(event => event.errors);
        errors.forEach(error => {
          const errorMessage =
            errorMessages()[error.code] || 'Erreur inconnue - Veuillez contacter notre support.';
          toast.error(errorMessage);
        });
      }}
      maxSize={sizeLimit}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} role={disableDrop ? 'div' : 'button'}>
          <RawFileDropzoneContainer
            disabled={disabled}
            dragInProgress={dragInProgress}
            small={small}
            hasErrors={hasErrors}
          >
            <input {...getInputProps()} data-testid="dropzone" />
            {progress !== undefined ? (
              <DropZoneProgress progress={progress} />
            ) : hasErrors ? (
              <DropZoneError onClickRetry={onClickRetry} multipleUpload={multiple} />
            ) : (
              <DropZoneInitial disabled={disabled} small={small} id={id} />
            )}
          </RawFileDropzoneContainer>
        </div>
      )}
    </Dropzone>
  );
};
