export const danger = '#990d35';
export const dark = '#21180f';
export const error400 = '#f34544';
export const gray100 = '#f4f4f4';
export const gray200 = '#e4e4e4';
export const gray300 = '#cdcdcd';
export const gray600 = '#727272';
export const gray900 = '#292929';
export const info = '#47549f';
export const info400 = '#616dac';
export const light = '#e4e4e4';
export const lighter = '#f4f4f4';
export const primary = 'var(--primary-400)';
export const primarylight = '#e5dacf';
export const primarylighter = '#f6f1ed';
export const progreen = '#1c3f3f';
export const success = '#308259';
export const success200 = '#a3dabd';
export const success400 = '#60c191';
export const warning = '#e37b3b';
export const warning400 = '#faaf53';
export const white = '#ffffff';
