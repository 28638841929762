import React from 'react';

import { Chantier } from 'features/EspaceClient3/types';

import { PaiementCard } from './PaiementCard';
import { ProvisionCard } from './ProvisionCard';

export const SuiviFinancier: React.FC<{ chantier: Chantier }> = ({ chantier }) => (
  <div className="mx-md sm-desktop:mx-0">
    <div className="flex items-center justify-between">
      <div className="text-ds-h5 font-bold my-sm">Suivi financier</div>
    </div>
    <div className="flex flex-col gap-xs">
      <ProvisionCard chantier={chantier} />
      <PaiementCard chantier={chantier} />
    </div>
  </div>
);
