import React from 'react';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import SwitchIndeterminate from '@travauxlib/shared/src/components/DesignSystem/assets/SwitchIndeterminate.svg?react';

export type DragProps = {
  isDragging?: boolean;
  className?: string;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
};

export const DragWidget: React.FC<DragProps> = ({
  className = '',
  dragHandleProps,
  isDragging,
}) => {
  const colorClassName = isDragging ? 'visible !text-dark' : '!text-gray-600';
  return (
    <div className={`${className} ${colorClassName}`} {...dragHandleProps}>
      <SwitchIndeterminate className="w-[20px] h-[20px]" />
    </div>
  );
};
