import React from 'react';

import { Lot } from '@travauxlib/shared/src/types';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

import { LotComponent } from './Lot';

type Props = {
  lots: Lot[];
};

export const LotsSommaire: React.FC<Props> = ({ lots }) => {
  if (lots.length === 0) {
    return null;
  }

  return (
    <div className="overflow-auto">
      {lots.map((lot, lotIndex) => {
        const lotId = lot.uuid || `lot-${slugify(lot.label)}`;
        return <LotComponent index={lotIndex} key={lotId} lot={lot} lotId={lotId} />;
      })}
    </div>
  );
};
