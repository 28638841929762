import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { SuiviChantierIntervenant } from '../types';

export const createUseDeleteSuiviChantierDraft =
  (actor: SuiviChantierIntervenant, APP_CONFIG: { apiURL: string }) =>
  (): UseMutateAsyncFunction<void, void, string> => {
    const { mutateAsync } = useMutation<void, void, string>({
      mutationFn: (chantierUuid: string) =>
        request(`${APP_CONFIG.apiURL}/${actor}/chantiers/${chantierUuid}/suivi-chantier-draft`, {
          method: 'DELETE',
        }),
    });

    return mutateAsync;
  };
