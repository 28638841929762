import React from 'react';

import { useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { AnswersNavigation } from './AnswersNavigation';
import { ContentLayout } from './ContentLayout';
import { Questions } from './Questions';
import { QuestionsReassurance } from './QuestionsReassurance';

import { FormValues, StepConfig } from '../types';
import { getNextStep } from '../utils/steps/getNextStep';
import { projectSteps } from '../utils/steps/projectSteps';

type Props = {
  setIsNavigatingToPastQuestion: (shouldUpdateFormValues: boolean) => void;
  isNavigatingToPastQuestion: boolean;
  steps: StepConfig[];
};

export const Steps: React.FC<Props> = ({
  setIsNavigatingToPastQuestion,
  isNavigatingToPastQuestion,
  steps,
}) => {
  const { stepId } = useParams();
  const form = useForm<FormValues>();
  const currentStep = projectSteps(form.getState().values).find(step => step.id === stepId)!;

  return (
    <ContentLayout
      avisImageUrl={currentStep.imageUrl}
      avisText={currentStep.avis.content}
      avisAuteur={currentStep.avis.author}
      rightContent={
        <AnswersNavigation
          steps={steps}
          stepId={stepId!}
          setIsNavigatingToPastQuestion={setIsNavigatingToPastQuestion}
          formValues={form.getState().values}
        />
      }
    >
      <Questions
        steps={steps}
        getNextStep={getNextStep(projectSteps)}
        setIsNavigatingToPastQuestion={setIsNavigatingToPastQuestion}
        isNavigatingToPastQuestion={isNavigatingToPastQuestion}
      />
      <div className="sm-desktop:block hidden">
        <QuestionsReassurance />
      </div>
    </ContentLayout>
  );
};
