import React from 'react';

import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Prestations } from '@travauxlib/shared/src/types';

type Props = {
  onChange: (prestations: Partial<Prestations>) => void;
  montantDejaPaye: number;
  montantRestantAPayer: number;
};

export const MontantsPaye: React.FC<Props> = ({
  onChange,
  montantDejaPaye,
  montantRestantAPayer,
}) => (
  <div>
    <div className="!flex !justify-between mb-md">
      <div>
        <NumberInput
          id="montantDejaPaye"
          label="Montant déjà payé"
          value={montantDejaPaye}
          onChange={(newMontant = montantDejaPaye) => onChange({ montantDejaPaye: newMontant })}
          min={0}
          suffix="€"
        />
      </div>
    </div>
    <div className="!flex !justify-between mb-md">
      <div>Montant restant à payer</div>
      <div className="!text-right">
        <EURCurrency amount={montantRestantAPayer} />
      </div>
    </div>
  </div>
);
