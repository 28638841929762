import classNames from 'classnames';

export const getThumbClassNames = (disabled?: boolean): string =>
  classNames(
    'slider-thumb:appearance-none slider-thumb:w-md slider-thumb:h-md slider-thumb:rounded-full',
    'slider-thumb:bg-primary slider-thumb:border-primary',
    'group-hover:slider-thumb:bg-primary-500 group-hover:slider-thumb:border-primary-500',
    'group-active:slider-thumb:bg-primary-600 group-active:slider-thumb:border-primary-600',
    { 'slider-thumb:!bg-neutral-300 slider-thumb:!border-neutral-300': disabled },
  );
