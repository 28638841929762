import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Person } from '../features/Index/types';

const PROJECTS_CONTACTS_KEY = 'PROJECTS_CONTACTS';
type ApiReturn = {
  [k: string]: Person;
};
export const useContacts = (): { isLoading: boolean; contacts: ApiReturn } => {
  const { isLoading, data } = useQuery<ApiReturn>({
    queryKey: [PROJECTS_CONTACTS_KEY],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/project/contacts`),
  });

  return { isLoading, contacts: data || {} };
};
