import React from 'react';

import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';

export type Props = {
  text?: string;
};

export const TextItem: React.FC<Props> = ({ text }) => (
  <div className="p-xs mb-xs relative flex break-inside-avoid rounded-xxs">
    <div className="grow">
      <div className="pt-sm">
        <MultilineParagraph
          paragraphClassName="mb-0 italic text-neutral-600"
          text={text}
          hasLinksOpenedInNewWindow
        />
      </div>
    </div>
  </div>
);
