import { useMutation } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { ClientCustomerSatisfaction } from 'types';

export const CLIENT_CUSTOMER_SATISFACTION_KEY = 'CLIENT_CUSTOMER_SATISFACTION';

const handleCreateClientCustomerSatisfaction = (body: {
  score: number;
  infoComplement?: string;
  scoreType: string;
  projectUuid: string;
  condition: string;
  feature: string;
}): Promise<ClientCustomerSatisfaction> =>
  request(`${APP_CONFIG.apiURL}/client/customer-satisfaction`, {
    method: 'POST',
    body,
  });

export const useCreateClientCustomerSatisfaction =
  (): typeof handleCreateClientCustomerSatisfaction => {
    const { mutateAsync } = useMutation({
      mutationFn: handleCreateClientCustomerSatisfaction,
      mutationKey: [CLIENT_CUSTOMER_SATISFACTION_KEY],
    });

    return mutateAsync;
  };
