import React from 'react';

type Props = {
  montantALibererTTC: number;
  proCompanyName: string;
};

export const LiberationDeFondsRecap: React.FC<Props> = ({ montantALibererTTC, proCompanyName }) =>
  montantALibererTTC <= 0 ? (
    <div>
      Aucune libération de fonds ne sera effectuée et <b>{proCompanyName}</b> sera informé de vos
      ajustements.
    </div>
  ) : null;
