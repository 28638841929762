export type TailwindBreakpoint = 'tablet' | 'sm-desktop' | 'md-desktop' | 'lg-desktop';

export const designSystemResponsiveBreakpoints: { [K in TailwindBreakpoint]: number } = {
  tablet: 600,
  'sm-desktop': 1024,
  'md-desktop': 1440,
  'lg-desktop': 1920,
};

// TODO: A remplacer par la vraie config tailwind
export const designSystemSpacings: { [k: string | number]: string } = {
  0: '0',
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  5: '1.5rem',
  6: '2rem',
  7: '3.5rem',
  8: '5.5rem',
  9: '8rem',
  '1/2': '50%',
  '1/3': '33.333333%',
  '2/3': '66.666667%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '1/6': '16.666667%',
  '2/6': '33.333333%',
  '3/6': '50%',
  '4/6': '66.666667%',
  '5/6': '83.333333%',
  '1/12': '8.333333%',
  '2/12': '16.666667%',
  '3/12': '25%',
  '4/12': '33.333333%',
  '5/12': '41.666667%',
  '6/12': '50%',
  '7/12': '58.333333%',
  '8/12': '66.666667%',
  '9/12': '75%',
  '10/12': '83.333333%',
  '11/12': '91.666667%',
  '1px': '1px',

  // NEW SPACING
  xxs: '0.25rem',
  xs: '0.5rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
  xxl: '3rem',
  '3xl': '4rem',
  '4xl': '6rem',
};
