import React from 'react';

import { SuiviLot } from '@travauxlib/shared/src/types';

import { SuiviChantierLotsSommaire } from './SuiviChantierLotsSommaire';

type Props = {
  lots: SuiviLot[];
};

export const SuiviChantierToolbar: React.FC<Props> = ({ lots }) => (
  <div className="h-full flex flex-col">
    <div className="flex shrink-0 overflow-hidden p-sm">
      <SuiviChantierLotsSommaire lots={lots} />
    </div>
  </div>
);
