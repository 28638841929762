import { useMatch } from 'react-router-dom';

import { Estimateur } from './constants';

import { EstimationSource } from '../types';

export const estimateurPublicPath = 'estimateur/public/*';
export const useIsEstimateurPublic = (): boolean => useMatch(estimateurPublicPath) !== null;

export const creditDuNordPath = 'credit-du-nord/estimateur/*';
export const useIsCreditDuNord = (): boolean => useMatch(creditDuNordPath) !== null;

export const hosmanPath = 'hosman/estimateur/*';
export const useIsHosman = (): boolean => useMatch(hosmanPath) !== null;

export const estimateurClientPath = 'client/estimateur/*';
export const useIsEstimateurClient = (): boolean => useMatch(estimateurClientPath) !== null;

export const estimateurPartnerPath = 'estimateur/*';
export const useIsEstimateurPartner = (): boolean => {
  const isEstimateurPublic = useIsEstimateurPublic();
  return useMatch(estimateurPartnerPath) !== null && !isEstimateurPublic;
};

type EstimateurConfig = {
  isCurrentEstimateur: boolean;
  source: EstimationSource;
};

export const useEstimateurSource = (): EstimationSource | undefined => {
  const estimateursConfig: {
    [K in Estimateur]?: EstimateurConfig;
  } = {
    cdn: {
      isCurrentEstimateur: useIsCreditDuNord(),
      source: 'cdn',
    },
    hosman: {
      isCurrentEstimateur: useIsHosman(),
      source: 'hosman',
    },
    public: {
      isCurrentEstimateur: useIsEstimateurPublic(),
      source: 'public',
    },
  };

  const currentEstimateur = Object.values(estimateursConfig).find(
    estimateurConfig => estimateurConfig.isCurrentEstimateur,
  );

  return currentEstimateur?.source;
};

type WhiteLabelPartner = {
  isCurrentEstimateur: boolean;
  label: string;
};

export const useWhiteLabelPartner = (): WhiteLabelPartner | undefined => {
  const estimateursConfig: {
    [K in Estimateur]?: WhiteLabelPartner;
  } = {
    cdn: {
      isCurrentEstimateur: useIsCreditDuNord(),
      label: 'Crédit du Nord',
    },
    hosman: {
      isCurrentEstimateur: useIsHosman(),
      label: 'Hosman',
    },
  };

  return Object.values(estimateursConfig).find(
    estimateurConfig => estimateurConfig.isCurrentEstimateur,
  );
};

export const newEstimationBasePath = 'new';
