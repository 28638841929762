import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { Pagination } from '@travauxlib/shared/src/components/Pagination';
import { usePagination } from '@travauxlib/shared/src/hooks/usePagination';
import { useScrollStatus } from '@travauxlib/shared/src/hooks/useScrollStatus';

import { useSorting } from './hooks/useSorting';
import { TableHeaderRow } from './TableHeaderRow';
import { TableRow } from './TableRow';
import { ColumnConfig, Item, RowAction, SizePaddingClassName } from './types';
export type { ColumnConfig } from './types';

type Props<T> = {
  className?: string;
  title?: string;
  size?: SizePaddingClassName;
  columnConfigs: ColumnConfig<T>[];
  items: Item<T>[];
  onRowClick?: (item: T) => void;
  rowActions?: (item: T) => RowAction[];
  // TODO prevent the use of fixFirstColumn if an item has additionalRowContent prop
  fixedColumn?: 'first' | 'last';
  renderAdditionalRowContent?: (item: T) => JSX.Element;
  itemsPerPage?: number;
  defaultSort?: { columnKey: keyof T; order: 'asc' | 'desc' };
  wrapLines?: boolean;
  inline?: boolean;
  autoResetPagination?: boolean;
  disableRow?: (item: T) => boolean;
  footer?: JSX.Element;
};

export const Table = <T extends object>({
  className,
  title,
  size = 'md',
  columnConfigs,
  items,
  onRowClick,
  disableRow,
  rowActions,
  fixedColumn,
  renderAdditionalRowContent,
  itemsPerPage,
  defaultSort,
  wrapLines,
  inline = false,
  autoResetPagination,
  footer,
}: Props<T>): JSX.Element => {
  const [sortedItems, onSortChanged, shouldDisplayChevron] = useSorting<Item<T>>(
    items,
    defaultSort,
  );
  const pagination = usePagination(sortedItems, itemsPerPage || Infinity, autoResetPagination);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | undefined>(undefined);
  const contentRef = useRef<HTMLDivElement>(null);
  const { hasHorizontalScroll } = useScrollStatus(contentRef);

  const hasExpandableRows =
    items.some(item => item.additionalRowContent) || !!renderAdditionalRowContent;

  useEffect(() => {
    setExpandedRowIndex(undefined);
  }, [pagination.currentPage]);

  return (
    <div className={classNames('bg-neutral-0 ', className)}>
      {!inline && title && <div className="text-ds-h5 mb-md px-md">{title}</div>}
      <div className="overflow-x-auto" ref={contentRef}>
        <table className="w-full border-separate border-spacing-0">
          {!inline && (
            <thead>
              <TableHeaderRow<T>
                size={size}
                columnConfigs={columnConfigs}
                // Pas de fixage de head pour la dernière colonne, ça décale tout quand le titre est vide
                fixedColumn={fixedColumn === 'first'}
                isHorizontallyScrollable={hasHorizontalScroll}
                onSortChanged={columnName => {
                  setExpandedRowIndex(undefined);
                  onSortChanged(columnName);
                }}
                shouldDisplayChevron={shouldDisplayChevron}
              />
            </thead>
          )}
          <>
            {pagination.items.map((item, index) => (
              <TableRow<T>
                disableRow={disableRow}
                wrapLines={wrapLines}
                key={`row-${index}`}
                item={item}
                rowIndex={index}
                size={size}
                additionalRowContent={item.additionalRowContent}
                renderAdditionalRowContent={renderAdditionalRowContent}
                onClickExpand={() =>
                  setExpandedRowIndex(expandedRowIndex =>
                    expandedRowIndex === index ? undefined : index,
                  )
                }
                isExpanded={expandedRowIndex === index}
                columnConfigs={columnConfigs}
                rowActions={rowActions}
                fixedColumn={fixedColumn}
                hasHorizontalScroll={hasHorizontalScroll}
                hasExpandableRows={hasExpandableRows}
                onRowClick={onRowClick}
                inline={inline}
              />
            ))}
          </>
          {footer && <tfoot className="[&_td]:py-sm">{footer}</tfoot>}
        </table>
      </div>
      {pagination.hasMultiplePages && (
        <div className="flex justify-end">
          <Pagination {...pagination} />
        </div>
      )}
    </div>
  );
};
