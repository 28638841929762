import { useQuery } from '@tanstack/react-query';
import { Stripe } from 'stripe';

import { request } from '@travauxlib/shared/src/utils/request';

const GET_DIRECT_PAYMENT_ACCOUNT_KEY = 'getDirectPaymentAccountKey';

export const useGetDirectPaymentAccount = (company: {
  stripeConnectAccountId?: string;
  slug: string;
}): {
  account?: Stripe.Account;
} => {
  const { data: account } = useQuery({
    queryKey: [GET_DIRECT_PAYMENT_ACCOUNT_KEY],
    queryFn: async (): Promise<Stripe.Account> =>
      request(`${APP_CONFIG.apiURL}/entreprises/${company.slug}/direct-payment`, {
        method: 'GET',
      }),
    staleTime: Infinity,
    enabled: !!company.stripeConnectAccountId,
  });

  return { account };
};
