import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

const COMPANY_CHIPS_KEY = 'PROJECT_COMPANY_CHIPS';

type ApiReturn = {
  title: string;
  isArchitecte: boolean;
  isBET: boolean;
  entityUuid: string;
}[];

export const useCompanyChip = (
  projectUuid: string,
  entityType: string,
): { isLoading: boolean; data: ApiReturn } => {
  const { isLoading, data } = useQuery<ApiReturn>({
    queryKey: [COMPANY_CHIPS_KEY, projectUuid, entityType],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/client/project/${projectUuid}/company-chips/${entityType}`),
  });

  return { isLoading, data: data || [] };
};
