import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

type Params = {
  montantTotalChantier: number;
  montantAvancementGlobalTTCWithCorrection: number;
  montantDejaPaye: number;
  threshold?: number;
};

type Return = {
  montantAvancementWithThreshold: number;
  montantGele: number;
};

export const getMontantAvancementWithThresholdAndMontantGele = ({
  montantTotalChantier,
  montantAvancementGlobalTTCWithCorrection,
  montantDejaPaye,
  threshold,
}: Params): Return => {
  const montantPlafond = threshold
    ? roundToTwoDecimals(montantTotalChantier * (threshold / 100))
    : montantAvancementGlobalTTCWithCorrection;

  const montantAvancementWithThreshold = Math.min(
    montantPlafond,
    montantAvancementGlobalTTCWithCorrection,
  );

  // Can't have more frozen money than the rest to pay
  const maxMontantGele = montantAvancementGlobalTTCWithCorrection - montantDejaPaye;
  const montantGele = Math.min(
    montantAvancementGlobalTTCWithCorrection - montantPlafond,
    maxMontantGele,
  );

  return { montantAvancementWithThreshold, montantGele: montantGele > 0 ? montantGele : 0 };
};
