import React from 'react';

import { Field } from 'react-final-form';

import { PlacesAutocomplete as PlacesAutoCompleteComponent } from '@travauxlib/shared/src/components/PlacesAutocomplete';

import { useIsLocationAddressable } from 'api/useIsLocationAddressable';
import { LocalisationAnswer } from 'features/OnboardingSolide/types';

type Props = {
  id: string;
  label: string;
  setNextStep: () => void;
};

export const LocalisationQuestion: React.FC<Props> = ({ id, label, setNextStep }) => {
  const validateLocation = useIsLocationAddressable();

  return (
    <Field<LocalisationAnswer>
      name={id}
      id={id}
      render={({ input: { onChange } }) => (
        <PlacesAutoCompleteComponent
          id={id}
          label={label}
          types={['postal_code']}
          onChange={async value => {
            if (!value) {
              return;
            }

            const { ok: isLocationAddressable } = await validateLocation(value);
            onChange({ value, isLocationAddressable });

            setNextStep();
          }}
          googleApiKey={APP_CONFIG.googleApiKey}
          autoFocus
          resetOnBlur
          onlyAllowNumber
        />
      )}
    />
  );
};
