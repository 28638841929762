import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { Textarea as RawTextarea, Props as TextareaProps } from '.';

const TextareaWrapper: React.FC<FieldRenderProps<string>> = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  id,
  ...rest
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <RawTextarea
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error && touched ? error : undefined}
      {...rest}
    />
  );
};
type Props = {
  name: string;
} & Omit<TextareaProps, 'value'> &
  UseFieldConfig<string>;

export const TextareaField: React.FC<Props> = props => (
  <Field component={TextareaWrapper} {...props} />
);
