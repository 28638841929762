import React from 'react';

import { CertificationRGE } from '@travauxlib/shared/src/types';
import { CustomCertification } from '@travauxlib/shared/src/types/company';
import {
  certifications,
  certificationsRGE,
} from '@travauxlib/shared/src/utils/selfDeclaredCertifications';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

type Props = {
  selfDeclaredCertifications: string[];
  customCertifications: CustomCertification[];
  proCompanyCertificationsRGE?: CertificationRGE[];
};

export const Certifications: React.FC<Props> = ({
  selfDeclaredCertifications,
  customCertifications,
  proCompanyCertificationsRGE,
}) => {
  const filteredCertifications = certifications.filter(certification =>
    selfDeclaredCertifications.includes(certification.name),
  );

  if (
    !filteredCertifications.length &&
    !customCertifications.length &&
    !proCompanyCertificationsRGE?.length
  ) {
    return null;
  }

  return (
    <div className="flex flex-wrap mt-lg mb-xl overflow-auto">
      {filteredCertifications.map(certification => (
        <img
          key={certification.name}
          src={`${baseImgixUrl}/static/logo-certifications/${certification.image}`}
          alt=""
          className="mr-md mb-sm max-h-[2.5rem]"
        />
      ))}
      {customCertifications.map(customCertification => (
        <img
          key={customCertification.uuid}
          src={customCertification.imageUrl}
          alt={customCertification.name}
          className="mr-md mb-sm max-h-[2.5rem]"
        />
      ))}
      {proCompanyCertificationsRGE?.map(certification => {
        const certificationDisplay = certificationsRGE.find(
          certif => certif.type === certification.certificationType,
        )!;

        return (
          <img
            key={certificationDisplay.name}
            src={`${baseImgixUrl}/static/logo-certifications/${certificationDisplay.image}`}
            alt={certificationDisplay.name}
            className="mr-md mb-sm max-h-[2.5rem]"
          />
        );
      })}
    </div>
  );
};
