import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { SUIVI_CHANTIERS_KEY } from '@travauxlib/shared/src/features/SuiviChantier/api/createSuiviChantierHistoryList';
import { request } from '@travauxlib/shared/src/utils/request';

import { SuiviChantierIntervenant } from '../types';

type Payload = {
  chantierUuid: string;
  formValues: string;
};

export const createUseSaveSuiviChantierDraft =
  (actor: SuiviChantierIntervenant, APP_CONFIG: { apiURL: string }) =>
  (): UseMutateAsyncFunction<void, void, Payload> => {
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation<void, void, Payload>({
      mutationFn: ({ chantierUuid, formValues }) =>
        request(`${APP_CONFIG.apiURL}/${actor}/chantiers/${chantierUuid}/suivi-chantier-draft`, {
          method: 'POST',
          body: formValues,
        }),

      onSuccess: (_, { chantierUuid, formValues }) => {
        queryClient.setQueryData(
          [SUIVI_CHANTIERS_KEY, chantierUuid, 'draft'],
          JSON.parse(formValues),
        );
      },
    });

    return mutateAsync;
  };
