import React, { useState } from 'react';

import _sum from 'lodash/sum';

import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Lot, MontantTVA } from '@travauxlib/shared/src/types';
import { shouldDisplayAutoliquidation } from '@travauxlib/shared/src/utils/shouldDisplayAutoliquidation';

import { MontantsTVA } from './MontantsTVA';

type Props = {
  montantsTVA: MontantTVA[];
  hideDetailsTVA?: boolean;
  displayDetailsInTooltip?: boolean;
  lots: Lot[];
};

export const TotalTVA: React.FC<Props> = ({
  montantsTVA,
  hideDetailsTVA,
  displayDetailsInTooltip,
  lots,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sumTVA = _sum(montantsTVA.map(m => m.montant));

  return (
    <>
      <div
        className="flex justify-between items-center text-ds-b2"
        role="section"
        aria-label="Total TVA"
      >
        <div className="flex items-center">
          Total&nbsp;TVA{' '}
          {!hideDetailsTVA &&
            (displayDetailsInTooltip ? (
              <WithTooltip
                trigger={
                  <span className="flex items-center ml-xxs w-md">
                    <HelpCircleOutline />
                  </span>
                }
              >
                {shouldDisplayAutoliquidation(lots, montantsTVA) ? (
                  <span>Autoliquidation</span>
                ) : (
                  <MontantsTVA montantsTVA={montantsTVA} darkTheme />
                )}
              </WithTooltip>
            ) : (
              <button
                type="button"
                className="py-0 px-md hover:underline bg-transparent text-neutral-700"
                onClick={() => setIsOpen(isOpen => !isOpen)}
              >
                {isOpen ? 'Cacher' : 'Détails'}
              </button>
            ))}
        </div>
        <div data-testid="totalTVA">
          <EURCurrency amount={sumTVA} />
        </div>
      </div>
      {isOpen && (
        <div className="bg-neutral-0">
          <MontantsTVA montantsTVA={montantsTVA} />
        </div>
      )}
    </>
  );
};
