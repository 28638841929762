import React from 'react';

import classNames from 'classnames';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';

import { RowAction } from './types';
import { columnClassName, verticalLastColShadowClassName } from './utils';

import { IconButton } from '../Buttons/IconButton';

type Props = {
  rowActions: RowAction[];
  fixedColumn?: 'first' | 'last';
};

export const ActionsColumn: React.FC<Props> = ({ rowActions, fixedColumn }) => (
  <td
    className={classNames(
      columnClassName,
      fixedColumn === 'last' && verticalLastColShadowClassName,
    )}
  >
    <ActionList
      small
      trigger={(toggle, referenceProps) => (
        <IconButton
          onClick={toggle}
          aria-label="Actions"
          data-testid="open-actions-menu"
          className="!p-0 !rounded-full !hover:bg-neutral-100 !hover:text-neutral-500"
          {...referenceProps}
        >
          <DotsMenu />
        </IconButton>
      )}
      items={rowActions.map(({ action, ...rest }) => ({
        ...rest,
        type: 'action',
        onClick: action,
      }))}
    />
  </td>
);
