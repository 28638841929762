import React from 'react';

import { SuiviChantierClient } from 'types';

import { SuiviChantierWrapper } from './components/SuiviChantierWrapper';
import { WithSuiviChantier } from './hocs/WithSuiviChantier';

type Props = {
  suiviChantier: SuiviChantierClient;
};

const RawSuiviChantier: React.FC<Props> = ({ suiviChantier }) => (
  <SuiviChantierWrapper suiviChantier={suiviChantier} />
);

export const SuiviChantier = WithSuiviChantier(RawSuiviChantier);
