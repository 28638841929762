import { useQuery } from '@tanstack/react-query';

import { SUIVI_CHANTIERS_KEY } from '@travauxlib/shared/src/features/SuiviChantier/api/createSuiviChantierHistoryList';
import {
  FormData,
  SuiviChantierIntervenant,
} from '@travauxlib/shared/src/features/SuiviChantier/types';
import { request } from '@travauxlib/shared/src/utils/request';

interface ApiReturn {
  isLoading: Boolean;
  suiviChantierDraft?: FormData;
}

export const createUseSuiviChantierDraft =
  (actor: SuiviChantierIntervenant, APP_CONFIG: { apiURL: string }) =>
  (chantierUuid: string): ApiReturn => {
    const { isLoading, data, fetchStatus } = useQuery<FormData>({
      queryKey: [SUIVI_CHANTIERS_KEY, chantierUuid, 'draft'],
      queryFn: () =>
        request(`${APP_CONFIG.apiURL}/${actor}/chantiers/${chantierUuid}/suivi-chantier-draft`),
    });

    return { isLoading: isLoading && fetchStatus !== 'idle', suiviChantierDraft: data };
  };
