import { useEffect } from 'react';

import { sendEventToTrackingModules } from 'utils/tracking';

import { JourneyEvents } from '../types';

export const useInscriptionStartedLegacyTracking = (
  isEventAlreadyTriggered: boolean,
  setIsEventAlreadyTriggered: (isEventAlreadyTriggered: boolean) => void,
): void =>
  useEffect(() => {
    if (!isEventAlreadyTriggered) {
      setIsEventAlreadyTriggered(true);
      sendEventToTrackingModules({ event: JourneyEvents.inscriptionStartedEvent });
    }
  }, []);
