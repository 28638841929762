import _groupBy from 'lodash/groupBy';
import _sum from 'lodash/sumBy';

import { LigneStatus, MontantTVA, Remises, TypeRemise } from '@travauxlib/shared/src/types';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { SuiviLigne, SuiviLot } from '../types';

const withRemise = (ligne: SuiviLigne, pourcentageRemise: number): SuiviLigne => ({
  ...ligne,
  prixHT: ligne.prixHT * (1 - pourcentageRemise / 100),
  prixHTWithoutFournitures: ligne.prixHTWithoutFournitures * (1 - pourcentageRemise / 100),
  prixFournituresHT: ligne.prixFournituresHT * (1 - pourcentageRemise / 100),
});

const computeTVAByAvancement = (ligne: SuiviLigne): number => {
  const tauxTva = ligne.tauxTVA;

  const montantTVACul = ligne.prixHTWithoutFournitures * (tauxTva / 100);
  const montantTVAFournitures = ligne.prixFournituresHT * (tauxTva / 100);

  const montantTVACulByAvancement = montantTVACul * (ligne.pourcentageAvancement / 100);
  const montantTVAFournituresByAvancement = ligne.hasFournitures ? montantTVAFournitures : 0;

  const trueTotal = montantTVACulByAvancement + montantTVAFournituresByAvancement;

  // Safeguard. Whatever rounding error shenanigans, always bill at most what the client signed on devis
  return roundToTwoDecimals(trueTotal) >= ligne.montantTVA ? ligne.montantTVA : trueTotal;
};

const getMontantByAvancementForLigne = (
  ligne: SuiviLigne,
  isPreviousCycleAvancement: boolean = false,
): number => {
  const poucentageAvancement = isPreviousCycleAvancement
    ? ligne.previousCyclePourcentageAvancement
    : ligne.pourcentageAvancement;
  const hasFournitures = isPreviousCycleAvancement
    ? ligne.previousCycleHasFournitures
    : ligne.hasFournitures;

  const trueTotal =
    ligne.prixHTWithoutFournitures * (poucentageAvancement / 100) +
    (hasFournitures ? ligne.prixFournituresHT : 0);

  // Safeguard. Whatever rounding error shenanigans, always bill at most what the client signed on devis
  return roundToTwoDecimals(trueTotal) >= ligne.prixHT ? ligne.prixHT : trueTotal;
};

const getMontantByAvancementForLignes = (
  lignes: SuiviLigne[],
  isPreviousCycleAvancement: boolean = false,
): number =>
  _sum(lignes, ligne => getMontantByAvancementForLigne(ligne, isPreviousCycleAvancement));

export const getMontantHTByAvancementForLots = (
  lots: SuiviLot[],
  isPreviousCycleAvancement: boolean = false,
): number =>
  _sum(lots, lot =>
    getMontantByAvancementForLignes(
      lot.lignes.filter(ligne => ligne.status !== LigneStatus.Free),
      isPreviousCycleAvancement,
    ),
  );

const getAvancementForLignes = (lignes: SuiviLigne[]): number => {
  const positivedLignes = lignes.map(ligne => ({
    ...ligne,
    prixHT: Math.abs(ligne.prixHT),
    prixHTWithoutFournitures: Math.abs(ligne.prixHTWithoutFournitures),
    prixFournituresHT: Math.abs(ligne.prixFournituresHT),
  }));
  const montantTotalHT = _sum(positivedLignes, 'prixHT');
  const montantTotalAvancementHT = getMontantByAvancementForLignes(positivedLignes);

  return (montantTotalAvancementHT / montantTotalHT) * 100;
};

export const getAvancementForLots = (lots: SuiviLot[]): number => {
  const lignes = lots.flatMap(lot => lot.lignes);

  return Math.floor(getAvancementForLignes(lignes));
};

export const parseFormDataToGetLots = (formData: Record<string, SuiviLot>): SuiviLot[] =>
  Object.values(formData);

// TODO: Fixer le calcul de la TVA. Distribuer la TVA sur la main d'oeuvre + materiaux. Et fourniture
// TODO: Pour pouvoir proprement la calculer
export const createMontantsTVA = (lots: SuiviLot[], remises: Remises): MontantTVA[] => {
  const remiseSum = _sum(remises.values, 'value');
  const allLignes = lots.flatMap(lot => lot.lignes);
  const allLignesWithoutTVA0 = allLignes.filter(ligne => ligne.tauxTVA != 0);

  if (remises.type === TypeRemise.Empty || remises.type === TypeRemise.Pourcentage) {
    const groupedLignesByTauxTVA = _groupBy(allLignesWithoutTVA0, 'tauxTVA');

    return Object.entries(groupedLignesByTauxTVA).map(([taux, lignes]) => {
      const tauxTVA = parseFloat(taux);
      const prixTotalHT = _sum(lignes, ligne =>
        getMontantByAvancementForLigne(withRemise(ligne, remiseSum)),
      );

      const montantTVA = (prixTotalHT * tauxTVA) / 100;

      return {
        taux: tauxTVA,
        base: roundToTwoDecimals(prixTotalHT),
        montant: roundToTwoDecimals(montantTVA),
      };
    });
  } else {
    const groupedLignesByTauxTVA = _groupBy(allLignesWithoutTVA0, 'tauxTVA');
    const prixTotalTTCAvantRemise = _sum(allLignes, 'prixTTC');
    const pourcentageRemise =
      100 * (1 - (prixTotalTTCAvantRemise - remiseSum) / prixTotalTTCAvantRemise);

    return Object.entries(groupedLignesByTauxTVA).map(([taux, lignes]) => {
      const montantTVALignesAvecRemise =
        _sum(lignes, ligne => computeTVAByAvancement(ligne)) * (1 - pourcentageRemise / 100);
      const prixTotalHT =
        _sum(lignes, ligne => getMontantByAvancementForLigne(ligne)) *
        (1 - pourcentageRemise / 100);

      const tauxTVA = parseFloat(taux);

      return {
        taux: tauxTVA,
        base: roundToTwoDecimals(prixTotalHT),
        montant: roundToTwoDecimals(montantTVALignesAvecRemise),
      };
    });
  }
};

export const hasLigneChanged = (ligne: SuiviLigne): boolean =>
  ligne.pourcentageAvancement !== ligne.previousCyclePourcentageAvancement ||
  ligne.hasFournitures !== ligne.previousCycleHasFournitures;

const doesLotContainsAChange = (lot: SuiviLot): boolean => lot.lignes.some(hasLigneChanged);

export const doesSuiviContainsAChange = (lots: SuiviLot[]): boolean =>
  lots.some(doesLotContainsAChange);
