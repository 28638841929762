import React from 'react';

import { partenairesPremiumDevisUrl } from 'utils/urls';

type SectionProps = {
  title: string;
  images: string[];
  className: string;
};

const LogoPartenaire: React.FC<LogoProps> = ({ className, image }) => (
  <div className={`flex align-items-center justify-center mb-md ${className}`}>
    <img src={image} alt={image} className="min-w-full" data-testid="partenaire-img" />
  </div>
);

const SectionPartenaire: React.FC<SectionProps> = ({ title, images, className }) => (
  <div className="mb-xl break-inside-avoid">
    <h3 className="mb-lg">{title}</h3>
    <div className="flex justify-center">
      {images.map(image => (
        <LogoPartenaire key={image} className={className} image={image} />
      ))}
    </div>
  </div>
);

type LogoProps = {
  image: string;
  className: string;
};

export const Partenaires: React.FC = () => (
  <div className="text-center">
    <div className="mb-lg">
      hemea vous recommande ses partenaires premium, sélectionnés pour la qualité de leurs services
      et de leurs matériaux, ainsi que leur durabilité dans le temps.
    </div>
    <SectionPartenaire
      title="PARTENAIRES PREMIUM"
      images={partenairesPremiumDevisUrl}
      className="w-full px-md max-w-4/12"
    />
  </div>
);
