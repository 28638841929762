import React from 'react';

import { Hemea } from './components/Hemea';
import { Partenaires } from './components/Partenaires';

export const MarketingHemea: React.FC = () => (
  <div className="break-inside-avoid">
    <Hemea />
    <Partenaires />
  </div>
);
