import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { CategoryName, QuestionProps } from 'features/Estimateur/types';

import { ItemAdder } from '../ItemAdder';
import { Title } from '../QuestionTitle';

type Mur = {
  longueur: number;
  murPorteur: boolean;
};
type MurARajouter = Pick<Mur, 'longueur'>;

type FormValues = {
  mursADemolir: Mur[];
  mursARajouter: MurARajouter[];
  answerType: CategoryName;
};

export const Cloisonnement: React.FC<QuestionProps<FormValues>> = ({
  submitAnswer,
  initialValues,
}) => (
  <Form<FormValues>
    initialValues={initialValues}
    onSubmit={submitAnswer}
    mutators={{ ...arrayMutators }}
  >
    {({ handleSubmit, valid, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="mb-[3.5rem]">
          <Title title="Murs et cloisonnement" />
          <div className="mb-xl">
            <ItemAdder<Mur>
              name="mursADemolir"
              newItem={{ longueur: 1, murPorteur: false }}
              label="Murs à démolir"
              deleteLabel="Ajouter un mur à ouvrir ou démolir"
            >
              {({ name, index, deleteButton }) => (
                <>
                  <div className="-mx-md mb-md flex flex-wrap !items-center">
                    <label
                      className="w-full px-md sm-desktop:max-w-3/12"
                      htmlFor={`${name}.longueur`}
                    >
                      Mur {index + 1}
                    </label>
                    <div className="w-full max-w-5/12 px-md sm-desktop:max-w-3/12">
                      <CheckboxField name={`${name}.murPorteur`} label="Mur porteur" />
                    </div>
                    <div className="w-full max-w-5/12 px-md sm-desktop:max-w-4/12">
                      <NumberInputField
                        name={`${name}.longueur`}
                        id={`${name}.longueur`}
                        label="Longueur"
                        validate={required}
                        min={0}
                        suffix="m"
                        initialValue={1}
                      />
                    </div>
                    <div className="w-full max-w-2/12 px-md">{deleteButton}</div>
                  </div>
                </>
              )}
            </ItemAdder>
          </div>
          <ItemAdder<MurARajouter>
            name="mursARajouter"
            newItem={{ longueur: 1 }}
            label="Cloisons à monter"
            deleteLabel="Ajouter une cloison à monter"
          >
            {({ name, index, deleteButton }) => (
              <React.Fragment key={name}>
                <div className="-mx-md mb-md flex flex-wrap !items-center">
                  <label
                    className="w-full max-w-5/12 px-md sm-desktop:max-w-3/12"
                    htmlFor={`${name}.longueur`}
                  >
                    Cloison {index + 1}
                  </label>
                  <div className="w-full max-w-5/12 px-md sm-desktop:max-w-4/12">
                    <NumberInputField
                      name={`${name}.longueur`}
                      id={`${name}.longueur`}
                      label="Longueur"
                      min={0}
                      validate={required}
                      suffix="m"
                      initialValue={1}
                    />
                  </div>
                  <div className="w-full max-w-2/12 px-md">{deleteButton}</div>
                </div>
              </React.Fragment>
            )}
          </ItemAdder>
        </div>
        <div className="text-center">
          <Button type="submit" disabled={!valid || submitting} loading={submitting}>
            Valider
          </Button>
        </div>
      </form>
    )}
  </Form>
);
