import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import {
  Tag,
  Props as TagProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  icon: React.ReactNode;
  title: string;
  version: number;
  tag: { label: string; variant: TagProps['variant'] };
  prixTotalTTC: number;
  dateSection: React.ReactNode;
  to: string;
  className?: string;
};

export const DevisCardExtended: React.FC<Props> = ({
  icon,
  title,
  version,
  tag,
  prixTotalTTC,
  dateSection,
  to,
  className,
}) => (
  <Card className={className} bodyClassNames="flex flex-col" aria-label={title} role="section">
    <div className="flex gap-xs text-neutral-800 mb-md">
      <ColoredCircle size="lg" variant="beige">
        {icon}
      </ColoredCircle>
      <div className="flex flex-col grow text-ds-b2 pt-3xs">
        <div className="flex justify-between gap-xs items-start">
          <div className="text-ds-b1 font-bold">{title}</div>
          <Tag {...tag} />
        </div>
        <div className="text-neutral-600 mb-xxs pb-3xs">Version {version}</div>
        <div className="flex justify-between gap-xs">
          <span>Montant</span>
          <EURCurrency className="font-medium" amount={prixTotalTTC} />
        </div>
        <div className="flex justify-between gap-xs">{dateSection}</div>
      </div>
    </div>
    <ButtonLink className="w-full tablet:w-auto self-end" to={to}>
      Afficher le devis
    </ButtonLink>
  </Card>
);
