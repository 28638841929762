import { useEffect, useState } from 'react';

import _orderBy from 'lodash/orderBy';

import { Sorting } from '../types';

export function useSorting<T extends object>(
  items: T[],
  defaultSort?: Sorting<T>,
): [T[], (columnKey: keyof T) => void, (columnKey: keyof T, direction: 'asc' | 'desc') => boolean] {
  const [sortedBy, setSortedBy] = useState<Sorting<T> | undefined>(
    defaultSort ||
      (items[0]?.hasOwnProperty('createdAt')
        ? {
            columnKey: 'createdAt' as keyof T,
            order: 'desc',
          }
        : undefined),
  );
  const [sortedItems, setSortedItems] = useState<T[]>(items);

  useEffect(() => {
    setSortedItems(sortedBy ? _orderBy(items, sortedBy?.columnKey, sortedBy.order) : items);
  }, [items]);

  const onSortChanged = (columnKey: keyof T): void => {
    const order = columnKey === sortedBy?.columnKey && sortedBy.order === 'asc' ? 'desc' : 'asc';

    setSortedItems(_orderBy(sortedItems, columnKey, order));
    setSortedBy({ order, columnKey });
  };

  const shouldDisplayChevron = (columnKey: keyof T, direction: 'asc' | 'desc'): boolean =>
    columnKey === sortedBy?.columnKey && sortedBy.order === direction;

  return [sortedItems, onSortChanged, shouldDisplayChevron];
}
