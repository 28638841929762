import React from 'react';

import BlueprintOutline from '@travauxlib/shared/src/components/DesignSystem/assets/BlueprintOutline.svg?react';
import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import ChatOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ChatOutline.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/Construction.svg?react';
import Draw from '@travauxlib/shared/src/components/DesignSystem/assets/Draw.svg?react';
import DrawOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DrawOutline.svg?react';
import EcoOutline from '@travauxlib/shared/src/components/DesignSystem/assets/EcoOutline.svg?react';
import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import PhotoCameraOutline from '@travauxlib/shared/src/components/DesignSystem/assets/PhotoCameraOutline.svg?react';
import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import StyleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/StyleOutline.svg?react';
import { ClientActionType } from '@travauxlib/shared/src/types';

export const actionIconMapper: { [K in ClientActionType]: React.ReactNode } = {
  [ClientActionType.TakeRdv]: <Calendar />,
  [ClientActionType.AddDpe]: <EcoOutline />,
  [ClientActionType.AddPlans]: <BlueprintOutline />,
  [ClientActionType.AddPhotos]: <PhotoCameraOutline />,
  [ClientActionType.SeeGuides]: <ChatOutline />,
  [ClientActionType.AddInspirations]: <StyleOutline />,
  [ClientActionType.SeeArchiDevis]: <Quote />,
  [ClientActionType.SeeArchiDevisTs]: <Quote />,
  [ClientActionType.SeeProDevis]: <Quote />,
  [ClientActionType.SeeProDevisTs]: <DrawOutline />,
  [ClientActionType.AddProvisions]: <Euro />,
  [ClientActionType.ValidateInitialAdp]: <Euro />,
  [ClientActionType.SeeSuiviChantier]: <Construction />,
  [ClientActionType.ValidateReceptionChantier]: <Draw />,
  [ClientActionType.ValidateLeveeReserves]: <Draw />,
};
