import React from 'react';

import classNames from 'classnames';

type Props = {
  className: string;
  width?: number;
  disabled?: boolean;
};

export const Trail: React.FC<Props> = ({ className, width, disabled }) => (
  <div
    className={classNames(
      'absolute h-xs top-xxs rounded-xxs',
      { '!bg-neutral-300': disabled },
      { 'w-full': width === undefined },
      className,
    )}
    style={width ? { width: `${width}%` } : {}}
  />
);
