import React from 'react';

import { Form } from 'react-final-form';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Remises, TypeRemise } from '@travauxlib/shared/src/types';

import { RemiseSelector } from './RemiseSelector';
import { TotalSummary } from './TotalSummary';

type Props = {
  totalHT: number;
  totalTTCAvantPrimes: number;
  remises: Remises;
  onChange: (remises: Remises) => void;
  totalHTAvantRemise: number;
  totalTTCAvantRemise: number;
};

export const RemiseTab: React.FC<Props> = ({
  totalHTAvantRemise,
  totalTTCAvantRemise,
  totalHT,
  totalTTCAvantPrimes,
  remises,
  onChange,
}) => (
  <Form<{ type: Remises['type'] } & Remises['values'][number]>
    initialValues={{
      type: remises.type === TypeRemise.Empty ? TypeRemise.Montant : remises.type,
    }}
    onSubmit={async (values, form) => {
      await onChange({
        type: values.type,
        values: [...remises.values, { label: values.label, value: values.value }],
      });
      setTimeout(form.restart);
    }}
  >
    {({ handleSubmit, submitting, values }) => (
      <form onSubmit={handleSubmit}>
        <TotalSummary
          className="mb-md"
          title="Avant remise"
          totalHT={totalHTAvantRemise}
          totalTTC={totalTTCAvantRemise}
        />
        <RemiseSelector
          hasAtLeastOneRemise={remises.values.length > 0}
          currentRemiseType={values.type}
          submitting={submitting}
        />
        {remises.values.length !== 0 && (
          <>
            <Table<Remises['values'][number]>
              className="mb-md"
              columnConfigs={[
                { name: 'Nom', columnKey: 'label', hideSort: true },
                {
                  name: values.type === TypeRemise.Montant ? 'Montant' : 'Pourcentage',
                  columnKey: 'value',
                  renderValue: (value: number) =>
                    values.type === TypeRemise.Montant ? (
                      <EURCurrency amount={value} />
                    ) : (
                      <span>{value}%</span>
                    ),
                  hideSort: true,
                },
                {
                  headless: true,
                  renderValue: (_, remise, index) => (
                    <IconButton
                      className="ml-auto"
                      data-testid={`delete-${remise.label}`}
                      onClick={() =>
                        onChange({
                          ...remises,
                          values: remises.values.filter((_, remiseIndex) => index !== remiseIndex),
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  ),
                  hideSort: true,
                },
              ]}
              items={remises.values}
            />
            <TotalSummary title="Après remise" totalHT={totalHT} totalTTC={totalTTCAvantPrimes} />
          </>
        )}
      </form>
    )}
  </Form>
);
