import React from 'react';

import classNames from 'classnames';
import { Link as RouterLink, Path } from 'react-router-dom';

export type GenericLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to?: string | Partial<Path>;
};

export type LinkSize = 'lg' | 'md' | 'sm' | 'xs';

type Props = GenericLinkProps & {
  classNames?: string;
  disabled?: boolean;
};

export const GenericLink: React.FC<Props> = ({
  to,
  href,
  classNames,
  disabled,
  children,
  ...rest
}) => {
  if (disabled) {
    return (
      <span className={classNames} role="link">
        {children}
      </span>
    );
  }

  return to ? (
    <RouterLink to={to} className={classNames} {...rest}>
      {children}
    </RouterLink>
  ) : (
    <a href={href} className={classNames} role="link" {...rest}>
      {children}
    </a>
  );
};

const linkStyleResetClassNames =
  'no-underline text-neutral-800 hover:no-underline hover:text-neutral-800';

export const UnstyledGenericLink: React.FC<Props> = ({ className, ...props }) => (
  <GenericLink className={classNames(linkStyleResetClassNames, className)} {...props} />
);
