import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ClientAccount } from '@travauxlib/shared/src/types';

import { openCalendly } from 'utils/calendly';
import { AppEvents, sendEventToTrackingModules } from 'utils/tracking';

import { Lead } from '../../types';

type Props = {
  accountOrLead: ClientAccount | Lead;
  calendlyUrl: string;
  calendlySource: string;
  wording?: string;
  onRdvTaken?: () => void;
};

export const CallUsButton: React.FC<Props> = ({
  accountOrLead,
  calendlyUrl,
  calendlySource,
  wording = 'Prendre un RDV téléphonique avec un expert projet (gratuit)',
  onRdvTaken,
}) => {
  const location = useLocation();
  const { email, firstName, lastName } = accountOrLead;

  useEffect(() => {
    const handleMessage = (e: any): void => {
      if (e?.data?.event === 'calendly.event_scheduled' && onRdvTaken) {
        onRdvTaken();
      }
    };
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const onClick = (): void => {
    sendEventToTrackingModules({
      event: AppEvents.calendlyButtonClicked,
      userInfo: { email, firstName, lastName },
      data: {
        category: location.pathname,
      },
    });
    openCalendly(accountOrLead, calendlyUrl, calendlySource);
  };

  return (
    <Button leftIcon={<Phone />} onClick={onClick} fullwidth>
      {wording}
    </Button>
  );
};
