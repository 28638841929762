import { Ligne, TypeLocation, DevisLocation } from '@travauxlib/shared/src/types';

export type LocationToDisplay = {
  uuid: string;
  label: string;
  typeLocation: TypeLocation;
  quantite: number;
  status?: 'added' | 'deleted';
};

export const getAllLocationsToDisplay = (
  ligne: Ligne,
  devisLocations: DevisLocation[],
): LocationToDisplay[] => {
  const currentLocations: LocationToDisplay[] = ligne.locations.flatMap(ligneLocation => {
    const location = devisLocations.find(location => location.uuid === ligneLocation.uuid);
    if (location) {
      return {
        ...location,
        ...ligneLocation,
        status:
          !!ligne.compare && !ligne.compare.locations.some(l => l.uuid === location.uuid)
            ? 'added'
            : undefined,
      };
    }
    return [];
  });

  if (!ligne.compare) {
    return currentLocations;
  }

  const commonLocations = currentLocations.filter(l => !l.status);
  const addedLocations = currentLocations.filter(l => l.status === 'added');

  const deletedLocations: LocationToDisplay[] = ligne.compare
    ? ligne.compare.locations.flatMap(ligneLocation => {
        const location = devisLocations.find(location => location.uuid === ligneLocation.uuid);
        if (location && !ligne.locations.some(l => l.uuid === ligneLocation.uuid)) {
          return [
            {
              ...location,
              ...ligneLocation,
              status: 'deleted',
            },
          ];
        }
        return [];
      })
    : [];

  return commonLocations.concat(addedLocations).concat(deletedLocations);
};
