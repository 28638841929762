import React from 'react';

import classNames from 'classnames';

import { options } from './constants';
import { CustomerSatisfactionCard } from './CustomerSatisfactionCard';

export type Props = {
  title?: string;
  onChange: (value: number) => void;
  containerClassName?: string;
} & Omit<React.HTMLProps<HTMLDivElement>, 'onChange'>;

export const CustomerSatisfaction: React.FC<Props> = ({
  value,
  id,
  disabled,
  title,
  onChange,
  containerClassName,
}) => (
  <div id={id} className={classNames(containerClassName)}>
    <div className="text-neutral-800 text-ds-b2 font-bold mb-md">
      {title ? title : 'Êtes-vous satisfait de la manière dont se déroule votre chantier ?'}
    </div>
    <div className="flex flex-wrap gap-sm">
      {options.map(option => (
        <CustomerSatisfactionCard
          selected={value === option.value}
          disabled={disabled}
          onChange={onChange}
          className={option.className}
          icon={option.icon}
          text={option.text}
          value={option.value}
          key={option.value}
        />
      ))}
    </div>
  </div>
);
