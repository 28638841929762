import React from 'react';

import classNames from 'classnames';

export type IconVariants =
  | 'primary'
  | 'beige'
  | 'secondary'
  | 'light'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

export type IconSizes =
  | '6xl'
  | '3xl'
  | '2xl'
  | 'xxl'
  | 'xl'
  | 'lg'
  | 'md'
  | 'sm'
  | 'xs'
  | 'xxs'
  | '3xs';

export type Props = {
  className?: string;
  variant?: IconVariants;
  size?: IconSizes;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

const SizesClassNames = {
  '6xl': 'w-[10rem] h-[10rem] p-[40px]',
  '3xl': 'w-[120px] h-[120px] p-[1.75rem]',
  '2xl': 'w-[120px] h-[120px] p-[1.25rem]',
  xxl: 'w-3xl h-3xl p-xs',
  xl: 'w-[56px] h-[56px] p-xs',
  lg: 'w-xxl h-xxl p-xs',
  md: 'w-[40px] h-[40px] p-xs',
  sm: 'w-xl h-xl p-[0.35rem]',
  xs: 'w-lg h-lg p-xxs',
  xxs: 'w-[20px] h-[20px] p-[0.15rem]',
  '3xs': 'w-md h-md p-[0.15rem]',
};

const VariantsClassNames = {
  primary: 'text-white bg-primary',
  beige: 'text-primary bg-beige-50',
  secondary: 'text-primary bg-beige-50',
  info: 'text-info-400 bg-info-50',
  success: 'text-success-400 bg-success-50',
  warning: 'text-warning-400 bg-warning-50',
  error: 'text-error-400 bg-error-50',
  light: 'text-neutral-400 bg-neutral-200',
};

const FontSizesClassNames = {
  '6xl': 'text-ds-h1',
  '3xl': 'text-ds-h1',
  '2xl': 'text-ds-h1',
  xxl: 'text-ds-h1',
  xl: 'text-ds-h1',
  lg: 'text-ds-h2',
  md: 'text-ds-h3',
  sm: 'text-ds-h4',
  xs: 'text-ds-b1',
  xxs: 'text-ds-b2',
  '3xs': 'text-ds-sm',
};

export const ColoredCircle: React.FC<Props> = ({
  className,
  size = 'md',
  variant,
  children,
  disabled,
  onClick,
}) => (
  <div
    data-testid="content-with-background"
    className={classNames(
      className,
      'rounded-[50%] flex items-center justify-center shrink-0',
      SizesClassNames[size],
      VariantsClassNames[variant || 'primary'],
      { '!bg-neutral-300 !text-neutral-400': disabled },
    )}
    onClick={onClick}
  >
    {typeof children === 'string' ? (
      <span className={classNames('font-bold', FontSizesClassNames[size])}>{children}</span>
    ) : (
      children
    )}
  </div>
);
