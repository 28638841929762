import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AppFeatures,
  EspaceClientEventProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class DocumentsTrackingClass extends Tracker<AppFeatures> {
  onDocumentUploaded = (properties: {
    [EspaceClientEventProperties.ProjectUuid]?: string;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Added,
      properties,
    });
  };
}

export const DocumentsTracking = new DocumentsTrackingClass(AppFeatures.Documents);
