import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Body: React.FC<Props> = ({ className = '', children }) => (
  <div className={`${className} p-4 sm-desktop:mt-4`}>
    <Card className="h-full">{children}</Card>
  </div>
);
