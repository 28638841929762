import React from 'react';

import classNames from 'classnames';

import { layoutGridMargins } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { baseImgixUrl, buildImgixURL } from '@travauxlib/shared/src/utils/urls';

type Props = {
  logoUrl?: string;
  rightLink?: JSX.Element | null;
  logoTo?: string;
  logoHref?: string;
};

export const Header: React.FC<Props> = ({ logoUrl, logoTo, logoHref, rightLink }) => (
  <header className="w-full bg-neutral-0 shadow-ds-xs z-20 sticky top-0">
    <div className={classNames(layoutGridMargins, 'flex items-center justify-between')}>
      <div className="flex items-center">
        <Link to={logoTo} href={logoHref} className="flex items-center my-md mr-sm">
          <ImgLazy
            url={`${baseImgixUrl}/static/logo-hemea-black-v2.png`}
            height={32}
            alt="Logo hemea"
            eagerLoading
          />
        </Link>
        {logoUrl && (
          <>
            <div className="h-xl w-[1px] bg-neutral-300 mr-sm" />
            <ImgLazy url={buildImgixURL(logoUrl)} alt="logo" height={32} />
          </>
        )}
      </div>
      {rightLink && <div className="flex items-center py-xs">{rightLink}</div>}
    </div>
  </header>
);
