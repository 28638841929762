import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { request } from '@travauxlib/shared/src/utils/request';

import { BonCommandeClient } from 'types';

export const BONCOMMANDE_KEY = 'BONCOMMANDE';

const fetchBonCommande = ({
  queryKey: [, { token }],
}: QueryFunctionContext<[string, { token: string }]>): Promise<BonCommandeClient> =>
  request(`${APP_CONFIG.apiURL}/client/bon-commande/${token}`);

export const useBonCommande = (
  token: string,
): { isLoading: boolean; bonCommande?: BonCommandeClient } => {
  const { isLoading, data } = useQuery<BonCommandeClient>({
    queryKey: [BONCOMMANDE_KEY, { token }],
    queryFn: fetchBonCommande,
    staleTime: Infinity,
    retry: (failureCount, error: AxiosError) => failureCount < 2 && error?.response?.status !== 404,
  });

  return {
    isLoading,
    bonCommande: data,
  };
};
