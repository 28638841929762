import React from 'react';

import { Facture } from 'types';

import { AdminFactureShow } from './AdminFactureShow';
import { ProFactureShow } from './ProFactureShow';

type Props = {
  facture: Facture;
  isPrint?: boolean;
};

export const FactureShow: React.FC<Props> = ({ facture, isPrint }) => (
  <>
    {facture.proContext && (
      <ProFactureShow facture={facture} proContext={facture.proContext} isPrint={isPrint} />
    )}
    {facture.cdplContext && (
      <AdminFactureShow facture={facture} cdplContext={facture.cdplContext} isPrint={isPrint} />
    )}
  </>
);
