import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Lead } from 'types';

export const useLastLeadCalendlyAndOwner = (): {
  isLoading: boolean;
  dealOwner?: Lead['dealOwner'];
} => {
  const { data, isLoading } = useQuery<Lead>({
    queryKey: ['lastLead'],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/lead/last`),
  });

  return {
    isLoading,
    dealOwner: data?.dealOwner,
  };
};
