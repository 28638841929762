import { SuiviLigneLegacy } from '@travauxlib/shared/src/types';

import { roundToTwoDecimals } from './format';

export const getCompletionFromLignes = (
  lignes: SuiviLigneLegacy[],
): { percentage: number; amount: number } => {
  const totalWeight = lignes.reduce(
    (total, ligne) => total + ligne.prixUnitaireHT * ligne.quantite,
    0,
  );

  if (totalWeight === 0) {
    return {
      amount: 0,
      percentage: 0,
    };
  }

  const totalAvancement = lignes.reduce(
    (total, ligne) =>
      total + (ligne.prixUnitaireHT * ligne.quantite * ligne.completionPercentage) / 100,
    0,
  );

  return {
    amount: roundToTwoDecimals(totalAvancement),
    percentage: Math.round((totalAvancement * 100) / totalWeight),
  };
};
