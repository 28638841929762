import React from 'react';

import { Field } from 'react-final-form';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { LocationsSelector } from '@travauxlib/shared/src/components/LocationsSelector';
import { DevisLocation } from '@travauxlib/shared/src/types';
import { required } from '@travauxlib/shared/src/utils/form';

type Props = {
  valid: boolean;
};

export const SelectLocations: React.FC<Props> = ({ valid }) => (
  <>
    <h3 className="font-bold mb-lg">Sélectionnez les pièces à rénover</h3>
    <div className="mb-lg">
      <Field
        name="locations"
        validate={value => value === undefined || value.length === 0}
        component={({
          input: { onChange, value: locations = [] },
        }: {
          input: {
            onChange: (newValue: DevisLocation[]) => void;
            value: DevisLocation[];
          };
        }) => (
          <LocationsSelector
            locations={locations}
            onChange={onChange}
            onDeleteLocation={onChange}
            locationCanBeDisabled
          />
        )}
      />
    </div>
    <div className="flex flex-wrap !items-center !justify-between mb-md">
      <NumberInputField
        label={
          <span className="flex items-center">
            Surface totale au sol de ces pièces
            <WithTooltip
              trigger={
                <span className="inline-flex items-center ml-xs">
                  <HelpCircle className="w-md" />
                </span>
              }
              position="bottom"
            >
              <div className="p-sm">
                Attention : indiquez la surface plancher, pas la surface Carrez
              </div>
            </WithTooltip>
          </span>
        }
        validate={required}
        id="surfaceTotale"
        name="surfaceTotale"
        min={0}
        suffix="m"
        className="w-full"
      />
    </div>
    <div className="flex flex-wrap !items-center !justify-between">
      <NumberInputField
        validate={required}
        label="Hauteur sous plafond"
        id="hauteurMur"
        name="hauteurMur"
        min={0}
        step={0.01}
        suffix="m"
        className="w-full"
      />
    </div>
    <div className="mt-xl text-right">
      <Button type="submit" disabled={!valid}>
        Suivant
      </Button>
    </div>
  </>
);
