import React from 'react';

import CrossCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircleOutline.svg?react';
import Lock from '@travauxlib/shared/src/components/DesignSystem/assets/Lock.svg?react';
import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFull.svg?react';
import StarHalf from '@travauxlib/shared/src/components/DesignSystem/assets/StarHalf.svg?react';
import StateChecked from '@travauxlib/shared/src/components/DesignSystem/assets/StateChecked.svg?react';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ChantierPvWithDocuments, PvType } from '@travauxlib/shared/src/features/Chantiers/types';

type Props = { pv?: ChantierPvWithDocuments };

// TODO Déduplique moi ! J'existe aussi dans shared même si je suis un peu différent
// À avoir avec Jalil/Eng pour essayer de fusionner tout ça!
export const PvStatusTag: React.FC<Props> = ({ pv }) => {
  const { hasReserves, isValidated, isRejected, pvType } = pv || {};

  if (hasReserves) {
    return (
      <Tag
        className="!bg-neutral-0 !p-0"
        icon={<StarHalf />}
        label="Validé avec réserves"
        variant="success"
      />
    );
  }

  if (isValidated && pvType === PvType.ReceptionChantier) {
    return (
      <Tag
        className="!bg-neutral-0 !p-0"
        icon={<StarFull />}
        label="Validé sans réserves"
        variant="success"
      />
    );
  }

  if (isValidated) {
    return (
      <Tag
        className="!bg-neutral-0 !p-0"
        icon={<StateChecked />}
        label="Validé"
        variant="success"
      />
    );
  }

  if (isRejected) {
    return (
      <Tag
        className="!bg-neutral-0 !p-0"
        icon={<CrossCircleOutline />}
        label="Refusé"
        variant="warning"
      />
    );
  }

  return <Tag className="!bg-neutral-0 !p-0" icon={<Lock />} label="En attente" variant="info" />;
};
