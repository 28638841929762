import React from 'react';

import classNames from 'classnames';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Props as TagProps } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ItemLink } from '@travauxlib/shared/src/components/ItemLink';

type Props = {
  title: string;
  items: Array<{
    title: string;
    label: string;
    price: number;
    to?: string;
    href?: string;
    tag?: TagProps;
  }>;
  className?: string;
};

export const CardWithLinks: React.FC<Props> = ({ title, items, className }) => (
  <Card
    className={classNames('rounded-md shadow-ds-sm', className)}
    bodyClassNames="pb-xxs !px-0"
    state="elevated"
    role="section"
    aria-label={title}
  >
    <div className="text-ds-b1 font-bold mb-xs px-md">{title}</div>
    {items.map(item => (
      <ItemLink
        key={item.title}
        title={item.title}
        to={item.to}
        href={item.href}
        label={item.label}
        tag={item.tag}
        price={item.price}
        rightIcon={<ChevronRight />}
      />
    ))}
  </Card>
);
