import React from 'react';

import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { FloatingComments } from './FloatingComments';
import { FloatingTooltip } from './FloatingTooltip';

import { useRevisionStore } from '../../hooks/useRevisionStore';
import { useDevisDisplayContext } from '../DevisDisplayContext';

type Props = {
  targetUuid: string;
  threadUuid?: string;
};

export const FloatingContent: React.FC<Props> = ({ targetUuid, threadUuid }) => {
  const { addComment, updateComment, deleteComment, isAuthor } = useDevisDisplayContext();
  const isTabletOrMobile = useIsTabletOrMobile();

  const { activeThread } = useRevisionStore();

  const isOpen =
    activeThread?.ligneUuid === targetUuid ||
    (threadUuid && activeThread?.threadUuid === threadUuid);

  if (!addComment || !updateComment || !deleteComment || isAuthor) {
    return null;
  }

  if (isOpen && !isTabletOrMobile) {
    return <FloatingComments targetUuid={targetUuid} threadUuid={threadUuid} />;
  }

  return <FloatingTooltip targetUuid={targetUuid} threadUuid={threadUuid} />;
};
