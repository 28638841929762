import React from 'react';

import classNames from 'classnames';

import { config } from './constants';
import { Level } from './types';

type Props = {
  level: Level;
};

export const Icon: React.FC<Props> = ({ level }) => {
  const { RawIcon, iconClassnames } = config[level];

  return (
    <span className={classNames('shrink-0 flex items-center w-md h-md', iconClassnames)}>
      <RawIcon />
    </span>
  );
};
