import { UserTracking } from '../../api/UserTracking';
import { Environments } from '../../types';
import { TrackingEvents } from '../../utils/tracking';

export type TrackingEvent<S> = {
  subFeature?: S;
  event: TrackingEvents;
  properties?: Object;
};

export function initTracking({
  token,
  enabled,
}: {
  token: string;
  enabled: boolean;
  environment: Environments;
}): void {
  UserTracking.init(token);

  // We disable the tracking when testing the application to avoid flooding mixpanel
  if (!enabled) {
    UserTracking.disable();
  }
}

export class Tracker<F, S = {}> {
  feature: F;

  constructor(feature: F) {
    this.feature = feature;
  }

  async sendTracking({ subFeature, event, properties }: TrackingEvent<S>): Promise<void> {
    const eventName = `${this.feature}${subFeature ? ` ${subFeature}` : ''}${event && ` ${event}`}`;
    return UserTracking.track(eventName, properties);
  }
}
