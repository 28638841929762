import { ComputedStepConfigs, FormValues } from '../../types';
import { onboardingAccountPath } from '../constants';

// Permet de recalculer à la volée le next step. C'est utile quand le next step est determiné par rapport
// à la réponse de la question précédente comme dans typeProjetStep
export const getNextStep =
  (rawSteps: ComputedStepConfigs) => (values: FormValues, currentIndex: number) => {
    const steps = rawSteps(values);

    return currentIndex === steps.length - 1
      ? { id: onboardingAccountPath }
      : steps[currentIndex + 1];
  };
