import { Config, Level } from './types';

import AlertCircle from '../../assets/AlertCircle.svg?react';
import CheckCircle from '../../assets/CheckCircle.svg?react';
import InfoCircle from '../../assets/InfoCircle.svg?react';
import InfoCircleOutline from '../../assets/InfoCircleOutline.svg?react';
import Warning from '../../assets/Warning.svg?react';

export const config: { [key in Level]: Config } = {
  info: {
    RawIcon: InfoCircle,
    iconClassnames: 'text-info-800',
    textClassNames: 'text-info-800',
  },
  success: {
    RawIcon: CheckCircle,
    iconClassnames: 'text-success-800',
    textClassNames: 'text-success-800',
  },
  warning: {
    RawIcon: AlertCircle,
    iconClassnames: 'text-warning-800',
    textClassNames: 'text-warning-800',
  },
  error: {
    RawIcon: Warning,
    iconClassnames: 'text-error-800',
    textClassNames: 'text-error-800',
  },
  light: {
    RawIcon: InfoCircleOutline,
    iconClassnames: 'text-neutral-700',
    textClassNames: 'text-neutral-600',
  },
};
