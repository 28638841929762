import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { request } from '@travauxlib/shared/src/utils/request';

import { useIsAuthor } from 'hooks/useIsAuthor';
import { Facture } from 'types';

const fetchFacture = ({
  queryKey: [, { token, isAuthor }],
}: QueryFunctionContext<[string, { token: string; isAuthor?: boolean }]>): Promise<Facture> =>
  request(`${APP_CONFIG.apiURL}/client/factures/${token}`, {
    params: {
      isAuthor,
    },
  });

export const useFacture = (token: string): { isLoading: boolean; facture?: Facture } => {
  const isAuthor = useIsAuthor();
  const { isLoading, data } = useQuery<Facture>({
    queryKey: ['factures', { token, isAuthor }],
    queryFn: fetchFacture,
    staleTime: Infinity,
    retry: (failureCount, error: AxiosError) => failureCount < 2 && error?.response?.status !== 404,
  });

  return {
    isLoading,
    facture: data,
  };
};
