import { useState, useEffect, useMemo } from 'react';

export type PaginationType<T> = {
  hasMultiplePages: boolean;
  items: T[];
  currentPage: number;
  canGoPrevious: boolean;
  canGoNext: boolean;
  setCurrentPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  totalPages: number;
  firstItemIndex: number;
  lastItemIndex: number;
  totalElements: number;
};

// for this hook to work correctly, the items must memoized
// otherwise the useEffect will reset the currentPage to 1 every time
export const usePagination = <T extends object>(
  memoizedItems: T[],
  nbPerPage: number,
  autoResetPagination: boolean = true,
): PaginationType<T> => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (autoResetPagination) {
      setCurrentPage(1);
    }
  }, [memoizedItems, nbPerPage, autoResetPagination]);

  const slicedItems = useMemo(
    () => memoizedItems.slice(0 + (currentPage - 1) * nbPerPage, 0 + currentPage * nbPerPage),
    [memoizedItems, currentPage, nbPerPage],
  );

  const totalElements = memoizedItems.length;

  return {
    hasMultiplePages: totalElements > nbPerPage,
    items: slicedItems,
    setCurrentPage,
    currentPage,
    canGoPrevious: currentPage > 1,
    canGoNext: currentPage * nbPerPage < totalElements,
    nextPage: () => setCurrentPage(currentPage => currentPage + 1),
    previousPage: () => setCurrentPage(currentPage => currentPage - 1),
    totalPages: Math.ceil(totalElements / nbPerPage),
    totalElements,
    firstItemIndex: 1 + (currentPage - 1) * nbPerPage,
    lastItemIndex: Math.min(totalElements, currentPage * nbPerPage),
  };
};
