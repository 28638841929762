import React from 'react';

export const Logo: React.FC<{
  customSize?: string;
  className?: string;
  src?: string;
  alt?: string;
}> = ({ customSize, className, src, alt }) => (
  <img
    style={{
      maxWidth: `${customSize ? parseInt(customSize, 10) * 2 : 400}px`,
      maxHeight: `${customSize || '100'}px`,
    }}
    className={className}
    alt={alt}
    src={src}
  />
);
