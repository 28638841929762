import React from 'react';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { tvaValues } from '@travauxlib/shared/src/utils/constants';

type Props = {
  tauxTVA: number;
  className?: string;
};

export const TvaTag: React.FC<Props> = ({ tauxTVA, className }) => {
  const badge = tvaValues[tauxTVA];
  return badge ? (
    <Tag
      size="md"
      variant={badge.level}
      label={`TVA ${badge.value.toLocaleString('fr')}`}
      className={className}
      isBorder
    />
  ) : (
    // backward compatibility for undefined TVA (like existing 9.1% values)
    <Tag
      size="md"
      variant="primary"
      label={`TVA ${tauxTVA.toLocaleString('fr')}`}
      isBorder
      className={className}
    />
  );
};
