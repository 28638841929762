import React, { MouseEventHandler } from 'react';

import { Level } from './types';

import CrossSymbol from '../../assets/CrossSymbol.svg?react';
import { IconButton } from '../Buttons/IconButton';

type Props = {
  level: Level;
  onClickClose?: MouseEventHandler<HTMLButtonElement>;
  small?: boolean;
};

export const CloseButton: React.FC<Props> = ({ onClickClose, level, small }) => (
  <IconButton
    className="shrink-0 ml-xxs -mr-xxs"
    onClick={onClickClose}
    variant={level}
    size={small ? 'sm' : 'md'}
  >
    <CrossSymbol data-testid="close-banner" />
  </IconButton>
);
