import React from 'react';

import classNames from 'classnames';

import { TvaTag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/TvaTag';
import { PrixHTLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/PrixHTLabel';
import { PrixUnitaireLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/PrixUnitaireLabel';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { Ligne } from '@travauxlib/shared/src/types';

type Props = {
  ligne: Ligne;
  hidePrices: boolean;
};

export const Prices: React.FC<Props> = ({ ligne, hidePrices }) => {
  const hasChangedTauxTVA = ligne.compare && ligne.tauxTVA !== ligne.compare.tauxTVA;
  const hasChangedQuantity = ligne.compare && ligne.quantite !== ligne.compare.quantite;
  const hasChangedUnite = ligne.compare && ligne.unite !== ligne.compare.unite;
  const hasChangedUnitPrice =
    ligne.compare && ligne.prixUnitaireHT !== ligne.compare.prixUnitaireHT;
  const hasChangedPrice = ligne.compare && ligne.prixHT !== ligne.compare.prixHT;
  const hasChangedStatus = ligne.compare && ligne.status !== ligne.compare.status;

  return (
    <div className="flex flex-wrap items-end mb-sm justify-end sm-desktop:justify-start text-neutral-800">
      <div className="sm-desktop:w-1/4 mr-xs sm-desktop:mr-0">
        {hasChangedTauxTVA && (
          <div className="text-ds-sm line-through text-neutral-800">
            TVA {ligne.compare?.tauxTVA}
          </div>
        )}
        <TvaTag
          tauxTVA={ligne.tauxTVA}
          className={classNames(ligne.comparisonStatus === 'missing' && 'line-through')}
        />
      </div>
      <div className="sm-desktop:w-1/4 mr-xs sm-desktop:mr-0 text-ds-b2">
        {ligne.compare && (hasChangedQuantity || hasChangedUnite) && (
          <div className="text-ds-sm line-through text-neutral-800">
            <QuantityLabel quantite={ligne.compare.quantite} unite={ligne.compare.unite} />
          </div>
        )}
        <div
          className={classNames(
            (hasChangedQuantity || hasChangedUnite) && 'font-bold text-info-800',
          )}
        >
          <QuantityLabel quantite={ligne.quantite} unite={ligne.unite} />
        </div>
      </div>
      <div className="sm-desktop:w-1/4 text-center mr-auto sm-desktop:mr-0 text-ds-b2">
        {!hidePrices && (
          <div>
            {ligne.compare && (hasChangedUnitPrice || hasChangedUnite) && (
              <div className="text-ds-sm line-through text-neutral-800">
                <PrixUnitaireLabel
                  prixUnitaireHT={ligne.compare.prixUnitaireHT}
                  unite={ligne.compare.unite}
                />
              </div>
            )}
            <div
              className={classNames(
                (hasChangedUnitPrice || hasChangedUnite) && 'font-bold text-info-800',
              )}
            >
              <PrixUnitaireLabel prixUnitaireHT={ligne.prixUnitaireHT} unite={ligne.unite} />
            </div>
          </div>
        )}
      </div>
      <div className="ml-xs text-ds-b2 font-medium">
        {!hidePrices && (
          <div>
            {ligne.compare && (hasChangedPrice || hasChangedStatus) && (
              <div className="text-ds-sm line-through text-neutral-800">
                <PrixHTLabel prixHT={ligne.compare.prixHT} status={ligne.compare.status} />
              </div>
            )}
            <div
              className={classNames(
                (hasChangedPrice || hasChangedStatus) && 'font-bold text-info-800',
              )}
            >
              <PrixHTLabel prixHT={ligne.prixHT} status={ligne.status} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
