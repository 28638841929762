import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const HeaderContainer: React.FC<Props> = ({ children }) => (
  <div className="flex justify-between items-center gap-xs bg-neutral-0 shadow-ds-xs py-xs px-md sm-desktop:px-lg sticky top-0 z-30">
    {children}
  </div>
);
