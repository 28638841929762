import React from 'react';

import classNames from 'classnames';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';

import { IconButton } from '../Buttons/IconButton';

type Props = {
  selected?: boolean;
  disabled?: boolean;
  label: string;
  icon?: JSX.Element;
  removable?: boolean;
  onClick?: () => void;
} & Omit<React.HTMLProps<HTMLDivElement>, 'onClick'>;

export const Chip: React.FC<Props> = ({
  disabled,
  selected,
  label,
  icon,
  removable = false,
  className,
  onClick,
  ...htmlProps
}: Props) => (
  <div
    className={classNames(
      className,
      'border h-xl  rounded-full inline-flex py-xxs outline-green-400',
      icon ? 'pl-xxs' : 'pl-sm',
      removable ? 'pr-xxs' : 'pr-sm',
      {
        'border-primary bg-primary': selected && !disabled,
      },
      {
        'hover:border-primary-500 hover:bg-primary-500 active:border-primary-600 active:bg-primary-600':
          selected && !disabled && !removable,
      },
      {
        'border-neutral-300 bg-neutral-0': !selected,
      },
      {
        'hover:bg-neutral-100 active:bg-neutral-200': !selected && !removable,
      },
      { 'pointer-events-none border-neutral-300': disabled },
      removable ? 'cursor-default' : 'cursor-pointer',
    )}
    tabIndex={0}
    onClick={removable || disabled ? undefined : onClick}
    {...htmlProps}
  >
    <span
      className={classNames(
        'text-ds-b1 font-bold inline-flex items-center whitespace-nowrap',
        {
          'text-neutral-0 hover:text-neutral-100 active:text-neutral-100': selected && !disabled,
        },
        { 'text-primary': !selected && !disabled },
        { 'text-neutral-300': disabled },
      )}
    >
      {icon && <div className="inline-flex items-center h-lg w-lg mr-xs">{icon}</div>}
      {label}
      {removable && (
        <IconButton
          className="ml-xs"
          size="sm"
          variant={selected && !disabled ? 'primary' : 'white'}
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
        >
          <CrossSymbol />
        </IconButton>
      )}
    </span>
  </div>
);
