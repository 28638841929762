import React from 'react';

import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';

import { Separator } from 'components/Separator';

type Props = {
  companyName: string;
  enterpriseOwner: string;
  contractorSignatureName: string;
};

export const Signature: React.FC<Props> = ({
  companyName,
  enterpriseOwner,
  contractorSignatureName,
}) => (
  <DevisFactureCard className="mb-md">
    <div className="flex -mx-md">
      <div className="w-full px-md">
        <strong className="mb-md">De la société {companyName}</strong>
        <div>{enterpriseOwner}</div>
      </div>
      <Separator />
      <div className="w-full px-md">
        <div>
          <strong className="mb-md">Du prestataire</strong>
          <br />
          <div className="mb-lg">
            {contractorSignatureName}
            <br />
            <em className="text-gray-600">Mention &quot;Bon pour accord&quot;</em>
          </div>
        </div>
      </div>
    </div>
  </DevisFactureCard>
);
