export const predefinedLots = [
  'Études - Conception',
  'Installation de chantier - Nettoyage',
  'Démolition',
  'Terrassement - Réseaux',
  'Maçonnerie - Gros œuvre - Structure',
  'Couverture - Étanchéité - Zinguerie',
  'Menuiseries extérieures',
  'Façade',
  'Aménagements extérieurs',
  'Isolation',
  'Plâtrerie - Cloisonnement',
  'Plomberie',
  'Chauffage - Ventilation - Climatisation',
  'Électricité',
  'Carrelage - Faïence',
  'Revêtements de sols',
  'Serrurerie - Métallerie',
  'Peinture',
  'Menuiseries intérieures - Agencement',
  'Cuisine',
];

export const DEFAULT_MARGIN = 30;
