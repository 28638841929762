import React from 'react';

import classNames from 'classnames';

import {
  iconSuffixClassName,
  makeInputContainerClassnames,
  makeInputMainClassnames,
  textSuffixClassName,
} from './commons';

import { Props as InputProps } from './index';

export interface Props
  extends Pick<InputProps, 'className' | 'disabled' | 'error' | 'onClick' | 'suffix'> {
  IconValue?: React.ComponentType<React.SVGProps<SVGElement>>;
}

export const IconInput: React.FC<Props> = ({
  className,
  disabled,
  error,
  onClick,
  IconValue,
  suffix,
}: Props) => {
  const IconSuffix = typeof suffix === 'object' && suffix;
  const textSuffix = typeof suffix === 'string' && suffix;

  return (
    <>
      <div
        className={classNames(
          className,
          'pr-xxs pt-0',
          makeInputContainerClassnames({ disabled, error }),
        )}
        onClick={onClick}
      >
        <div
          className={classNames(
            '!pt-sm pl-sm !pb-sm !pr-0 !mr-0 -my-[1px]',
            makeInputMainClassnames({}),
          )}
        >
          {IconValue && <IconValue width={24} />}
        </div>
        {IconSuffix && <div className={iconSuffixClassName}>{IconSuffix}</div>}
        {textSuffix && <span className={textSuffixClassName}>{textSuffix}</span>}
      </div>
    </>
  );
};
