import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { BonCommandeClient } from 'types';

import { BonCommandeContentHeader } from './BonCommandeContentHeader';
import { Signature } from './Signature';

export type Props = {
  bonCommande: BonCommandeClient;
};

export const BonCommandeContent: React.FC<Props> = ({ bonCommande }) => (
  <div className="px-xs sm-desktop:px-xl mx-auto w-full sm-desktop:pt-[3.5rem] py-lg">
    <BonCommandeContentHeader bonCommande={bonCommande} />
    <div className="text-ds-h4 pb-md">Détails des prestations</div>
    <div className="mb-lg">
      {bonCommande.lots
        .filter(lot => lot.lignes.some(ligne => ligne.selected))
        .map((lot, lotIndex) => (
          <div key={lot.uuid} className="break-inside-avoid">
            <div className="flex font-bold pt-md">
              <div className="flex text-gray-600 text-ds-sm pt-xxs sm-desktop:pr-lg pr-xxs">{`${
                lotIndex + 1
              }.0`}</div>
              {lot.label}
            </div>
            <div className="sm-desktop:pl-lg">
              {lot.lignes
                .filter(ligne => ligne.selected)
                .map((ligne, ligneIndex) => (
                  <div
                    key={ligne.uuid}
                    className="flex w-full justify-between py-lg flex-wrap px-xs"
                  >
                    <div className="w-full md-desktop:w-3/5 flex">
                      <div className="flex text-gray-600 text-ds-sm pt-xxs pr-xxs">{`${lotIndex + 1}.${
                        ligneIndex + 1
                      }`}</div>
                      {ligne.designation}
                    </div>
                    <div className="flex w-full justify-between md-desktop:w-1/3 pt-xs md-desktop:pt-0">
                      <div className="flex w-full text-gray-600 justify-end">
                        <QuantityLabel quantite={ligne.quantite} unite={ligne.unite} />
                      </div>
                      <div className="flex justify-end w-full text-gray-600">
                        <EURCurrency amount={roundToTwoDecimals(ligne.prixUnitaireHT)} />
                      </div>
                      <div className="flex justify-end w-full">
                        <EURCurrency
                          amount={roundToTwoDecimals(ligne.prixUnitaireHT * ligne.quantite)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
    <Signature
      companyName={bonCommande.proCompany.name}
      enterpriseOwner={`${bonCommande.proCompany.firstNameRepresentantLegal} ${bonCommande.proCompany.lastNameRepresentantLegal}`}
      contractorSignatureName={bonCommande.contractor.name}
    />
  </div>
);
