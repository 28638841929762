import { GenericFacture } from '../../types';

export const getFacturationTotals = (
  linkedFactures: Omit<GenericFacture, 'lots' | 'montantsTVA' | 'isCloture' | 'isSituation'>[],
): { montantDejaPaye: number; montantRestantAPayer: number; totalTTC: number } => {
  let montantDejaPaye = 0;
  let montantRestantAPayer = 0;
  let totalTTC = 0;

  linkedFactures?.forEach(facture => {
    const prixTotalTTC = facture.isAvoir ? -facture.prixTotalTTC : facture.prixTotalTTC;
    totalTTC += prixTotalTTC;
    if (facture.status === 'paid') {
      montantDejaPaye += prixTotalTTC;
    } else {
      montantRestantAPayer += prixTotalTTC;
    }
  });

  return {
    montantDejaPaye: montantDejaPaye,
    montantRestantAPayer: montantRestantAPayer,
    totalTTC: totalTTC,
  };
};
