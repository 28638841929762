import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  GuidesClientSubFeatures,
  TrackingEvents,
  GuidesClientEventProperties,
  AppFeatures,
} from '@travauxlib/shared/src/utils/tracking';

class GuidesClientTrackingClass extends Tracker<AppFeatures, GuidesClientSubFeatures> {
  onGuidesClientConceptViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.Concept,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientGarantiesViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.Garanties,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientInterlocuteursViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.Interlocuteurs,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientAccompagnementsViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.Accompagnements,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientArchitectesViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.Architectes,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientArchitecteTabViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.ArchitecteTab,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientAchatFournituresViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.AchatFournitures,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientCompteSequestreViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.CompteSequestre,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientPrixDevisViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.PrixDevis,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientDemarrageViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.Demarrage,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientSuiviChantierViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.SuiviChantier,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientReceptionViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.Reception,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onGuidesClientRedirectionLinkClicked(properties: {
    [GuidesClientEventProperties.PageContainingLink]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.RedirectionLink,
      event: TrackingEvents.Clicked,
      properties,
    });
  }

  onGuidesClientServicesHemeaViewed(properties: {
    [GuidesClientEventProperties.ParentGuideSection]: string;
  }): void {
    this.sendTracking({
      subFeature: GuidesClientSubFeatures.ServicesHemea,
      event: TrackingEvents.Viewed,
      properties,
    });
  }
}

export const GuidesClientTracking = new GuidesClientTrackingClass(AppFeatures.GuidesClient);
