import React, { useEffect } from 'react';

import { RootPdf } from 'components/RootPdf';
import { FactureShow } from 'features/Factures/components/FactureShow';
import { Facture } from 'types';

type Props = {
  facture: Facture;
};

export const FacturePrint: React.FC<Props> = ({ facture }) => {
  useEffect(() => {
    window.status = 'ready';
  }, []);

  return (
    <RootPdf>
      <FactureShow facture={facture} isPrint />
    </RootPdf>
  );
};
