import React from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';

import {
  MultiSelectFormattedAnswer,
  OnboardingSelectOption,
} from 'features/OnboardingSolide/types';

type Props = {
  option: OnboardingSelectOption;
  onChange: (answer: MultiSelectFormattedAnswer | string[]) => void;
  selectedOptions: string[];
  otherAnswer?: string;
  setNextStep: () => void;
};

export const MultiSelectOption: React.FC<Props> = ({
  option,
  onChange,
  selectedOptions,
  otherAnswer,
  setNextStep,
}) => {
  const { label, value, isOtherAnswer } = option;
  const isOptionSelected = selectedOptions?.includes(value);

  return (
    <>
      <div className="mb-md">
        <Checkbox
          label={option.label}
          checked={isOtherAnswer ? otherAnswer !== undefined : isOptionSelected}
          onChange={isChecked =>
            isChecked
              ? onChange({
                  selectedOptions: [...selectedOptions, ...(isOtherAnswer ? [] : [value])],
                  otherAnswer: isOtherAnswer ? '' : otherAnswer,
                })
              : onChange({
                  selectedOptions: selectedOptions.filter(
                    selectedOption => selectedOption !== value,
                  ),
                  otherAnswer: isOtherAnswer ? undefined : otherAnswer,
                })
          }
        />
      </div>
      {isOtherAnswer && otherAnswer !== undefined && (
        <Input
          className="mb-xl"
          label={label}
          id={label}
          value={otherAnswer}
          onChange={value => onChange({ selectedOptions, otherAnswer: value })}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              if (otherAnswer) {
                onChange([...selectedOptions, otherAnswer]);
                setNextStep();
              }
            }
          }}
          autoFocus
        />
      )}
    </>
  );
};
