import AccountPicto from '@travauxlib/shared/src/components/DesignSystem/assets/AccountPicto.svg?react';
import AdvisorsPicto from '@travauxlib/shared/src/components/DesignSystem/assets/AdvisorsPicto.svg?react';
import CalculatePicto from '@travauxlib/shared/src/components/DesignSystem/assets/CalculatePicto.svg?react';
import CalendarPicto from '@travauxlib/shared/src/components/DesignSystem/assets/CalendarPicto.svg?react';
import DialoguePicto from '@travauxlib/shared/src/components/DesignSystem/assets/DialoguePicto.svg?react';
import EstimateurPicto from '@travauxlib/shared/src/components/DesignSystem/assets/EstimateurPicto.svg?react';
import FolderAskPicto from '@travauxlib/shared/src/components/DesignSystem/assets/FolderAskPicto.svg?react';
import Label from '@travauxlib/shared/src/components/DesignSystem/assets/Label.svg?react';
import ListPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ListPicto.svg?react';
import QuotePicto from '@travauxlib/shared/src/components/DesignSystem/assets/QuotePicto.svg?react';
import SheetDocPicto from '@travauxlib/shared/src/components/DesignSystem/assets/SheetDocPicto.svg?react';
import SponsorshipPicto from '@travauxlib/shared/src/components/DesignSystem/assets/SponsorshipPicto.svg?react';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { GuidesClientEventProperties } from '@travauxlib/shared/src/utils/tracking';

import { GuidesClientTracking } from 'utils/tracking/GuidesTracking';

import * as articles from './articles';

import {
  GuidesConfigs,
  GuidesSectionTag,
  GuidesSections,
  HemeaOfferTag,
  MonitorProjectTag,
  StartProjectStartTag,
} from '../types';

const tagAccompagnementEssentiel = (
  <Tag label="Offre Support" variant="info" size="md" icon={<Label />} />
);

export const hemeaOfferGuidesConfigs: GuidesConfigs = {
  [HemeaOfferTag.Concept]: {
    cardConfig: {
      title: 'Le concept hemea',
      icon: <EstimateurPicto />,
    },
    pageConfig: {
      title: 'Le concept hemea',
      articles: articles.hemeaConcept,
      tracking: () =>
        GuidesClientTracking.onGuidesClientConceptViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'l’accompagnement hemea',
        }),
    },
  },
  [HemeaOfferTag.ServicesHemea]: {
    cardConfig: {
      title: 'Les services hemea',
      icon: <DialoguePicto />,
    },
    pageConfig: {
      title: 'Les services hemea',
      introduction: articles.servicesHemea.introduction,
      articles: articles.servicesHemea.articles,
      tracking: () =>
        GuidesClientTracking.onGuidesClientServicesHemeaViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'l’accompagnement hemea',
        }),
    },
  },
  [HemeaOfferTag.Garanties]: {
    cardConfig: { title: 'Les garanties', icon: <SponsorshipPicto /> },
    pageConfig: {
      title: 'Les garanties',
      introduction: articles.garanties.introduction,
      articles: articles.garanties.articles,
      tracking: () =>
        GuidesClientTracking.onGuidesClientGarantiesViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'l’accompagnement hemea',
        }),
    },
  },
  [HemeaOfferTag.Interlocuteurs]: {
    cardConfig: {
      title: 'Les interlocuteurs',
      icon: <AdvisorsPicto />,
    },
    pageConfig: {
      title: 'Les interlocuteurs',
      articles: articles.interlocuteurs,
      tracking: () =>
        GuidesClientTracking.onGuidesClientInterlocuteursViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'l’accompagnement hemea',
        }),
    },
  },
  [HemeaOfferTag.Accompagnements]: {
    cardConfig: {
      title: 'Les accompagnements',
      icon: <SheetDocPicto />,
    },
    pageConfig: {
      title: 'Les accompagnements',
      articles: articles.accompagnement,
      tracking: () =>
        GuidesClientTracking.onGuidesClientAccompagnementsViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'l’accompagnement hemea',
        }),
    },
  },
  [HemeaOfferTag.Architectes]: {
    cardConfig: { title: 'Les architectes', icon: <FolderAskPicto /> },
    pageConfig: {
      title: 'Les architectes',
      articles: articles.architecte.articles,
      tracking: () =>
        GuidesClientTracking.onGuidesClientArchitecteTabViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'l’accompagnement hemea',
        }),
    },
  },
  [HemeaOfferTag.ArchitectesInterieur]: {
    cardConfig: { title: "Les architectes d'intérieur", hidden: true },
    pageConfig: {
      title: "Les architectes d'intérieur",
      articles: articles.architecteInterieurDecorateur,
      tracking: () =>
        GuidesClientTracking.onGuidesClientArchitectesViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'l’accompagnement hemea',
        }),
    },
  },
};

export const startProjectGuidesConfigs: GuidesConfigs = {
  [StartProjectStartTag.Fournitures]: {
    cardConfig: { title: 'L’achat de fournitures', icon: <ListPicto /> },
    pageConfig: {
      title: 'L’achat de fournitures',
      articles: articles.fournitures.articles,
      introduction: articles.fournitures.introduction,
      tracking: () =>
        GuidesClientTracking.onGuidesClientAchatFournituresViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'debuter son projet',
        }),
    },
  },
  [StartProjectStartTag.CompteSequestre]: {
    cardConfig: { title: 'Le compte séquestre', icon: <CalculatePicto /> },
    pageConfig: {
      title: 'Le compte séquestre',
      articles: articles.paiement,
      tracking: () =>
        GuidesClientTracking.onGuidesClientCompteSequestreViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'debuter son projet',
        }),
    },
  },
  [StartProjectStartTag.Prix]: {
    cardConfig: { title: 'Le prix d’un devis', icon: <QuotePicto /> },
    pageConfig: {
      title: 'Le prix d’un devis',
      introduction: articles.prix.introduction,
      articles: articles.prix.articles,
      tracking: () =>
        GuidesClientTracking.onGuidesClientPrixDevisViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'debuter son projet',
        }),
    },
  },
};

export const monitorProjectGuidesConfigs: GuidesConfigs = {
  [MonitorProjectTag.Demarrage]: {
    cardConfig: { title: 'Le démarrage', icon: <SheetDocPicto /> },
    pageConfig: {
      title: (
        <div className="flex items-center gap-xs">Le démarrage {tagAccompagnementEssentiel}</div>
      ),
      introduction: articles.demarrage.introduction,
      articles: articles.demarrage.articles,
      tracking: () =>
        GuidesClientTracking.onGuidesClientDemarrageViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'debuter son projet',
        }),
    },
  },
  [MonitorProjectTag.Suivi]: {
    cardConfig: { title: "Le suivi d'avancement", icon: <CalendarPicto /> },
    pageConfig: {
      title: "Le suivi d'avancement",
      articles: articles.suivi.articles,
      tracking: () =>
        GuidesClientTracking.onGuidesClientSuiviChantierViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'debuter son projet',
        }),
    },
  },
  [MonitorProjectTag.Reception]: {
    cardConfig: { title: 'La réception', icon: <AccountPicto /> },
    pageConfig: {
      title: (
        <div className="flex items-center gap-xs">La réception {tagAccompagnementEssentiel}</div>
      ),
      introduction: articles.reception.introduction,
      articles: articles.reception.articles,
      tracking: () =>
        GuidesClientTracking.onGuidesClientReceptionViewed({
          [GuidesClientEventProperties.ParentGuideSection]: 'debuter son projet',
        }),
    },
  },
};

export const guidesConfigs: GuidesConfigs = {
  ...hemeaOfferGuidesConfigs,
  ...startProjectGuidesConfigs,
  ...monitorProjectGuidesConfigs,
};

export const sections: GuidesSections = {
  [GuidesSectionTag.Hemea]: {
    configs: hemeaOfferGuidesConfigs,
    title: "L'accompagnement hemea",
  },
  [GuidesSectionTag.CommencerSonProjet]: {
    configs: startProjectGuidesConfigs,
    title: 'Débuter son projet',
  },
  [GuidesSectionTag.SuivreSonProjet]: {
    configs: monitorProjectGuidesConfigs,
    title: 'Suivre son projet',
  },
};
