import React, { useState } from 'react';

import classNames from 'classnames';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { DevisComment } from '@travauxlib/shared/src/types';

import { CommentHeader } from './CommentHeader';

import { useDevisDisplayContext } from '../DevisDisplayContext';

type Props = {
  senderName: string;
  comment: DevisComment;
  editComment?: () => void;
  className?: string;
  rightContent?: JSX.Element | null;
};

export const DisplayedComment: React.FC<Props> = ({
  senderName,
  comment,
  editComment,
  className,
  rightContent,
}) => {
  const { isReviewed, token, senderType } = useDevisDisplayContext();
  const [isHoverActif, setIsHoverActif] = useState(false);

  const canEdit = !isReviewed && token === comment.devisToken && senderType === comment.senderType;
  return (
    <div data-testid="fucke" className={className}>
      <CommentHeader
        senderName={senderName}
        commentDate={comment.createdAt}
        rightContent={rightContent}
      />
      <div
        className={classNames(
          'ml-xl pl-xs pr-xxs text-ds-b2 pb-3xs text-neutral-800 rounded-xxs flex items-center justify-between',

          canEdit && 'hover:bg-neutral-100 cursor-pointer sm-desktop:min-h-[34px]',
        )}
        onClick={() => (canEdit ? editComment?.() : undefined)}
        onMouseEnter={() => (canEdit ? setIsHoverActif(true) : undefined)}
        onMouseLeave={() => (canEdit ? setIsHoverActif(false) : undefined)}
      >
        <div className="overflow-hidden">
          <MultilineParagraph text={comment.text} paragraphClassName="mb-0" />
        </div>
        {isHoverActif && (
          <IconButton>
            <Edit height={20} width={20} />
          </IconButton>
        )}
      </div>
    </div>
  );
};
