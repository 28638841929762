import React from 'react';

import { Form } from 'react-final-form';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

type Props = {
  handleDeleteEstimation: (estimationUuid: string) => void;
  handleClose: () => void;
  estimationUuid?: string;
};

export const DeleteEstimationModal: React.FC<Props> = ({
  estimationUuid,
  handleDeleteEstimation,
  handleClose,
}) => (
  <Modal
    title="Êtes-vous sûr de vouloir supprimer cette estimation ?"
    isOpen={estimationUuid !== undefined}
    handleClose={handleClose}
    size="lg"
  >
    <Form
      onSubmit={() => {
        if (!estimationUuid) {
          return;
        }
        handleDeleteEstimation(estimationUuid);
        handleClose();
      }}
    >
      {({ handleSubmit }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Valider',
            type: 'submit',
          }}
          cancelAction={{ label: 'Annuler', onClick: handleClose }}
        />
      )}
    </Form>
  </Modal>
);
