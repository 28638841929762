import { Dayjs } from 'dayjs';

import { GooglemapsAddress } from '@travauxlib/shared/src/types';

export interface StepStructure {
  id: ProjectQuestion;
  type: QuestionType;
  title: string;
  helperText?: string;
  imageUrl: string;
  avis: {
    content: string;
    author: string;
  };
  eventKey: OnboardingEventProperty;
}

export type OnboardingSelectOption = {
  value: string;
  label: string;
  formattedLabel?: string;
  subLabel?: string;
  subOptions?: OnboardingSelectOption[];
  isOtherAnswer?: boolean;
};

export interface SelectStepConfig extends StepStructure {
  type: QuestionType.Select;
  options: OnboardingSelectOption[] | ((now: Dayjs) => OnboardingSelectOption[]);
}

export interface MultiSelectStepConfig extends StepStructure {
  type: QuestionType.MultiSelect;
  options: OnboardingSelectOption[];
}

export interface InputStepConfig extends StepStructure {
  type: QuestionType.Input;
  label: string;
}

export interface TextAreaStepConfig extends StepStructure {
  type: QuestionType.TextArea;
  label: string;
  isSkippable?: boolean;
}

export interface NumberInputStepConfig extends StepStructure {
  type: QuestionType.NumberInput;
  label: string;
  suffix: string;
}

export interface LocalisationStepConfig extends StepStructure {
  type: QuestionType.Localisation;
  label: string;
}

export type StepConfig =
  | SelectStepConfig
  | MultiSelectStepConfig
  | InputStepConfig
  | TextAreaStepConfig
  | NumberInputStepConfig
  | LocalisationStepConfig;

export type ComputedStepConfigs = (values?: FormValues) => StepConfig[];

export type VariableSurfaceStepConfig = Pick<
  StepStructure,
  'title' | 'helperText' | 'imageUrl' | 'avis'
>;

export enum QuestionType {
  Select = 'select',
  MultiSelect = 'multi_select',
  Input = 'input',
  TextArea = 'text_area',
  Localisation = 'localisation',
  NumberInput = 'number_input',
}

export type LocalisationAnswer = {
  value: GooglemapsAddress;
  isLocationAddressable: boolean;
};

export type MultiSelectFormattedAnswer = { selectedOptions: string[]; otherAnswer?: string };
export type SelectFormattedAnswer = { selectedOption: string; otherAnswer: string };

export enum ProjectQuestion {
  Need = 'need',
  TypeProjet = 'typeProjet',
  Localisation = 'localisation',
  DateTravaux = 'dateTravaux',
  BesoinArchitecte = 'besoinArchitecte',
  CritereSelection = 'critereSelection',
  Budget = 'budget',
  Description = 'description',
  TypeBien = 'typeBien',
  StadeAchat = 'stadeAchat',
  Surface = 'surfaceTotale',
  TypeRenovationEnergetique = 'typeRenovationEnergetique',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Optin = 'optin',
}

export type FormValues = StringAnswer &
  NumberAnswer &
  StringArrayAnswer &
  BooleanAnswer & { [ProjectQuestion.Localisation]: LocalisationAnswer };

export type StringAnswer = {
  [K in
    | ProjectQuestion.Need
    | ProjectQuestion.TypeProjet
    | ProjectQuestion.DateTravaux
    | ProjectQuestion.BesoinArchitecte
    | ProjectQuestion.CritereSelection
    | ProjectQuestion.Budget
    | ProjectQuestion.Description
    | ProjectQuestion.TypeBien
    | ProjectQuestion.StadeAchat
    | ProjectQuestion.FirstName
    | ProjectQuestion.LastName
    | ProjectQuestion.PhoneNumber
    | ProjectQuestion.Email]: string;
};

export type NumberAnswer = {
  [ProjectQuestion.Surface]: number;
};

export type StringArrayAnswer = {
  [ProjectQuestion.TypeRenovationEnergetique]: string[];
};

export type BooleanAnswer = {
  [ProjectQuestion.Optin]: boolean;
};

export enum OnboardingEventProperty {
  ProjectNeed = 'Project Need',
  ProjectLocalisation = 'Project Localisation',
  ProjectType = 'Project Type',
  PropertyType = 'Property Type',
  ProjectSurface = 'Project Surface',
  PurchaseStage = 'Purchase Stage',
  ProjectStartDate = 'Project Start Date',
  ArchitectNeed = 'Architect Need',
  MainCriteria = 'Main Criteria',
  Budget = 'Budget',
  ProjectDetail = 'Project Detail',
  TypeOfEnergyEfficiencyWorks = 'Type Of Energy-Efficiency Works',
  OnboardingStep = 'Onboarding Step',
  OnboardingVersion = 'Onboarding Version',
}

export enum JourneyEvents {
  // This event is triggered after each answer of the user to track what he answered
  answerEvent = 'tracking_event',
  // This event is triggered at the beginning of our onboarding client
  projectStartedEvent = 'journey.project.started',
  // This event is triggered when the user arrives on the page for inscription
  inscriptionStartedEvent = 'journey.inscription.started',
}

export enum OnboardingEvents {
  // This event is triggered when the user successfully finish his account inscription
  createdAccountEvent = 'client.createAccount',
}

export enum Partenariat {
  figaro = 'figaro',
}
