import React from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';

import { SizePaddingClassName } from './types';
import { columnClassName, sizePaddingColumnClassName } from './utils';

import { IconButton } from '../Buttons/IconButton';

type Props = {
  hasAdditionalContent: boolean;
  onClickExpand: () => void;
  isExpanded: boolean;
  size: SizePaddingClassName;
};

export const ExpandColumn: React.FC<Props> = ({
  hasAdditionalContent,
  onClickExpand,
  isExpanded,
  size,
}) => (
  <td className={classNames(sizePaddingColumnClassName[size], columnClassName)}>
    {hasAdditionalContent && (
      <IconButton data-testid="expand-button" size="sm" onClick={onClickExpand}>
        {isExpanded ? <ChevronUp /> : <ChevronDown />}
      </IconButton>
    )}
  </td>
);
