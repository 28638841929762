import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { request } from '@travauxlib/shared/src/utils/request';

import { SuiviChantierClient } from 'types';

export const SUIVICHANTIER_KEY = 'SUIVICHANTIER';

const fetchSuiviChantier = ({
  queryKey: [, { token }],
}: QueryFunctionContext<[string, { token: string }]>): Promise<SuiviChantierClient> =>
  request(`${APP_CONFIG.apiURL}/client/suivi-chantier/${token}`);

export const useSuiviChantier = (
  token: string,
): { isLoading: boolean; suiviChantier?: SuiviChantierClient } => {
  const { isLoading, data } = useQuery<SuiviChantierClient>({
    queryKey: [SUIVICHANTIER_KEY, { token }],
    queryFn: fetchSuiviChantier,
    staleTime: Infinity,
    retry: (failureCount, error: AxiosError) => failureCount < 2 && error?.response?.status !== 404,
  });

  return {
    isLoading,
    suiviChantier: data,
  };
};
