import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SUIVI_CHANTIERS_KEY } from '@travauxlib/shared/src/features/SuiviChantier/api/createSuiviChantierHistoryList';
import { SuiviChantierClient } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const useSuiviChantierInitState = (
  chantierUuid: string,
): { suiviChantierInitState?: SuiviChantierClient; isLoading: boolean } => {
  const { data, isPending } = useQuery<SuiviChantierClient>({
    queryKey: [SUIVI_CHANTIERS_KEY, chantierUuid, 'init'],
    queryFn: async () => {
      try {
        return await request(
          `${APP_CONFIG.apiURL}/client/chantiers/${chantierUuid}/suivi-chantier-init`,
        );
      } catch (err) {
        toast.error("Le resumé des suivi n'a pas pu être récupéré");
        throw err;
      }
    },
  });

  return { suiviChantierInitState: data, isLoading: isPending };
};
