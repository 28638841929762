import React from 'react';

import classNames from 'classnames';

import { Checkbox } from '../Checkbox';
import { FormControlListProps } from '../FormControl/commons';

export type Props = FormControlListProps<string[], string> & {
  label?: string;
  checkAllLabel?: string;
  renderingOptions?: {
    optionClassName?: string;
  };
};

export const CheckboxList: React.FC<Props> = ({
  options,
  className,
  value,
  onChange,
  id,
  disabled,
  checkAllLabel,
  inline,
  renderingOptions,
  label,
}) => (
  <>
    {label && (
      <label htmlFor={id} className="font-bold mb-xs">
        {label}
      </label>
    )}
    <div className={classNames(inline && 'flex items-center', className)} id={id} data-testid={id}>
      {checkAllLabel && (
        <div className={classNames(inline && 'mr-xs')}>
          <Checkbox
            id={id && `${id || ''}_all`}
            checked={options.length === value.length}
            onChange={() =>
              options.length === value.length || value.length > 0
                ? onChange([])
                : onChange(options.map(opt => opt.value))
            }
            label={checkAllLabel}
            disabled={disabled}
            indeterminate={options.length !== value.length && value.length > 0}
          />
        </div>
      )}
      {options.map(option => (
        <div
          className={classNames(inline && 'mr-xs', renderingOptions?.optionClassName)}
          key={`${option.value}${option.label}`}
        >
          <Checkbox
            id={id && `${id || ''}_${option.value || ''}`}
            checked={value.includes(option.value)}
            onChange={() =>
              value.includes(option.value)
                ? onChange(value.filter(v => v !== option.value))
                : onChange([...value, option.value])
            }
            label={option.label}
            disabled={disabled || option.disabled}
          />
        </div>
      ))}
    </div>
  </>
);
