import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

import { Props as ReassuranceItemProps, ReassuranceItem } from './ReassuranceItem';

type Props = {
  items: ReassuranceItemProps[];
};

export const ReassuranceCard: React.FC<Props> = ({ items }) => (
  <Card bodyClassNames="flex flex-col sm-desktop:flex-row gap-xs sm-desktop:gap-md">
    {items.map(reassuranceItem => (
      <ReassuranceItem
        key={reassuranceItem.title}
        icon={reassuranceItem.icon}
        title={reassuranceItem.title}
        description={reassuranceItem.description}
      />
    ))}
  </Card>
);
