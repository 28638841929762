import React, { useState } from 'react';

import _sum from 'lodash/sum';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links/Link';
import { RemisePrimesModal } from '@travauxlib/shared/src/features/LDDToolbar/components/RemisePrimes/Modal';
import { RemisePrimeDisplay } from '@travauxlib/shared/src/features/LDDToolbar/components/RemisePrimes/RemisePrimeDisplay';
import { Prestations, TypeRemise } from '@travauxlib/shared/src/types';

type Props = {
  prestations: Omit<Prestations, 'title' | 'token' | 'lots'>;
  onChange: (prestations: Partial<Prestations>) => void;
};

export const RemisePrimes: React.FC<Props> = ({ prestations, onChange }) => {
  const [isRemiseModalOpen, setIsRemiseModalOpen] = useState(false);
  const { remises, primes, montantRemise } = prestations;
  const remiseSum = _sum(remises.values.map(remise => remise.value));

  return (
    <div className="flex flex-col gap-xxs">
      {remises.values.map((remise, index) => (
        <RemisePrimeDisplay
          key={`${remise.label}-${remise.value}`}
          onDelete={() =>
            onChange({
              remises: {
                ...remises,
                values: remises.values.filter((_, remiseIndex) => index !== remiseIndex),
              },
            })
          }
          label={remise.label}
          typeRemise={remises.type}
          value={
            remises.type === TypeRemise.Montant
              ? remise.value
              : (remise.value / remiseSum) * montantRemise
          }
        />
      ))}
      {primes.map((prime, index) => (
        <RemisePrimeDisplay
          key={`${prime.label}-${prime.amount}`}
          onDelete={() =>
            onChange({ primes: primes.filter((_, primeIndex) => index !== primeIndex) })
          }
          label={prime.label}
          value={prime.amount}
        />
      ))}
      <Link onClick={() => setIsRemiseModalOpen(true)} inline>
        Ajouter une remise/prime
      </Link>
      <RemisePrimesModal
        isOpen={isRemiseModalOpen}
        prestations={prestations}
        onChange={onChange}
        handleClose={() => setIsRemiseModalOpen(false)}
      />
    </div>
  );
};
