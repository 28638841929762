import React from 'react';

import _partition from 'lodash/partition';
import { Field } from 'react-final-form';

import RightChevron from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import {
  MultiSelectFormattedAnswer,
  OnboardingSelectOption,
} from 'features/OnboardingSolide/types';

import { MultiSelectOption } from './MultiSelectOption';

type Props = {
  id: string;
  options: OnboardingSelectOption[];
  setNextStep: () => void;
};

export const MultiSelectQuestion: React.FC<Props> = ({ id, options, setNextStep }) => {
  const optionKeys = options.map(option => option.value);

  return (
    <Field<MultiSelectFormattedAnswer>
      id={id}
      name={id}
      format={value => {
        if (!value) {
          return { selectedOptions: [] };
        }

        if (Array.isArray(value)) {
          const [selectedOptions, otherAnswer] = _partition(value, selectedOption =>
            optionKeys.includes(selectedOption),
          );

          return {
            selectedOptions,
            otherAnswer: otherAnswer.length === 1 ? otherAnswer[0] : undefined,
          };
        }

        return value;
      }}
      render={({
        input: {
          onChange,
          value: { selectedOptions, otherAnswer },
        },
      }) => (
        <div className="flex flex-col">
          <div className="mb-xl">
            {options.map(option => (
              <MultiSelectOption
                key={option.value}
                option={option}
                onChange={onChange}
                selectedOptions={selectedOptions}
                otherAnswer={otherAnswer}
                setNextStep={setNextStep}
              />
            ))}
          </div>
          <Button
            className="self-end"
            rightIcon={<RightChevron />}
            onClick={() => {
              otherAnswer ? onChange([...selectedOptions, otherAnswer]) : onChange(selectedOptions);

              setNextStep();
            }}
            disabled={selectedOptions.length === 0 && !otherAnswer}
          >
            Suivant
          </Button>
        </div>
      )}
    />
  );
};
