import React from 'react';

import Calculate from '@travauxlib/shared/src/components/DesignSystem/assets/CalculatePartner.svg?react';
import KeyFigures from '@travauxlib/shared/src/components/DesignSystem/assets/KeyFigures.svg?react';
import List from '@travauxlib/shared/src/components/DesignSystem/assets/ListPartner.svg?react';

export const SIZE_ICON = 160;

export type CardAction = {
  label: string;
  to?: string;
  href?: string;
  description: string;
  title: string;
  icon: JSX.Element;
};

export const cardActions: (urlDashboardReporting?: string) => CardAction[] = (
  urlDashboardReporting?: string,
) => [
  {
    icon: <List width={SIZE_ICON} height={SIZE_ICON} />,
    label: 'Envoyer un contact client',
    to: 'send-lead',
    description: 'Transmettre les informations du client, du projet, et du stade d’achat',
    title: 'Contact client hemea',
  },
  {
    label: 'Accéder à l’estimateur en ligne',
    to: '/estimateur',
    description: 'Réaliser une estimation des travaux de façon simple et intuitive',
    title: 'Estimateur en ligne',
    icon: <Calculate width={SIZE_ICON} height={SIZE_ICON} />,
  },
  ...(urlDashboardReporting
    ? [
        {
          href: urlDashboardReporting,
          label: 'Accéder à l’outil de reporting',
          description: 'Suivre le statut de vos dossiers client envoyés à hemea',
          title: 'Outil de reporting',
          icon: <KeyFigures width={SIZE_ICON} height={SIZE_ICON} />,
        },
      ]
    : []),
];
