import React from 'react';

import EmailDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/EmailDuoTone.svg?react';
import PhoneDuoColor from '@travauxlib/shared/src/components/DesignSystem/assets/PhoneDuoColor.svg?react';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { Person } from 'features/EspaceClient3/features/Index/types';

type Props = {
  contact: Person;
};

export const MyAdviserInfo: React.FC<Props> = ({ contact }) => (
  <div className="flex gap-md items-center">
    <Avatar
      className="shrink-0"
      size="xl"
      pictureURL={contact.picture}
      firstName={contact.firstName}
      lastName={contact.lastName}
    />
    <div>
      <div className="text-ds-b1 font-bold">{contact.fullName}</div>
      <div className="text-ds-sm text-neutral-600 -mt-xxs mb-xxs">{contact.jobTitle}</div>
      <div className="flex items-center mb-xxs">
        <PhoneDuoColor className="w-xmd h-xmd mr-xxs" />
        <Link href={`tel:${contact.phone}`}>{contact.phone}</Link>
      </div>
      <div className="flex items-center">
        <EmailDuoTone className="w-xmd h-xmd mr-xxs" />
        <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
      </div>
    </div>
  </div>
);
