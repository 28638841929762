import React from 'react';

import { Remises as RemisesType, TypeRemise } from '@travauxlib/shared/src/types';

import { SimpleTotal } from './SimpleTotal';

type Props = {
  remises: RemisesType;
  prixTotalHTAvantRemise: number;
  prixTotalTTCAvantRemise: number;
  prixTotalHT: number;
  withEllipsisOnLabel?: boolean;
};

export const Remises: React.FC<Props> = ({
  remises,
  prixTotalHTAvantRemise,
  prixTotalTTCAvantRemise,
  prixTotalHT,
  withEllipsisOnLabel,
}) => (
  <>
    {remises.values.map(remise => {
      const correpondingMontantForHT =
        remises.type === TypeRemise.Pourcentage
          ? (remise.value / 100) * prixTotalHTAvantRemise
          : prixTotalHTAvantRemise *
            (1 - (prixTotalTTCAvantRemise - remise.value) / prixTotalTTCAvantRemise);

      return (
        <SimpleTotal
          className="text-ds-b2"
          key={remise.label}
          data-testid={remise.label}
          label={remise.label}
          total={-correpondingMontantForHT}
          withEllipsisOnLabel={withEllipsisOnLabel}
        />
      );
    })}
    <SimpleTotal
      className="text-ds-b1 font-medium"
      label="Total HT après remise"
      total={prixTotalHT}
    />
  </>
);
