import React from 'react';

import { CircleProgressPublicProps, CircularProgress } from './index';

const lerp = (a: number, b: number, amnt: number): number => a + amnt * (b - a);

export const AnimatedCircleProgress: React.FC<CircleProgressPublicProps> = ({ value, ...rest }) => {
  const [animatedValue, setAnimatedValue] = React.useState(0);

  React.useEffect(() => {
    if (value !== animatedValue) {
      let to = setTimeout(() => {
        const calculatedNextValue = lerp(animatedValue, value, 0.025);
        if (Math.ceil(calculatedNextValue) === value) {
          setAnimatedValue(value);
        } else {
          setAnimatedValue(calculatedNextValue);
        }
      }, 20);
      return () => clearTimeout(to);
    }
  }, [value, animatedValue]);

  return <CircularProgress value={value} overrideDashValue={animatedValue} {...rest} />;
};
