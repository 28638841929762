import React from 'react';

import classNames from 'classnames';

import { DevisCustomization } from '@travauxlib/shared/src/types';

type Props = {
  customization?: DevisCustomization;
};

export const ColoredElement: React.FC<React.HTMLProps<HTMLDivElement>> = ({ color, ...props }) => (
  <div {...props} style={{ color }} />
);

export const Header: React.FC<Props> = ({ customization }) => (
  <div className="mb-sm">
    <ColoredElement
      className={classNames('text-ds-h4 font-medium', customization?.titleFontStyle)}
      color={customization?.textColor}
    >
      Détail des prestations
    </ColoredElement>
  </div>
);
