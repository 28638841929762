import React from 'react';

import classNames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import Imgix from 'react-imgix';

import { useSSR } from '../../hooks/useSSR';

export type Props = {
  url: string;
  sizes?: string;
  imgixParams?: any;
  width?: number;
  height?: number;
  alt?: string;
  eagerLoading?: boolean;
  alwaysUseSSRRendering?: boolean;
  className?: string;
  buildFallbackUrl?: (url: string) => string;
  skipSsr?: boolean; // use this property for HUGE images wich should not be rendered at all on the server
  onClick?: () => void;
};

// react-imgix don't like being given localhost urls and WILL throw
// this should fallback correcly
const _ImgLazy: React.FC<Props> = ({
  url,
  sizes,
  imgixParams,
  width,
  height,
  alt = '',
  eagerLoading = false,
  className,
  buildFallbackUrl,
  skipSsr = false, // Skips entierly the ssr render
  alwaysUseSSRRendering = false, // Invariant errors are a big no. When switching from image to imgx, it flushes everything
  ...rest
}) => {
  const [bundleLoaded, setBundledLoaded] = React.useState(false);
  const [imgixLoaded, setImgixLoaded] = React.useState(false);

  React.useEffect(() => {
    setBundledLoaded(true);
  }, []);

  const { isServer } = useSSR();
  const fallBackComponent = (
    <img
      width={width}
      height={height}
      alt={alt}
      loading={eagerLoading ? 'eager' : 'lazy'}
      className={classNames('max-w-full h-auto', className)}
      sizes={sizes}
      src={buildFallbackUrl?.(url) ?? url}
      {...rest}
    />
  );

  // Imgix whines if onMounted props changes between renders and ask us about using class component.
  // But useCallback was made for that, I guess they don't know about it idk
  const onMountedImgxCallback = React.useCallback(
    (
      ref: React.RefObject<HTMLPictureElement | HTMLImageElement | HTMLSourceElement> | undefined,
    ) => {
      if (ref) {
        (ref as any).onload = () => {
          setImgixLoaded(true);
        };
      }

      if (ref && (ref as any).complete) {
        setImgixLoaded(true);
      }
    },
    [],
  );

  if (isServer && skipSsr) {
    return null;
  }

  return (
    <>
      {(!bundleLoaded || alwaysUseSSRRendering || !imgixLoaded) && !skipSsr && fallBackComponent}
      {((bundleLoaded && !alwaysUseSSRRendering) || skipSsr) && (
        <ErrorBoundary fallbackRender={() => fallBackComponent}>
          <Imgix
            width={width}
            height={height}
            htmlAttributes={{
              loading: eagerLoading ? 'eager' : 'lazy',
              alt,
              ...rest,
            }}
            className={classNames('max-w-full h-auto', className, {
              'w-[0px] h-[0px]': !imgixLoaded,
            })}
            sizes={sizes}
            src={url}
            imgixParams={imgixParams}
            onMounted={onMountedImgxCallback}
            disableLibraryParam
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export const ImgLazy: React.FC<Props> = props => <_ImgLazy {...props} skipSsr />;
export const SSRImgLazy: React.FC<Props> = props => <_ImgLazy {...props} />;
