import { FormValues, ProjectQuestion, StepConfig } from '../types';

export const getPreviousAnswers = (
  steps: StepConfig[],
  answers: FormValues,
  currentIndex: number,
): object => {
  const previousAndCurrentStepIds = steps.slice(0, currentIndex).map(step => step.id);

  return Object.entries(answers)
    .filter(([key]) => previousAndCurrentStepIds.includes(key as ProjectQuestion))
    .reduce((answers, [key, answer]) => ({ ...answers, [key]: answer }), {});
};
