import { useIsBelowBreakpoint } from './useIsBelowBreakpoint';

import { TailwindBreakpoint } from '../utils/sizes';
import { truncateWordsWithTooltip } from '../utils/truncateWordsWithTooltip';

type Props = {
  value?: string | number;
  maxCharsForScreenSize?: {
    [K in TailwindBreakpoint | 'giga-screen']?: number;
  };
};

export type Truncate = ({
  value,
  maxCharsForScreenSize,
}: Pick<Props, 'value' | 'maxCharsForScreenSize'>) => JSX.Element | string;

export const useTruncate = (): Truncate => {
  const screenSizes: {
    [K in TailwindBreakpoint]?: boolean;
  } = {
    tablet: useIsBelowBreakpoint('tablet'),
    'sm-desktop': useIsBelowBreakpoint('sm-desktop'),
    'md-desktop': useIsBelowBreakpoint('md-desktop'),
    'lg-desktop': useIsBelowBreakpoint('lg-desktop'),
  };

  const currentScreenSize = Object.entries(screenSizes).find(([, value]) => value);

  return ({ maxCharsForScreenSize, value }) => {
    if (!value) {
      return '';
    }

    if (!currentScreenSize && maxCharsForScreenSize?.['giga-screen']) {
      return truncateWordsWithTooltip(value, maxCharsForScreenSize['giga-screen']);
    }

    return truncateWordsWithTooltip(
      value,
      currentScreenSize &&
        maxCharsForScreenSize &&
        maxCharsForScreenSize[currentScreenSize[0] as TailwindBreakpoint],
    );
  };
};
