import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECTS_KEY } from 'features/EspaceClient3/api/useProjects';

const handleValidateAction = ({
  projectUuid,
  actionType,
}: {
  projectUuid: string;
  actionType: string;
}): Promise<void> => {
  const requestUrl = `${APP_CONFIG.apiURL}/client/projects/${projectUuid}/actions/${actionType}/validate`;

  return request(requestUrl, { method: 'POST' });
};

export const useValidateAction = (): {
  validateAction: typeof handleValidateAction;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: validateAction, isPending } = useMutation({
    mutationFn: handleValidateAction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECTS_KEY] });
    },
    onError: () => {
      toast.error('Un problème est survenu, veuillez réessayer');
    },
  });

  return { validateAction, isLoading: isPending };
};
