import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { Props as SliderProps, Slider } from '.';

type Props = {
  name: string;
} & Omit<SliderProps, 'value' | 'onChange'> &
  UseFieldConfig<number>;

const SliderWrapper: React.FC<FieldRenderProps<number>> = ({
  input: { value, onChange },
  id,
  meta,
  ...rest
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <Slider
      id={id}
      value={value || rest.minValue || 0}
      onChange={onChange}
      error={error && touched ? error : undefined}
      {...rest}
    />
  );
};

export const SliderField: React.FC<Props> = props => <Field component={SliderWrapper} {...props} />;
