import SentimentDissatisfied from '@travauxlib/shared/src/components/DesignSystem/assets/SentimentDissatisfied.svg?react';
import SentimentExtremelyDissatisfied from '@travauxlib/shared/src/components/DesignSystem/assets/SentimentExtremelyDissatisfied.svg?react';
import SentimentNeutral from '@travauxlib/shared/src/components/DesignSystem/assets/SentimentNeutral.svg?react';
import SentimentSatisfied from '@travauxlib/shared/src/components/DesignSystem/assets/SentimentSatisfied.svg?react';
import SentimentVerySatisfied from '@travauxlib/shared/src/components/DesignSystem/assets/SentimentVerySatisfied.svg?react';

export const options = [
  {
    className: 'text-error-800 !bg-error-50 !border-error-800',
    text: 'Très insatisfait',
    icon: <SentimentExtremelyDissatisfied />,
    value: 1,
  },
  {
    className: 'text-warning-800 bg-warning-50 border-warning-800',
    text: 'Insatisfait',
    icon: <SentimentDissatisfied />,
    value: 2,
  },
  {
    className: 'text-primary-800 bg-primary-50 border-primary-800',
    text: 'Neutre',
    icon: <SentimentNeutral />,
    value: 3,
  },
  {
    className: 'text-info-800 !bg-info-50 !border-info-800',
    text: 'Satisfait',
    icon: <SentimentSatisfied />,
    value: 4,
  },
  {
    className: 'text-success-800 bg-success-50 border-success-800',
    text: 'Très satisfait',
    icon: <SentimentVerySatisfied />,
    value: 5,
  },
];
