import _partition from 'lodash/partition';

import { isFactured } from './isFactured';

import { GenericFacture } from '../../types';

export const getSeparatedFacturesLists = <
  T extends Pick<GenericFacture, 'isAvoir' | 'isCloture' | 'status'>,
>(
  facturesList: T[],
): {
  avoirList: T[];
  facturesListWithoutAvoir: T[];
  facturesListWithoutClotureAndAvoir: T[];
  factureSolde?: T;
  hasCloture: boolean;
  isClotured: boolean;
} => {
  let [avoirList, facturesListWithoutAvoir] = _partition(facturesList, 'isAvoir');
  let [cloture, facturesListWithoutClotureAndAvoir] = _partition(
    facturesListWithoutAvoir,
    'isCloture',
  );

  const factureSolde = cloture[0];
  const hasCloture = !!factureSolde;
  const isClotured = isFactured(factureSolde);

  return {
    avoirList,
    facturesListWithoutAvoir,
    facturesListWithoutClotureAndAvoir,
    factureSolde,
    hasCloture,
    isClotured,
  };
};
