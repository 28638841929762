import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ArchitectureOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ArchitectureOutline.svg?react';
import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeft.svg?react';
import CheckCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircleOutline.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/Construction.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useGetDevisFactures } from 'features/EspaceClient3/api/useGetDevisFactures';
import { StatusDisplay } from 'features/EspaceClient3/utils';

export const AllDevis: React.FC = () => {
  const navigate = useNavigate();
  const { uuid, proCompanyUuid } = useParams<{ uuid: string; proCompanyUuid: string }>();
  const { devisFactures, isLoading } = useGetDevisFactures(uuid!);

  if (isLoading) {
    return <LoaderWrapper />;
  }

  const orderedProCompanies = devisFactures?.proCompanies.sort(pc1 =>
    pc1.uuid === proCompanyUuid ? -1 : 1,
  );

  return (
    <div className="mx-md sm-desktop:mx-0">
      <div className="flex items-center gap-xxs text-ds-h5 text-neutral-800 font-bold my-sm">
        <IconButtonLink to=".." title="Tous mes devis">
          <ArrowLeft />
        </IconButtonLink>
        Tous mes devis
      </div>
      {orderedProCompanies?.map(proCompany => (
        <Card key={proCompany.uuid} className="mb-md rounded-md">
          <div className="mb-xs flex gap-xs">
            <ColoredCircle variant="beige">
              {proCompany.companyType === 'pro' ? <Construction /> : <ArchitectureOutline />}
            </ColoredCircle>
            <div className="flex flex-col">
              <div className="text-ds-b1 text-neutral-800 font-bold">{proCompany.name}</div>
              <div className="text-neutral-700 text-ds-sm">
                {proCompany.companyType === 'pro' ? 'Entreprise' : 'Architecte'}
              </div>
            </div>
          </div>
          {proCompany.devis.map(devis => (
            <div
              onClick={() => navigate(`/pro/devis/${devis.token}`)}
              key={devis.token}
              className="last:border-none border-b flex justify-between py-xxs gap-xxs cursor-pointer"
            >
              <div className="flex items-center gap-xxs grow truncate">
                <div className="flex flex-col grow truncate">
                  <div className="flex justify-between items-center gap-xs">
                    <div className="text-ds-b2 text-neutral-800 font-normal truncate">
                      {devis.title}
                    </div>
                    <div className="text-neutral-800 text-ds-sm font-normal">
                      {devis.status && (
                        <div className="flex gap-xxs items-center">
                          {devis.status === 'signed' && (
                            <CheckCircleOutline className="w-md h-lg text-success-600 shrink-0" />
                          )}
                          <div>{StatusDisplay[devis.status]}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-ds-sm text-neutral-600">
                      Envoyé le {formatFrenchDate(devis.sentAt)}
                    </div>
                    <div className="flex justify-end grow">
                      <EURCurrency
                        className="text-ds-b2 text-neutral-800 font-medium"
                        amount={devis.prixTotalTTC}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <IconButton>
                  <ChevronRight />
                </IconButton>
              </div>
            </div>
          ))}
        </Card>
      ))}
    </div>
  );
};
