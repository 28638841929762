import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import { GooglemapsAddress } from '@travauxlib/shared/src/types';
import {
  required,
  requiredValidEmail,
  requiredValidPhoneNumber,
  validateConditions,
  validateGoogleMapAddress,
} from '@travauxlib/shared/src/utils/form';

import { useLastLeadCalendlyAndOwner } from 'api/useLastLeadCalendlyAndOwner';

import { useEstimateurTracking } from './useEstimateurTracking';

import { useSubmitLeadFromPublicEstimateur } from '../features/Public/api/useSubmitLeadFromPublicEstimateur';
import { Estimation, RecallFormData } from '../types';
import { EstimateurEventAction } from '../utils/constants';
import { useIsEstimateurClient, useWhiteLabelPartner } from '../utils/paths';

export type RecallModalProps = {
  handleClose: () => void;
  estimation?: Estimation;
  affiliateToken?: string;
  userAddress?: GooglemapsAddress;
};

const RecallModal: React.FC<RecallModalProps> = ({
  handleClose,
  estimation,
  affiliateToken,
  userAddress,
}) => {
  const isEstimateurClient = useIsEstimateurClient();
  const { isLoading: loading } = useLastLeadCalendlyAndOwner();
  const doTracking = useEstimateurTracking();
  const whiteLabelPartner = useWhiteLabelPartner();
  const submitLead = useSubmitLeadFromPublicEstimateur();

  useEffect(() => {
    doTracking(EstimateurEventAction.RecallStarted);
  }, []);

  if (isEstimateurClient && loading) {
    return <LoaderWrapper />;
  }
  const title = estimation
    ? `Rénovation ${estimation?.context.typeLogement} de ${estimation?.context.surfaceTotale}m²`
    : 'Rénovation de votre bien';

  return (
    <Modal isOpen title={title} handleClose={handleClose}>
      <Form<RecallFormData>
        onSubmit={values =>
          submitLead(
            {
              affiliateToken,
              estimation,
              ...values,
            },
            {
              onSuccess: () => {
                const userInfo = {
                  email: values.email,
                  firstName: values.firstName,
                  lastName: values.lastName,
                };

                doTracking(EstimateurEventAction.RecallFinished, userInfo);
                handleClose();
              },
            },
          )
        }
      >
        {({ handleSubmit, submitting }) => (
          <ModalContent
            cancelAction={{
              label: 'Annuler',
              disabled: submitting,
              onClick: handleClose,
            }}
            validateAction={{
              label: 'Valider',
              type: 'submit',
              disabled: submitting,
              loading: submitting,
            }}
            handleSubmit={handleSubmit}
          >
            <InputField
              id="firstName"
              name="firstName"
              className="mb-md"
              label="Prénom"
              validate={required}
            />
            <InputField
              id="lastName"
              name="lastName"
              className="mb-md"
              label="Nom"
              validate={required}
            />
            <PlacesAutocompleteField
              id="googleMapsAddress"
              name="googleMapsAddress"
              className="mb-md"
              label="Code postal"
              googleApiKey={APP_CONFIG.googleApiKey}
              initialInputValue={
                userAddress?.formatted_address || estimation?.googleMapsAddress?.formatted_address
              }
              validate={validateGoogleMapAddress('postal_code')}
              autoSubmit
            />
            <InputField
              id="phoneNumber"
              name="phoneNumber"
              className="mb-md"
              label="Téléphone"
              validate={requiredValidPhoneNumber}
            />
            <InputField
              id="email"
              name="email"
              className="mb-md"
              label="Email"
              validate={requiredValidEmail}
            />
            <TextareaField
              id="description"
              name="description"
              className="mb-md"
              label="Description complémentaire"
            />
            <CheckboxField
              name="cgu"
              className="mb-md"
              validate={validateConditions}
              label={
                <>
                  Je déclare accepter les{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.hemea.com/fr/legal"
                  >
                    Conditions Générales d'Utilisation
                  </a>{' '}
                  d'hemea
                </>
              }
            />
            {whiteLabelPartner?.isCurrentEstimateur && (
              <CheckboxField
                name="sendDataToWhiteLabel"
                validate={validateConditions}
                label={`J'accepte que mon nom, prénom et numéro de téléphone soient communiqués à ${whiteLabelPartner.label}`}
              />
            )}
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};

export const useRecallModal = ({
  estimation,
  affiliateToken,
  userAddress,
}: {
  estimation?: Estimation;
  affiliateToken?: string;
  userAddress?: GooglemapsAddress;
}): { recallModal: JSX.Element | null; toggleModal: () => void } => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return {
    recallModal: isOpen ? (
      <RecallModal
        handleClose={() => setIsOpen(false)}
        estimation={estimation}
        affiliateToken={affiliateToken}
        userAddress={userAddress}
      />
    ) : null,
    toggleModal: () => setIsOpen(isOpen => !isOpen),
  };
};
