import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Contractor } from '@travauxlib/shared/src/types';

export type ContractorInformationsProps = {
  contractor: Contractor;
};

export const ContractorInformations: React.FC<ContractorInformationsProps> = ({ contractor }) => {
  const { capital, siret } = contractor;

  return (
    <small className="block text-gray-600">
      {!!capital && (
        <>
          Au capital de <EURCurrency amount={capital} />
        </>
      )}
      <br />
      {siret ? `Siret : ${siret} ` : ''} <br />
    </small>
  );
};
