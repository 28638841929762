import { AlertVariant, BrandVariant } from '@travauxlib/shared/src/types/designSystem';

export const borderColorByVariant: { [K in AlertVariant | BrandVariant | 'beige']: string } = {
  info: 'border-info-400',
  warning: 'border-warning-400',
  error: 'border-error-400',
  success: 'border-success-400',
  primary: 'border-primary',
  beige: 'border-primary',
};
