import React from 'react';

import { Form } from 'react-final-form';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { Estimation } from 'features/Estimateur/types';

import { PartnerFields } from '../../New/components/PartnerProjectInfo';

type Props = {
  handleUpdateEstimation: (estimation: Estimation) => void;
  handleClose: () => void;
  estimation?: Estimation;
};

export const EditEstimationInfos: React.FC<Props> = ({
  estimation,
  handleUpdateEstimation,
  handleClose,
}) => (
  <Modal isOpen={estimation !== undefined} handleClose={handleClose} size="lg">
    <Form<Estimation>
      initialValues={estimation}
      onSubmit={values => {
        handleUpdateEstimation(values);
        handleClose();
      }}
    >
      {({ handleSubmit }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          title="Modifier le projet"
          validateAction={{
            label: 'Valider',
            type: 'submit',
          }}
          cancelAction={{ label: 'Annuler', onClick: handleClose }}
        >
          <PartnerFields
            initialGoogleMapsInputValue={estimation?.googleMapsAddress?.formatted_address}
          />
        </ModalContent>
      )}
    </Form>
  </Modal>
);
