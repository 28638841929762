import React from 'react';

type Props = {
  isReceptionChantierThresholdReached: boolean;
  isLeveeReservesThresholdReached: boolean;
  areThresholdsRaised: boolean;
  currentThreshold: number;
};

export const PlafondPercentage: React.FC<Props> = ({
  isReceptionChantierThresholdReached,
  isLeveeReservesThresholdReached,
  areThresholdsRaised,
  currentThreshold,
}) => {
  if (!isReceptionChantierThresholdReached && !isLeveeReservesThresholdReached) {
    return null;
  }

  return areThresholdsRaised ? (
    <div className="text-success">(Plafond levé)</div>
  ) : (
    <div className="text-neutral-600">(Plafond {currentThreshold}%)</div>
  );
};
