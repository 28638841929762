import { useQuery } from '@tanstack/react-query';

import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';
import { request } from '@travauxlib/shared/src/utils/request';

import { ProjectDocument } from '../types';

export const PROJECT_DOCUMENTS_KEY = 'PROJECT_DOCUMENTS';

export const useProjectDocuments = (
  projectUuid: string,
): { projectDocuments: { [K in DocumentTag]?: ProjectDocument[] }; isLoading: boolean } => {
  const { isLoading, data } = useQuery<{ [K in DocumentTag]?: ProjectDocument[] }>(
    // TODO Use plutôt [Projects_KEY, projectUuid, 'documents'] IMO
    // A revalider avec la teamz ec3, peut être fait dans un second temps
    {
      queryKey: [PROJECT_DOCUMENTS_KEY, projectUuid],
      queryFn: () => request(`${APP_CONFIG.apiURL}/clients/projects/${projectUuid}/files`),
    },
  );

  return { projectDocuments: data || {}, isLoading };
};
