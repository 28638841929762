import { ChipSize } from './types';

import { IconSizes } from '../ColoredCircle';

export const chipClassNamesByVariant: {
  [key in ChipSize]: {
    paddingClassNames: string;
    textContainerClassNames: string;
    labelClassNames: string;
    helperTextClassNames: string;
    leftIconSize: IconSizes;
    leftIconClassNames: string;
    rightIconClassNames: string;
  };
} = {
  lg: {
    paddingClassNames: 'px-xs py-sm',
    textContainerClassNames: 'flex-col mr-xs',
    labelClassNames: 'text-ds-b1 -my-xxs',
    helperTextClassNames: 'text-ds-b2',
    leftIconSize: 'md',
    leftIconClassNames: 'mr-xs',
    rightIconClassNames: 'w-lg h-lg',
  },
  xmd: {
    paddingClassNames: 'px-xs pb-xs pt-md',
    textContainerClassNames: 'flex-col mr-xs',
    labelClassNames: 'text-ds-b1 -my-xxs',
    helperTextClassNames: 'text-ds-b2',
    leftIconSize: 'md',
    leftIconClassNames: 'mr-xs',
    rightIconClassNames: 'w-lg h-lg',
  },
  md: {
    paddingClassNames: 'p-xxs',
    textContainerClassNames: 'flex-col mr-xs',
    labelClassNames: 'text-ds-b2 -my-xxs font-bold',
    helperTextClassNames: 'text-ds-sm',
    leftIconSize: 'sm',
    leftIconClassNames: 'mr-xs',
    rightIconClassNames: 'w-lg h-lg',
  },
  sm: {
    paddingClassNames: 'p-xxs pr-xs',
    textContainerClassNames: 'items-center mr-xxs',
    labelClassNames: 'text-ds-b2 mr-xxs',
    helperTextClassNames: 'text-ds-sm',
    leftIconSize: 'xs',
    leftIconClassNames: 'mr-xxs',
    rightIconClassNames: 'w-md h-md',
  },
};
export const chipVerticalClassNamesByVariant: {
  [key in ChipSize]: {
    paddingClassNames: string;
    labelClassNames: string;
    helperTextClassNames: string;
    checkBoxClassNames: string;
    centerIconSize: IconSizes;
    centerIconClassNames?: string;
  };
} = {
  lg: {
    paddingClassNames: 'px-md py-xs',
    labelClassNames: 'text-ds-b1 mt-xs',
    checkBoxClassNames: 'top-xs right-xs',
    helperTextClassNames: 'text-ds-b2',
    centerIconSize: 'lg',
  },
  xmd: {
    paddingClassNames: 'px-xs align-middle',
    labelClassNames: 'text-ds-b1 mt-xs',
    checkBoxClassNames: 'top-xs right-xs',
    helperTextClassNames: 'text-ds-b2',
    centerIconSize: 'lg',
  },
  md: {
    paddingClassNames: 'px-md py-xs',
    labelClassNames: 'text-ds-b2 mt-xs',
    helperTextClassNames: 'text-ds-sm',
    checkBoxClassNames: 'top-xs right-xs',
    centerIconSize: 'sm',
  },
  sm: {
    paddingClassNames: 'px-xs py-xxs',
    labelClassNames: 'text-ds-sm mt-xxs',
    helperTextClassNames: 'text-ds-sm',
    checkBoxClassNames: 'top-xxs right-xxs',
    centerIconSize: 'xs',
    centerIconClassNames: 'mt-xxs',
  },
};
