import React from 'react';

import { DevisItem } from '@travauxlib/shared/src/types';

import { Ligne } from './components/Ligne';
import { PageBreak } from './PageBreak';
import { TextItem } from './TextItem';

export type Props = {
  lotIndex: number;
  itemIndex: number;
  item: DevisItem;
  lotFacturationPercentage?: number;
};

export const Item: React.FC<Props> = ({ item, ...props }) => {
  if (item.type === 'pagebreak') {
    return <PageBreak />;
  }

  if (item.type === 'text') {
    return <TextItem text={item.text} />;
  }

  return <Ligne ligne={item} {...props} />;
};
