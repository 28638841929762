import React from 'react';

import GuideDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/GuideDuoTone.svg?react';
import { TagTS } from '@travauxlib/shared/src/features/SuiviChantier/components/TagTS';

import { AvancementInfo } from './AvancementInfo';

import { SuiviLot } from '../../types';
import { SuiviLotContainer } from '../SuiviLotContainer';

type Props = {
  suiviLots: SuiviLot[];
};

export const SuiviClientSummaryByDevis: React.FC<Props> = ({ suiviLots }) => (
  <div className="flex flex-col sm-desktop:gap-lg gap-md mb-md sm-desktop:mb-3xl">
    {suiviLots.map(lot => (
      <SuiviLotContainer
        key={lot.uuid}
        label={`Devis ${lot.devisNumber}`}
        Icon={() => <GuideDuoTone />}
        lignes={lot.lignes}
        validateActionOrTag={ligne => <TagTS ligne={ligne} />}
        ligneContent={ligne => (
          <AvancementInfo
            ligne={ligne}
            namePrefix={`${ligne.lotUuid}.lignes[${ligne.indexInLot}]`}
            includeUnchangedLines
          />
        )}
      />
    ))}
  </div>
);
