import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

const CLIENT_DECLARED_SOURCE_KEY = 'CLIENT_DECLARED_SOURCE';

type ApiReturn = {
  replied: boolean;
};

export const useClientDeclaredSource = (
  projectUuid: string,
): { isLoading: boolean; hasReplied: boolean } => {
  const { isLoading, data } = useQuery<ApiReturn>({
    queryKey: [CLIENT_DECLARED_SOURCE_KEY, projectUuid],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/projects/${projectUuid}/declared-sources`),
  });

  return { isLoading, hasReplied: data?.replied ?? true }; // By default don't show modal
};

const postClientDeclaredSource = (
  projectUuid: string,
  declaredSource: string,
): Promise<{ ok: boolean }> =>
  request(
    `${APP_CONFIG.apiURL}/client/projects/${projectUuid}/declared-sources/${declaredSource}`,
    {
      method: 'POST',
    },
  );

export const useSetClientDeclaredSource = (
  projectUuid: string,
): UseMutateAsyncFunction<{ ok: boolean }, unknown, string> => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: (declaredSource: string) => postClientDeclaredSource(projectUuid, declaredSource),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [CLIENT_DECLARED_SOURCE_KEY, projectUuid] }),
  });

  return mutateAsync;
};
