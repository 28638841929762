import React, { useState } from 'react';

import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useRecallModal } from 'features/Estimateur/hooks/useRecallModal';
import { Answer, Estimation } from 'features/Estimateur/types';

import { Desktop } from './components/Desktop';
import { Mobile } from './components/Mobile';

import { SendLeadModal } from '../Questions/components/SendLeadModal';

type Props = {
  estimation: Estimation;
  organizationName?: string;
  submitAnswer: (answer: Answer, index?: number) => Promise<unknown>;
  submitEstimation: (estimation: Estimation) => Promise<unknown>;
  deleteAnswer: (answerIndex: number) => Promise<unknown>;
  printUrl?: string;
  isClientEstimateur: boolean;
  affiliateToken?: string;
};

export const Result: React.FC<Props> = ({
  isClientEstimateur,
  estimation,
  organizationName,
  submitAnswer,
  submitEstimation,
  deleteAnswer,
  printUrl,
  affiliateToken,
}) => {
  const [isSendLeadModalOpen, setIsSendLeadModalOpen] = useState<boolean>(false);
  const isTabletOrMobile = useIsTabletOrMobile();
  const { toggleModal, recallModal } = useRecallModal({ estimation, affiliateToken });

  return (
    <>
      {estimation.result && isTabletOrMobile ? (
        <Mobile
          isClientEstimateur={isClientEstimateur}
          deleteAnswer={deleteAnswer}
          submitAnswer={submitAnswer}
          submitEstimation={submitEstimation}
          estimation={estimation}
          toggleRecallModal={toggleModal}
          openLeadModal={() => setIsSendLeadModalOpen(true)}
        />
      ) : (
        <Desktop
          isClientEstimateur={isClientEstimateur}
          printUrl={printUrl}
          organizationName={organizationName}
          deleteAnswer={deleteAnswer}
          submitAnswer={submitAnswer}
          submitEstimation={submitEstimation}
          estimation={estimation}
          toggleRecallModal={toggleModal}
          openLeadModal={() => setIsSendLeadModalOpen(true)}
        />
      )}
      {recallModal}
      {isSendLeadModalOpen && (
        <SendLeadModal estimation={estimation} handleClose={() => setIsSendLeadModalOpen(false)} />
      )}
    </>
  );
};
