import React from 'react';

import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { Chantier } from 'features/EspaceClient3/types';
import { formatIBANForDisplay } from 'features/EspaceClient3/utils';

type Props = {
  chantier: Chantier;
};

export const ChantierSolde: React.FC<Props> = ({ chantier }) => (
  <Card
    className="flex-grow mx-md sm-desktop:mx-0 !rounded-md"
    aria-label="Compte séquestre"
    role="section"
  >
    <div className="text-ds-b1 font-bold text-neutral-800 mb-xxs">Compte séquestre</div>
    <div className="mb-xs">
      <div className="text-ds-xs text-neutral-600 font-bold">Solde disponible</div>
      <div className="text-neutral-800 w-fit text-ds-h4 font-bold">
        <EURCurrency amount={chantier.soldeCompteSequestre} />
      </div>
    </div>
    <div className="flex text-ds-sm text-neutral-700 justify-between items-center">
      <div>IBAN :{formatIBANForDisplay(chantier)}</div>
      <ButtonLink
        href={`${APP_CONFIG.apiURL}/client/chantiers/${chantier.slug}/iban-pdf`}
        variant="tertiary"
        leftIcon={<OpenTab />}
        size="sm"
        target="_blank"
        data-testid="iban-pdf"
      />
    </div>
  </Card>
);
