import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { Checkbox as RawCheckbox, Props as CheckboxProps } from '.';

type Props = {
  name: string;
} & Omit<CheckboxProps, 'checked' | 'defaultValue'> &
  UseFieldConfig<boolean>;

export const CheckboxWrapper: React.FC<FieldRenderProps<boolean>> = ({
  input: { value, onChange, onBlur, onFocus, name },
  id,
  meta,
  ...rest
}) => {
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);
  const { touched } = meta;
  return (
    <RawCheckbox
      id={id}
      name={name}
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={touched ? error : undefined}
      {...rest}
    />
  );
};
export const CheckboxField: React.FC<Props> = props => (
  <Field component={CheckboxWrapper} {...props} />
);
