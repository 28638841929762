import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  totalTTC: number;
  isIndeterminate?: boolean;
};

export const TotalTTC: React.FC<Props> = ({ totalTTC, isIndeterminate }) => (
  <div className="flex mb-xs justify-between items-center">
    <div className="flex items-center text-ds-b2">
      <p className="mb-0 font-bold">
        Total&nbsp;<span className="font-normal text-neutral-600">TTC</span>
      </p>
    </div>
    <div data-testid="totalTTC">
      <EURCurrency amount={totalTTC} isIndeterminate={isIndeterminate} />
    </div>
  </div>
);
