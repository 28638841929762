import { PvType } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';

import { SuiviLot } from '../types';

import { getAvancementForLots } from './index';

type ThresholdInfoParams = {
  pvType: PvType;
  threshold?: number;
  isPro: boolean;
};

type ThresholdInfoReturn = {
  thresholdCard: string;
  confirmationModal: string;
};

const getThresholdInfo = ({
  pvType,
  threshold,
  isPro,
}: ThresholdInfoParams): ThresholdInfoReturn | undefined => ({
  confirmationModal:
    pvType === PvType.ReceptionChantier
      ? `Les paiements validés par le client au-delà du plafond (${threshold}%) seront libérés automatiquement dès que le procès-verbal de réception de chantier sera signé.`
      : `La libération des fonds est plafonnée à ${threshold}% tant que le PV de levée des réserves n'a pas été transmis et validé par le client.`,
  thresholdCard: isPro
    ? `Les paiements au-delà de ce plafond sont mis en attente tant que le procès-verbal de ${PvDocumentName[pvType]} n’est pas signé par les deux parties.`
    : `Dès que le procès-verbal de ${PvDocumentName[pvType]} aura été signé par les deux parties, le montant dépassant le plafond sera automatiquement libéré à l'entrepreneur.`,
});

type ComputeThresholdParams = {
  lots: SuiviLot[];
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
  hasReserves: boolean;
  isReceptionChantierPvValidated: boolean;
  isLeveeReservesPvValidated: boolean;
  isPro: boolean;
};

type ComputeThresholdReturn = {
  currentThreshold?: number;
  thresholdInfo: ReturnType<typeof getThresholdInfo>;
};

export const computeThreshold = ({
  lots,
  receptionChantierThreshold,
  leveeReservesThreshold,
  hasReserves,
  isReceptionChantierPvValidated,
  isLeveeReservesPvValidated,
  isPro,
}: ComputeThresholdParams): ComputeThresholdReturn => {
  const currentThreshold = getCurrentThreshold({
    avancementGlobal: getAvancementForLots(lots),
    receptionChantierThreshold,
    leveeReservesThreshold,
    hasReserves,
    isReceptionChantierPvValidated,
    isLeveeReservesPvValidated,
  });

  const thresholdInfo =
    currentThreshold &&
    getThresholdInfo({
      pvType: currentThreshold.type,
      threshold: currentThreshold.value,
      isPro,
    });

  return { currentThreshold: currentThreshold?.value, thresholdInfo };
};

type CurrentThresholdParams = {
  avancementGlobal: number;
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
  isReceptionChantierPvValidated: boolean;
  isLeveeReservesPvValidated: boolean;
  hasReserves: boolean;
};

type CurrentThresholdReturn = {
  type: PvType;
  value: number;
};

export const getCurrentThreshold = ({
  avancementGlobal,
  receptionChantierThreshold,
  leveeReservesThreshold,
  isReceptionChantierPvValidated,
  isLeveeReservesPvValidated,
  hasReserves,
}: CurrentThresholdParams): CurrentThresholdReturn | undefined => {
  if (isLeveeReservesPvValidated) {
    return undefined;
  }

  const isReceptionChantierThresholdReached =
    !!receptionChantierThreshold && avancementGlobal > receptionChantierThreshold;

  if (isReceptionChantierThresholdReached && !isReceptionChantierPvValidated) {
    return { type: PvType.ReceptionChantier, value: receptionChantierThreshold };
  }

  const isLeveeReservesThresholdReached =
    !!leveeReservesThreshold && avancementGlobal > leveeReservesThreshold;

  if (isLeveeReservesThresholdReached && (hasReserves || !receptionChantierThreshold)) {
    return { type: PvType.LeveeReserves, value: leveeReservesThreshold };
  }

  return undefined;
};
