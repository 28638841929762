import { filesize } from 'filesize';

export const roundToNDecimals = (value: number, decimals: number): number => {
  const factor = 10 ** decimals;
  return Math.round(value * factor) / factor;
};

export const roundToOneDecimal = (value: number): number => roundToNDecimals(value, 1);
export const roundToTwoDecimals = (value: number): number => roundToNDecimals(value, 2);

export const formatFileSize = (size: number): string =>
  filesize(size, {
    symbols: { B: 'o', MB: 'Mo', kB: 'ko' },
    base: 10,
    separator: ',',
  });

export const formatNumber = (value: number): string => value.toLocaleString().replace(/\s/g, '');

export const capitalize = (str: string): string =>
  `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;

export const truncate = (str: string, maxLength: number): string =>
  `${str.slice(0, maxLength).trim()}${str.length > maxLength ? '...' : ''}`;
