import React from 'react';

import classNames from 'classnames';
import { Field, UseFieldConfig, FieldRenderProps, useForm } from 'react-final-form';

import { GooglemapsAddress } from '@travauxlib/shared/src/types';

import { Props as PlacesAutocompleteProps } from '.';
import { PlacesAutocomplete as RawPlacesAutocomplete } from '.';

const PlacesAutocompleteWrapper: React.FC<
  FieldRenderProps<GooglemapsAddress> & PlacesAutocompleteProps
> = ({
  input: { onChange, name },
  meta: { error, dirty, touched },
  id,
  disabled,
  label,
  resetOnBlur,
  initialInputValue,
  autoSubmit,
  googleApiKey,
  onlyAllowFrance,
  className,
  placeholder,
  floatingResults,
  dense,
  ...rest
}) => {
  const form = useForm();

  return (
    <div className={classNames('!flex', className)}>
      <div className="!flex-grow">
        <RawPlacesAutocomplete
          {...rest}
          id={id}
          disabled={disabled}
          autoSubmit={autoSubmit}
          types={['geocode']}
          label={label}
          inputName={name}
          onChange={onChange}
          onSubmit={form.submit}
          error={(dirty || touched || autoSubmit) && error}
          googleApiKey={googleApiKey}
          initialInputValue={initialInputValue}
          resetOnBlur={resetOnBlur}
          floatingResults={floatingResults}
          onlyAllowFrance={onlyAllowFrance}
          placeholder={placeholder}
          dense={dense}
        />
      </div>
    </div>
  );
};

type Props = {
  name: string;
} & Omit<PlacesAutocompleteProps, 'value' | 'onChange'> &
  UseFieldConfig<GooglemapsAddress>;

export const PlacesAutocompleteField: React.FC<Props> = props => (
  <Field component={PlacesAutocompleteWrapper} {...props} />
);
