import React from 'react';

import classNames from 'classnames';

export const overlayWidth = '21rem';

type Props = {
  active: boolean;
  height: string;
  userWidth?: string;
  children: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
};
export const OverlayContainer: React.FC<Props> = ({
  children,
  active,
  height,
  userWidth = '',
  containerRef,
}) => (
  <div
    ref={containerRef}
    className={classNames(
      `overflow-y-scroll overflow-x-auto bg-white max-w-[100vw] transition-[width] duration-[200ms] ${height}`,
      {
        'w-0': !active,
        'w-[21rem]': active && !userWidth,
        [userWidth]: active && userWidth,
      },
    )}
  >
    {children}
  </div>
);
