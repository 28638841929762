import React from 'react';

import classNames from 'classnames';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Prestations } from '@travauxlib/shared/src/types';

type Props = {
  totalHT: number;
  prixHTAvantRemise?: number;
  small?: boolean;
  prixTotalFournitureHT?: number;
  prixTotalMainOeuvreHT?: number;
  onChange?: (prestations: Partial<Prestations>) => void;
  showFournitures?: boolean;
  showMainOeuvre?: boolean;
  isEstimateur?: boolean;
};

export const TotalHT: React.FC<Props> = props => {
  const {
    totalHT,
    prixHTAvantRemise,
    small,
    prixTotalFournitureHT,
    prixTotalMainOeuvreHT,
    onChange,
    showFournitures = true,
    showMainOeuvre = false,
    isEstimateur,
  } = props;

  return (
    <>
      {!!prixHTAvantRemise && (
        <div
          className={classNames('!flex !justify-between !items-center', {
            '!mb-md': !small,
          })}
        >
          <div className={classNames({ '!text-ds-sm': small })}>Total HT avant remise</div>
          <div data-testid="prixHTAvantRemise" className="!text-right">
            <EURCurrency amount={prixHTAvantRemise} />
          </div>
        </div>
      )}
      <>
        <div className="!flex !justify-between !items-center">
          <div
            className={classNames({
              'font-h4 !font-bold !mb-0': !small,
              'text-ds-sm': small,
            })}
          >
            Total&nbsp;HT
          </div>
          <div
            data-testid="totalHT"
            className={classNames('!mb-0 !text-right !font-bold', {
              'text-ds-b1': small,
              'text-ds-h5': !small,
            })}
          >
            <EURCurrency amount={totalHT} />
          </div>
        </div>
        {!!prixTotalFournitureHT && (
          <div className="!py-md">
            <div className="!flex !justify-between !items-center !mb-sm">
              <div>Dont fournitures</div>
              <div data-testid="prixHTAvantRemise" className="!text-right">
                <EURCurrency amount={prixTotalFournitureHT} />
              </div>
            </div>
            {!isEstimateur && onChange && (
              <div>
                <Checkbox
                  id="showFournitures"
                  label="Afficher le prix des fournitures au client"
                  checked={showFournitures}
                  onChange={checked => onChange({ showFournitures: checked })}
                />
              </div>
            )}
          </div>
        )}
        {!!prixTotalMainOeuvreHT && (
          <div className="!py-md">
            <div className="!flex !justify-between !items-center !mb-sm">
              <div>Dont main d'œuvre</div>
              <div data-testid="prixTotalMainOeuvreHT" className="!text-right">
                <EURCurrency amount={prixTotalMainOeuvreHT} />
              </div>
            </div>
            {!isEstimateur && onChange && (
              <div>
                <Checkbox
                  id="showMainOeuvre"
                  label="Afficher le prix des mains d'œuvre au client"
                  checked={showMainOeuvre}
                  onChange={checked => onChange({ showMainOeuvre: checked })}
                />
              </div>
            )}
          </div>
        )}
      </>
    </>
  );
};
