import React from 'react';

import classNames from 'classnames';

export interface DropdownOption<T> {
  label: string;
  value: T;
  icon?: React.ComponentType<React.SVGProps<SVGElement>>;
  disabled?: boolean;
}

export interface DropdownOptionProps<T> {
  selected: boolean;
  option: DropdownOption<T>;
  onOptionClick: (newOption: DropdownOption<T>) => void;
  className?: string;
  small?: boolean;
}

const dropDownOptionsClassnames = (
  selected: boolean,
  disabled?: boolean,
  small?: boolean,
): string =>
  classNames(
    'flex items-center px-md py-sm text-ds-b1 h-[3rem] cursor-pointer',
    'hover:bg-neutral-100',
    {
      'bg-neutral-200': selected,
      'text-neutral-300 bg-neutral-100 pointer-events-none': disabled,
      '!px-xs !py-xxs !h-[1.75rem]': small,
    },
  );

export function DropdownInputOption<T>({
  option,
  selected,
  onOptionClick,
  className,
  small = false,
}: DropdownOptionProps<T>): JSX.Element {
  return (
    <div
      role="option"
      aria-selected={selected}
      className={classNames(className, dropDownOptionsClassnames(selected, option.disabled, small))}
      onClick={() => onOptionClick(option)}
    >
      {option.icon && (
        <div className="w-lg mr-xxs -ml-xxs">
          <option.icon width={24} />
        </div>
      )}
      <span className="truncate">{option.label}</span>
    </div>
  );
}
