// Extracted with
//
// ```
// $ libphonenumber-generate-metadata metadata.min.json --countries FR --extended
// ```
export const metadataFR = {
  country_phone_code_to_countries: { 33: ['FR'] },
  countries: {
    FR: [
      '33',
      '[1-9]\\d{8}',
      [
        ['([1-79])(\\d{2})(\\d{2})(\\d{2})(\\d{2})', '$1 $2 $3 $4 $5', ['[1-79]']],
        ['(8\\d{2})(\\d{2})(\\d{2})(\\d{2})', '$1 $2 $3 $4', ['8'], '0 $1'],
      ],
      '0',
      '0$1',
      null,
      null,
      null,
      null,
      [
        '[1-5]\\d{8}',
        '(?:6\\d|7[3-9])\\d{7}',
        '80[0-5]\\d{6}',
        '89[1-37-9]\\d{6}',
        null,
        null,
        '80[6-9]\\d{6}',
        null,
        '9\\d{8}',
        '8(?:1[0-29]|2[0156]|84|90)\\d{6}',
      ],
    ],
  },
};
