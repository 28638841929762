import React from 'react';

import { Outlet } from 'react-router-dom';

import { BonCommandePrint } from 'features/BonCommande/features/Print';
import { WithBonCommande } from 'features/BonCommande/hocs/WithBonCommande';
import { DevisProPrint } from 'features/DevisPro/features/Print';
import { WithDevisPro } from 'features/DevisPro/hocs/WithDevisPro';
import { EstimateurPrint } from 'features/Estimateur/features/Print';
import { FacturePrint } from 'features/Factures/features/Print';
import { WithFacture } from 'features/Factures/hocs/WithFacture';
import { SuiviChantierPrint } from 'features/SuiviChantier/features/Print';
import { WithSuiviChantier } from 'features/SuiviChantier/hocs/WithSuiviChantier';

const DevisProPrintWithData = WithDevisPro<{
  byLocations?: boolean;
}>(DevisProPrint);

const FacturePrintWithData = WithFacture<{}>(FacturePrint);

const BonCommandePrintWithData = WithBonCommande<{}>(BonCommandePrint);
const SuiviChantierPrintWithData = WithSuiviChantier<{}>(SuiviChantierPrint);

export const Index: React.FC<{}> = () => (
  <main data-testid="print" className="h-full w-full">
    <Outlet />
  </main>
);

export const Print = {
  Index,
  BonCommande: BonCommandePrintWithData,
  SuiviChantier: SuiviChantierPrintWithData,
  Facture: FacturePrintWithData,
  DevisPro: DevisProPrintWithData,
  Estimateur: EstimateurPrint,
};
