import React, { FC } from 'react';

import { QuestionType, StepConfig } from 'features/OnboardingSolide/types';

import { InputQuestion } from './components/InputQuestion';
import { LocalisationQuestion } from './components/LocalisationQuestion';
import { MultiSelectQuestion } from './components/MultiSelectQuestion';
import { NumberInputQuestion } from './components/NumberInputQuestion';
import { SelectQuestion } from './components/SelectQuestion';

type Props = {
  step: StepConfig;
  setNextStep: () => void;
};

export const QuestionSelector: FC<Props> = ({ step, setNextStep }) => {
  switch (step.type) {
    case QuestionType.Select:
      return <SelectQuestion {...step} setNextStep={setNextStep} />;
    case QuestionType.MultiSelect:
      return <MultiSelectQuestion {...step} setNextStep={setNextStep} />;
    case QuestionType.Input:
      return <InputQuestion {...step} setNextStep={setNextStep} />;
    case QuestionType.TextArea:
      return <InputQuestion {...step} setNextStep={setNextStep} multiLine />;
    case QuestionType.NumberInput:
      return <NumberInputQuestion {...step} setNextStep={setNextStep} />;
    case QuestionType.Localisation:
      return <LocalisationQuestion {...step} setNextStep={setNextStep} />;
  }
};
