import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { RadioButtonsList as RawRadioButtonsList, Props as RadioButtonsListProps } from '.';

type Props<T> = {
  name: string;
} & Omit<RadioButtonsListProps<T>, 'value' | 'onChange'> &
  UseFieldConfig<T>;

type RadioButtonsListWrapperProps<T> = FieldRenderProps<T> &
  Omit<RadioButtonsListProps<T>, 'onChange' | 'value'>;

export function RadioButtonsListWrapper<T>({
  input: { onChange, value },
  meta,
  ...rest
}: RadioButtonsListWrapperProps<T>): JSX.Element {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);
  return (
    <RawRadioButtonsList value={value} onChange={onChange} {...rest} error={touched && error} />
  );
}

export function RadioButtonsListField<T>(props: Props<T>): JSX.Element {
  return <Field component={RadioButtonsListWrapper} {...props} />;
}
