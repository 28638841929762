import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const SUIVI_CHANTIERS_KEY = 'suivi-chantiers';

export const createSuiviChantierHistoryList =
  (actor: string, APP_CONFIG: { apiURL: string }) =>
  (
    chantierUuidOrSlug: string,
  ): { suiviChantierHistoryList: SuiviChantierCycleHistory[]; isLoading: boolean } => {
    const { data, isLoading } = useQuery<{ data: SuiviChantierCycleHistory[] } | undefined>({
      queryKey: [SUIVI_CHANTIERS_KEY, chantierUuidOrSlug],
      queryFn: async () => {
        try {
          return await request(
            `${APP_CONFIG.apiURL}/${actor}/chantiers/${chantierUuidOrSlug}/suivi-chantier-history`,
          );
        } catch (err) {
          toast.error("L'historique des suivis n'ont pas pu être récupérés");
          throw err;
        }
      },
    });

    return { suiviChantierHistoryList: data?.data || [], isLoading };
  };
