export const replaceItemAtIndex = <T>(list: T[], item: T, index: number): T[] => {
  if (index === list.length - 1) {
    return [...list.slice(0, index), item];
  }

  return [...list.slice(0, index), item, ...list.slice(index + 1, list.length)];
};

export const addItemAtIndex = <T>(list: T[], item: T, index: number): T[] => {
  if (index >= list.length) {
    return [...list, item];
  }

  return [...list.slice(0, index), item, ...list.slice(index, list.length)];
};

export const removeItemAtIndex = <T>(list: T[], index: number): T[] => {
  if (index > list.length - 1) {
    return [...list.slice(index - 1)];
  }

  return [...list.slice(0, index), ...list.slice(index + 1, list.length)];
};

export const changeItemIndex = <T>(list: T[], previousIndex: number, newIndex: number): T[] => {
  const item = list[previousIndex];
  return addItemAtIndex(removeItemAtIndex(list, previousIndex), item, newIndex);
};

export const swap = <T>(list: T[], index: number, diff: -1 | 1): T[] =>
  changeItemIndex(list, index, index + diff);
