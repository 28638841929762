import dayjs, { Dayjs } from 'dayjs';

import { typeTravauxOptions } from '@travauxlib/shared/src/utils/constants';

import {
  ComputedStepConfigs,
  LocalisationStepConfig,
  OnboardingEventProperty,
  ProjectQuestion,
  QuestionType,
  SelectStepConfig,
  TextAreaStepConfig,
} from 'features/OnboardingSolide/types';

import { constructionSteps } from './constructionSteps';
import { renovationSteps } from './renovationSteps';

import { baseImgixOnboardingUrl } from '../constants';

const needStep: SelectStepConfig = {
  id: ProjectQuestion.Need,
  type: QuestionType.Select,
  title: 'En quoi hemea peut vous aider ?',
  helperText: 'Sélectionnez la situation qui décrit le mieux votre projet',
  options: [
    {
      label: 'Je recherche une entreprise pour réaliser mes travaux',
      formattedLabel: 'Recherche une entreprise',
      value: 'pas_entreprise',
    },
    {
      label: "J'ai besoin d'un devis pour ma banque",
      formattedLabel: 'Devis pour banque',
      value: 'banque',
    },
    {
      label: "Je recherche de l'inspiration et des conseils",
      formattedLabel: 'Inspiration et conseils',
      value: 'inspiration',
    },
    {
      label: 'Je veux une estimation pour mon projet',
      formattedLabel: 'Besoin estimation',
      value: 'estimation',
    },
    {
      label: 'Je ne sais pas',
      formattedLabel: 'Je ne sais pas',
      value: 'N/A',
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-1.png`,
  avis: {
    content: 'Leur professionnalisme et leur réactivité ont participé au succès du chantier',
    author: 'Agathe',
  },
  eventKey: OnboardingEventProperty.ProjectNeed,
};

const localisationStep: LocalisationStepConfig = {
  id: ProjectQuestion.Localisation,
  type: QuestionType.Localisation,
  title: 'Quel est le code postal de votre chantier ?',
  label: 'Code postal',
  helperText: 'Le code postal permet d’affiner l’estimation du prix de vos travaux',
  imageUrl: `${baseImgixOnboardingUrl}/onboard-7.png`,
  avis: {
    content:
      'hemea nous a accompagné de la meilleure des façons dans la réalisation de notre projet',
    author: 'Mikael',
  },
  eventKey: OnboardingEventProperty.ProjectLocalisation,
};

const typeProjetStep: SelectStepConfig = {
  id: ProjectQuestion.TypeProjet,
  type: QuestionType.Select,
  title: 'Quel type de projet souhaitez-vous réaliser ?',
  helperText: 'Sélectionnez un élément qui décrit le mieux votre projet',
  options: typeTravauxOptions,
  imageUrl: `${baseImgixOnboardingUrl}/onboard-2.png`,
  avis: {
    content:
      'Première fois pour nous dans l’univers des travaux, hemea nous a vraiment bien accompagné',
    author: 'Camille',
  },
  eventKey: OnboardingEventProperty.ProjectType,
};

const dateTravauxStep: (now: Dayjs) => SelectStepConfig = now => ({
  id: ProjectQuestion.DateTravaux,
  type: QuestionType.Select,
  title: 'Quand souhaitez-vous commencer les travaux ?',
  helperText: 'En fonction de l’urgence des travaux, le coût de votre projet peut évoluer',
  options: [
    {
      label: `Avant ${now.add(3, 'month').format('MMMM YYYY')}`,
      value: '0-3',
    },
    {
      label: `Entre ${now.add(3, 'month').format('MMMM YYYY')} et ${now
        .add(6, 'month')
        .format('MMMM YYYY')}`,
      value: '3-6',
    },
    {
      label: `Après ${now.add(6, 'month').format('MMMM YYYY')}`,
      value: '6+',
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-11.png`,
  avis: {
    content:
      'Je suis pleinement satisfait de mes travaux et du sérieux de l’accompagnement de hemea',
    author: 'Marc',
  },
  eventKey: OnboardingEventProperty.ProjectStartDate,
});

const besoinArchitecteStep: SelectStepConfig = {
  id: ProjectQuestion.BesoinArchitecte,
  type: QuestionType.Select,
  title: "Pensez-vous avoir besoin d'un architecte ?",
  helperText:
    'Grâce à l’accompagnement hemea, une équipe d’architectes peut vous aider sur l’aménagement, la structure, l’architecture d’intérieur',
  options: [
    {
      label: 'Oui',
      value: 'oui',
      formattedLabel: "Besoin d'un architecte",
    },
    {
      label: 'Non',
      value: 'non_pas_besoin',
      formattedLabel: "Pas besoin d'un architecte",
    },
    {
      label: "J'en ai déjà un",
      value: 'non_deja_architecte',
      formattedLabel: 'A déjà un architecte',
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-9.png`,
  avis: {
    content: 'Accompagnement, conseil et suivi au top',
    author: 'Jérôme',
  },
  eventKey: OnboardingEventProperty.ArchitectNeed,
};

const critereSelectionStep: SelectStepConfig = {
  id: ProjectQuestion.CritereSelection,
  type: QuestionType.Select,
  title: 'Quel est le critère de choix le plus important ?',
  helperText: 'Sélectionnez un élément qui décrit le mieux votre projet',
  options: [
    {
      label: 'Qualité',
      value: 'qualite',
    },
    {
      label: 'Délais',
      value: 'delais',
    },
    {
      label: 'Prix',
      value: 'prix',
    },
    {
      label: 'Conseils',
      value: 'conseils',
    },
    {
      label: 'Suivi',
      value: 'accompagnement',
    },
    {
      label: 'Autre',
      value: 'other',
      subLabel: 'Critère',
      isOtherAnswer: true,
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-6.png`,
  avis: {
    content: 'Une rénovation en toute confiance',
    author: 'Julie',
  },
  eventKey: OnboardingEventProperty.MainCriteria,
};

const budgetStep: SelectStepConfig = {
  id: ProjectQuestion.Budget,
  type: QuestionType.Select,
  title: 'Quel est votre budget ?',
  helperText:
    'Le budget que vous nous indiquez doit intégrer le coût de l’architecte. Le chef de projet sera à vos côtés pour affiner cette estimation',
  options: [
    {
      label: 'Moins de 10 000\xa0€',
      value: '0-10000',
    },
    {
      label: 'Entre 10 000\xa0€ et 30 000\xa0€',
      value: '10000-30000',
    },
    {
      label: 'Entre 30 000\xa0€ et 50 000\xa0€',
      value: '30000-50000',
    },
    {
      label: 'Entre 50 000\xa0€ et 100 000\xa0€',
      value: '50000-100000',
    },
    {
      label: 'Entre 100 000\xa0€ et 250 000\xa0€',
      value: '100000-250000',
    },
    {
      label: 'Plus de 250 000\xa0€',
      value: '+250000',
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-8.png`,
  avis: {
    content: 'Nos interlocuteurs chez hemea ont été d’excellents conseils',
    author: 'Alban',
  },
  eventKey: OnboardingEventProperty.Budget,
};

const descriptionStep: TextAreaStepConfig = {
  id: ProjectQuestion.Description,
  type: QuestionType.TextArea,
  title: 'Voulez-vous décrire plus en détail votre projet ?',
  helperText: 'Merci de ne pas partager vos coordonnées ici',
  label: 'Décrire mon projet',
  isSkippable: true,
  imageUrl: `${baseImgixOnboardingUrl}/onboard-5.png`,
  avis: {
    content: 'Je suis sûr d’être entre de bonnes mains',
    author: 'Patrick',
  },
  eventKey: OnboardingEventProperty.ProjectDetail,
};

export const projectSteps: ComputedStepConfigs = values => [
  needStep,
  localisationStep,
  typeProjetStep,
  ...(values?.typeProjet === 'construction' ? constructionSteps : renovationSteps(values)),
  dateTravauxStep(dayjs()),
  besoinArchitecteStep,
  critereSelectionStep,
  budgetStep,
  descriptionStep,
];
