import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { ClientCustomerSatisfaction } from 'types';

import { CLIENT_CUSTOMER_SATISFACTION_KEY } from './useCreateClientCustomerSatisfaction';

export const useGetClientCustomerSatisfaction = (
  projectUuid: string,
): { isLoading: boolean; clientCustomerSatisfaction: ClientCustomerSatisfaction[] } => {
  const { isLoading, data } = useQuery<ClientCustomerSatisfaction[]>({
    queryKey: [CLIENT_CUSTOMER_SATISFACTION_KEY],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/client/customer-satisfaction/project/${projectUuid}`),
  });

  return { isLoading, clientCustomerSatisfaction: data || [] };
};
