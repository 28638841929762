import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useProject } from 'features/EspaceClient3/api/useProject';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { MainRecap } from './components/MainRecap';
import { SuiviChantierDisabled } from './components/SuiviChantierDisabled';

export const SuiviChantierRecap: React.FC = () => {
  const navigate = useNavigate();
  const { uuid, chantierUuid } = useParams<{ uuid: string; chantierUuid: string }>();
  const { project, isLoading } = useProject(uuid!);

  useEffect(() => {
    if (project) {
      EspaceClientTracking.onEspaceClientChantierViewed({
        'Project Uuid': project.uuid,
        'Project Name': project.title,
        'Project Phase': project.etape,
        Empty: false,
      });
    }
  }, [project]);

  if (isLoading) {
    return <LoaderWrapper />;
  }

  if (!chantierUuid) {
    navigate('/');
    return null;
  }
  const shouldDisplaySuiviRecap = project?.chantiers.find(
    chantier => chantier.uuid === chantierUuid,
  )?.suiviChantierEnabled;

  return shouldDisplaySuiviRecap ? (
    <MainRecap chantierUuid={chantierUuid} />
  ) : (
    <SuiviChantierDisabled />
  );
};
