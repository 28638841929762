import { Lot, MontantTVA } from '../types';

export const shouldDisplayAutoliquidation = (lots: Lot[], montantsTVA: MontantTVA[]): boolean => {
  const hasLigneInPrestation = lots.some(lot => lot.items.some(item => item.type === 'ligne'));

  const allLigneHaveStatusToEdit = lots.every(lot =>
    lot.items.filter(item => item.type === 'ligne').every(item => item.status === 'to_edit'),
  );

  return montantsTVA.length === 0 && hasLigneInPrestation && !allLigneHaveStatusToEdit;
};
