import React from 'react';

import _partition from 'lodash/partition';

import { Company, Insurance } from '@travauxlib/shared/src/types/company';
import { isURLAnImage } from '@travauxlib/shared/src/utils/urls';

import { Attachment as AttachmentType } from 'types';

import { Attachment } from './Attachment';

type Props = {
  isHemeaDeal: boolean;
  attachments: AttachmentType[];
  company: Company;
  className?: string;
  insurances: Insurance[];
};

export const getRibLabelFromUrl = (s: string): string => {
  const res = s.split('/');
  return res[res.length - 1];
};

export const Attachments: React.FC<Props> = ({ attachments, isHemeaDeal, company, insurances }) => {
  const { ribUrl } = company;

  const allAttachments =
    ribUrl && !isHemeaDeal
      ? [
          ...attachments,
          {
            label: getRibLabelFromUrl(ribUrl),
            fileUrl: ribUrl,
          },
        ]
      : attachments;

  if (insurances.length === 0 && allAttachments.length === 0) {
    return null;
  }

  const [imagesAttachments, otherAttachments] = _partition(allAttachments, ({ fileUrl }) =>
    isURLAnImage(fileUrl),
  );

  return (
    <div className="mb-xxl break-inside-avoid">
      <div className="text-ds-h4 font-medium mb-md">Pièces jointes</div>
      <div className="flex flex-wrap">
        {imagesAttachments.map(({ fileUrl, label }) => (
          <Attachment key={fileUrl} fileUrl={fileUrl} label={label} />
        ))}
      </div>
      <div className="flex flex-wrap">
        {otherAttachments.map(({ fileUrl, label }) => (
          <Attachment key={fileUrl} fileUrl={fileUrl} label={label} />
        ))}
      </div>
      <div className="flex flex-wrap">
        {insurances.map(({ file, label }) => (
          <Attachment key={file} fileUrl={file} label={label} />
        ))}
      </div>
    </div>
  );
};
