import React from 'react';

import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { SuiviLot } from '@travauxlib/shared/src/types';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';
import { getCompletionFromLignes } from '@travauxlib/shared/src/utils/getCompletionFromLignes';

type Props = {
  lot: SuiviLot;
  lotIndex: number;
};

export const SuiviLotClient: React.FC<Props> = ({ lot, lotIndex }) => {
  const completionLot = getCompletionFromLignes(lot.lignes);

  return (
    <div className="text-left px-md">
      <h4 className="font-bold flex justify-between">
        <span>
          <span className="text-gray-600 !text-ds-sm pt-xxs pr-sm">{`${lotIndex + 1}.0`}</span>
          {lot.label}
        </span>
        <span>{completionLot.percentage} %</span>
      </h4>
      <div className="pt-sm">
        {lot.lignes.map((ligne, indexLigne) => (
          <div key={ligne.designation} className="text-left mb-md">
            <div className="flex justify-between w-full p-xs">
              <div className="min-w-1/2 flex">
                <div className="text-gray-600 text-ds-sm pt-xxs px-sm">{`${lotIndex + 1}.${
                  indexLigne + 1
                }`}</div>
                <div>{ligne.designation}</div>
              </div>
              <div className="text-right flex">
                <div className="whitespace-nowrap">
                  <QuantityLabel quantite={ligne.quantite} unite={ligne.unite} />
                </div>
                <div className="px-md">
                  {roundToTwoDecimals(ligne.prixUnitaireHT * ligne.quantite)} €
                </div>
                <div>{ligne.completionPercentage} %</div>
              </div>
            </div>
            {ligne.comment && (
              <div className="ml-lg pl-lg text-gray-600">
                <div className="italic">{ligne.comment}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
