import React from 'react';

import classNames from 'classnames';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { certificationsRGE } from '@travauxlib/shared/src/utils/selfDeclaredCertifications';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { Devis } from 'types';

import { Assurance } from './Assurance';
import { Delais } from './Delais';
import { RGEContractorDetails } from './RGEContractorDetails';

type Props = {
  devis: Devis;
  className?: string;
  devisSummary: React.ReactNode;
};

export const Summary: React.FC<Props> = ({ devis, className, devisSummary }) => {
  if (!devis.proContext) {
    return <>{devisSummary}</>;
  }

  const {
    dateDebut,
    deal: { isHemea },
    company: { isArchitecte },
    dateFin,
    insurances,
    hasSignedDevisPdf,
    isRGE,
    contractors,
    proCompanyCertificationsRGE,
  } = devis.proContext;

  return (
    <div data-testid="summary" className={className}>
      <div className="flex flex-wrap -mx-xs sm-desktop:-mx-md mb-xmd">
        {isRGE ? (
          <div className="w-full px-md flex flex-col sm-desktop:flex-row sm-desktop:justify-between gap-md">
            {dateDebut && (
              <Delais
                isHemeaDeal={isHemea}
                isArchitecte={isArchitecte}
                dateDebut={dateDebut}
                dateFin={dateFin}
                isRGE={isRGE}
              />
            )}
            <div className="mb-md grow">{insurances && <Assurance insurances={insurances} />}</div>
          </div>
        ) : (
          <div className="mb-xmd sm-desktop:mb-0 w-full px-md sm-desktop:max-w-1/2">
            {dateDebut && !hasSignedDevisPdf && (
              <Delais
                isHemeaDeal={isHemea}
                isArchitecte={isArchitecte}
                dateDebut={dateDebut}
                dateFin={dateFin}
                isRGE={isRGE}
              />
            )}
            <div className="mb-xmd">
              {isHemea && !isArchitecte && insurances && <Assurance insurances={insurances} />}
            </div>
          </div>
        )}

        <div
          className={classNames('px-md flex flex-wrap', isRGE ? 'w-full gap-md' : 'w-full h-full')}
        >
          {contractors && contractors.length !== 0 && (
            <RGEContractorDetails contractors={contractors!} />
          )}
          {isRGE && proCompanyCertificationsRGE?.length !== 0 && (
            <DevisFactureCard
              title="Certifications RGE"
              className={classNames(
                'w-full',
                contractors?.length !== 0 ? 'sm-desktop:w-1/2' : 'sm-desktop:max-w-1/2',
              )}
            >
              <div className="flex flex-col tablet:flex-row tablet:flex-wrap sm-desktop:flex-col">
                {proCompanyCertificationsRGE?.map(certification => {
                  const certificationDisplay = certificationsRGE.find(
                    certif => certif.type === certification.certificationType,
                  )!;

                  return (
                    <div className="flex items-center mb-xs" key={certificationDisplay.name}>
                      <img
                        src={`${baseImgixUrl}/static/logo-certifications/${certificationDisplay.image}`}
                        alt=""
                        className="!mx-md max-w-xl shrink-0 sm-desktop:max-w-4xl"
                      />
                      <div className="flex flex-col">
                        <Link
                          className="font-bold whitespace-nowrap text-ds-b1"
                          href={certification.downloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {certificationDisplay.name}
                        </Link>
                        <div>
                          Valable du {formatFrenchDate(certification.dateDebutValidite)} au{' '}
                          {formatFrenchDate(certification.dateFinValidite)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </DevisFactureCard>
          )}
          {devisSummary}
          {!isRGE && dateDebut && hasSignedDevisPdf && (
            <div className="flex mt-md w-full">
              <Delais
                isHemeaDeal={isHemea}
                isArchitecte={isArchitecte}
                dateDebut={dateDebut}
                dateFin={dateFin}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
