import React from 'react';

import { Link } from '../Links';

type Props = {
  actionLabel?: string;
  href?: string;
  to?: string;
  onClick?: () => void;
};

export const ActionLink: React.FC<Props> = ({ href, to, actionLabel, onClick }) => (
  <Link
    className="ml-xxs py-[2px] self-start text-nowrap"
    href={href}
    to={to}
    onClick={onClick}
    variant="secondary"
    inline
  >
    {actionLabel || 'Action'}
  </Link>
);
