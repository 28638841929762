import axios, { AxiosError, AxiosResponse } from 'axios';

import { stringifyParams } from './urls';

export const isAxiosError = (error: AxiosError | Error): error is AxiosError => 'response' in error;

export const isNotFound = (error: Error | AxiosError): boolean =>
  isAxiosError(error) && error?.response?.status === 404;

export const isConflict = (error: Error | AxiosError): boolean =>
  isAxiosError(error) && error?.response?.status === 409;

export const isForbidden = (error: Error | AxiosError): boolean =>
  isAxiosError(error) && error?.response?.status === 403;

export const isUnauthorized = (error: Error | AxiosError): boolean =>
  isAxiosError(error) && error?.response?.status === 401;

export const isInternalServerError = (error: Error | AxiosError): boolean =>
  isAxiosError(error) && error?.response?.status === 500;

export function checkStatus(response: Response): Response | undefined {
  if (response.ok) {
    return response;
  }

  throw new Error(`${response.status} ${response.statusText}`);
}

export const cleanError = (error: AxiosError): void => {
  const payload = error.response;
  if (typeof payload?.config?.data === 'string' && payload.config.data.includes('password')) {
    payload.config.data = '';
  }

  throw error;
};

export type Options = {
  params?: object;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: object | string;
  headers?: {
    ['Content-Type']?: string;
  };
  credentials?: 'include';
  responseType?: 'blob';
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  returnFullResponse?: boolean;
};

export const formatOptions = (url: string, opt: Options): object => {
  const options = {
    ...opt,
    headers: {
      'Content-Type': opt.body instanceof FormData ? 'multipart/form-data' : 'application/json',
      ...opt.headers,
    },
  };
  return {
    ...options,
    url,
    paramsSerializer: stringifyParams,
    withCredentials: true,
    method: options.method ? options.method : 'GET',
    data: options.body,
  };
};

export async function request(url: string, opt: Options = {}): Promise<any> {
  const { returnFullResponse, ...otherOpts } = opt;
  const options = formatOptions(url, otherOpts);

  return axios(options)
    .then((response: AxiosResponse) => {
      if (response.status === 204) {
        return null;
      }

      return returnFullResponse ? response : response.data;
    })
    .catch(cleanError);
}
