import React from 'react';

import { useNavigate } from 'react-router-dom';

import ChantierPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ChantierPicto.svg?react';
import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { Project } from 'features/EspaceClient3/types';

import { Title } from './Title';

import { nonAddressableExplanations } from '../utils';

type Props = {
  project: Project;
};

export const ProjectNonAddressable: React.FC<Props> = ({ project }) => {
  const navigate = useNavigate();
  const nonAddressableExplanation =
    nonAddressableExplanations[project.nonAdressableReason!] ||
    nonAddressableExplanations['default'];

  return (
    <div className="mx-md sm-desktop:mx-0">
      <Title additionalPresentationInfo={project.additionalPresentationInfo} />
      <EmptyState
        className="mb-md"
        illustration={<ChantierPicto />}
        iconIllustrationSize="md"
        title={nonAddressableExplanation!.title}
        description={nonAddressableExplanation!.description}
        primaryAction={{
          label: 'Estimer un autre projet',
          onClick: () => navigate('/onboarding/project'),
        }}
      />
      <div className="text-center">
        <Link href="https://www.hemea.com" size="lg" icon={<OpenTab />} variant="secondary">
          Consulter notre site
        </Link>
      </div>
    </div>
  );
};
