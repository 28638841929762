import { useMutation, UseMutateAsyncFunction } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Estimation, RecallFormData } from 'features/Estimateur/types';

type SubmitLeadPayload = {
  estimation?: Estimation;
  affiliateToken?: string;
} & RecallFormData;

const handleSubmitLead = async (payload: SubmitLeadPayload): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/client/estimateurs/public/leads`;

  await request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useSubmitLeadFromPublicEstimateur = (): UseMutateAsyncFunction<
  void,
  unknown,
  SubmitLeadPayload
> => {
  const { mutateAsync: submitLead } = useMutation({
    mutationFn: handleSubmitLead,
    onSuccess: () => {
      toast.success('Merci, nous avons bien reçu votre demande de rappel');
    },
  });

  return submitLead;
};
