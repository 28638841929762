import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { DatePicker as RawDatePicker, Props as DatePickerProps } from '.';

export type WrapperProps = Omit<DatePickerProps, 'value' | 'onChange'> & FieldRenderProps<any>;

const DatePickerWrapper: React.FC<WrapperProps> = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  ...rest
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);
  return (
    <RawDatePicker
      value={value}
      onChange={onChange}
      error={touched && error}
      onBlur={onBlur}
      onFocus={onFocus}
      {...rest}
    />
  );
};

type Props = {
  name: string;
} & Omit<DatePickerProps, 'value' | 'onChange'> &
  UseFieldConfig<any>; //TODO: fix datePicker any

export const DatePickerField: React.FC<Props> = props => (
  <Field component={DatePickerWrapper} {...props} />
);
