import React from 'react';

import { DevisFactureTag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/DevisFactureTag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { FactureMinimalView } from 'types';

type Props = {
  facturesList: FactureMinimalView[];
};
export const LinkedFactureDisplay: React.FC<Props> = ({ facturesList }) => {
  const items = facturesList.map(facture => (
    <div
      key={facture.token}
      className="mb-lg sm-desktop:mb-0 flex flex-row flex-wrap items-start justify-end sm-desktop:justify-between"
    >
      <div className="flex items-start w-full sm-desktop:w-10/12 my-xs">
        <div className="w-1/2">{facture.title}</div>
        <div className="w-1/4 text-left">{facture.numero}</div>
        <DevisFactureTag status={facture.status} />
      </div>
      <div>
        {facture.isAvoir && '- '}
        {facture.prixTotalHT}€ HT
      </div>
    </div>
  ));

  return (
    <>
      {items}
      <div className="flex flex-row items-center justify-between font-bold pt-xl">
        <div>Total déjà facturé</div>
        <EURCurrency
          amount={facturesList.reduce(
            (prev, facture) =>
              prev + (facture.isAvoir ? -facture.prixTotalHT : facture.prixTotalHT),
            0,
          )}
          suffix=" HT"
        />
      </div>
    </>
  );
};
