import React from 'react';

import classNames from 'classnames';

import { LegalFooter } from './LegalFooter';

export const MainContentLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div
    className={classNames(
      'sm-desktop:col-start-5 sm-desktop:col-end-10 md-desktop:col-end-9',
      'flex flex-col sm-desktop:py-xl',
    )}
  >
    {children}
    <div className="hidden sm-desktop:block">
      <LegalFooter />
    </div>
  </div>
);
