import React from 'react';

import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

type OwnProps = {
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
  openCrisp?: () => void;
  isForPro?: boolean;
};

type Props = OwnProps & {
  handleClose: () => void;
};

const ThresholdModal: React.FC<Props> = ({
  handleClose,
  receptionChantierThreshold,
  leveeReservesThreshold,
  openCrisp,
  isForPro,
}) => {
  const endWording = isForPro ? 'transmis et validé par le client' : 'signé par les deux parties';

  return (
    <ModalContent
      validateAction={{ label: 'Fermer', onClick: handleClose }}
      cancelAction={openCrisp && { label: 'Nous contacter', onClick: openCrisp }}
    >
      <div className="flex flex-col gap-xs text-ds-b2 text-neutral-600">
        <div>hemea a mis en place un système de plafonnement des paiements sur les chantiers.</div>
        {receptionChantierThreshold && (
          <div>
            Les paiements sont plafonnés à{' '}
            <span className="text-neutral-700 font-medium">
              {receptionChantierThreshold}% du montant total des travaux
            </span>{' '}
            tant que le procès-verbal de réception de chantier n’a pas été {endWording}.
          </div>
        )}
        {leveeReservesThreshold && (
          <div>
            Les paiements sont plafonnés à{' '}
            <span className="text-neutral-700 font-medium">
              {leveeReservesThreshold}% du montant total des travaux
            </span>{' '}
            s’il y a des réserves et que le procès-verbal de levée des réserves n’a pas été{' '}
            {endWording}.
          </div>
        )}
        {isForPro && (
          <div className="text-neutral-700 font-medium">
            Continuez à envoyer vos états d'avancement pour informer le client de la progression du
            chantier.
          </div>
        )}
      </div>
    </ModalContent>
  );
};

export const useOpenThresholdModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(ThresholdModal, {
      title: 'Paiements plafonnés',
      closable: true,
      ...props,
    });
};
