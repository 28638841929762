import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AppFeatures,
  EspaceClientEventProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class ClientCustomerSatisfactionTrackingClass extends Tracker<AppFeatures> {
  onClientCustomerSatisfactionCompleted = (properties: {
    [EspaceClientEventProperties.CustomerSatisfactionCondition]: string;
    [EspaceClientEventProperties.CustomerSatisfactionScore]: number;
    [EspaceClientEventProperties.CustomerSatisfactionType]: string;
    [EspaceClientEventProperties.CustomerSatisfactionFeature]: AppFeatures;
    [EspaceClientEventProperties.CustomerSatisfactionSubFeature]?: string;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Completed,
      properties,
    });
  };

  onClientCustomerSatisfactionSkipped = (properties: {
    [EspaceClientEventProperties.CustomerSatisfactionCondition]: string;
    [EspaceClientEventProperties.CustomerSatisfactionScore]: number;
    [EspaceClientEventProperties.CustomerSatisfactionType]: string;
    [EspaceClientEventProperties.CustomerSatisfactionFeature]: AppFeatures;
    [EspaceClientEventProperties.CustomerSatisfactionSubFeature]?: string;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Skipped,
      properties,
    });
  };
}

export const ClientCustomerSatisfactionTracking = new ClientCustomerSatisfactionTrackingClass(
  AppFeatures.CustomerSatisfactionPoll,
);
