import React from 'react';

import { Right } from './types';
import { displayElement } from './utils';

type Props = {
  right: Right;
  value?: boolean | string;
  disabled?: boolean;
};

export const RightPart = React.forwardRef<HTMLButtonElement, Props>(
  ({ right, value, disabled }, ref) => (
    <div className="flex items-center">
      {displayElement({ element: right, value, disabled, ref })}
    </div>
  ),
);
