import React from 'react';

import classNames from 'classnames';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  total: number;
  label: string | JSX.Element;
  className?: string;
  ['data-testid']?: string;
  withEllipsisOnLabel?: boolean;
  crossedOut?: boolean;
  sectionName?: string;
};

export const SimpleTotal: React.FC<Props> = ({
  total,
  label,
  className,
  'data-testid': dataTestId,
  withEllipsisOnLabel,
  crossedOut,
  sectionName,
}) => (
  <div
    className={classNames('flex justify-between', className)}
    role="section"
    aria-label={sectionName || label.toString()}
  >
    <div
      className={classNames('mr-xs', {
        'text-ellipsis overflow-hidden whitespace-nowrap': withEllipsisOnLabel,
      })}
    >
      {label}
    </div>
    <div className="text-right">
      <EURCurrency amount={total} data-testid={dataTestId} crossedOut={crossedOut} />
    </div>
  </div>
);
