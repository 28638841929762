import React from 'react';

import { Address } from '@travauxlib/shared/src/types';

type Props = {
  customerName: string;
  projectAddress: Address;
};

export const ProjectDestinataire: React.FC<Props> = ({ customerName, projectAddress }) => (
  <div className="text-right">
    <div className="inline-block bg-lighter p-md">
      <p>A l'attention de :</p>
      <div className="font-bold">{customerName}</div>
      <div>
        {projectAddress.address} <br />
        {projectAddress.postalCode} {projectAddress.city}
      </div>
    </div>
  </div>
);
