import React from 'react';

import classNames from 'classnames';

import { FormControlLabel, FormControlProps } from '../FormControl/commons';

export type Props = FormControlProps<boolean>;

export const Toggle: React.FC<Props> = ({
  checked,
  disabled,
  onChange,
  label,
  className,
  ...rest
}) => (
  <div
    data-testid="toggle"
    className={classNames('inline-flex items-center group cursor-pointer text-ds-b1', {
      'pointer-events-none': disabled,
    })}
    role="button"
    onClick={() => !disabled && onChange?.(!checked)}
    onKeyPress={() => !disabled && onChange?.(!checked)}
    tabIndex={0}
  >
    <div
      className={classNames(
        className,
        'relative h-lg min-w-[40px] rounded-full border outline-blue-500 duration-200',
        { 'bg-neutral-100': !disabled },
        { 'bg-neutral-300': disabled },
        {
          'border-neutral-300 group-hover:bg-neutral-200 group-active:bg-neutral-300':
            !checked && !disabled,
        },
        {
          'bg-primary-400 border-primary-400 group-hover:bg-primary-500 group-hover:border-primary-500 group-active:bg-primary-600 group-active:border-primary-600':
            checked && !disabled,
        },
        { 'border-neutral-300': disabled && checked },
      )}
      {...rest}
    >
      <div
        className={classNames(
          'absolute w-xl h-xl rounded-full duration-300 opacity-0 group-hover:opacity-10',
          // Repositionne "l'ombre" correctement sur le rond du toggle. 5px = padding du container
          ' translate-y-[-5px]',
          { 'translate-x-[11px] bg-primary-500': checked },
          { 'translate-x-[-5px] bg-neutral-800': !checked },
        )}
      />
      <div
        className={classNames(
          'peer h-lg w-lg bg-neutral-0 rounded-full -mt-[1px] border border-neutral-300 duration-300',
          { 'bg-neutral-0  shadow-ds-xs hover:shadow-ds-md': !disabled },
          { 'bg-neutral-100': disabled },
          // Permets de déclencher l'animation de déplacement du rond sur la track. Avant c'etait géré par le flex mais incompatible avec l'animation
          { '-ml-[1px] translate-x-0': !checked },
          // 15px = 40 (track size) - 24 (rounded size) - 1 (negative margin so it does not overflow track size)
          { 'translate-x-[15px]': checked },
          { 'border-primary-400 group-hover:border-primary-500': checked && !disabled },
        )}
      />
    </div>
    <FormControlLabel label={label} />
  </div>
);
