import React from 'react';

import classNames from 'classnames';

import Spinner from '@travauxlib/shared/src/components/DesignSystem/assets/Spinner.svg?react';

import { GenericLink, GenericLinkProps } from './commons';

import { ActionDisabledWithTooltip } from '../Buttons/ActionDisabledWithTooltip';
import { ButtonContent, ButtonProps, makeButtonClassnames } from '../Buttons/Button';

export type ButtonLinkProps = GenericLinkProps & ButtonProps;

export const ButtonLink: React.FC<ButtonLinkProps> = props => {
  const {
    children,
    disabled = false,
    className,
    leftIcon,
    rightIcon,
    size = 'md',
    variant,
    fullwidth,
    disabledMessageTooltip,
    positionTooltip,
    loading,
    widthTooltip,
    ...rest
  } = props;
  const buttonProps: ButtonProps = {
    leftIcon,
    rightIcon,
    size,
    variant,
    fullwidth,
    disabled,
    children,
  };
  const linkClassName = classNames('inline-block', className, makeButtonClassnames(buttonProps));

  return (
    <ActionDisabledWithTooltip
      disabled={disabled}
      disabledMessage={disabledMessageTooltip}
      position={positionTooltip}
      widthTooltip={widthTooltip}
    >
      <GenericLink classNames={linkClassName} disabled={disabled} {...rest}>
        <ButtonContent
          fixBorder={variant === 'secondary'}
          leftIcon={leftIcon}
          rightIcon={
            loading ? <Spinner className="animate-spin" data-testid="loading-icon" /> : rightIcon
          }
          size={size}
        >
          {children}
        </ButtonContent>
      </GenericLink>
    </ActionDisabledWithTooltip>
  );
};
