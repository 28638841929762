import { TypeOptions } from 'react-toastify';

import { Config, Level } from './types';

import AlertCircle from '../../assets/AlertCircle.svg?react';
import CheckCircle from '../../assets/CheckCircle.svg?react';
import InfoCircle from '../../assets/InfoCircle.svg?react';
import Warning from '../../assets/Warning.svg?react';

export const config: { [key in Level]: Config } = {
  info: {
    RawIcon: InfoCircle,
    containerClassnames: 'border-info-800 bg-info-50',
    iconClassnames: 'text-info-800',
  },
  success: {
    RawIcon: CheckCircle,
    containerClassnames: 'border-success-800 bg-success-50 ',
    iconClassnames: 'text-success-800',
  },
  warning: {
    RawIcon: AlertCircle,
    containerClassnames: 'border-warning-800 bg-warning-50',
    iconClassnames: 'text-warning-800',
  },
  error: {
    RawIcon: Warning,
    containerClassnames: 'border-error-800 bg-error-50',
    iconClassnames: 'text-error-800',
  },
};

export const toastContainerContextClass: { [key in TypeOptions]: string } = {
  info: config.info.containerClassnames,
  success: config.success.containerClassnames,
  warning: config.warning.containerClassnames,
  error: config.error.containerClassnames,
  default: '',
};
