import React, { useEffect } from 'react';

import ProjectPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectPicto.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

import { ReassuranceCard } from 'features/EspaceClient3/components/ReassuranceCard';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { chantierReassurance } from '../utils/constants';

export const EmptyChantier: React.FC = () => {
  useEffect(() => {
    EspaceClientTracking.onEspaceClientChantierEmptyViewed();
  }, []);

  return (
    <div className="m-md sm-desktop:mb-3xl sm-desktop:mx-0">
      <EmptyState
        className="mb-md sm-desktop:mb-xl"
        illustration={<ProjectPicto />}
        iconIllustrationSize="md"
        title="Aucun chantier en cours"
        description="Signez un devis travaux avec une entreprise pour démarrer votre chantier"
      />
      <ReassuranceCard items={chantierReassurance} />
    </div>
  );
};
