import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { ButtonGroup as RawButtonGroup, Props as ButtonGroupProps } from '.';

type Props = {
  name: string;
} & Omit<ButtonGroupProps, 'value' | 'onChange'> &
  UseFieldConfig<string | boolean>;

export const ButtonGroupWrapper: React.FC<
  FieldRenderProps<string | boolean> & Omit<ButtonGroupProps, 'value' | 'onChange'>
> = ({ input: { value, onChange }, ...rest }) => (
  <RawButtonGroup onChange={onChange} value={value} {...rest} />
);

export const ButtonGroupField: React.FC<Props> = (props: Props) => (
  <Field component={ButtonGroupWrapper} {...props} />
);
