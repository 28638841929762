import React from 'react';

import { Placement } from '@floating-ui/react';
import classNames from 'classnames';

import { WithTooltip } from '../WithTooltip';

export type Props = {
  children: React.ReactNode;
  disabledMessage?: false | string;
  position?: Placement;
  disabled?: boolean;
  widthTooltip?: string;
  disabledTriggerClassName?: string;
};

export const ActionDisabledWithTooltip: React.FC<Props> = ({
  children,
  position,
  disabledMessage,
  disabled,
  widthTooltip,
  disabledTriggerClassName,
}) =>
  disabledMessage && disabled ? (
    <WithTooltip
      fixedFloating
      trigger={<div className={classNames('relative', disabledTriggerClassName)}>{children}</div>}
      position={position}
      width={widthTooltip ?? 'auto'}
    >
      <>{disabledMessage}</>
    </WithTooltip>
  ) : (
    <>{children}</>
  );
