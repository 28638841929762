import React from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

import { IconButton } from '../Buttons/IconButton';

type Props = {
  title: string | React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  intro?: string | JSX.Element;
  defaultExpanded?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  headerAction?: JSX.Element;
  labelClassName?: string;
  children: React.ReactNode;
  onChange?: (isExpanded: boolean) => void;
};

export const AccordionItem: React.FC<Props> = ({
  title,
  className,
  labelClassName,
  icon,
  intro,
  defaultExpanded = false,
  outlined,
  disabled,
  children,
  headerAction,
  onChange,
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const handleToggle = (): void => {
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);
    onChange?.(newExpandedState);
  };
  return (
    <div
      className={classNames(className, disabled ? 'bg-neutral-100' : 'bg-neutral-0', {
        'rounded-xxs shadow-ds-xs': !outlined,
      })}
      role="section"
      aria-label={title?.toString()}
      id={typeof title === 'string' ? slugify(title) : undefined}
    >
      <div
        role="button"
        className={classNames(
          'flex justify-between items-center group py-sm',
          { 'pl-md pr-sm': !outlined },
          { 'cursor-pointer': !disabled },
          { 'pb-xs': expanded && !!intro },
          { 'pb-md': expanded && !intro },
        )}
        onClick={() => !disabled && !headerAction && handleToggle()}
      >
        <div
          className={classNames('test-ds-b1 font-bold flex  items-center', labelClassName, {
            'text-neutral-300': disabled,
          })}
        >
          {headerAction && <>{headerAction}</>}
          {icon && <span className="w-lg h-lg mr-xs flex items-center shrink-0">{icon}</span>}
          <span>{title}</span>
        </div>
        <IconButton
          className={
            disabled ? '' : 'group-hover:text-neutral-700 group-hover:bg-neutral-100 shrink-0'
          }
          disabled={disabled}
          data-testid="accordion-item-button"
          size="md"
          onClick={() => headerAction && handleToggle()}
        >
          {expanded ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
      </div>
      {expanded && (
        <div className={classNames('pb-md', { 'px-md': !outlined })}>
          {intro && <div className="mb-xs text-ds-b2 text-neutral-600">{intro}</div>}
          {children}
        </div>
      )}
    </div>
  );
};
