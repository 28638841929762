import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';
import Search from '@travauxlib/shared/src/components/DesignSystem/assets/Search.svg?react';
import Swap from '@travauxlib/shared/src/components/DesignSystem/assets/Swap.svg?react';

import { Props as ReassuranceItemProps } from 'features/EspaceClient3/components/ReassuranceCard/ReassuranceItem';

export const chantierReassurance: ReassuranceItemProps[] = [
  {
    icon: <Search />,
    title: "Suivi de l'avancement par lot",
    description: 'Retrouvez l’avancement détaillé pour chaque lot de votre chantier',
  },
  {
    icon: <CheckSymbol />,
    title: 'Contrôle sur la libération des fonds',
    description: "Validez les états d'avancement du chantier pour libérer les fonds",
  },
  {
    icon: <Swap />,
    title: 'Suivi des modifications',
    description: 'Le récapitulatif de votre chantier se met à jour en fonction des avenants signés',
  },
];
