import React from 'react';

import Brand from '@travauxlib/shared/src/components/DesignSystem/assets/Brand.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

type Props = {
  title: string;
  content: string;
  onClick: () => void;
};

export const ThresholdInfoCard: React.FC<Props> = ({ title, content, onClick }) => (
  <Card className="border-l-4 border-primary-400 !rounded-xxs" bodyClassNames="flex flex-col">
    <div className="flex gap-xs">
      <Brand className="text-primary-400 size-lg shrink-0" />
      <div className="flex flex-col gap-xs mb-md">
        <div className="text-ds-b1 font-bold text-neutral-800">{title}</div>
        <div className="text-ds-b2 text-neutral-700">{content}</div>
      </div>
    </div>
    <Link className="ml-auto" onClick={onClick} size="lg" inline>
      En savoir plus
    </Link>
  </Card>
);
