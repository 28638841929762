import React from 'react';

import classNames from 'classnames';

import {
  Loader,
  LoaderProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

type LoaderWrapperProps = {
  size?: LoaderProps['size'];
  isFullHeightScreen?: boolean;
};

export const LoaderWrapper: React.FC<LoaderWrapperProps> = ({
  size = 'lg',
  isFullHeightScreen,
}) => (
  <div
    className={classNames(
      'w-full flex items-center justify-center grow',
      isFullHeightScreen ? 'h-screen' : 'h-full',
    )}
  >
    <Loader size={size} />
  </div>
);
