import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { Input as RawInput, Props as InputProps } from '.';

const InputWrapper: React.FC<FieldRenderProps<string>> = ({
  input: { value, onChange, onBlur, onFocus, type },
  meta,
  id,
  ...rest
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <RawInput
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error && touched ? error : undefined}
      type={type}
      {...rest}
    />
  );
};

type Props = {
  name: string;
} & Omit<InputProps, 'value'> &
  UseFieldConfig<string>;

export const InputField: React.FC<Props> = props => <Field component={InputWrapper} {...props} />;
