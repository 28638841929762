import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';

import { CardAction, cardActions } from '../utils/constants';

type Props = {
  urlDashboardReporting?: string;
};

export const ActionCards: React.FC<Props> = ({ urlDashboardReporting }) => (
  <div className="flex justify-center flex-col sm-desktop:flex-row sm-desktop:items-center gap-md">
    {cardActions(urlDashboardReporting).map(
      (action: CardAction) =>
        action && (
          <Card className="w-full sm-desktop:w-[332px]" key={action.title}>
            <div className="flex justify-center mb-md">{action.icon}</div>
            <div className="mb-xl">
              <div className="text-ds-h5 text-neutral-800 font-bold mb-xxs">{action.title}</div>
              <div className="text-ds-b1 text-neutral-600">{action.description}</div>
            </div>
            <ButtonLink fullwidth to={action.to} href={action.href}>
              {action.label}
            </ButtonLink>
          </Card>
        ),
    )}
  </div>
);
