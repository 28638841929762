import React from 'react';

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { required, validateConditions } from '@travauxlib/shared/src/utils/form';
import { logosUrl } from '@travauxlib/shared/src/utils/urls';

import { stripeCardComponentOptions } from './stripeComponentOptions';
import { WaitMessage } from './WaitMessage';

type Props = {
  submitting: boolean;
  submitButtonLabel: string;
};

export const WAIT_INTERVAL = 5000;

export const FacturePaymentCardFormContent: React.FC<Props> = ({
  submitting,
  submitButtonLabel,
}) => (
  <>
    <InputField name="name" id="iban-titulaire" type="text" label="Titulaire" validate={required} />
    <div className="py-sm">
      <div className="mb-sm">
        <CardNumberElement options={stripeCardComponentOptions} />
      </div>
      <div className="flex justify-between">
        <CardExpiryElement options={stripeCardComponentOptions} />
        <div className="mr-sm" />
        <CardCvcElement options={stripeCardComponentOptions} />
      </div>
    </div>
    <CheckboxField
      name="acceptConditions"
      id="acceptConditions"
      className="pb-sm"
      validate={validateConditions}
      label="En fournissant ma carte bancaire, j'autorise hemea et Stripe, leur prestataire de paiement, à prélever le montant indiqué."
    />
    <div className="flex items-center justify-center">
      <Button
        type="submit"
        disabled={submitting}
        loading={submitting}
        rightIcon={<img className="w-3xl h-lg" src={`${logosUrl}/hemea_pay_white.svg`} />}
        fullwidth
      >
        {submitting ? <WaitMessage waitInterval={WAIT_INTERVAL} /> : submitButtonLabel}
      </Button>
    </div>
  </>
);
