import React, { useState } from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

import {
  categories,
  CategoryWrapper,
} from 'features/Estimateur/features/Questions/utils/categories';
import { Estimation } from 'features/Estimateur/types';

type Props = {
  estimation: Estimation;
  submitEstimation: (estimation: Estimation) => Promise<unknown>;
  isClientEstimateur: boolean;
};

export const Details: React.FC<Props> = ({ estimation, isClientEstimateur, submitEstimation }) => {
  const [activeDesignation, setActiveDesignation] = useState<string | undefined>(undefined);
  const lots = estimation.result?.lots || [];

  const deleteLine = (normalizedIdentifier: string): void => {
    submitEstimation({
      ...estimation,
      context: {
        ...estimation.context,
        deletedLinesIdentifiers: estimation.context.deletedLinesIdentifiers?.length
          ? [...estimation.context.deletedLinesIdentifiers, normalizedIdentifier]
          : [normalizedIdentifier],
      },
    });
  };
  const retrieveLine = (normalizedIdentifier: string): void => {
    submitEstimation({
      ...estimation,
      context: {
        ...estimation.context,
        deletedLinesIdentifiers: estimation.context.deletedLinesIdentifiers.filter(
          el => el !== normalizedIdentifier,
        ),
      },
    });
  };

  if (lots.length === 0) {
    return (
      <div className="text-center pt-sm">
        <h4>
          Votre estimation va se compléter ici au fur et à mesure que vous rajouterez des
          prestations.
        </h4>
        <ButtonLink to="../add" leftIcon={<PlusSymbol />} size="sm">
          Ajouter une première prestation
        </ButtonLink>
      </div>
    );
  }
  return (
    <>
      <div className="text-right">
        <h5 className="mb-0">Prix HT</h5>
      </div>
      <div>
        <div className="mb-xl pb-xl">
          {lots.map(({ label, totalHT, lignes }) => (
            <div id={`lot-${slugify(label)}`} className="mb-lg" key={label}>
              <div className="flex justify-between">
                <h4>{label}</h4>
                <h4>
                  <EURCurrency amount={totalHT} />
                </h4>
              </div>
              {lignes.map(
                ({
                  designation,
                  description,
                  quantity,
                  prixHT,
                  unitLabel,
                  answerType,
                  normalizedIdentifier,
                  isDeleted,
                }) => {
                  const category = categories.find(({ value }) => value === answerType);
                  const isActive = designation === activeDesignation;
                  return (
                    <div className="flex" key={designation}>
                      <CategoryWrapper
                        withUnderscoreOnHover={!!description && !isActive}
                        onClick={
                          description
                            ? () => setActiveDesignation(isActive ? undefined : designation)
                            : undefined
                        }
                        className={classNames(
                          'w-full flex items-center py-sm mb-xs text-ds-b1',
                          { '!cursor-pointer': description },
                          { '!bg-neutral-100 line-through !text-neutral-600': isDeleted },
                        )}
                        category={category}
                      >
                        <div className="w-full px-md tablet:max-w-7/12 ">{designation}</div>
                        <div className="tablet:!text-right whitespace-nowrap px-md max-w-2/12 w-full">
                          {quantity.toLocaleString()} {unitLabel}
                        </div>
                        {!isClientEstimateur && (
                          <div className="w-full px-md tablet:max-w-2/12 tablet:mr-md sm-desktop:mr-0 text-right">
                            <EURCurrency amount={prixHT} />
                          </div>
                        )}
                        {description && (
                          <div className="w-lg mx-auto">
                            {isActive ? <ChevronUp /> : <ChevronDown />}
                          </div>
                        )}
                        {isActive && description && (
                          <div className="w-full px-md pt-sm text-neutral-600">
                            <MultilineParagraph text={description} paragraphClassName="mb-0" />
                          </div>
                        )}
                      </CategoryWrapper>
                      {!isClientEstimateur && (
                        <IconButton
                          data-testid="toggle-line-button"
                          className={classNames('self-center ml-md', {
                            'text-neutral-600': isDeleted,
                          })}
                          onClick={() =>
                            isDeleted
                              ? retrieveLine(normalizedIdentifier)
                              : deleteLine(normalizedIdentifier)
                          }
                          size="xs"
                        >
                          {isDeleted ? <PlusSymbol /> : <Delete />}
                        </IconButton>
                      )}
                    </div>
                  );
                },
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
