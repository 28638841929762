import { TypeBien } from '@travauxlib/shared/src/types';

import {
  OnboardingEventProperty,
  ProjectQuestion,
  QuestionType,
  SelectStepConfig,
  StepConfig,
  VariableSurfaceStepConfig,
} from 'features/OnboardingSolide/types';

import { surfaceStep } from './commonSteps';

import { baseImgixOnboardingUrl } from '../constants';

const typeBienStep: SelectStepConfig = {
  id: ProjectQuestion.TypeBien,
  type: QuestionType.Select,
  title: 'Quel type de bien souhaitez-vous construire ?',
  options: [
    {
      label: 'Maison',
      value: TypeBien.Maison,
      subLabel: 'Style de la maison',
      subOptions: [
        {
          label: 'Maison en briques / parpaings',
          value: TypeBien.MaisonBriques,
        },
        { label: 'Maison en bois', value: TypeBien.MaisonBois },
        { label: 'Maison container', value: TypeBien.MaisonContainer },
        { label: 'Maison', value: TypeBien.Maison },
      ],
    },
    {
      label: 'Immeuble',
      value: TypeBien.Immeuble,
    },
    {
      label: 'Local professionnel',
      value: TypeBien.BienProfessionel,
    },
    {
      label: 'Autre',
      value: 'other',
      subLabel: 'Type de construction',
      isOtherAnswer: true,
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-1.png`,
  avis: {
    content: 'Nous sommes ravis de la conception réalisée',
    author: 'Marie-Josée',
  },
  eventKey: OnboardingEventProperty.PropertyType,
};

const stadeAchatStep: SelectStepConfig = {
  id: ProjectQuestion.StadeAchat,
  type: QuestionType.Select,
  title: 'Possédez-vous déjà le terrain ?',
  helperText:
    "La signature d'un avant-contrat pour l'achat d'un terrain doit s'effectuer avec un notaire",
  options: [
    {
      label: 'Oui',
      value: 'proprietaire_terrain',
      formattedLabel: 'Propriétaire du terrain',
    },
    {
      label: 'Non',
      value: 'sans_terrain',
      formattedLabel: 'Sans terrain',
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-2.png`,
  avis: {
    content: 'Nous sommes ravis de la conception réalisée',
    author: 'Marie-Josée',
  },
  eventKey: OnboardingEventProperty.PurchaseStage,
};

const constructionSurfaceStepConfig: VariableSurfaceStepConfig = {
  title: 'Quelle est la surface du bien à construire ?',
  helperText:
    'Certaines autorisations seront parfois nécessaires en fonction du projet (ex: permis de construire)',
  imageUrl: `${baseImgixOnboardingUrl}/onboard-3.png`,
  avis: {
    content: 'Nous sommes ravis de la conception réalisée',
    author: 'Marie-Josée',
  },
};

export const constructionSteps: StepConfig[] = [
  typeBienStep,
  surfaceStep(constructionSurfaceStepConfig),
  stadeAchatStep,
];
