import React from 'react';

import classNames from 'classnames';
import _orderBy from 'lodash/orderBy';

import Gift from '@travauxlib/shared/src/components/DesignSystem/assets/Gift.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ColoredElement } from '@travauxlib/shared/src/features/DevisDisplay/components/Header';
import { getDetailedDevisFacturationStatus } from '@travauxlib/shared/src/utils/facture/getDetailedDevisFacturationStatus';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { FacturePrestationsSummary } from 'components/DevisFactures/FacturePrestationsSummary';
import { FactureSoldePrestationsSummary } from 'components/DevisFactures/FactureSoldePrestationsSummary';
import { DocumentHeader } from 'components/DocumentHeader';
import { useDevisPro } from 'features/DevisPro/api/useDevisPro';
import { Facture, ProFactureContext } from 'types';

import { FactureLayout } from './FactureLayout';
import { LinkedFactureDisplay } from './LinkedFactureDisplay';

type Props = {
  facture: Facture;
  proContext: ProFactureContext;
  isPrint?: boolean;
};

export const ProFactureShow: React.FC<Props> = ({ facture, proContext, isPrint }) => {
  const { numero, dateEmission, proDevisToken } = facture;
  const { company, deal, linkedFactures, customization } = proContext;

  const chantierAddress = deal.chantierAddress || deal.billingAddress;

  const sortedLinkedFactures = _orderBy(linkedFactures, 'dateEmission', 'desc');
  const customizationData = !deal.isHemea ? customization : undefined;
  const { devis } = useDevisPro(proDevisToken);

  const facturationStatus =
    devis && getDetailedDevisFacturationStatus([...sortedLinkedFactures, facture], devis);

  return (
    <FactureLayout
      facture={facture}
      header={
        <DocumentHeader
          proContext={proContext}
          title={numero && `Facture n°${numero}`}
          dateBlock={<span>En date du {formatFrenchDate(dateEmission)}</span>}
        />
      }
      customerName={deal.customerName}
      proCompanyName={company.name}
      customizationData={customization}
      chantierAddress={chantierAddress}
      isPrint={isPrint}
    >
      <>
        {facture.isCloture &&
          !facture.isSituation &&
          (facturationStatus?.factureSoldeTotals?.montantRemise || 0) > 0 && (
            <>
              <div className="flex flex-row items-center justify-between text-ds-b2 mt-xs text-primary">
                <div className="flex items-center">
                  <div className="w-md h-md flex items-center mr-xxs">
                    <Gift />
                  </div>
                  Remise
                </div>
                <EURCurrency
                  amount={facturationStatus?.factureSoldeTotals?.montantRemise}
                  suffix="HT"
                />
              </div>
              <div className="flex flex-row items-center justify-between font-bold mt-xs">
                <div>Total Facturable</div>
                <EURCurrency
                  amount={
                    (facturationStatus?.factureSoldeTotals?.totalTravauxHT || 0) -
                    (facturationStatus?.factureSoldeTotals?.montantRemise || 0)
                  }
                  suffix="HT"
                />
              </div>
            </>
          )}
        {facture.isCloture && sortedLinkedFactures.length !== 0 && (
          <div className="mb-xxl">
            <ColoredElement
              className={classNames('mb-md text-ds-h4 font-medium', customization?.titleFontStyle)}
              color={customizationData?.textColor}
            >
              Acomptes déjà facturés
            </ColoredElement>

            <LinkedFactureDisplay facturesList={sortedLinkedFactures} />
          </div>
        )}
        <div className="break-inside-avoid !pt-xl">
          {facture.isCloture && !facture.isSituation ? (
            facturationStatus?.factureSoldeTotals && (
              <FactureSoldePrestationsSummary
                facture={facture}
                factureSoldeTotals={facturationStatus?.factureSoldeTotals}
              />
            )
          ) : (
            <FacturePrestationsSummary
              facture={facture}
              customization={customizationData}
              linkedFactures={sortedLinkedFactures}
            />
          )}
        </div>
      </>
    </FactureLayout>
  );
};
