import React, { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { ClientAccount } from '@travauxlib/shared/src/types';

import { Authentication } from 'features/Authentication/Loadable';

import { EspaceClient3Routes } from '../features/EspaceClient3/routes';
import { EstimateurClient } from '../features/Estimateur/features/Client';
import { useIsEstimateurClient } from '../features/Estimateur/utils/paths';

type Props = {
  isLoading: boolean;
  account?: ClientAccount;
};

export const AuthenticatedRoutes: React.FC<Props> = ({ isLoading, account }) => {
  const navigate = useNavigate();
  const isEstimateurClient = useIsEstimateurClient();

  const [params] = useSearchParams();

  const redirect = params.get('redirect');

  useEffect(() => {
    if (account && redirect) {
      navigate(redirect || '/');
    }
  }, [account, redirect]);

  if (isLoading) {
    return <LoaderWrapper />;
  }

  if (!account) {
    return <Authentication />;
  }

  return isEstimateurClient ? <EstimateurClient /> : <EspaceClient3Routes />;
};
