import { Unit, UnitValue } from '@travauxlib/shared/src/types';

export const unitaire: Unit = {
  value: 'unitaire',
  label: 'unité',
  priceUnit: '€',
  unitaire: true,
};

export const units: { [key in UnitValue]: Unit } = {
  unitaire,
  m: {
    value: 'm',
    label: 'm',
    priceUnit: '€ / m',
  },
  m2: {
    value: 'm2',
    label: 'm²',
    priceUnit: '€ / m²',
  },
  m3: {
    value: 'm3',
    label: 'm³',
    priceUnit: '€ / m³',
  },
  ml: {
    value: 'ml',
    label: 'ml',
    priceUnit: '€ / ml',
  },
  h: {
    value: 'h',
    label: 'heure',
    priceUnit: '€ / h',
  },
  j: {
    value: 'j',
    label: 'jour',
    priceUnit: '€ / j',
  },
  kg: {
    value: 'kg',
    label: 'kg',
    priceUnit: '€ / kg',
  },
  forfait: {
    value: 'forfait',
    label: 'forfait',
    priceUnit: '€ / forfait',
  },
};
