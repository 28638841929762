import { buildURL } from 'react-imgix';

export const stringifyParams = (params: {
  [K: string]: undefined | boolean | string | string[];
}): string => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => searchParams.append(key, item));
    } else if (value !== null && value !== undefined) {
      searchParams.set(key, value.toString());
    }
  });

  return searchParams.toString();
};

export const getUrlParams = (location: { search: string }): { [K: string]: string } => {
  const params = new URLSearchParams(location.search);
  const result: { [K: string]: string } = {};
  for (let [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
};

export const getUrlParam = (location: { search: string }, key: string): string | undefined =>
  new URLSearchParams(location.search).get(key) || undefined;

export const isURLAnImage = (url: string): boolean => {
  const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
  return ext === 'jpg' || ext === 'jpeg' || ext === 'png';
};

export const baseImgixUrl = 'https://images.hemea.com';
export const logosUrl = `${baseImgixUrl}/static/designsystem/logos`;

// ts-prune-ignore-next
export const iconsUrl = `${baseImgixUrl}/static/designsystem/icons`;

// react-imgix doesn't export ImigixParams type, so we have to define it ourselves
type ImgixParams = {
  w?: number;
  h?: number;
  q?: number;
  ar?: string;
  auto?: string;
  fit?: string;
  fm?: string;
  dpr?: number;
};

export const buildImgixURL = (src: string, imgixParams?: ImgixParams): string => {
  const imgixDefaultParams: ImgixParams = {
    auto: 'compress,format',
    q: 80,
  };
  const mergedImgixParams = { ...imgixDefaultParams, ...imgixParams };
  const imgixOptions = { disableLibraryParam: true } as any; // react-imgix type requires src, which is wrong, so we have to type as any

  return buildURL(src, mergedImgixParams, imgixOptions);
};

/**
 * Builds imgix url for CSS depending on device ratio and width
 *
 * The goal is to optimize image size by reducing quality for higher DPR device (q=40)
 * and by preventing the request of larger than origin images (fit=max).
 * Best practices based on https://docs.imgix.com/tutorials/responsive-images-srcset-imgix
 */
export const buildImgixBackgroundUrl = (
  url: string,
  options: { w: number; dpr?: number },
): string => {
  const { w, dpr } = options;

  const imageUrl = buildImgixURL(url, {
    w,
    dpr,
    ...(dpr &&
      dpr > 1 && {
        q: 40, // with higher dpr, we can lower quality
        fit: 'max', // never generate images larger than source
      }),
  });
  const dprSuffix = dpr ? ` ${dpr}x` : '';
  return `url(${imageUrl})${dprSuffix}`;
};

export const parametresBaseUrl = '/account/parametres';
