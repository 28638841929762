import { Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import {
  ChiffreAffairesDeclare,
  DevisFactureStatuses,
  DevisStatuses,
  FactureStatuses,
  Gamme,
  TypeSuivi,
  TypeTravaux,
} from '@travauxlib/shared/src/types';

export type CompanyStatutLabel =
  | 'SARL'
  | 'Auto-entrepreneur'
  | 'EURL'
  | 'SAS'
  | 'SASU'
  | 'SA'
  | 'Artisan'
  | 'Autre'
  | 'Profession libérale'
  | 'EI'
  | 'EIRL';

export const companyStatusIds: { [key: string]: CompanyStatutLabel } = {
  SARL: 'SARL',
  AutoEntrepreneur: 'Auto-entrepreneur',
  EURL: 'EURL',
  SAS: 'SAS',
  SASU: 'SASU',
  SA: 'SA',
  Artisan: 'Artisan',
  Autre: 'Autre',
  ProfessionLiberale: 'Profession libérale',
  EI: 'EI',
  EIRL: 'EIRL',
};

export const typeSuiviWordings: {
  [K in TypeSuivi]: {
    value: TypeSuivi;
    label: string;
    badgeStyle: Variant;
    commercialLabel: string;
  };
} = {
  [TypeSuivi.CustomerCare]: {
    value: TypeSuivi.CustomerCare,
    label: 'Suivi CC',
    badgeStyle: 'info',
    commercialLabel: 'Essentiel',
  },
  [TypeSuivi.ServiceHemea]: {
    value: TypeSuivi.ServiceHemea,
    label: 'Service hemea',
    badgeStyle: 'info',
    commercialLabel: 'Offre Support',
  },
  [TypeSuivi.TravauxPlanner]: {
    value: TypeSuivi.TravauxPlanner,
    label: 'Suivi TP',
    badgeStyle: 'success',
    commercialLabel: 'Travaux Planner',
  },
  [TypeSuivi.TravauxPlannerWithService]: {
    value: TypeSuivi.TravauxPlannerWithService,
    label: 'Suivi TP + Service hemea',
    badgeStyle: 'success',
    commercialLabel: 'Offre Expertise',
  },
  [TypeSuivi.Architecte]: {
    value: TypeSuivi.Architecte,
    label: 'Suivi Archi',
    badgeStyle: 'error',
    commercialLabel: 'Architecte',
  },
};

export type CompanyStatus = {
  label: CompanyStatutLabel;
  value: CompanyStatutLabel;
  hasCapital: boolean;
};

export const companyStatuses: CompanyStatus[] = [
  {
    label: companyStatusIds.SARL,
    value: companyStatusIds.SARL,
    hasCapital: true,
  },
  {
    label: companyStatusIds.AutoEntrepreneur,
    value: companyStatusIds.AutoEntrepreneur,
    hasCapital: false,
  },
  {
    label: companyStatusIds.EURL,
    value: companyStatusIds.EURL,
    hasCapital: true,
  },
  {
    label: companyStatusIds.SAS,
    value: companyStatusIds.SAS,
    hasCapital: true,
  },
  {
    label: companyStatusIds.SASU,
    value: companyStatusIds.SASU,
    hasCapital: true,
  },
  {
    label: companyStatusIds.SA,
    value: companyStatusIds.SA,
    hasCapital: true,
  },
  {
    label: companyStatusIds.Artisan,
    value: companyStatusIds.Artisan,
    hasCapital: false,
  },
  {
    label: companyStatusIds.ProfessionLiberale,
    value: companyStatusIds.ProfessionLiberale,
    hasCapital: false,
  },
  {
    label: companyStatusIds.Autre,
    value: companyStatusIds.Autre,
    hasCapital: true,
  },
  {
    label: companyStatusIds.EI,
    value: companyStatusIds.EI,
    hasCapital: true,
  },
  {
    label: companyStatusIds.EIRL,
    value: companyStatusIds.EIRL,
    hasCapital: true,
  },
];

export const proSpecializations = [
  'Construction',
  'Couverture',
  'Extension',
  'Fenêtres',
  'Peinture',
  'Rénovation',
  'Surélévation',
];

export const contractantGeneral = 'Contractant général - Entreprise générale';
/**
 * Corps d'état assurantiels selon notre propre nomenclature.
 * Attention : si tu changes un item dans cette liste il faudra migrer la db !
 */
export const corpsEtat = [
  {
    options: [
      'Chapes liquides',
      'Charpente et structures bois',
      'Charpente et structures métal',
      'Chauffage',
      'Climatisation',
      'Cloisons - Faux-plafonds',
      contractantGeneral,
    ],
  },
  {
    options: [
      'Couverture',
      'Électricité',
      'Étanchéité',
      'Fondation',
      'Isolation thermique',
      'Maçonnerie - Béton armé (mur porteur)',
      'Menuiseries extérieures',
      'Menuiseries intérieures',
    ],
  },
  {
    options: [
      'Miroiterie',
      'Parquet',
      'Peinture',
      'Plomberie',
      'Revêtements durs (Carrelage)',
      'Revêtements souples',
      'Serrurerie',
      'VRD',
    ],
  },
];

export const allCorpsEtat = corpsEtat
  .reduce((acc, corpsEtatGroup) => [...acc, ...corpsEtatGroup.options], [] as string[])
  .sort();

export const certifications = [
  'Audit énergétique',
  'Borne de recharge pour véhicules électriques',
  'Chaudière bois à alimentation automatique',
  'Chaudière bois à alimentation manuelle',
  'Chaudière gaz à très haute performance énergétique (avec ou sans programmateur de chauffage)',
  'Chaudière micro-cogénération gaz avec programmateur de chauffage',
  'Chauffe-eau solaire individuel',
  'Chauffe-eau thermodynamique',
  'Équipement hybride photovoltaïque et thermique à circulation d’eau',
  'Foyer fermé et insert à bûches ou à granulés',
  'Isolation des fenêtres de toit',
  'Isolation des fenêtres ou portes-fenêtres',
  'Isolation des murs par l’extérieur',
  'Isolation des murs par l’intérieur',
  'Isolation des parois vitrées',
  'Isolation des planchers de combles perdus',
  'Isolation des portes d’entrée donnant sur l’extérieur',
  'Isolation des rampants de toiture ou des plafonds de combles',
  'Isolation des toitures terrasses ou des toitures par l’extérieur',
  'Mon accompagnateur rénov',
  'Pompe à chaleur géothermique ou solarothermique',
  'Pompes à chaleur air/eau, air/air et eau/eau',
  'Pose de volets isolants',
  'Pose d’une seconde fenêtre à double vitrage renforcée devant une fenêtre existante',
  'Poêle à bûches et cuisinière à bûches',
  'Poêle à granulés et cuisinière à granulés',
  'Radiateur électrique à régulation électronique à fonctions avancées',
  'Rénovation globale',
  'Système solaire combiné',
  'Systèmes de ventilation',
];

export const architectAccreditations = [
  {
    value: 'maitre_oeuvre',
    label: "Maître d'œuvre",
  },
  {
    value: 'archi_dplg',
    label: 'DPLG',
  },
  {
    value: 'archi_hmonp',
    label: 'DE-HMONP',
  },
  {
    value: 'archi_de',
    label: 'DE',
  },
  {
    value: 'archi_interieur',
    label: "Architecte d'intérieur",
  },
  {
    value: 'deco_interieur',
    label: "Décorateur d'intérieur",
  },
  {
    value: 'bet',
    label: 'BET',
  },
];

export const proProfileFlags = [
  {
    label: '',
    options: ['BET intégré', 'Travaille avec des architectes', 'RDV le samedi', 'Utilise WhatsApp'],
  },
];

export const chiffreAffairesDeclare: {
  value: ChiffreAffairesDeclare;
  label: string;
}[] = [
  {
    value: 'less_100K',
    label: '<100K',
  },
  {
    value: '100_400K',
    label: '100-400K',
  },
  {
    value: '400_800K',
    label: '400-800K',
  },
  {
    value: 'more_800K',
    label: '800K+',
  },
];

export const devisFactureStatuses: {
  [K in DevisFactureStatuses]: {
    value: K;
    label: string;
    level: Variant;
  };
} = {
  paid: {
    value: FactureStatuses.paid,
    label: 'Payée',
    level: 'success',
  },
  awaiting_payment: {
    value: FactureStatuses.awaiting_payment,
    label: 'Att. paiement',
    level: 'primary',
  },
  signed: {
    value: DevisStatuses.signed,
    label: 'Signé',
    level: 'success',
  },
  sent: {
    value: DevisStatuses.sent,
    label: 'Envoyé',
    level: 'info',
  },
  draft: {
    value: FactureStatuses.draft,
    label: 'Brouillon',
    level: 'light',
  },
  rejected: {
    value: DevisStatuses.rejected,
    label: 'Refusé',
    level: 'error',
  },
  canceled: {
    value: DevisStatuses.canceled,
    label: 'Annulé',
    level: 'warning',
  },
  to_be_revised: {
    value: DevisStatuses.to_be_revised,
    label: 'À réviser',
    level: 'warning',
  },
};

export const gammeOptions: Array<{
  value: Gamme;
  label: string;
  className?: string;
  activeClassName?: string;
}> = [
  {
    label: 'Primo',
    value: Gamme.primo,
    className: 'border border-dark font-bold bg-white text-dark hover:bg-success-100',
    activeClassName: 'bg-success-100',
  },
  {
    label: 'Confort',
    value: Gamme.confort,
    className: 'border border-dark font-bold bg-white text-dark hover:bg-info hover:text-white',
    activeClassName: '!text-white !bg-info',
  },
  {
    label: 'Premium',
    value: Gamme.premium,
    className: 'border border-dark font-bold bg-white text-dark hover:bg-primary hover:text-white',
    activeClassName: '!bg-primary !text-white',
  },
  {
    label: 'Exclusif',
    value: Gamme.exclusif,
  },
];

export const headerElementsPosition: {
  [K in string]: {
    logoAndDates: string;
    addresses: string;
  };
} = {
  1: {
    logoAndDates: '',
    addresses: '',
  },
  2: {
    logoAndDates: 'sm-desktop:!flex-row-reverse',
    addresses: '',
  },
  3: {
    logoAndDates: '',
    addresses: 'sm-desktop:!flex-row-reverse',
  },
  4: {
    logoAndDates: 'sm-desktop:!flex-row-reverse',
    addresses: 'sm-desktop:!flex-row-reverse',
  },
  enveloppe: {
    logoAndDates: '',
    addresses: '',
  },
};

export const novareConstructionSiret = '81377641600022';

export enum InsuranceFormType {
  decennaleAndRCPro = 'Décennale + RC pro',
  decennale = 'Décennale',
  RCPro = 'RC pro',
}

export const tvaValues: {
  [K in number]: {
    value: number;
    level: Variant;
  };
} = {
  0: {
    value: 0,
    level: 'success',
  },
  5.5: {
    value: 5.5,
    level: 'info',
  },
  10: {
    value: 10,
    level: 'light',
  },
  20: {
    value: 20,
    level: 'warning',
  },
};

export const typeTravauxOptions = [
  {
    label: 'Rénovation complète',
    value: TypeTravaux.RenovationComplete,
  },
  {
    label: 'Rénovation partielle',
    value: TypeTravaux.RenovationPartielle,
  },
  {
    label: 'Extension',
    value: TypeTravaux.Extension,
  },
  {
    label: 'Surélévation',
    value: TypeTravaux.Surelevation,
  },
  {
    label: 'Rénovation énergétique',
    value: TypeTravaux.RenovationEnergetique,
  },
  {
    label: 'Construction',
    value: TypeTravaux.Construction,
  },
  {
    label: "Aménagements d'extérieur",
    value: TypeTravaux.AmenagementExterieur,
  },
];
