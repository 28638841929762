import React from 'react';

import { ProgressBar } from '../ProgressBar';

export const DropZoneError: React.FC<{
  onClickRetry: () => void;
  multipleUpload: boolean;
}> = ({ onClickRetry, multipleUpload }) => (
  <>
    <ProgressBar value={100} size="sm" variant="error" />
    <>
      <div className="text-ds-xs sm-desktop:text-ds-b2 mt-xs text-neutral-600 text-center text-error-800 sm-desktop:mb-xxs">
        Le téléchargement {multipleUpload ? "d'un ou plusieurs fichiers" : 'du fichier'} a échoué
      </div>
      <div
        className="cursor-pointer text-ds-xs hover:underline sm-desktop:text-ds-b1 text-neutral-800 font-medium text-center mt-xxs"
        onClick={onClickRetry}
      >
        Réessayer
      </div>
    </>
  </>
);
