import { ClientActionType } from '@travauxlib/shared/src/types';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';

export const documentActions: { [key in DocumentTag]?: ClientActionType } = {
  [DocumentTag.DPE]: ClientActionType.AddDpe,
  [DocumentTag.Plan]: ClientActionType.AddPlans,
  [DocumentTag.Photo]: ClientActionType.AddPhotos,
  [DocumentTag.Decoration]: ClientActionType.AddInspirations,
};

export const documentSuccessMessage: { [key in DocumentTag]?: string } = {
  [DocumentTag.DPE]: 'DPE ajouté avec succès !',
  [DocumentTag.Plan]: 'Plan ajouté avec succès !',
  [DocumentTag.Photo]: 'Photos ajoutées avec succès !',
  [DocumentTag.Decoration]: 'Inspirations ajoutées avec succès !',
  [DocumentTag.Other]: 'Document ajouté avec succès !',
};
