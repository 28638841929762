import React from 'react';

import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { Primes } from '@travauxlib/shared/src/features/LDDToolbar/components/Primes';
import { DevisCustomization } from '@travauxlib/shared/src/types';
import { getFacturationTotals } from '@travauxlib/shared/src/utils/facture/getFacturationTotals';

import { Facture, FactureMinimalView } from 'types';

import { FacturationInfos } from './FacturationInfos';
import { LotRecap } from './LotRecap';
import { MontantsTVA } from './MontantsTVA';
import { Prices } from './Prices';
import { TotalPrices } from './TotalPrices';

export type Props = {
  facture: Facture;
  customization?: DevisCustomization;
  linkedFactures: FactureMinimalView[];
};

const ModalitePaiementComponent = (days: number): JSX.Element => (
  <small className="text-gray-600" data-testid="modalites-paiement">
    Règlement par virement -{' '}
    {days === 0 ? 'dès réception' : `Sous ${days} jour${days > 1 ? 's' : ''} après réception`}
    <br />
    Pas d&apos;escompte en cas de paiement anticipé.
    <br />
    Tout retard de paiement sera passible d&apos;une pénalité de 2,5 fois le taux légal en vigueur.
    <br />
    Ainsi que d&apos;une indemnité forfaitaire pour frais de recouvrement de 40 €
    <br />
    (Loi n°2012-387 du 22 mars 2012 du Code de Commerce)
    <br />
  </small>
);

export const FacturePrestationsSummary: React.FC<Props> = ({
  facture,
  customization,
  linkedFactures,
}) => {
  const totaux = facture.isCloture
    ? getFacturationTotals(linkedFactures)
    : {
        montantDejaPaye: facture.montantDejaPaye,
        montantRestantAPayer: facture.montantRestantAPayer,
      };

  return (
    <DevisFactureCard className="!mb-[5.5rem]" customization={customization}>
      <LotRecap lots={facture.lots} customization={customization} />
      <Prices prestations={facture} />
      <MontantsTVA montantsTVA={facture.montantsTVA} lots={facture.lots} />
      <Primes primes={facture.primes} />
      <TotalPrices displayedPrixTotalTTC={facture.prixTotalTTC} prestations={facture} />
      <FacturationInfos facture={facture} totaux={totaux} isCloture={facture.isCloture} />
      {facture.isAvoir ? null : ModalitePaiementComponent(facture.delaiPaiement)}
    </DevisFactureCard>
  );
};
