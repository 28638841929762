import React from 'react';

import { Routes, Navigate, Route } from 'react-router-dom';

import { GooglemapsAddress } from '@travauxlib/shared/src/types';

import { Estimation } from 'features/Estimateur/types';

import { ConfigurationForm, FormValues } from '../components/ConfigurationForm';
import { steps } from '../utils/constants';

type Props = {
  isEstimateurPartner: boolean;
  estimation?: Estimation;
  setUserAddress?: (address: GooglemapsAddress) => void;
  handleSubmitConfiguration: (values: FormValues) => void;
};

export const NewRoutes: React.FC<Props> = ({
  isEstimateurPartner,
  estimation,
  handleSubmitConfiguration,
  setUserAddress,
}) => {
  const shouldGoToFirstStep = isEstimateurPartner && !estimation?.firstName;
  const startingStep = steps[shouldGoToFirstStep ? 0 : 1];

  return (
    <Routes>
      <Route
        path=":step"
        element={
          <ConfigurationForm
            estimation={estimation}
            setUserAddress={setUserAddress}
            onSubmit={handleSubmitConfiguration}
          />
        }
      />
      <Route path="" element={<Navigate replace to={startingStep} />} />
    </Routes>
  );
};
