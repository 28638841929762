import { useMemo } from 'react';

import { DIRECT_PAYMENT_LIMIT_AMOUNT } from '../config/facture';

type Params = {
  facture: { prixTotalTTC: number; montantRestantAPayer?: number };
  company?: { stripeConnectAccountId?: string };
  directPaymentAccount?: { charges_enabled?: boolean };
  isHemea?: boolean;
};

export const useDirectPaymentAvailableForFacture = ({
  facture,
  company,
  isHemea = false,
  directPaymentAccount,
}: Params): {
  factureIsEligible: boolean;
  companyHasDirectPaymentCapabilities: boolean;
  directPaymentAvailableForFacture: boolean;
} =>
  useMemo(() => {
    const companyHasDirectPaymentCapabilities = !!(
      company?.stripeConnectAccountId && directPaymentAccount?.charges_enabled
    );
    const factureIsEligible =
      (facture.montantRestantAPayer ?? facture.prixTotalTTC) < DIRECT_PAYMENT_LIMIT_AMOUNT &&
      !isHemea;

    return {
      factureIsEligible,
      companyHasDirectPaymentCapabilities,
      directPaymentAvailableForFacture: factureIsEligible && companyHasDirectPaymentCapabilities,
    };
  }, [isHemea, company, facture, directPaymentAccount]);
