import { Ligne, Lot, DevisLocation } from '@travauxlib/shared/src/types';

import { generateLineComparison } from './generateLineComparison';

import { LotComparison } from '../types';

type Devis = {
  lots: Lot[];
  locations: DevisLocation[];
};

type Options = {
  matchThread: boolean;
};

export const generateDevisComparison = (
  baseDevis: Devis,
  compareDevis: Devis,
  { matchThread }: Options = { matchThread: false },
): LotComparison[] => {
  const comparison: LotComparison[] = [];
  // first we find similarities
  baseDevis.lots.map(originalLot => {
    const baseLines = originalLot.items.filter(ligne => ligne.type === 'ligne') as Ligne[];
    const lotToCompare = compareDevis.lots.find(
      lot => lot.uuid === originalLot.uuid || lot.label === originalLot.label,
    );

    if (!lotToCompare) {
      return comparison.push({
        status: 'missing',
        base: originalLot,
        lignes: generateLineComparison(baseLines, [], [], [], matchThread),
      });
    }

    const compareLines = lotToCompare.items.filter(ligne => ligne.type === 'ligne') as Ligne[];

    comparison.push({
      base: originalLot,
      compare: lotToCompare,
      lignes: generateLineComparison(
        baseLines,
        compareLines,
        baseDevis.locations,
        compareDevis.locations,
        matchThread,
      ),
      status: originalLot.label === lotToCompare.label ? 'unchanged' : 'modified',
    });
  });

  compareDevis.lots
    .filter(
      originalLot =>
        !baseDevis.lots.some(
          lotToCompare =>
            lotToCompare.uuid === originalLot.uuid || lotToCompare.label === originalLot.label,
        ),
    )
    .map(lot =>
      comparison.push({
        status: 'added',
        compare: lot,
        lignes: generateLineComparison(
          [],
          lot.items.filter(item => item.type === 'ligne') as Ligne[],
          [],
          [],
          matchThread,
        ),
      }),
    );

  return comparison;
};
