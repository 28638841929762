import React from 'react';

import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { Devis } from 'types';

import { DureeValidite } from './DureeValidite';

type Props = {
  devis: Devis;
};

export const DevisDates: React.FC<Props> = ({ devis }) => {
  const { isPreChiffrage, date, dureeValidite, proContext } = devis;

  if (isPreChiffrage) {
    return null;
  }
  return (
    <div className="flex flex-col">
      {proContext?.isRGE && <>Devis RGE</>}
      <span>En date du {formatFrenchDate(date)}</span>
      {dureeValidite && <DureeValidite date={date} dureeValidite={dureeValidite} />}
      {proContext?.dateVisiteTechniquePrealable && (
        <span>
          Visite technique préalable : {formatFrenchDate(proContext?.dateVisiteTechniquePrealable)}
        </span>
      )}
    </div>
  );
};
