import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links/Link';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { isURLAnImage } from '@travauxlib/shared/src/utils/urls';

const Image: React.FC<Props> = ({ fileUrl }) => (
  <Link href={fileUrl} target="_blank" rel="noopener noreferrer">
    <ImgLazy alt="" url={fileUrl} className="mb-xs" width={130} height={130} />
  </Link>
);

const OtherAttachment: React.FC<Props> = ({ fileUrl, label }) => (
  <Link href={fileUrl} target="_blank" rel="noopener noreferrer">
    <span className="mr-xs">{label}</span>
  </Link>
);

type Props = {
  fileUrl: string;
  label: string;
};

export const Attachment: React.FC<{ fileUrl: string; label: string }> = ({ fileUrl, label }) => (
  <div key={fileUrl} className="mb-sm px-xs sm-desktop:max-w-6/12">
    {isURLAnImage(fileUrl) ? (
      <Image fileUrl={fileUrl} label={label} />
    ) : (
      <OtherAttachment fileUrl={fileUrl} label={label} />
    )}
  </div>
);
