import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SUIVI_CHANTIERS_KEY } from '@travauxlib/shared/src/features/SuiviChantier/api/createSuiviChantierHistoryList';
import { SuiviChantierClient } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const useSuiviChantier = (
  chantierUuid: string,
  suiviChantierUuid: string,
): { suiviChantier?: SuiviChantierClient; isLoading: boolean } => {
  const { data, isLoading, fetchStatus } = useQuery<SuiviChantierClient>({
    queryKey: [SUIVI_CHANTIERS_KEY, chantierUuid, 'latest'],
    queryFn: async () => {
      try {
        return await request(
          `${APP_CONFIG.apiURL}/client/chantiers/${chantierUuid}/suivi-chantier/${suiviChantierUuid}`,
        );
      } catch (err) {
        toast.error("Le suivi n'a pas pu être récupéré");
        throw err;
      }
    },
  });

  return { suiviChantier: data, isLoading: isLoading && fetchStatus !== 'idle' };
};
