import React from 'react';

import classNames from 'classnames';

import { Card } from '../Card';

type Props = {
  selected: boolean;
  className: string;
  onChange: (value: number) => void;
  value: number;
  icon: JSX.Element;
  text: string;
  disabled?: boolean;
};

export const CustomerSatisfactionCard: React.FC<Props> = ({
  selected,
  className,
  onChange,
  value,
  icon,
  text,
  disabled,
}) => (
  <Card
    state="outlined"
    className={classNames(
      '!rounded-md w-[7.5rem] h-[5rem]',
      selected && className,
      disabled ? '' : 'cursor-pointer', // TODO: eng
    )}
    bodyClassNames="p-xs"
    onClick={() => !disabled && onChange(value)}
    data-testid={`customer-card-${value}`}
  >
    <div className="flex justify-center mb-xs">
      <span className="w-xl h-xl">{icon}</span>
    </div>
    <div className="text-neutral-800 text-ds-b2 font-medium flex justify-center">{text}</div>
  </Card>
);
