import React from 'react';

import _range from 'lodash/range';

import HandshakeOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandshakeOutline.svg?react';
import RealEstateOutline from '@travauxlib/shared/src/components/DesignSystem/assets/RealEstateOutline.svg?react';
import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFull.svg?react';

import { ReassuranceItem } from './ReassuranceItem';
import { ReassuranceSection } from './ReassuranceSection';

export const QuestionsReassurance: React.FC = () => (
  <ReassuranceSection>
    <ReassuranceItem
      className="flex-col"
      icon={
        <>
          {_range(5).map(value => (
            <StarFull className="text-primary w-lg h-lg mb-xxs sm-desktop:mb-0" key={value} />
          ))}
        </>
      }
    >
      <>
        +600&nbsp;avis
        <span className="sm-desktop:hidden mx-xxs">|</span>
        <span>Excellent</span>
      </>
    </ReassuranceItem>
    <ReassuranceItem icon={<RealEstateOutline className="text-primary w-lg h-lg" />}>
      <>3000 chantiers réalisés</>
    </ReassuranceItem>
    <ReassuranceItem icon={<HandshakeOutline className="text-primary w-lg h-lg" />}>
      <>300 entrepreneurs labellisés</>
    </ReassuranceItem>
  </ReassuranceSection>
);
