import React from 'react';

import MinusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/MinusSymbol.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { DevisLocation } from '@travauxlib/shared/src/types';

import { ItemProps } from './types';

import { IconButton } from '../DesignSystem/components/Buttons/IconButton';
import { ColoredCircle } from '../DesignSystem/components/ColoredCircle';

type Props = {
  locationType: ItemProps;
  locations: DevisLocation[];
  onAdd: (newLocation: ItemProps) => void;
  onDelete: (deletedLocation: DevisLocation) => void;
  disableReason?: string;
};

export const LocationAdder: React.FC<Props> = ({
  locationType,
  locations,
  onAdd,
  onDelete,
  disableReason,
}) => {
  const countOccurrence = ({ label, type }: ItemProps): number =>
    locations.filter(location => location.typeLocation === (type || label)).length;

  const handleAdd = ({ label, type }: ItemProps): void => {
    const newItem = { label, typeLocation: type || label };

    return onAdd(newItem);
  };

  const handleDelete = ({ label, type }: ItemProps): void => {
    const deletedLocation = [...locations]
      .reverse()
      .find(location => location.typeLocation === (type || label));

    return onDelete(deletedLocation!);
  };

  const count = countOccurrence(locationType);

  return (
    <div className="!flex !items-center !justify-between !font-bold sm-desktop:!mb-md !mb-sm">
      {locationType.label}
      <div className="!flex !items-center">
        <IconButton
          data-testid={`delete-${locationType.label}`}
          onClick={() => (count ? handleDelete(locationType) : undefined)}
        >
          <ColoredCircle variant="success" size="xs">
            <MinusSymbol className="text-gray-900" />
          </ColoredCircle>
        </IconButton>

        <span className="mx-sm">{count}</span>
        <WithTooltip
          position="right"
          trigger={
            <IconButton
              disabled={!!disableReason}
              data-testid={`add-${locationType.label}`}
              onClick={() => handleAdd(locationType)}
            >
              <ColoredCircle variant="success" size="xs">
                <PlusSymbol className="text-gray-900" />
              </ColoredCircle>
            </IconButton>
          }
        >
          {disableReason ? <div className="!p-md">{disableReason}</div> : undefined}
        </WithTooltip>
      </div>
    </div>
  );
};
