import React, { useEffect } from 'react';

// click will not behave correctly, use mouseup if you don't want
// the default behavior
type PossibleEvent =
  | DocumentEventMap['mousedown']
  | DocumentEventMap['touchstart']
  | DocumentEventMap['mouseup']
  | DocumentEventMap['touchend'];

type Handler = (event: PossibleEvent) => void;

type Options = {
  disabled?: boolean;
  event?: 'mousedown' | 'mouseup';
  outsideClickIgnoreClass?: string;
};

export const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: Handler,
  options?: Options,
): void => {
  const disabled = options?.disabled || false;
  const events = options?.event === 'mouseup' ? ['mouseup', 'touchend'] : ['mousedown', 'touchend'];

  const outsideClickIgnoreClass = options?.outsideClickIgnoreClass || 'ignore-click-outside';
  useEffect(() => {
    const listener = (event: PossibleEvent): void => {
      // closest checks if the element you clicked on or his children possess the ignore-click-outside class
      const clickOnIgnoredElement = (event.target as HTMLElement).closest(
        `.${outsideClickIgnoreClass}`,
      );

      // Do nothing if clicking ref's element or descendent elements or prevented element and its children
      if (!ref.current || ref.current.contains(event.target as Node) || clickOnIgnoredElement) {
        return;
      }

      handler(event);
    };
    if (disabled) {
      return () => {};
    }

    events.forEach(event => document.addEventListener(event, listener as EventListener));

    return () => {
      events.forEach(event => document.removeEventListener(event, listener as EventListener));
    };
  }, [ref, handler]);
};
