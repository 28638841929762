import React from 'react';

import classNames from 'classnames';

import { AlertMessageBody } from './AlertMessageBody';
import { config } from './constants';
import { Level } from './types';

type Props = {
  title?: string;
  actionLabel?: string;
  level: Level;
  onClickClose?: () => void;
  className?: string;
  href?: string;
  to?: string;
  children?: React.ReactNode;
};

export const AlertMessage: React.FC<Props> = ({ children, className, level, ...rest }) => {
  const { containerClassnames } = config[level];

  return (
    <div
      className={classNames(
        'px-sm border rounded flex',
        children ? 'py-xxs' : 'py-xs',
        containerClassnames,
        className,
      )}
    >
      <AlertMessageBody level={level} {...rest}>
        {children}
      </AlertMessageBody>
    </div>
  );
};
