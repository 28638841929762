import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  montantALibererTTC: number;
  currentThreshold?: number;
  montantGele: number;
};

export const ThresholdAndFrozenMoneyRecap: React.FC<Props> = ({
  montantALibererTTC,
  currentThreshold,
  montantGele,
}) => {
  const montantValide = montantALibererTTC + montantGele;

  if (montantGele <= 0) {
    return montantALibererTTC > 0 ? (
      <div>
        <EURCurrency amount={montantALibererTTC} className="font-bold" /> vont être versés à
        l’entreprise.
      </div>
    ) : null;
  }

  return (
    <div>
      {montantALibererTTC <= 0 ? (
        <>
          Vous avez atteint le plafond de <b>{currentThreshold}%</b> au-delà duquel les paiements
          validés sont gelés. L’intégralité des{' '}
          <EURCurrency className="font-bold" amount={montantGele} /> validés va être gelé.
        </>
      ) : (
        <>
          Vous avez atteint le plafond de <b>{currentThreshold}%</b> au-delà duquel les paiements
          validés sont gelés. Sur les <EURCurrency className="font-bold" amount={montantValide} />{' '}
          validés, <EURCurrency className="font-bold" amount={montantGele} /> vont être gelés et{' '}
          <EURCurrency className="font-bold" amount={montantALibererTTC} /> seront versés
          immédiatement à l’entreprise.
        </>
      )}
    </div>
  );
};
