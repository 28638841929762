import React from 'react';

import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { GuidePage } from '../components/GuidePage';
import { GuidesSection } from '../components/GuidesSection';
import { GuidesTags } from '../types';
import { guidesConfigs } from '../utils/constants';
import { guidesBaseUrl } from '../utils/urls';

const GuidePageWrapper: React.FC = () => {
  const { guide, uuid } = useParams();
  const guideConfig = guidesConfigs[guide as GuidesTags];

  return guideConfig ? (
    <GuidePage {...guideConfig.pageConfig} />
  ) : (
    <Navigate replace to={guidesBaseUrl(uuid!)} />
  );
};

export const GuideRoutes: React.FC = () => (
  <Routes>
    <Route path="" element={<GuidesSection />} />
    <Route path=":guide" element={<GuidePageWrapper />} />
  </Routes>
);
