import React from 'react';

import ProjectPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectPicto.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

import { ReassuranceCard } from 'features/EspaceClient3/components/ReassuranceCard';
import { chantierReassurance } from 'features/EspaceClient3/features/Chantier/utils/constants';

export const SuiviChantierDisabled: React.FC = () => (
  <div className="m-md sm-desktop:mb-3xl sm-desktop:mx-0">
    <EmptyState
      className="mb-md sm-desktop:mb-xl"
      illustration={<ProjectPicto />}
      iconIllustrationSize="md"
      title="La fonctionnalité de suivi de chantier n'est pas activée"
      description="Contactez hemea pour en savoir plus sur cette fonctionallité"
    />
    <ReassuranceCard items={chantierReassurance} />
  </div>
);
