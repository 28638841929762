import { GenericFacture, MontantTVA } from '../../types';

interface MontantWithIsAvoir extends MontantTVA {
  isAvoir?: boolean;
}

export const getTotalFacturedMontantsTVA = (
  facturedFactureList: GenericFacture[],
): MontantTVA[] => {
  let ret: {
    [key: number]: { base: number; montant: number };
  } = {};

  // On sépare chacun des montants dans un tableau en indicant s'ils sont lié à un avoir
  const montants: MontantWithIsAvoir[] = facturedFactureList.flatMap(facture =>
    facture.montantsTVA.map(montant => ({
      ...montant,
      isAvoir: facture.isAvoir,
    })),
  );

  /** On additionne les montants en créant soit une nouvelle entrée si elle est pas présente,
   * soit en ajoutant soit en soustrayant (dans le cas d'un avoir) au montant total du taux correspondant
   *
   */
  montants.map(montant => {
    const curr = ret[montant.taux];
    if (curr) {
      ret[montant.taux] = {
        base: curr.base + (montant.isAvoir ? -montant.base : montant.base),
        montant: curr.montant + (montant.isAvoir ? -montant.montant : montant.montant),
      };
    } else {
      ret[montant.taux] = {
        base: montant.isAvoir ? -montant.base : montant.base,
        montant: montant.isAvoir ? -montant.montant : montant.montant,
      };
    }
  });

  return Object.entries(ret).map(([taux, montants]) => ({
    taux: Number(taux),
    base: montants.base,
    montant: montants.montant,
  }));
};
