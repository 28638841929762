import { request } from '@travauxlib/shared/src/utils/request';

export const publicLeadAutoAssign = (token: string): void => {
  request(`${APP_CONFIG.apiURL}/client/lead/auto-assign/${token}`, {
    method: 'POST',
  }).catch(e => {
    // eslint-disable-next-line
    console.error('Auto assignment of lead failed because of error : ', e);
  });
};
