import React, { useCallback, useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import InfoCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleOutline.svg?react';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';
import { documentsSections } from '@travauxlib/shared/src/utils/project';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useProject } from 'features/EspaceClient3/api/useProject';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { useProjectDocuments } from './api/useProjectDocuments';
import { DocumentMenu } from './components/DocumentMenu';
import { useOpenDocumentModal } from './hooks/useOpenDocumentModal';

export const Documents: React.FC = () => {
  const { uuid: projectUuid } = useParams<{ uuid: string }>();
  const { project } = useProject(projectUuid!);
  const [searchParams] = useSearchParams();
  const openDocumentModal = useOpenDocumentModal();
  const { projectDocuments, isLoading } = useProjectDocuments(projectUuid!);

  useEffect(() => {
    if (project) {
      EspaceClientTracking.onEspaceClientDocumentsViewed({
        'Project Uuid': project.uuid,
        'Project Name': project.title,
      });
    }
  }, [project]);

  const handleOpenModal = useCallback(
    (tag: DocumentTag) => {
      const { label, description } = documentsSections[tag]!;

      return openDocumentModal({
        projectUuid: projectUuid!,
        tag,
        title: label,
        description,
      });
    },
    [projectDocuments],
  );

  useEffect(() => {
    const tag = searchParams?.get('tag');

    if (tag) {
      handleOpenModal(tag as DocumentTag);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="text-ds-h5 py-sm mx-md sm-desktop:mx-0 font-bold">
        Mes documents du projet
      </div>
      <div className="py-xxs bg-neutral-0 rounded-xxs shadow-ds-xxs">
        {Object.entries(documentsSections).map(([tag, { icon, label }]) => {
          const parsedTag = tag as DocumentTag;
          const isMandatory = parsedTag !== DocumentTag.Other;
          const documentUploadedAt = projectDocuments[parsedTag]?.[0].createdAt;

          return (
            <ListItem
              className="last:border-b-0 text-ds-b2 !rounded-none"
              key={tag}
              state="filled"
              size="sm"
              onChange={() => !documentUploadedAt && handleOpenModal(parsedTag)}
              left={{ type: 'iconBackground', icon, variant: 'beige', className: 'mt-xxs' }}
              middle={{ title: <span className="font-normal">{label}</span> }}
              right={
                documentUploadedAt
                  ? {
                      type: 'jsx',
                      children: <DocumentMenu onClickEdit={() => handleOpenModal(parsedTag)} />,
                    }
                  : { type: 'icon', icon: <ChevronRight /> }
              }
            >
              {documentUploadedAt ? (
                <div className="-mt-xs text-neutral-600">
                  Ajouté le{' '}
                  <span className="text-neutral-800">{formatFrenchDate(documentUploadedAt)}</span>
                </div>
              ) : isMandatory ? (
                <div className="flex items-center gap-xxs -mt-xs text-info-800">
                  <InfoCircleOutline className="w-xmd h-xmd" />
                  Manquant
                </div>
              ) : (
                <div className="-mt-xs text-neutral-600">Facultatif</div>
              )}
            </ListItem>
          );
        })}
      </div>
    </div>
  );
};
