import { PaymentIntent } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

export type PayFactureByCardPayload = {
  factureToken: string;
  paymentMethodId: string;
};

const handlePayFactureByCard = async (payload: PayFactureByCardPayload): Promise<PaymentIntent> => {
  const paymentIntent: PaymentIntent = await request(
    `${APP_CONFIG.apiURL}/client/factures/${payload.factureToken}/pay`,
    {
      method: 'POST',
      body: {
        paymentMethodId: payload.paymentMethodId,
      },
    },
  );

  if (paymentIntent.status !== 'succeeded') {
    throw new Error('Votre paiment a échoué');
  }

  return paymentIntent;
};

export const usePayFactureByCard = (
  onSuccess: () => void,
): {
  payFactureByCard: typeof handlePayFactureByCard;
  isLoading: boolean;
} => {
  const { mutateAsync: payFactureByCard, isPending } = useMutation({
    mutationFn: handlePayFactureByCard,
    onSuccess: () => {
      onSuccess();
      toast.success('Votre paiment à été traité avec succès');
    },
    onError: () => {
      toast.error('Il y a eu un prolbème lors du paiement');
    },
  });
  return { payFactureByCard, isLoading: isPending };
};
