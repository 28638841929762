import _groupBy from 'lodash/groupBy';

import { Ligne, MontantTVA } from '../../types';
import { roundToTwoDecimals } from '../format';

export const getTotalTVAFromMontants = (montants: MontantTVA[]): number =>
  montants.reduce((curr, val) => curr + val.montant, 0);

const applyDifference = (prixHT: number, difference: number): number =>
  prixHT * (1 - difference / 100);

export const getMontantsTVA = (paidLines: Ligne[], percentageDiff: number): MontantTVA[] => {
  const linesWithTVA = paidLines.filter(l => l.tauxTVA !== 0);
  const linesByTauxTVA = _groupBy(linesWithTVA, l => l.tauxTVA);

  return Object.entries<Ligne[]>(linesByTauxTVA)
    .map(([tauxTVA, lignes]) => {
      const prixTotalHT = roundToTwoDecimals(
        lignes.reduce((prev, l) => prev + applyDifference(l.prixHT, percentageDiff), 0),
      );
      const montantTVA = roundToTwoDecimals((prixTotalHT * Number(tauxTVA)) / 100);
      return { taux: Number(tauxTVA), base: prixTotalHT, montant: montantTVA };
    })
    .sort((a, b) => a.taux - b.taux);
};
