import React, { createContext, useContext, useEffect, useState } from 'react';

import { ConsentValue } from './constants';
import { getDefaultConsentsFromCookie, parseConsentPayload } from './getDefaultConsentsFromCookie';
import { updateConsents as updateGTMAndCookieConsents } from './updateConsents';

import { ConsentPayload, GrantedConsents } from '../../types';

type ConsentsContextProps = {
  updateConsents: (consentPayload: ConsentPayload) => void;
  acceptAllConsents: () => void;
  rejectAllConsents: () => void;
  grantedConsents: GrantedConsents;
};

const ConsentsContext = createContext<ConsentsContextProps>({
  updateConsents: () => {},
  acceptAllConsents: () => {},
  rejectAllConsents: () => {},
  grantedConsents: getDefaultConsentsFromCookie(),
});

export const useConsents = (): ConsentsContextProps => useContext(ConsentsContext);

export const useIsTrackingAuthorized = (): boolean => {
  const [isTrackingAuthorized, setIsTrackingAuthorized] = useState(false);
  const {
    grantedConsents: { isPersonalizationConsentGranted },
  } = useConsents();

  useEffect(() => {
    setIsTrackingAuthorized(isPersonalizationConsentGranted);
  }, [isPersonalizationConsentGranted]);

  return isTrackingAuthorized;
};

export const ConsentsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [grantedConsents, setGrantedConsents] = useState<GrantedConsents>(
    getDefaultConsentsFromCookie(),
  );

  const updateConsents = (consentPayload: ConsentPayload): void => {
    updateGTMAndCookieConsents(consentPayload);
    setGrantedConsents(parseConsentPayload(consentPayload));
  };

  const acceptAllConsents = (): void => {
    const acceptAllPayload: ConsentPayload = {
      ad_storage: ConsentValue.Granted,
      analytics_storage: ConsentValue.Granted,
      personalization_storage: ConsentValue.Granted,
    };

    updateConsents(acceptAllPayload);
  };

  const rejectAllConsents = (): void => {
    const rejectAllPayload: ConsentPayload = {
      ad_storage: ConsentValue.Denied,
      analytics_storage: ConsentValue.Denied,
      personalization_storage: ConsentValue.Denied,
    };

    updateConsents(rejectAllPayload);
  };

  return (
    <ConsentsContext.Provider
      value={{ updateConsents, acceptAllConsents, rejectAllConsents, grantedConsents }}
    >
      {children}
    </ConsentsContext.Provider>
  );
};
