import React from 'react';

import { DownloadPDFButton } from 'components/DownloadPDFButton';
import { Facture, ProFactureContext } from 'types';

import { DirectPaymentButton } from './DirectPaymentButton';

type Props = {
  printFactureUrl: string;
  isAuthor: boolean;
  isFacturePaid: boolean;
  proContext?: ProFactureContext;
  facture: Facture;
};

export const FactureActions: React.FC<Props> = ({
  printFactureUrl,
  isAuthor,
  isFacturePaid,
  proContext,
  facture,
}) => (
  <div className="flex gap-sm mb-md sm-desktop:fixed bottom-md sm-desktop:left-1/2 sm-desktop:-translate-x-1/2 z-20">
    <DownloadPDFButton url={printFactureUrl} />
    {!isFacturePaid && proContext && !isAuthor && (
      <DirectPaymentButton facture={facture} proContext={proContext} />
    )}
  </div>
);
