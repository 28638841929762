import React from 'react';

import classNames from 'classnames';

import { DevisCustomization } from '@travauxlib/shared/src/types';

type Props = {
  title?: string;
  className?: string;
  ['data-testid']?: string;
  customization?: DevisCustomization;
  children: React.ReactNode;
};

export const DevisFactureCard: React.FC<Props> = ({
  'data-testid': testId,
  title,
  className = '',
  children,
  customization,
}) => (
  <div
    data-testid={testId}
    className={classNames('print:bg-white border p-xs sm-desktop:p-md rounded', className)}
  >
    {title && (
      <div
        className={classNames('mb-md opacity-80', customization?.subTitleFontStyle || 'font-bold')}
        style={{ color: customization?.textColor }}
      >
        {title}
      </div>
    )}
    {children}
  </div>
);
