import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { scrollContainerId } from '../utils/constants';

export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollContainer = document.getElementById(scrollContainerId);
    scrollContainer?.scrollTo?.({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
};
