import { useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

type APIResponse = { name: string; content: Blob };

export const downloadFile = async ({
  url,
  index,
}: {
  url: string;
  index: number;
}): Promise<APIResponse> => {
  const response = await request(url, { responseType: 'blob', returnFullResponse: true });

  const contentDisposition = response.headers !== null && response.headers['content-disposition'];
  const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);

  return {
    name: filenameMatch ? filenameMatch[1] : `fichier ${index}`,
    content: response.data,
  };
};

type DownloadFunction = (args: { urls: string[]; zipName: string }) => Promise<void>;

export const useDownloadAsZip = (): { downloadAsZip: DownloadFunction; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutateAsync } = useMutation({
    mutationFn: downloadFile,
    onError: () => {
      toast.error('Le téléchargement a échoué, veuillez ré-essayer');
    },
  });

  const downloadAsZip: DownloadFunction = async ({ urls, zipName }) => {
    setIsLoading(true);

    const data = urls.map((url, index) => mutateAsync({ url, index }));
    const result = await Promise.all(data);

    const jszip = new JSZip();
    const folder = jszip.folder(zipName);
    result.forEach(file => {
      const filename = file.name.split('/').at(-1)!;
      folder?.file(filename, file.content, { createFolders: false });
    });
    const blob = await jszip.generateAsync({ type: 'blob' });

    setIsLoading(false);
    saveAs(blob, zipName);
  };

  return { downloadAsZip, isLoading };
};
