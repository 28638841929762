import React from 'react';

import classNames from 'classnames';

import { IconIllustrationSize } from './types';
import { iconBackgroundSizeMapper, illustrationSizeMapper } from './utils/constants';

import { Button, ICommonButtonProps } from '../Buttons/Button';
import { ColoredCircle } from '../ColoredCircle';
import { ButtonLink, ButtonLinkProps } from '../Links/ButtonLink';

type Props = {
  icon?: React.ReactNode;
  illustration?: React.ReactNode;
  title?: string;
  description: string | React.ReactNode;
  primaryAction?: ICommonButtonProps & { label: string };
  secondaryAction?: ICommonButtonProps & { label: string };
  primaryLink?: ButtonLinkProps & { label: string };
  iconIllustrationSize?: IconIllustrationSize;
  className?: string;
  inline?: boolean;
  fullWidth?: boolean;
};

export const EmptyState: React.FC<Props> = ({
  icon,
  illustration,
  title,
  description,
  primaryAction,
  primaryLink,
  secondaryAction,
  iconIllustrationSize,
  className,
  inline = false,
  fullWidth = false,
}) => (
  <div
    className={classNames('flex flex-col items-center gap-md', { '!flex-row': inline }, className)}
  >
    {icon && (
      <ColoredCircle
        variant="beige"
        size={
          iconIllustrationSize
            ? iconBackgroundSizeMapper[iconIllustrationSize]
            : iconBackgroundSizeMapper['lg']
        }
      >
        {icon}
      </ColoredCircle>
    )}
    {illustration && (
      <div
        data-testid="empty-state-illustration"
        className={
          iconIllustrationSize
            ? illustrationSizeMapper[iconIllustrationSize]
            : illustrationSizeMapper['sm']
        }
      >
        {illustration}
      </div>
    )}
    <div className={classNames('flex flex-col text-center', { '!text-left': inline })}>
      {title && <span className="text-neutral-800 font-bold mb-xxs">{title}</span>}
      <span className="text-neutral-600 text-[14px]">{description}</span>
    </div>
    {primaryAction && (
      <Button {...primaryAction} fullwidth={fullWidth}>
        {primaryAction.label}
      </Button>
    )}
    {primaryLink && (
      <ButtonLink {...primaryLink} fullwidth={fullWidth}>
        {primaryLink.label}
      </ButtonLink>
    )}
    {secondaryAction && (
      <Button {...secondaryAction} variant="secondary" fullwidth={fullWidth}>
        {secondaryAction.label}
      </Button>
    )}
  </div>
);
