import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { PvType } from '@travauxlib/shared/src/features/Chantiers/types';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { PROJECTS_KEY } from 'features/EspaceClient3/api/useProjects';

type Payload = {
  chantierUuid: string;
  pvType: PvType;
  pvUuid: string;
  hasReserves: boolean;
  dateSignature: string;
};

const handleValidatePv = ({ chantierUuid, ...body }: Payload): Promise<void> => {
  const requestUrl = `${APP_CONFIG.apiURL}/client/chantiers/${chantierUuid}/validate-pv`;

  return request(requestUrl, { method: 'POST', body });
};

export const useValidatePv = (): {
  validatePv: typeof handleValidatePv;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: validatePv, isPending } = useMutation({
    mutationFn: handleValidatePv,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECTS_KEY] });
    },
    onError: (err: Error) => {
      if (isConflict(err)) {
        toast.error('Un autre document a été uploadé!');
      } else {
        toast.error('Un problème est survenu, veuillez réessayer');
      }
    },
  });

  return { validatePv, isLoading: isPending };
};
