import React, { MouseEventHandler, ReactNode } from 'react';

import classNames from 'classnames';

import { BannerBody } from './BannerBody';
import { config } from './constants';
import { Level } from './types';

type Props = {
  title?: ReactNode;
  actionLabel?: string;
  level: Level;
  onClickClose?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  href?: string;
  to?: string;
  small?: boolean;
  fullWidthLink?: boolean;
  icon?: JSX.Element;
};

export const Banner: React.FC<Props> = ({ className, level, small, ...rest }) => {
  const { containerClassnames } = config[level];

  return (
    <div
      className={classNames(
        'px-sm border-b flex shadow-ds-md',
        containerClassnames,
        small ? 'py-xxs' : 'py-xs',
        className,
      )}
    >
      <BannerBody level={level} small={small} {...rest} />
    </div>
  );
};
