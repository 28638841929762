import React from 'react';

import { Modal } from './Modal';
import { ModalContent, Props as ModalContentProps } from './ModalContent';
import { Props as ModalProps } from './types';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
};

export const SimpleModal: React.FC<Props & ModalContentProps & Omit<ModalProps, 'children'>> = ({
  children,
  ...rest
}) => (
  <Modal {...rest}>
    <ModalContent {...rest}>{children}</ModalContent>
  </Modal>
);
