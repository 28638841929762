import { CookieAttributes } from 'js-cookie';

export enum Cookie {
  Consents = 'hemeaConsents',
  DeviceFirstPage = 'tlDeviceFirstPage',
  DeviceFirstPageReferrer = 'tlDeviceFirstPageReferrer',
  DeviceFirstPageDate = 'tlDeviceFirstPageDate',
  SessionFirstPage = 'tlSessionFirstPage',
  SessionFirstPageReferrer = 'tlSessionFirstPageReferrer',
  SessionFirstPageDate = 'tlSessionFirstPageDate',
  WebsiteLastPathCookie = 'WebsiteLastPathCookie',
  Gclids = 'tlGclids',
  ConversionButton = 'tlConversionButton',
}

// TODO Find a better way to handle the cookies that use this variable
// TODO Because it's not always the behaviour we want. More info with worst dev Alexandre
// Session cookies https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#define_the_lifetime_of_a_cookie
export const sessionCookieAttributes: CookieAttributes = {
  path: '/',
  domain: '.hemea.com', // TODO: Probably should find a better way but for now it's ok
  sameSite: 'None',
  secure: true,
};

export const defaultCookieAttributes: CookieAttributes = {
  expires: 90,
  ...sessionCookieAttributes,
};
// If you modify this, think about the rgpd script in website
