import React from 'react';

import { Link, useMatch } from 'react-router-dom';

import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { Layout } from 'features/Estimateur/components/Layout';
import { EstimateurRoutes } from 'features/Estimateur/routes';
import { Header } from 'features/Partner/components/Header';
import { espaceClientUrl } from 'utils/urls';

import { useEstimations } from './api/useEstimations';

import { newEstimationBasePath } from '../../utils/paths';

export const EstimateurClient: React.FC = () => {
  const { isLoading, estimations, submitEstimation, deleteEstimation } = useEstimations();
  const isNewProjectPage = useMatch(`${newEstimationBasePath}`) !== null;
  if (isLoading || !estimations) {
    return <LoaderWrapper />;
  }

  return (
    <Layout>
      <Header
        logoTo={espaceClientUrl}
        rightLink={
          <>
            <div className="mr-md flex text-ds-sm sm-desktop:text-ds-b2">
              <Link to="">Mes projets</Link>
              {!isNewProjectPage && (
                <Link className="hidden sm-desktop:inline ml-md" to="new">
                  Nouveau projet
                </Link>
              )}
            </div>
            <div className="flex">
              <ButtonLink to={espaceClientUrl}>Espace client</ButtonLink>
            </div>
          </>
        }
      />
      <EstimateurRoutes
        isClientEstimateur
        estimations={estimations}
        submitEstimation={submitEstimation}
        deleteEstimation={deleteEstimation}
      />
    </Layout>
  );
};
