import React, { useEffect } from 'react';

import classNames from 'classnames';
import { Suggestion } from 'react-places-autocomplete';

import { usePrevious } from '@travauxlib/shared/src/hooks/usePrevious';

import { Input } from '../DesignSystem/components/Input';
import { DenseSizes } from '../DesignSystem/components/Input/types';

const atMostALocalityTypes = [
  'locality',
  'sublocality',
  'postal_code',
  'street_address',
  'route',
  'premise',
];

type GetSuggestionItemProps = (suggestion: Suggestion, props: { className: string }) => object;

type Props = {
  onDisplayResults?: () => void;
  disabled?: boolean;
  getInputProps: any;
  suggestions: readonly Suggestion[];
  getSuggestionItemProps: GetSuggestionItemProps;
  floatingResults?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  inputName?: string;
  id?: string;
  autoFocus?: boolean;
  label: string;
  error?: string;
  onlyAllowNumber?: boolean;
  placeholder?: string;
  dense?: DenseSizes;
  className?: string;
};

export const InputAndResults: React.FC<Props> = ({
  onDisplayResults,
  getInputProps,
  suggestions,
  getSuggestionItemProps,
  disabled,
  floatingResults,
  onBlur,
  inputName,
  id,
  autoFocus = false,
  label,
  error,
  onlyAllowNumber,
  placeholder,
  dense,
  className,
}) => {
  const prevSuggestions = usePrevious(suggestions) || [];
  useEffect(() => {
    if (onDisplayResults && prevSuggestions.length === 0 && suggestions.length > 0) {
      // when the dropdown appear, scroll down until the dialog to display the whole list
      onDisplayResults();
    }
  }, []);

  const filteredSuggestions = suggestions.filter(
    suggestion => !!suggestion.types.find(type => atMostALocalityTypes.includes(type)),
  );

  const inputProps = getInputProps({
    onBlur,
    name: inputName,
  });

  return (
    <div className="relative">
      <Input
        {...inputProps}
        disabled={disabled}
        placeholder={placeholder}
        label={label}
        id={id}
        data-testid={id}
        data-hj-whitelist
        error={error}
        nativeOnChange={inputProps.onChange}
        autoFocus={autoFocus}
        inputMode={onlyAllowNumber ? 'numeric' : 'text'}
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
          const { key } = event;
          if (onlyAllowNumber && key !== 'Enter' && !key.match(/[-+0-9,.]/)) {
            event.preventDefault();
          }
        }}
        className={className}
        dense={dense}
      />
      <div
        className={classNames('border rounded-b-xs overflow-hidden bg-neutral-0', {
          'border-0': suggestions.length === 0,
          'absolute z-30': floatingResults,
        })}
      >
        {filteredSuggestions.map(suggestion => (
          <div
            data-testid="result"
            {...getSuggestionItemProps(suggestion, {
              className: classNames('flex items-center min-h-[3.125rem] border-t first:border-0', {
                'bg-lighter cursor-pointer': suggestion.active,
              }),
            })}
            key={suggestion.placeId}
          >
            <div className="!p-md">{suggestion.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
