import { useEffect, useState } from 'react';

import {
  designSystemResponsiveBreakpoints,
  TailwindBreakpoint,
} from '@travauxlib/shared/src/utils/sizes';

const useIsBelowSize = (size: number, defaultValue?: boolean): boolean => {
  const mediaQuery = `(min-width: ${size}px)`;
  const isBrowser = typeof window !== 'undefined';

  /*
    This is ugly as hell but it looks like it's necessary
    gatsby will need everytime a defaultValue otherwise it won't work properly.
    without the isBrowser test, there's a ReferenceError even if we stay in the defaultValue case

    the previous code seemed fine but it wouldn't work there was some weird rendering problems

    for instance this Component

  export const Phone: React.FC = () => {
    const isTabletOrMobile = useIsTabletOrMobile(false);
    return (
      <a
        className={classNames(
          '!flex !items-center !font-bold !px-0 px-lg-md',
          isTabletOrMobile ? '!text-white' : '!text-dark',
        )}
        href="tel:0182285600"
      >
        <div>{isTabletOrMobile ? '!text-white' : '!text-dark'}</div>
        01 82 28 56 00
      </a>
    );
  };

  would render the wrong class: !text-dark but the correct text text-white

  Go check it if you dare to change how this Component work, you probably will break something
  */

  const [isBelowSize, setIsBelowSize] = useState(
    defaultValue === undefined && isBrowser
      ? !window?.matchMedia?.(mediaQuery).matches
      : defaultValue || false,
  );

  useEffect(() => {
    const handler = (): void => setIsBelowSize(!window?.matchMedia?.(mediaQuery).matches);
    window.addEventListener('resize', handler);

    handler();
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return isBelowSize;
};

export const useIsBelowBreakpoint = (
  breakpoint: TailwindBreakpoint,
  defaultValue?: boolean,
): boolean => useIsBelowSize(designSystemResponsiveBreakpoints[breakpoint], defaultValue);
