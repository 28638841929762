import React from 'react';

import classNames from 'classnames';

type Props = {
  id: string;
  value: number;
  onChange: (value: number) => void;
  isMaxBound: boolean;
  isBeforeAvancement: boolean;
  disabled?: boolean;
  showValue?: boolean;
};

export const Step: React.FC<Props> = ({
  id,
  value,
  onChange,
  isMaxBound,
  isBeforeAvancement,
  disabled,
  showValue,
}) => (
  <label
    className="cursor-pointer mb-0 text-neutral-600"
    htmlFor={id}
    onClick={() => onChange(value)}
  >
    <div
      className={classNames(
        'relative left-0 w-sm h-sm rounded-full',
        { '!bg-neutral-300': disabled },
        showValue ? 'top-[-1.125rem]' : 'top-[-0.875rem]',
        isBeforeAvancement
          ? 'bg-primary group-hover:bg-primary-500 group-active:bg-primary-600'
          : 'bg-neutral-0 border-neutral-300 border-2',
      )}
    />
    {showValue && (
      <div className={classNames('absolute top-0', { 'right-0': isMaxBound })}>{value}</div>
    )}
  </label>
);
