import React from 'react';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import Settings from '@travauxlib/shared/src/components/DesignSystem/assets/Settings.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';

import { Answer, Estimation } from 'features/Estimateur/types';

import { PrestationsList } from './PrestationsList';

type Props = {
  estimation: Estimation;
  submitAnswer: (answer: Answer, index?: number) => Promise<unknown>;
  deleteAnswer: (answerIndex: number) => Promise<unknown>;
};

export function Prestations(props: Props): JSX.Element {
  const { estimation, submitAnswer, deleteAnswer } = props;
  return (
    <>
      <div className="!px-md !pt-md !pb-xl">
        <div className="!mb-lg">
          <div className="!uppercase !text-gray-600">Projet</div>
          <h3 className=" !mb-0 !mt-0">
            {estimation.firstName && (
              <>
                {estimation.firstName} {estimation.lastName}
              </>
            )}
          </h3>
        </div>
        <ButtonLink className="mb-md" to="../add" leftIcon={<PlusSymbol />} size="sm" fullwidth>
          Ajouter une prestation
        </ButtonLink>
        <ButtonLink to="../context" leftIcon={<Settings />} size="sm" fullwidth>
          Configurer les pièces
        </ButtonLink>
      </div>
      <div className="!overflow-auto !px-md">
        {(estimation.answers || []).length > 0 && (
          <PrestationsList
            answers={estimation.answers || []}
            submitAnswer={submitAnswer}
            deleteAnswer={deleteAnswer}
          />
        )}
      </div>
    </>
  );
}
