import React from 'react';

import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { ThresholdInfoAccordion } from './ThresholdInfoAccordion';
import { ThresholdInfoCard } from './ThresholdInfoCard';

type Props = {
  title: string;
  content: string;
  onClick: () => void;
};

export const ThresholdInfo: React.FC<Props> = ({ title, content, onClick }) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  return isTabletOrMobile ? (
    <ThresholdInfoAccordion title={title} content={content} onClick={onClick} />
  ) : (
    <ThresholdInfoCard title={title} content={content} onClick={onClick} />
  );
};
