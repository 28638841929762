import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  EspaceClientSubFeatures,
  TrackingEvents,
  EspaceClientEventProperties,
  AppFeatures,
} from '@travauxlib/shared/src/utils/tracking';

class EspaceClientTrackingClass extends Tracker<AppFeatures, EspaceClientSubFeatures> {
  onEspaceClientLoggedIn(): void {
    this.sendTracking({ event: TrackingEvents.LoggedIn });
  }

  onEspaceClientLoggedOut(): void {
    this.sendTracking({ event: TrackingEvents.LoggedOut });
  }

  onEspaceClientActionClicked(properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]: string;
    [EspaceClientEventProperties.ActionType]: string;
    [EspaceClientEventProperties.ActionTitle]: string;
  }): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Action,
      event: TrackingEvents.Clicked,
      properties,
    });
  }

  onEspaceClientReferralCTAClicked(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.ReferralCTA,
      event: TrackingEvents.Clicked,
    });
  }

  onEspaceClientEstimateProjectCTAClicked(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.EstimateProjectCTA,
      event: TrackingEvents.Clicked,
    });
  }

  onEspaceClientDocumentsExplanationViewed(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.DocumentsExplanation,
      event: TrackingEvents.Viewed,
    });
  }

  onEspaceClientProjectNameEdited(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.ProjectName,
      event: TrackingEvents.Edited,
    });
  }

  onEspaceClientReferralPageViewed(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.ReferralPage,
      event: TrackingEvents.Viewed,
    });
  }

  onEspaceClientEstimateProjectPageViewed(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.EstimateProjectPage,
      event: TrackingEvents.Viewed,
    });
  }

  onEspaceClientHomeViewed(properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]: string;
  }): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Home,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onEspaceClientPaymentViewed(properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]: string;
    [EspaceClientEventProperties.Empty]: boolean;
  }): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Payment,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onEspaceClientPaymentEmptyViewed(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Payment,
      event: TrackingEvents.Viewed,
      properties: { [EspaceClientEventProperties.Empty]: true },
    });
  }

  onEspaceClientChantierViewed(properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]: string;
    [EspaceClientEventProperties.ProjectPhase]?: string;
    [EspaceClientEventProperties.Empty]: boolean;
  }): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Chantiers,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onEspaceClientChantierEmptyViewed(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Chantiers,
      event: TrackingEvents.Viewed,
      properties: { [EspaceClientEventProperties.Empty]: true },
    });
  }

  onEspaceClientProjectStepViewed(properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]: string;
  }): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.ProjectStep,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onEspaceClientAskForHelpClicked(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.AskForHelp,
      event: TrackingEvents.Clicked,
    });
  }

  onEspaceClientGuidesViewed(): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Guides,
      event: TrackingEvents.Viewed,
    });
  }

  onEspaceClientInvoicesAndBillsViewed(properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]: string;
  }): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.InvoicesAndBills,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onEspaceClientDocumentsViewed(properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]: string;
  }): void {
    this.sendTracking({
      subFeature: EspaceClientSubFeatures.Documents,
      event: TrackingEvents.Viewed,
      properties,
    });
  }
}

export const EspaceClientTracking = new EspaceClientTrackingClass(AppFeatures.EspaceClient);
