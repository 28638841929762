import { TypeSuivi } from '@travauxlib/shared/src/types';

import { Devis } from 'types';

export const computePrixWithPrestation =
  (devis: Devis, typeSuivi: TypeSuivi) =>
  (prix: number): number => {
    switch (typeSuivi) {
      case TypeSuivi.TravauxPlanner:
        return devis.prixTravauxPlannerTTC + prix;
      case TypeSuivi.TravauxPlannerWithService:
        return devis.prixTravauxPlannerTTC + devis.prixServiceHemeaTTC + prix;
      case TypeSuivi.ServiceHemea:
        return devis.prixServiceHemeaTTC + prix;
      default:
        return prix;
    }
  };
