import { ClientAccount } from '@travauxlib/shared/src/types';

import { Lead } from '../types';

function normalizePhone(phoneNumber?: string): string | undefined {
  // we only handle french phone numbers at this point, so add +33
  if (!phoneNumber) {
    return;
  }
  return phoneNumber.startsWith('+') || phoneNumber.startsWith('33')
    ? phoneNumber
    : '+33'.concat(phoneNumber);
}

export function openCalendly(
  accountOrLead: ClientAccount | Lead,
  calendlyLink: string,
  calendlySource: string,
): void {
  const { firstName, lastName, email, phoneNumber } = accountOrLead;

  try {
    Calendly.initPopupWidget({
      url: calendlyLink,
      prefill: {
        email,
        name: `${firstName} ${lastName}`,
        customAnswers: { a1: normalizePhone(phoneNumber) },
      },
      utm: { utmSource: calendlySource },
    });
  } catch (error) {
    // if we can't display the Calendly popup, the user is redirected ton the Calendly page
    // Better not to lose him at this stage !
    if (error instanceof Error) {
      window.location.assign(calendlyLink);
    }
  }
}
