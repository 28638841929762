import React from 'react';

import classNames from 'classnames';

import { Level } from './types';

import { Link } from '../Links';

type Props = {
  actionLabel?: string;
  href?: string;
  to?: string;
  level: Level;
};

export const ActionLink: React.FC<Props> = ({ href, to, actionLabel, level }) => (
  <Link
    className={classNames('ml-xxs py-[2px] self-start', {
      '!text-neutral-0': level === 'primary',
    })}
    href={href}
    to={to}
    variant="secondary"
    inline
  >
    {actionLabel || 'Action'}
  </Link>
);
