import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

export const ONBOARDING_STORAGE_KEY = 'STORE_ONBOARDING';
export const DEFAULT_ROW_NUMBER = 6;
const HEADER_HEIGHT = 64;
export const contentHeight = `calc(100vh - ${HEADER_HEIGHT}px)`;
export const scrollContainerId = 'scrollContainerId';

export const onboardingAccountPath = 'account';
export const onboardingProjectSavedPath = 'project-saved';

export const baseImgixOnboardingUrl = `${baseImgixUrl}/static/onboarding-client`;
