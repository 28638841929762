import React from 'react';

import { SimpleTotal } from './SimpleTotal';

type Props = {
  amount: number;
  className?: string;
};

export const ResteAPayer: React.FC<Props> = ({ amount, className }) => (
  <SimpleTotal
    className={className}
    label="Reste à payer"
    total={amount}
    data-testid="reste-a-payer"
  />
);
