import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  title: string;
  totalHT?: number;
  totalTTC: number;
  totalTTCLabel?: string;
  className?: string;
};

const Total: React.FC<{ title: string; amount: number }> = ({ title, amount }) => (
  <div className="flex justify-between">
    {title}
    <EURCurrency className="font-bold" amount={amount} />
  </div>
);

export const TotalSummary: React.FC<Props> = ({
  title,
  totalHT,
  totalTTC,
  totalTTCLabel,
  className,
}: Props) => (
  <div className={className}>
    <div className="font-bold">{title}</div>
    {!!totalHT && <Total title="Total HT" amount={totalHT} />}
    <Total title={totalTTCLabel || 'Total TTC'} amount={totalTTC} />
  </div>
);
