import React from 'react';

import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useDevisPro } from '../api/useDevisPro';

type Props = {
  replacedSignedDevisToken: string;
};

export const WarningForReplacingSignature: React.FC<Props> = ({ replacedSignedDevisToken }) => {
  const { devis } = useDevisPro(replacedSignedDevisToken);

  return devis ? (
    <>
      La signature du présent devis entraînera l'annulation de l'intégralité du devis N°
      {devis.numero} (daté du {formatFrenchDate(devis.sentAt)} et signé par {devis.customerName} en
      date du {formatFrenchDate(devis.proContext?.signature?.dateSignature)}). Toutes les clauses
      contractuelles associées seront également annulées.
    </>
  ) : null;
};
