import { sendEventToGTM } from '@travauxlib/shared/src/api/tracking';

import { JourneyEvents, StepConfig } from '../types';

export const sendLegacyAnswerEventToGTM = (currentStep: StepConfig | 'account'): void => {
  const isAccountStep = currentStep === 'account';

  sendEventToGTM(JourneyEvents.answerEvent, {
    category: `answer_${isAccountStep ? 'account' : currentStep.id}`,
  });
};
