import { StadeAchat, TypeBien } from '@travauxlib/shared/src/types';

import {
  ComputedStepConfigs,
  MultiSelectStepConfig,
  OnboardingEventProperty,
  ProjectQuestion,
  QuestionType,
  SelectStepConfig,
  VariableSurfaceStepConfig,
} from 'features/OnboardingSolide/types';

import { surfaceStep } from './commonSteps';

import { baseImgixOnboardingUrl } from '../constants';

const typeBienStep: SelectStepConfig = {
  id: ProjectQuestion.TypeBien,
  type: QuestionType.Select,
  title: 'Quel est le bien concerné ?',
  options: [
    {
      label: 'Appartement',
      value: TypeBien.Appartement,
    },
    {
      label: 'Maison',
      value: TypeBien.Maison,
    },
    {
      label: 'Immeuble',
      value: TypeBien.Immeuble,
    },
    {
      label: 'Bien professionnel',
      subLabel: 'Type de bien',
      value: TypeBien.BienProfessionel,
      subOptions: [
        { label: 'Restaurant / Bar', value: TypeBien.RestauBar },
        { label: 'Bureaux', value: TypeBien.Bureaux },
        { label: 'Hôtel', value: TypeBien.Hotel },
        { label: 'Salon de coiffure', value: TypeBien.SalonCoiffure },
        { label: 'Cabinet médical', value: TypeBien.CabinetMedical },
        { label: 'Autre', value: 'bien_pro_autre' },
      ],
    },
    {
      label: 'Autre',
      value: TypeBien.Autre,
      subLabel: 'Type de bien',
      isOtherAnswer: true,
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-4.png`,
  avis: {
    content: 'Nous avons choisi hemea pour suivre nos travaux',
    author: 'Aude',
  },
  eventKey: OnboardingEventProperty.PropertyType,
};

const stadeAchatStep: SelectStepConfig = {
  id: ProjectQuestion.StadeAchat,
  type: QuestionType.Select,
  title: 'Où en êtes-vous dans votre projet ?',
  options: [
    {
      label: "J'ai fait quelques visites",
      value: StadeAchat.BienVisite,
    },
    {
      label: "J'ai fait une offre",
      value: StadeAchat.Offre,
    },
    {
      label: "J'ai signé mon compromis",
      value: StadeAchat.CompromisSigne,
    },
    {
      label: 'Je suis propriétaire',
      value: StadeAchat.Proprietaire,
    },
    {
      label: 'Je suis juste curieux',
      value: StadeAchat.Curieux,
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-5.png`,
  avis: {
    content: "J'ai beaucoup apprécié l'accompagnement de hemea dans la consultation",
    author: 'Agathe',
  },
  eventKey: OnboardingEventProperty.PurchaseStage,
};

enum RenovationEnergetiqueOuvrage {
  IsolationInterieur = 'isolation_interieur',
  IsolationExterieur = 'isolation_exterieur',
  FenetresEtPortes = 'fenetres_et_portes',
  Chauffage = 'chauffage',
  ElectriciteEtDomotique = 'electricite_et_domotique',
  ComblesEtToitures = 'combles_et_toitures',
  PanneauxPhotovolatiques = 'panneaux_photovoltaiques',
}

const renovationEnergetiqueStep: MultiSelectStepConfig = {
  id: ProjectQuestion.TypeRenovationEnergetique,
  type: QuestionType.MultiSelect,
  title: 'Quels sont les travaux à réaliser ?',
  helperText: 'Plusieurs choix possibles',
  options: [
    {
      label: 'Isolation intérieure',
      value: RenovationEnergetiqueOuvrage.IsolationInterieur,
    },
    {
      label: 'Isolation extérieure',
      value: RenovationEnergetiqueOuvrage.IsolationExterieur,
    },
    {
      label: 'Fenêtres et portes',
      value: RenovationEnergetiqueOuvrage.FenetresEtPortes,
    },
    {
      label: 'Chauffage / Pompe à chaleur',
      value: RenovationEnergetiqueOuvrage.Chauffage,
    },
    {
      label: 'Électricité et domotique',
      value: RenovationEnergetiqueOuvrage.ElectriciteEtDomotique,
    },
    {
      label: 'Combles et toitures',
      value: RenovationEnergetiqueOuvrage.ComblesEtToitures,
    },
    {
      label: 'Panneaux photovoltaïques',
      value: RenovationEnergetiqueOuvrage.PanneauxPhotovolatiques,
    },
    {
      label: 'Autre',
      value: 'other',
      subLabel: 'Type de travaux',
      isOtherAnswer: true,
    },
  ],
  imageUrl: `${baseImgixOnboardingUrl}/onboard-2.png`,
  avis: {
    content:
      'Première fois pour nous dans l’univers des travaux, hemea nous a vraiment bien accompagné',
    author: 'Camille',
  },
  eventKey: OnboardingEventProperty.TypeOfEnergyEfficiencyWorks,
};

const renovationSurfaceStepConfig: VariableSurfaceStepConfig = {
  title: 'Quelle est la surface du bien à rénover ?',
  helperText: 'Renseignez la superficie totale des pièces concernées par les travaux',
  imageUrl: `${baseImgixOnboardingUrl}/onboard-10.png`,
  avis: {
    content: 'Très bon suivi de chantier et de choix des entreprises',
    author: 'Jean-Marc',
  },
};

export const renovationSteps: ComputedStepConfigs = values => [
  typeBienStep,
  ...(values?.typeProjet === 'renovation_energetique' ? [renovationEnergetiqueStep] : []),
  surfaceStep(renovationSurfaceStepConfig),
  stadeAchatStep,
];
