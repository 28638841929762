import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECTS_KEY } from './useProjects';

import { Badges } from '../types';

export const useBadges = (projectUuid: string): { isLoading: boolean; badges: Badges } => {
  const { isLoading, data } = useQuery<Badges>({
    queryKey: [PROJECTS_KEY, projectUuid, 'badges'],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/project/${projectUuid}/badge-counts`),
  });

  return { isLoading, badges: data || { actions: 0, companies: {}, payments: {} } };
};
