import classNames from 'classnames';

import { TabsPosition, TabsSize } from './types';

const flexClassName = 'flex items-center justify-center';

const makeTabLinkPaddingClassName = (size: TabsSize): string =>
  classNames('px-md', size === 'md' ? 'pt-[11px] pb-[13px]' : 'py-xs');

const makeTabLinkTextClassName = ({
  size,
  disabled,
}: {
  size: TabsSize;
  disabled: boolean;
}): string =>
  classNames(
    'font-medium',
    size === 'md' ? 'text-ds-b1' : 'text-ds-sm',
    disabled
      ? 'text-neutral-300 hover:text-neutral-300' // override default hover color
      : 'text-neutral-600 hover:text-neutral-800 active:text-primary',
  );

const tabItemFocusVisibleClassName =
  'relative focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-info-500 focus-visible:rounded-xxs';

export const makeTabLinkClassName = ({
  size,
  disabled,
}: {
  size: TabsSize;
  disabled: boolean;
}): string =>
  classNames(
    flexClassName,
    makeTabLinkPaddingClassName(size),
    makeTabLinkTextClassName({ size, disabled }),
    {
      'cursor-not-allowed': disabled,
    },
    'hover:no-underline',
    tabItemFocusVisibleClassName,
  );

// We use a ::after pseudo-element to make it overlap with !container bottom border
const makeActiveTabBottomBorderClassNames = ({ size }: { size: TabsSize }): string =>
  classNames(
    'relative after:content-[""] after:absolute after:bg-primary active:after:bg-primary-600 after:inset-x-0 after:bottom-0',
    {
      'after:h-xxs': size === 'md',
      'after:h-[2px]': size === 'sm',
    },
  );

export const makeTabLinkActiveClassName = ({ size }: { size: TabsSize }): string =>
  classNames(
    '!text-primary hover:!text-primary-500 active:!text-primary-600',
    makeActiveTabBottomBorderClassNames({ size }),
  );

export const makeTabItemClassName = ({
  fullWidth,
  position,
}: {
  fullWidth: boolean;
  position: TabsPosition;
}): string =>
  classNames({
    'min-w-0': fullWidth, // Make text-overflow with ellipsis work
    'flex-1': fullWidth, // Tabs have equal width and take all space available
    'first:ml-auto': position === 'center' || position === 'right',
    'last:mr-auto': position === 'center',
  });

// Should be removed when preflight will be activated in tailwind
const ulCssResetClassNames = 'list-none mb-0 pl-0';

// We use a ::before pseudo-element to make !container bottom !border overlap with active item bottom border
export const makeTabsContainerBottomBorderClassNames = ({ size }: { size: TabsSize }): string =>
  classNames(
    'relative before:content-[""] before:absolute before:bg-neutral-300 before:bottom-0 before:inset-x-0',
    {
      'before:h-[2px] ': size === 'md',
      'before:h-[1px]': size === 'sm',
    },
    'grow',
  );

export const makeTabsContainerClassName = ({ fullWidth }: { fullWidth: boolean }): string =>
  classNames(
    ulCssResetClassNames,
    /*
     * To make scroll work for positions 'center' and `right`, we have to keep justify-start and use
     * auto margin instead (see makeTabItemClassName).
     */
    'flex justify-start',
    {
      'overflow-x-auto no-scrollbar': !fullWidth, // Make !container scrollable
      // Does not work with center or right alignment
    },
  );

export const makeOverflowButtonClassNames = ({
  size,
  buttonPosition,
  buttonClassNames,
}: {
  size: TabsSize;
  buttonPosition: 'left' | 'right';
  buttonClassNames: string;
}): string =>
  classNames(
    buttonClassNames,
    size === 'md' ? 'w-xxl' : 'w-xl',
    'shrink-0 flex justify-center items-center',
    'absolute top-0 bottom-0 z-10',
    buttonPosition === 'left' ? 'left-0' : 'right-0',
  );
