import React, { useState } from 'react';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';

type Props = { title: string };

export const InvalidTokenAlertMessage: React.FC<Props> = ({ title }) => {
  const [isAlertClosed, setIsAlertClosed] = useState(false);

  return isAlertClosed ? null : (
    <AlertMessage
      className="mb-xl"
      level="warning"
      onClickClose={() => setIsAlertClosed(true)}
      title={title}
    />
  );
};
