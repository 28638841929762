import React from 'react';

import { Left } from './types';
import { displayElement } from './utils';

type Props = {
  left: Left;
  value?: string | boolean;
  disabled?: boolean;
};

export const LeftPart: React.FC<Props> = ({ left, value, disabled }) => (
  <div>{displayElement({ element: left, value, disabled })}</div>
);
