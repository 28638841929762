import jsCookies from 'js-cookie';

import { ConsentValue } from './constants';

import { ConsentPayload, GrantedConsents } from '../../types';
import { Cookie } from '../cookies/constants';

export const parseConsentPayload = (consentPayload: ConsentPayload): GrantedConsents => ({
  isAnalyticsConsentGranted: consentPayload.analytics_storage === ConsentValue.Granted,
  isAdConsentGranted: consentPayload.ad_storage === ConsentValue.Granted,
  isPersonalizationConsentGranted: consentPayload.personalization_storage === ConsentValue.Granted,
});

export const getDefaultConsentsFromCookie = (): GrantedConsents => {
  const cookie = jsCookies.get(Cookie.Consents);

  if (!cookie) {
    return {
      isAnalyticsConsentGranted: false,
      isAdConsentGranted: false,
      isPersonalizationConsentGranted: false,
    };
  }

  const parsedConsentCookie: ConsentPayload = JSON.parse(cookie);

  return parseConsentPayload(parsedConsentCookie);
};
