import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { FormControlListProps } from '../FormControl/commons';

import { CheckboxList as RawCheckboxList, Props as CheckboxListProps } from '.';

type Props = {
  name: string;
} & Omit<CheckboxListProps, 'value' | 'onChange'> &
  UseFieldConfig<string[]>;

const CheckboxListWrapper: React.FC<
  FieldRenderProps<string[]> & Omit<FormControlListProps<string[], string>, 'onChange' | 'value'>
> = ({ input: { value, onChange }, ...rest }) => (
  <RawCheckboxList value={value} onChange={onChange} {...rest} />
);

export function CheckboxListField(props: Props): JSX.Element {
  return <Field component={CheckboxListWrapper} {...props} />;
}
