import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Estimation } from 'features/Estimateur/types';

type SubmitLeadPayload = {
  estimation: Estimation;
  affiliateToken: string;
  description: string;
  emailCommercial: string;
};

const handleSubmitLead = async (payload: SubmitLeadPayload): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/partners/estimateur/leads`;
  await request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useSubmitLead = (): typeof handleSubmitLead => {
  const { mutateAsync: submitLead } = useMutation({
    mutationFn: handleSubmitLead,
    onSuccess: () => {
      toast.success('Merci, le projet a bien été envoyé à hemea');
    },
    onError: () => {
      toast.success(
        "Il y a eu un problème avec l'envoi de ce projet. Le prénom, nom et téléphone du client doivent être renseignés sur la page mes projets",
      );
    },
  });

  return submitLead;
};
