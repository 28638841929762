import classNames from 'classnames';

import { ModalSize } from './types';

export const makeFadeAnimationClassNames = (startingOpacity?: string): string =>
  classNames('transition-[opacity] duration-300 opacity-0', startingOpacity);

export const modalConfigBySize: {
  [K in 'tabletAndMobile' | 'desktop']: {
    makeClassNames: (isMounted: boolean) => string;
    transitionDuration: number;
    modalOuterMargin: number;
  };
} = {
  tabletAndMobile: {
    makeClassNames: isMounted =>
      classNames('transition-[bottom] duration-500 -bottom-full', {
        '!bottom-[0%]': isMounted,
      }),
    transitionDuration: 500,
    modalOuterMargin: 48,
  },
  desktop: {
    makeClassNames: isMounted =>
      classNames(
        'top-1/2 -translate-y-1/2',
        makeFadeAnimationClassNames(isMounted ? 'opacity-100' : undefined),
      ),
    transitionDuration: 300,
    modalOuterMargin: 96,
  },
};

export const modalWidthBySize: { [K in ModalSize]: string } = {
  md: 'sm-desktop:col-start-4 sm-desktop:col-end-10',
  lg: 'sm-desktop:col-start-3 sm-desktop:col-end-11',
};
