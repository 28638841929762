import React from 'react';

export enum EtapeProjet {
  Etude = 'etude_project',
  Archi = 'etude_archi',
  Pro = 'pro',
  Devis = 'devis',
  AvantChantier = 'pre_chantier',
  Chantier = 'chantier',
  ApresChantier = 'post_chantier',
}

export type EtapeConfig = {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  items: string[];
};
