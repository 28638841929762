import React from 'react';

import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ArchitectureOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ArchitectureOutline.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/Construction.svg?react';
import SquareFoot from '@travauxlib/shared/src/components/DesignSystem/assets/SquareFoot.svg?react';
import { ChipPicto } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/ChipPicto';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useBadges } from '../api/useBadges';
import { useCompanyChip } from '../api/useCompanyChips';

type Props = {
  entityType: string;
  buildNavigationUrl: (entityUuid: string) => string;
  entityUrlGetter: string;
  emptyState?: JSX.Element;
  hideForPaths?: string[];
};

export const ChipsNavigation: React.FC<Props> = ({
  entityType,
  buildNavigationUrl,
  entityUrlGetter,
  emptyState,
  hideForPaths,
}) => {
  const { uuid, ...rest } = useParams();
  const { badges, isLoading: isLoadingBadges } = useBadges(uuid!);
  const selectedUuidFromUrl = rest[entityUrlGetter];
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, data } = useCompanyChip(uuid!, entityType);

  React.useEffect(() => {
    if (!isLoading && data.length > 0 && !selectedUuidFromUrl) {
      navigate(
        { pathname: buildNavigationUrl(data[0].entityUuid), search: searchParams.toString() },
        { replace: true },
      );
    }
  }, [data, isLoading, selectedUuidFromUrl]);

  if (isLoading || isLoadingBadges) {
    return <LoaderWrapper />;
  }

  if (hideForPaths && hideForPaths.some(path => location.pathname.includes(path))) {
    return <Outlet />;
  }

  if (data.length === 0) {
    return emptyState ?? null;
  }

  const chipBadges =
    entityType === 'paiements'
      ? badges.payments
      : entityType === 'devis-factures'
        ? badges.companies
        : {};

  return (
    <>
      {data.length > 1 && (
        <div className="pt-md pl-md pb-xs sm-desktop:pl-0 w-min-content overflow-auto flex [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          {data.map(chipEntity => {
            const isSelectedEntity = chipEntity.entityUuid === selectedUuidFromUrl;
            const sliceLimit = isSelectedEntity ? 24 : 14;

            return (
              <ChipPicto
                key={chipEntity.entityUuid}
                className="ml-xs first:ml-0 shrink-0 after:border-0"
                leftIcon={
                  chipEntity.isBET ? (
                    <SquareFoot />
                  ) : chipEntity.isArchitecte ? (
                    <ArchitectureOutline />
                  ) : (
                    <Construction />
                  )
                }
                label={
                  chipEntity.title.length > sliceLimit
                    ? `${chipEntity.title.slice(0, sliceLimit)}...`
                    : chipEntity.title
                }
                helperText={
                  chipEntity.isBET
                    ? "Bureau d'études"
                    : chipEntity.isArchitecte
                      ? 'Architecte'
                      : 'Entreprise'
                }
                isActive={isSelectedEntity}
                onMounted={e => {
                  if (isSelectedEntity && e) {
                    e.scrollIntoView({ inline: 'center' });
                  }
                }}
                onClick={e => {
                  navigate(buildNavigationUrl(chipEntity.entityUuid));
                  if (e) {
                    e.scrollIntoView({ inline: 'center' });
                  }
                }}
                size="md"
                fullColor
                badgeCount={chipBadges[chipEntity.entityUuid]}
              />
            );
          })}
        </div>
      )}
      <Outlet />
    </>
  );
};
