import React from 'react';

import _delay from 'lodash/delay';
import _throttle from 'lodash/throttle';

export const useScrollStatus: (ref: React.RefObject<HTMLElement>) => {
  isScrollLeft: boolean;
  isScrollTop: boolean;
  isScrollRight: boolean;
  isScrollBottom: boolean;
  hasHorizontalScroll: boolean;
  hasVerticalScroll: boolean;
} = (ref: React.RefObject<HTMLElement>) => {
  const [isScrollTop, setIsScrollTop] = React.useState(false);
  const [isScrollBottom, setIsScrollBottom] = React.useState(false);
  const [isScrollLeft, setIsScrollLeft] = React.useState(false);
  const [isScrollRight, setIsScrollRight] = React.useState(false);
  const [hasHorizontalScroll, setHasHorizontalScroll] = React.useState(false);
  const [hasVerticalScroll, setHasVerticalScroll] = React.useState(false);

  const updateScrollStatus = (): void => {
    if (ref && ref?.current) {
      const { scrollHeight, scrollTop, clientHeight, scrollWidth, scrollLeft, clientWidth } =
        ref.current!;
      setIsScrollTop(Math.floor(scrollTop) === 0);
      setIsScrollBottom(scrollHeight <= Math.ceil(scrollTop + clientHeight));
      setIsScrollLeft(Math.floor(scrollLeft) === 0);
      setIsScrollRight(scrollWidth <= Math.ceil(scrollLeft + clientWidth));
      setHasHorizontalScroll(scrollWidth > clientWidth);
      setHasVerticalScroll(scrollHeight > clientHeight);
    }
  };

  const throttledUpdateScrollStatus = _throttle(updateScrollStatus, 100);
  const delayedUpdateScrollStatus = (): number => _delay(updateScrollStatus, 50);

  React.useEffect(() => {
    updateScrollStatus();
    window.addEventListener('resize', throttledUpdateScrollStatus);
    ref.current?.addEventListener('scroll', throttledUpdateScrollStatus);
    ref.current?.addEventListener('click', delayedUpdateScrollStatus);
    return () => {
      window.removeEventListener('resize', throttledUpdateScrollStatus);
      ref.current?.removeEventListener('scroll', throttledUpdateScrollStatus);
      ref.current?.removeEventListener('click', () => delayedUpdateScrollStatus);
    };
  }, []);

  return {
    isScrollTop,
    isScrollBottom,
    isScrollLeft,
    isScrollRight,
    hasHorizontalScroll,
    hasVerticalScroll,
  };
};
