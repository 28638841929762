export const getInitials = (fullName: string): string => {
  const splitted = fullName
    .toLowerCase()
    .replace(/[^\sa-z]/g, '')
    .split(/\s+/)
    .filter(el => el !== '');
  if (splitted.length === 1) {
    return splitted[0].slice(0, 2).toUpperCase();
  }
  return `${splitted[0][0]}${splitted[1][0]}`.toUpperCase();
};
