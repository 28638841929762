import React from 'react';

import classNames from 'classnames';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MontantTVA } from '@travauxlib/shared/src/types';

type Props = {
  montantsTVA: MontantTVA[];
  darkTheme?: boolean;
};

export const MontantsTVA: React.FC<Props> = ({ montantsTVA, darkTheme = false }) => (
  <table
    className={classNames('table', darkTheme ? 'bg-neutral-800' : 'table-striped')}
    data-testid="montants-tva"
  >
    <thead>
      <tr>
        <th>Base</th>
        <th className="text-center">Taux</th>
        <th className="text-right">Montant</th>
      </tr>
    </thead>
    <tbody className="border-t border-white">
      {montantsTVA
        .sort((a, b) => a.taux - b.taux)
        .map((montant, index) => (
          <tr key={index}>
            <td>
              <EURCurrency amount={montant.base} />
            </td>
            <td className="text-center">{montant.taux.toLocaleString('fr-FR')}%</td>
            <td className="text-right">
              <EURCurrency amount={montant.montant} />
            </td>
          </tr>
        ))}
    </tbody>
  </table>
);
