import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { DevisDisplay } from '@travauxlib/shared/src/features/DevisDisplay';
import { switchToLotsByLocations } from '@travauxlib/shared/src/utils/switchToLotsByLocations';

import { DevisPrestationsSummary } from 'components/DevisFactures/DevisPrestationsSummary';
import { Introduction } from 'components/DevisFactures/Introduction';
import { DocumentHeader } from 'components/DocumentHeader';
import { RootPdf } from 'components/RootPdf';
import { CurrentDevisProvider } from 'features/DevisPro/components/CurrentDevisContext';
import { DevisDates } from 'features/DevisPro/components/DevisDates';
import { GarbageCenters } from 'features/DevisPro/components/GarbageCenters';
import { MediateurConsommation } from 'features/DevisPro/components/MediateurConsommation/MediateurConsommation';
import { getDevisTitle } from 'features/DevisPro/utils';
import { useIsAuthor } from 'hooks/useIsAuthor';
import { Devis } from 'types';

import { PrintedComments } from './components/PrintedComments';

import { getRibLabelFromUrl } from '../../components/Attachments';
import { MarketingHemea } from '../../components/MarketingHemea';
import { ModalitesHemea } from '../../components/ModalitesHemea';
import { Summary } from '../../components/Summary';
import { ModalitesDevis } from '../../components/Summary/ModalitesDevis';
import { Signature } from '../../components/Summary/Signature';

type Props = {
  devis: Devis;
  byLocations?: boolean;
};

export const DevisProPrint: React.FC<Props> = props => {
  const devis = props.byLocations
    ? {
        ...props.devis,
        lots: switchToLotsByLocations(props.devis.lots, props.devis.locations),
      }
    : props.devis;

  const {
    proContext,
    isPreChiffrage,
    token,
    defaultTypeSuivi,
    locations,
    lots,
    headerText,
    replacedSignedDevisToken,
    showFournitures,
    title,
    adminContext,
  } = devis;

  useEffect(() => {
    window.status = 'ready';
  }, []);

  const isAuthor = useIsAuthor();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const hidePrices = urlSearchParams.get('hidePrices') === 'true';
  const isForYousign = urlSearchParams.get('isForYousign') === 'true';
  const isHemea = proContext?.deal.isHemea || isPreChiffrage;

  const allAttachments: Array<{ fileUrl: string; label: string }> =
    proContext?.company.ribUrl && !isHemea
      ? [
          ...proContext?.attachments,
          {
            label: getRibLabelFromUrl(proContext?.company.ribUrl),
            fileUrl: proContext?.company.ribUrl,
          },
        ]
      : proContext?.attachments || [];

  const hasAttachments = allAttachments.length > 0;
  const customizationData = isHemea ? undefined : proContext?.customization;
  const shouldShowHemeaModalites = isHemea && !proContext?.company.isArchitecte;

  return (
    <CurrentDevisProvider devis={devis}>
      <RootPdf>
        <>
          <DocumentHeader
            proContext={proContext}
            adminContext={adminContext}
            title={getDevisTitle(devis, !!proContext?.company.isArchitecte)}
            dateBlock={<DevisDates devis={devis} />}
          />
          <Introduction
            title={title}
            introductionLetter={proContext?.introductionLetter}
            showMarketingHemea={isHemea}
            isArchitecte={!!proContext?.company.isArchitecte}
            customization={customizationData}
            replacedSignedDevisToken={replacedSignedDevisToken}
            isPreChiffrage={isPreChiffrage}
          />
        </>
        {!hidePrices && (
          <div className="break-inside-avoid pt-lg">
            <Summary
              devis={devis}
              devisSummary={
                <DevisPrestationsSummary
                  className={classNames('w-full', { 'sm-desktop:w-1/2': proContext?.isRGE })}
                  devis={devis}
                  customization={customizationData}
                  isHemea={isHemea}
                />
              }
            />
          </div>
        )}
        <div
          className={classNames({
            'mb-xl': isHemea || hasAttachments,
          })}
        >
          <DevisDisplay
            isAuthor={isAuthor}
            hidePrices={hidePrices}
            lots={lots}
            headerText={headerText}
            proCompanyName={proContext?.company.name}
            customerName={proContext?.deal.customerName || adminContext?.customerName || 'Client'}
            locations={locations}
            contractors={proContext?.contractors}
            showFournitures={showFournitures}
            customization={customizationData}
            isRGE={proContext?.isRGE}
            token={token}
          />
        </div>
        <>
          {hasAttachments && (
            <div data-testid="pj" className="mb-lg">
              <h1 className="mb-xs">Pièces jointes</h1>
              {allAttachments.map(({ fileUrl, label }) => (
                <div key={fileUrl}>
                  <a href={fileUrl}>{label}</a>
                </div>
              ))}
            </div>
          )}
          {proContext?.proMediateurConsommation && (
            <MediateurConsommation
              proMediateurConsommation={proContext.proMediateurConsommation}
              customization={customizationData}
            />
          )}
          {shouldShowHemeaModalites && <ModalitesHemea devis={devis} isReadOnly />}
          {devis.garbageCenters.length > 0 && (
            <GarbageCenters
              garbageCenters={devis.garbageCenters}
              customization={customizationData}
            />
          )}
          {proContext && (
            <>
              {!hidePrices && (
                <ModalitesDevis
                  modalitesPaiement={proContext.modalitesPaiement}
                  modalitesProvision={proContext.modalitesProvision}
                  isHemeaDeal={proContext.deal.isHemea}
                  typeSuivi={defaultTypeSuivi}
                  customization={customizationData}
                />
              )}
              {!isPreChiffrage && (
                <Signature
                  devis={devis}
                  proContext={proContext}
                  customization={customizationData}
                  isForYousign={isForYousign}
                  isPrint
                />
              )}
            </>
          )}
          {isHemea && <MarketingHemea />}
        </>
        {proContext?.comments && Object.keys(proContext.comments).length !== 0 && (
          <PrintedComments devis={devis} proContext={proContext} />
        )}
      </RootPdf>
    </CurrentDevisProvider>
  );
};
