import React from 'react';

import classNames from 'classnames';

import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

type Props = {
  className?: string;
  url: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  label?: string;
  iconOnly?: boolean;
};

export const DownloadPDFButton: React.FC<Props> = ({
  className,
  url,
  label = 'Télécharger le PDF',
  variant = 'secondary',
  iconOnly,
}) =>
  iconOnly ? (
    <IconButtonLink
      className={classNames('shrink-0', className)}
      href={url}
      target="_blank"
      download
    >
      <Download />
    </IconButtonLink>
  ) : (
    <ButtonLink
      href={url}
      className={classNames('shrink-0', className)}
      variant={variant}
      rightIcon={<Download />}
      target="_blank"
      download
    >
      {label}
    </ButtonLink>
  );
