import _sumBy from 'lodash/sumBy';

import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { FactureSoldeTotals, getFactureSoldeTotals } from './getFactureSoldeTotals';
import { getSeparatedFacturesLists } from './getSeparatedFacturesLists';
import { getTotalFacturedMontantsTVA } from './getTotalFacturedMontantsTVA';
import { isFactured } from './isFactured';

import { Devis, DevisFacturationStatus, GenericFacture } from '../../types';

export const getDetailedDevisFacturationStatus = (
  facturesList: GenericFacture[],
  devis: Pick<Devis, 'prixTotalTTC' | 'prixTotalHT' | 'prixTotalHTAvantRemise'>,
): DevisFacturationStatus => {
  const { avoirList, facturesListWithoutClotureAndAvoir, hasCloture, isClotured, factureSolde } =
    getSeparatedFacturesLists(facturesList);

  let factureSoldeTotals: FactureSoldeTotals | undefined;
  /**
   * MONTANTS DEJA FACTURES
   *
   * Montant déjà facturés i.e soit déjà envoyé au client, soit déjà réglé (la facture dans ce cas là
   * n'est plus supprimable / modifiable)
   */

  let facturedAvoirList = avoirList.filter(f => isFactured(f));
  let facturedFacturesListWithoutClotureAndAvoir = facturesListWithoutClotureAndAvoir.filter(f =>
    isFactured(f),
  );

  // Base total deja facture HT
  const totalMontantAvoirFacturedHT = _sumBy(facturedAvoirList, 'prixTotalHT');
  const totalDejaFactureWithoutClotureHT =
    _sumBy(facturedFacturesListWithoutClotureAndAvoir, 'prixTotalHT') - totalMontantAvoirFacturedHT;
  let totalDejaFactureHT = totalDejaFactureWithoutClotureHT;

  // Base total deja facture TTC

  const totalMontantAvoirFacturedTTC = _sumBy(facturedAvoirList, 'prixTotalTTC');
  const totalDejaFactureWithoutClotureTTC =
    _sumBy(facturedFacturesListWithoutClotureAndAvoir, 'prixTotalTTC') -
    totalMontantAvoirFacturedTTC;
  let totalDejaFactureTTC = totalDejaFactureWithoutClotureTTC;

  /**
   * MONTANT PRENANT EN COMPTE LES FACTURES NON FACTUREES
   *
   * Permet de créer des factures en connaissant le montant déjà créé, n'exclus donc pas les brouillons
   */

  const totalMontantAvoirHT = _sumBy(avoirList, 'prixTotalHT');
  const totalMontantAvoirTTC = _sumBy(avoirList, 'prixTotalTTC');

  let totalDejaCreeWithoutClotureHT =
    _sumBy(facturesListWithoutClotureAndAvoir, 'prixTotalHT') - totalMontantAvoirHT;

  let totalDejaCreeHT = totalDejaCreeWithoutClotureHT;

  /**
   * MONTANTS DE PAIEMENT LORSQU IL Y A EU DES PAIEMENTS INTERMEDIAIRES
   *
   * Lorsqu'une facture est facturé, nous devons prendre en compte les montants déjà payés ainsi que les montants
   * restant a payé pour calculer correctement la progression de notre facturation
   */

  let totalMontantDejaPayeTTC =
    _sumBy(facturedFacturesListWithoutClotureAndAvoir, 'montantDejaPaye') -
    totalMontantAvoirFacturedTTC;

  let totalMontantEnAttentePaiementTTC = _sumBy(
    facturedFacturesListWithoutClotureAndAvoir.filter(
      facture => facture.status === 'awaiting_payment',
    ),
    'montantRestantAPayer',
  );

  /**
   * COMPORTEMENTS SPECIFIQUES FACTURE DE SOLDE
   *
   * Si jamais il y a une facture de solde, il y a des ajustement à effectuer sur
   * les différents montant pour que l'ensemble soit cohérent
   */

  let totalFactureSoldeTTC = 0;

  if (factureSolde) {
    // Quand on fait une facture de situation, les factures sont forcément relatives les unes
    // aux autres et le montant de la facture en elle même est donc correct
    let { totalTTC = 0, totalHT = 0 } = {
      totalHT: factureSolde.prixTotalHT,
      totalTTC: factureSolde.prixTotalTTC,
    };

    totalDejaCreeHT += totalHT;

    if (!factureSolde.isSituation) {
      factureSoldeTotals = getFactureSoldeTotals(
        factureSolde,
        totalDejaFactureWithoutClotureHT,
        totalDejaFactureWithoutClotureTTC,
        getTotalFacturedMontantsTVA([
          ...facturedAvoirList,
          ...facturedFacturesListWithoutClotureAndAvoir,
        ]),
        devis,
      );
      totalHT = factureSoldeTotals?.totalHT ?? 0;
      totalTTC = factureSoldeTotals?.totalTTC ?? 0;
    }

    if (isClotured) {
      totalFactureSoldeTTC = totalTTC;
      totalDejaFactureHT += totalHT;
      totalDejaFactureTTC += totalTTC;

      if (factureSolde.status === 'paid') {
        totalMontantDejaPayeTTC += totalTTC;
      } else if (factureSolde.status === 'awaiting_payment') {
        totalMontantEnAttentePaiementTTC += totalTTC;
      }
    }
  }

  /**
   * CALCUL DES TOTAUX
   */

  const referenceAmountTTC = isClotured
    ? totalMontantDejaPayeTTC + totalMontantEnAttentePaiementTTC
    : devis.prixTotalTTC;

  const percentageDejaPayeTTC = roundToTwoDecimals(
    (totalMontantDejaPayeTTC / referenceAmountTTC) * 100,
  );

  const percentageEnAttentePaiementTTC = roundToTwoDecimals(
    (totalMontantEnAttentePaiementTTC / referenceAmountTTC) * 100,
  );

  const totalMontantRestantAFacturerTTC = referenceAmountTTC - totalDejaFactureTTC;

  const percentageRestantAFacturerTTC = roundToTwoDecimals(
    100 - percentageDejaPayeTTC - percentageEnAttentePaiementTTC,
  );

  return {
    percentageDejaPayeTTC,
    percentageEnAttentePaiementTTC,
    percentageRestantAFacturerTTC,
    totalDejaCreeHT,
    totalMontantAvoirHT,
    totalMontantAvoirTTC,
    totalDejaFactureHT,
    totalDejaFactureTTC,
    totalMontantDejaPayeTTC,
    totalMontantEnAttentePaiementTTC,
    totalMontantRestantAFacturerTTC,
    referenceAmountTTC,
    totalFactureSoldeTTC,
    isClotured,
    hasCloture,
    factureSoldeTotals,
  };
};
