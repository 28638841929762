import { createSuiviChantierHistoryList } from './createSuiviChantierHistoryList';

import {
  SuiviChantierCycleHistory,
  SuiviChantierEtapeHistory,
  SuiviChantierIntervenant,
} from '../types';

export const doesSuiviChantierEtapeExistsFactory =
  (actor: string, intervenant: SuiviChantierIntervenant, APP_CONFIG: { apiURL: string }) =>
  (
    chantierSlug: string,
    cycleUuid?: string,
  ): {
    isLoading: boolean;
    suiviChantierEtapeExists: boolean;
    allCyclesComplete: boolean;
    isCycleCanceled: boolean;
    existingSuivi?: SuiviChantierEtapeHistory;
    existingCycle?: SuiviChantierCycleHistory;
  } => {
    const { suiviChantierHistoryList, isLoading } = createSuiviChantierHistoryList(
      actor,
      APP_CONFIG,
    )(chantierSlug);

    const uncompleteCycle = suiviChantierHistoryList?.find(
      (s: SuiviChantierCycleHistory) => !s.etapes['client'] && s.status !== 'canceled',
    );

    const isCycleCanceled = suiviChantierHistoryList?.find(
      (s: SuiviChantierCycleHistory) => s.uuid === cycleUuid && s.status === 'canceled',
    );

    const existingSuiviEtape = suiviChantierHistoryList?.find(
      (s: SuiviChantierCycleHistory) => s.uuid === cycleUuid,
    )?.etapes[intervenant];

    return {
      suiviChantierEtapeExists: !!existingSuiviEtape,
      existingSuivi: existingSuiviEtape,
      isCycleCanceled: !!isCycleCanceled,
      existingCycle: uncompleteCycle,
      allCyclesComplete:
        suiviChantierHistoryList?.every((s: SuiviChantierCycleHistory) => !!s.etapes['client']) ??
        false,
      isLoading,
    };
  };
