import React from 'react';

import classNames from 'classnames';

import { GenericLink, GenericLinkProps } from './commons';

import { ActionDisabledWithTooltip } from '../Buttons/ActionDisabledWithTooltip';
import {
  CustomIconButtonProps,
  IconButtonContent,
  makeIconButtonClassnames,
} from '../Buttons/IconButton';

export const IconButtonLink: React.FC<GenericLinkProps & CustomIconButtonProps> = ({
  size = 'md',
  variant = 'white',
  className,
  children,
  disabled = false,
  ...rest
}) => {
  const linkClassName = classNames(
    className,
    'inline-flex items-center justify-center',
    makeIconButtonClassnames({ size, variant, disabled }),
  );

  return (
    <ActionDisabledWithTooltip
      disabled={disabled}
      disabledMessage={rest.disabledMessageTooltip}
      position={rest.positionTooltip}
    >
      <GenericLink classNames={linkClassName} disabled={disabled} {...rest}>
        <IconButtonContent size={size}>{children}</IconButtonContent>
      </GenericLink>
    </ActionDisabledWithTooltip>
  );
};
