import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Bank from '@travauxlib/shared/src/components/DesignSystem/assets/Bank.svg?react';
import PaymentsOutline from '@travauxlib/shared/src/components/DesignSystem/assets/PaymentsOutline.svg?react';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { Chantier } from 'features/EspaceClient3/types';

import { InsufficientFunds } from './InsufficientFunds';
import { LiberationFondsModal } from './LiberationFondsModal';

type Props = {
  chantier: Chantier;
};

export const Events: React.FC<Props> = ({ chantier }) => {
  const navigate = useNavigate();
  const [openedModal, setOpenedModal] = useState<
    'insufficientFunds' | 'liberationFonds' | undefined
  >(undefined);
  const handleClose = (): void => setOpenedModal(undefined);

  // TODO Plug me back when flux financier will be bg
  // const incomingUnprovisionedADPs = chantier.incomingUnprovisionedADPs;
  const suiviToValidate = chantier.suiviToValidate;
  const adpToValidate = chantier.appelDePaiementToValidate;
  const isSoldeEnough = (montant: number): boolean => chantier.soldeCompteSequestre >= montant;

  return (
    <div className="flex flex-col gap-xs mx-md sm-desktop:mx-0">
      {/*{incomingUnprovisionedADPs.map(adp => (*/}
      {/*  <EventCard*/}
      {/*    icon={<Bank />}*/}
      {/*    title="Provisions attendues"*/}
      {/*    dueDate={adp.dateEffet}*/}
      {/*    variant="info"*/}
      {/*  >*/}
      {/*    Vous devez provisionner{' '}*/}
      {/*    <EURCurrency className="text-neutral-800 font-medium" amount={adp.montant} /> sur votre*/}
      {/*    compte séquestre*/}
      {/*  </EventCard>*/}
      {/*))}*/}
      {chantier.montantProvisionsAttendues > 0 && (
        <EventCard icon={<Bank />} title="Provisions attendues à ce jour" variant="info">
          Vous devez provisionner{' '}
          <EURCurrency
            className="text-neutral-800 font-medium"
            amount={chantier.montantProvisionsAttendues}
          />{' '}
          sur votre compte séquestre
        </EventCard>
      )}
      {adpToValidate && (
        <EventCard
          icon={<PaymentsOutline />}
          title="Libération de fonds"
          dueDate={adpToValidate.createdAt}
          variant="warning"
          action={{
            label: 'Débloquer les fonds',
            onClick: () =>
              isSoldeEnough(adpToValidate.montant)
                ? setOpenedModal('liberationFonds')
                : setOpenedModal('insufficientFunds'),
          }}
        >
          Vous avez{' '}
          <EURCurrency className="text-neutral-800 font-medium" amount={adpToValidate.montant} /> en
          attente de versement à l'entreprise
        </EventCard>
      )}
      {suiviToValidate && (
        <EventCard
          icon={<PaymentsOutline />}
          title="Libération de fonds"
          dueDate={suiviToValidate.createdAt}
          variant="warning"
          action={{
            label: "Valider l'état d'avancement",
            onClick: () =>
              isSoldeEnough(suiviToValidate.montantALiberer)
                ? navigate(
                    `../../chantiers/${chantier.uuid}/suivi-chantier/${suiviToValidate.cycleUUID}`,
                  )
                : setOpenedModal('insufficientFunds'),
          }}
        >
          Validez l’état d’avancement de votre chantier pour débloquer la somme de{' '}
          <EURCurrency
            className="text-neutral-800 font-medium"
            amount={suiviToValidate.montantALiberer}
          />
        </EventCard>
      )}
      <InsufficientFunds isOpen={openedModal === 'insufficientFunds'} handleClose={handleClose} />
      <LiberationFondsModal
        isOpen={openedModal === 'liberationFonds'}
        handleClose={handleClose}
        slug={chantier.slug}
        proCompanyName={chantier.proCompanyName}
        appelDePaiement={adpToValidate}
      />
    </div>
  );
};
