import React from 'react';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import Print from '@travauxlib/shared/src/components/DesignSystem/assets/Print.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { LotsSommaire } from '@travauxlib/shared/src/features/LDDToolbar/components/LotsSommaire';
import { DevisLocation, GooglemapsAddress } from '@travauxlib/shared/src/types';

import { useEstimateurTracking } from 'features/Estimateur/hooks/useEstimateurTracking';
import { useShouldShowRecallButton } from 'features/Estimateur/hooks/useShouldShowRecallButton';
import { EstimationResult } from 'features/Estimateur/types';
import { EstimateurEventAction } from 'features/Estimateur/utils/constants';
import { useIsEstimateurPublic } from 'features/Estimateur/utils/paths';

type Props = {
  locations: DevisLocation[];
  estimationResult: EstimationResult;
  organizationName?: string;
  printUrl?: string;
  toggleRecallModal: () => void;
  googleMapsAddress?: GooglemapsAddress;
  isClientEstimateur: boolean;
  openLeadModal: () => void;
};

export const Summary: React.FC<Props> = ({
  estimationResult,
  organizationName,
  printUrl,
  toggleRecallModal,
  googleMapsAddress,
  isClientEstimateur,
  openLeadModal,
}) => {
  const showRecallButton = useShouldShowRecallButton(googleMapsAddress);
  const isPublic = useIsEstimateurPublic();
  const doTracking = useEstimateurTracking();

  return (
    <>
      <LotsSommaire
        lots={estimationResult.lots.map(l => ({
          ...l,
          items: [],
          prixTotalHT: l.totalHT,
        }))}
      />
      <div className="px-md">
        <div className="mt-lg mb-xs flex justify-between font-bold">
          <span className="mr-sm flex-shrink">Total HT</span>
          <EURCurrency amount={estimationResult.totalHT} />
        </div>
        <div className="mb-xs flex justify-between">
          <span className="mr-sm flex-shrink">TVA</span>
          <EURCurrency amount={estimationResult.totalTVA} />
        </div>
        <h2 className="mb-0 text-center">
          <EURCurrency amount={estimationResult.totalTTC} /> TTC
        </h2>
        <div className="mb-lg text-center !text-ds-sm">Estimation non contractuelle.</div>
        {showRecallButton && (
          <Button
            className="mb-lg"
            leftIcon={<Phone />}
            onClick={() => toggleRecallModal()}
            fullwidth
          >
            Je souhaite être rappelé
          </Button>
        )}
        {!isClientEstimateur && !isPublic && (
          <Button className="mb-lg" onClick={openLeadModal} leftIcon={<Envelope />} fullwidth>
            Envoyer le projet à hemea
          </Button>
        )}
        {printUrl && (
          <ButtonLink
            variant="secondary"
            className="mb-lg"
            target="_blank"
            href={printUrl}
            onClick={() => doTracking(EstimateurEventAction.PdfDownloaded)}
            leftIcon={<Print />}
            fullwidth
          >
            Télécharger en pdf
          </ButtonLink>
        )}
        {organizationName === 'hemea' && (
          <ButtonLink
            variant="secondary"
            target="_blank"
            href={`${printUrl}?isDevis=true`}
            leftIcon={<Print />}
            fullwidth
          >
            Devis
          </ButtonLink>
        )}
      </div>
    </>
  );
};
