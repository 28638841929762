import React from 'react';

import dayjs from 'dayjs';

import { DureeValidite as DureeValiditeType } from '@travauxlib/shared/src/types';
import { frenchDateFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  date: string;
  dureeValidite: DureeValiditeType;
};

const getTimeLabel = ({ dureeValidite: { unit, quantity }, date }: Props): string => {
  if (unit === 'mois') {
    return dayjs(date).add(quantity, 'month').format(frenchDateFormat);
  }
  if (unit === 'semaine') {
    return dayjs(date).add(quantity, 'week').format(frenchDateFormat);
  }
  return dayjs(date).add(quantity, 'day').format(frenchDateFormat);
};

export const DureeValidite: React.FC<Props> = ({ date, dureeValidite }) => (
  <div>Valable jusqu'au {getTimeLabel({ date, dureeValidite })}</div>
);
