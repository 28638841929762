import React from 'react';

import { UnitValue } from '@travauxlib/shared/src/types';
import { units } from '@travauxlib/shared/src/utils/units';
import { getPluralizedUnitLabel } from '@travauxlib/shared/src/utils/wording';

type Props = {
  quantite: number;
  unite: UnitValue;
};

export const QuantityLabel: React.FC<Props> = ({ quantite, unite }) => {
  const unit = units[unite];
  return (
    <>
      {quantite.toLocaleString('fr')} {getPluralizedUnitLabel(unit.label, quantite)}
    </>
  );
};
