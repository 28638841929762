import { useEffect } from 'react';

import jsCookie from 'js-cookie';

import { Cookie } from '@travauxlib/shared/src/utils/cookies/constants';

import { sendEventToTrackingModules } from 'utils/tracking';

import { JourneyEvents } from '../types';

const readConversionPageFromCookies = (): string => {
  const websiteLastPathCookieValue = jsCookie.get(Cookie.WebsiteLastPathCookie);

  return websiteLastPathCookieValue
    ? `${APP_CONFIG.wwwURL}${websiteLastPathCookieValue}`
    : document.referrer;
};

export const useProjectStartedLegacyTracking = (isStartingOnboarding: boolean): void => {
  useEffect(() => {
    if (isStartingOnboarding) {
      const websiteLastPath = readConversionPageFromCookies();
      const conversionButton = new URLSearchParams(location.search).get('customer_need')!;

      sendEventToTrackingModules({
        event: JourneyEvents.projectStartedEvent,
        data: {
          category: JourneyEvents.projectStartedEvent,
          action: websiteLastPath,
          label: conversionButton,
        },
      });
    }
  }, []);
};
