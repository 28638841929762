import React from 'react';

import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { useDirectPaymentAvailableForFacture } from '@travauxlib/shared/src/hooks/useDirectPaymentAvailableForFacture';

import { Facture, ProFactureContext } from 'types';

import { useGetDirectPaymentAccount } from '../api/useGetDirectPaymentAccount';

type DirectPaymentButtonProps = {
  facture: Facture;
  proContext: ProFactureContext;
};

export const DirectPaymentButton: React.FC<DirectPaymentButtonProps> = ({
  facture,
  proContext: { company, deal },
}) => {
  const { account } = useGetDirectPaymentAccount(company);
  const { directPaymentAvailableForFacture } = useDirectPaymentAvailableForFacture({
    facture,
    company,
    isHemea: deal.isHemea,
    directPaymentAccount: account,
  });

  return directPaymentAvailableForFacture ? (
    <ButtonLink variant="primary" fullwidth to={`/factures/${facture.token}/payment`}>
      Payer ma facture par CB
    </ButtonLink>
  ) : null;
};
