import React from 'react';

import dayjs from 'dayjs';
import { Field } from 'react-final-form';

import { SelectFormattedAnswer, SelectStepConfig } from 'features/OnboardingSolide/types';

import { SelectOption } from './SelectOption';

type Props = {
  setNextStep: () => void;
} & Pick<SelectStepConfig, 'id' | 'options'>;

export const SelectQuestion: React.FC<Props> = ({ id, options: optionProps, setNextStep }) => {
  const options = typeof optionProps === 'function' ? optionProps(dayjs()) : optionProps;

  return (
    <Field<SelectFormattedAnswer>
      name={id}
      id={id}
      format={value => {
        if (!value) {
          return { selectedOption: '', otherAnswer: '' };
        }

        if (typeof value === 'string') {
          const isSelectedAnswerInSubOptions = options.some(option =>
            option.subOptions?.some(subOption => subOption.value === value),
          );

          const isSelectedAnswerExistsInOptions = options.some(
            option =>
              option.value === value ||
              option.subOptions?.some(subOption => subOption.value === value),
          );

          return {
            selectedOption: isSelectedAnswerExistsInOptions ? value : 'other',
            otherAnswer:
              isSelectedAnswerExistsInOptions && !isSelectedAnswerInSubOptions ? '' : value,
          };
        }

        return value;
      }}
      render={({
        input: {
          onChange,
          value: { selectedOption, otherAnswer },
        },
      }) => (
        <div className="flex flex-col">
          {options.map(option => (
            <SelectOption
              key={option.value}
              option={option}
              setNextStep={setNextStep}
              onChange={onChange}
              selectedOption={selectedOption}
              otherAnswer={otherAnswer}
            />
          ))}
        </div>
      )}
    />
  );
};
