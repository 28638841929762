import React from 'react';

import classNames from 'classnames';

import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';

import { stickyTop } from '../utils/classNames';
import { contentHeight } from '../utils/constants';

export const RightSpaceLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isBelowDesktopSM = useIsBelowBreakpoint('sm-desktop');

  return (
    <div
      className={classNames(
        'sm-desktop:col-start-10 sm-desktop:col-span-3',
        'flex flex-col sm-desktop:pt-xl',
        stickyTop,
        { hidden: !children && isBelowDesktopSM },
      )}
      style={{ height: isBelowDesktopSM ? 'auto' : contentHeight }}
    >
      {children}
    </div>
  );
};
