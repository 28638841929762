import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import {
  Button,
  ICommonButtonProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';

import { SuiviAmounts } from './SuiviAmounts';

import { SuiviChantierComputed, SuiviLot } from '../types';

type Props = {
  lots: SuiviLot[];
  computed: SuiviChantierComputed;
  action: { label: string } & ICommonButtonProps;
  intervenant: 'client' | 'tp' | 'pro';
  children: ({
    showDetails,
    setShowDetails,
  }: {
    showDetails: boolean;
    setShowDetails: (showDetails: boolean) => void;
  }) => JSX.Element;
  thresholdInfo: React.ReactNode;
};

export const SuiviFooter: React.FC<Props> = ({
  lots,
  computed,
  action,
  intervenant,
  children,
  thresholdInfo,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const { label, ...actionProps } = action;
  const hasMontantGele = computed.montantGele > 0;

  const detailsRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(detailsRef, () => {
    if (showDetails) {
      setShowDetails(false);
    }
  });

  return (
    <div
      className={classNames(
        'sticky bottom-0 top-lg z-20 mt-auto flex flex-col col-span-full shadow-ds-xl',
        'sm-desktop:shadow-ds-xxs sm-desktop:col-span-3 sm-desktop:mt-0 sm-desktop:mb-auto sm-desktop:gap-md',
      )}
      ref={detailsRef}
    >
      {thresholdInfo}
      <div
        className={classNames(
          'flex flex-col justify-between bg-neutral-0 px-md py-sm',
          'sm-desktop:mt-0 sm-desktop:justify-normal sm-desktop:mb-auto sm-desktop:rounded-xxs',
          { 'rounded-t-md': showDetails && !thresholdInfo },
        )}
      >
        {intervenant !== 'client' && (!isTabletOrMobile || showDetails) && (
          <SuiviAmounts lots={lots} computed={computed} />
        )}
        <div className={hasMontantGele ? 'mb-lg sm-desktop:mb-xl' : 'mb-md'}>
          {children({ showDetails, setShowDetails })}
        </div>
        <Button {...actionProps} fullwidth>
          {action.label}
        </Button>
      </div>
    </div>
  );
};
