import React from 'react';

import PaperSearch from '@travauxlib/shared/src/components/DesignSystem/assets/PaperSearch.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

export const EmptyDevisFactures: React.FC = () => (
  <Card>
    <EmptyState
      icon={<PaperSearch />}
      title="Aucun devis reçu"
      description="Vous serez avertis quand vous recevrez un devis pour votre projet"
    />
  </Card>
);
