import React from 'react';

type Props = {
  header: JSX.Element;
  sidebar: JSX.Element | null;
  children: React.ReactNode;
  footer?: JSX.Element | null;
};

export const DocumentDisplayLayout: React.FC<Props> = ({ header, sidebar, footer, children }) => (
  <div className="flex flex-col h-full">
    {header}
    <div className="grow flex overflow-hidden">
      {/* The w-0 is pretty weird but it works as flex-grow will do the job. Otherwise,
       * "A flex item cannot be smaller than the size of its content along the main axis". As we can have huge pdf inside the content of this page
       * this can cause troubles.
       */}
      <div className="grow w-0">{children}</div>
      <div className="w-[20.5rem] shrink-0 hidden sm-desktop:block">{sidebar}</div>
    </div>
    {footer}
  </div>
);
