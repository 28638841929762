import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { HemeaOfferTag, MonitorProjectTag, StartProjectStartTag } from '../types';

export const guidesBaseUrl = (projectUuid: string): string => `/projets/${projectUuid}/guides`;

export const offreHemeaGuideUrl = `${guidesBaseUrl}/${HemeaOfferTag.Concept}`;
export const servicesHemeaGuideUrl = `${guidesBaseUrl}/${HemeaOfferTag.ServicesHemea}`;
export const assuranceGuideUrl = `${guidesBaseUrl}/${HemeaOfferTag.Garanties}`;
export const interlocuteurGuideUrl = `${guidesBaseUrl}/${HemeaOfferTag.Interlocuteurs}`;
export const accompagnementGuideUrl = `${guidesBaseUrl}/${HemeaOfferTag.Accompagnements}`;
export const architecteGuideUrl = `${guidesBaseUrl}/${HemeaOfferTag.Architectes}`;
export const architecteInterieurGuideUrl = `${guidesBaseUrl}/${HemeaOfferTag.ArchitectesInterieur}`;

export const fournituresGuideUrl = `${guidesBaseUrl}/${StartProjectStartTag.Fournitures}`;
export const paiementGuideUrl = `${guidesBaseUrl}/${StartProjectStartTag.CompteSequestre}`;
export const prixGuideUrl = `${guidesBaseUrl}/${StartProjectStartTag.Prix}`;

export const demarrageGuideUrl = `${guidesBaseUrl}/${MonitorProjectTag.Demarrage}`;
export const suiviGuideUrl = `${guidesBaseUrl}/${MonitorProjectTag.Suivi}`;
export const receptionGuideUrl = `${guidesBaseUrl}/${MonitorProjectTag.Reception}`;

export const offresWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/offres`;
export const offresAccompagnementWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/offres/accompagnement`;
export const garantiesWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/guide-conseils/travaux/assurances-garanties-btp`;
export const architecteInterieurWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/architecte-interieur`;
export const architecteWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/architecture/architecte/dplg-de-hmonp`;
export const receptionWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/guide-conseils/travaux/reception-travaux`;
export const protectionWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/offres/protection-hemea `;

export const guidesImgixUrl = `${baseImgixUrl}/static/espace-client/guides`;
