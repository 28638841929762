import { Props as ImageProps } from '@travauxlib/shared/src/components/Images/ImgLazy';

export enum GuidesSectionTag {
  Hemea = 'offre-hemea',
  CommencerSonProjet = 'commencer-son-projet',
  SuivreSonProjet = 'suivre-son-projet',
}

export enum HemeaOfferTag {
  Concept = 'concept',
  ServicesHemea = 'services-hemea',
  Garanties = 'garanties',
  Interlocuteurs = 'interlocuteurs',
  Accompagnements = 'accompagnements',
  ProjetHemea = 'projet-avec-hemea',
  Architectes = 'architectes',
  ArchitectesInterieur = 'architectes-interieur',
}

export enum StartProjectStartTag {
  Fournitures = 'fournitures',
  CompteSequestre = 'compte-sequestre',
  Prix = 'prix',
}

export enum MonitorProjectTag {
  Demarrage = 'demarrage',
  Suivi = 'chantier',
  Reception = 'reception',
}

export type GuidesTags = HemeaOfferTag | StartProjectStartTag | MonitorProjectTag;

export type GuidesSections = {
  [K in GuidesSectionTag]: GuidesSectionConfig;
};

export type GuidesSectionConfig = {
  title: string | JSX.Element;
  configs: GuidesConfigs;
};

export type GuidesConfigs = {
  [K in GuidesTags]?: {
    cardConfig: GuideCardConfig;
    pageConfig: GuidePageConfig;
  };
};

export type GuideCardConfig = {
  title: string;
  icon?: JSX.Element;
  hidden?: boolean;
};

export type GuidePageConfig = {
  title: string | JSX.Element;
  introduction?: string | JSX.Element;
  articles: ArticleConfig[];
  tracking: () => void;
};

export type ArticleConfig = {
  isTipSheet?: boolean;
  isWrapped?: boolean;
  noBackground?: boolean;
  title: string | JSX.Element;
  content?: string | JSX.Element;
  articleHeight?: number;
  image?: {
    position: 'left' | 'right' | 'top' | 'cover';
    isLarge?: boolean;
    withPadding?: boolean;
    imageProps: ImageProps;
  };
  icon?: {
    position: 'left' | 'right' | 'top';
    element: JSX.Element;
    size?: '6xl' | '3xl' | '2xl';
  };
  link?: {
    position?: 'left' | 'right';
    element: JSX.Element;
  };
};
