import React from 'react';

type Props = {
  isOrdered?: boolean;
  children: React.ReactNode;
};

export const ArticleListItems: React.FC<Props> = ({ children, isOrdered = false }) => {
  const listClassnames = 'm-0 ml-sm pl-xs list-outside';
  return isOrdered ? (
    <ol className={listClassnames}>{children}</ol>
  ) : (
    <ul className={listClassnames}>{children}</ul>
  );
};
