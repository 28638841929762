import React from 'react';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import CheckCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircleOutline.svg?react';
import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { useRevisionStore } from '@travauxlib/shared/src/features/DevisDisplay/hooks/useRevisionStore';
import { DevisComment } from '@travauxlib/shared/src/types';

import { useDevisDisplayContext } from '../DevisDisplayContext';

type Props = {
  threadUuid: string;
  onClose: () => void;
  lastComment: DevisComment;
  isDeleted: boolean;
};

export const ThreadActions: React.FC<Props> = ({ threadUuid, lastComment, onClose, isDeleted }) => {
  const { comments: devisComments, toggleValidatedThread } = useDevisDisplayContext();
  const [diffTypes, setDiffTypes] = useRevisionStore(state => [
    state.diffTypes,
    state.setDiffTypes,
  ]);
  const commentsList = devisComments?.[threadUuid!] || [];

  return (
    <div className="flex">
      {!isDeleted && commentsList.filter(l => l.senderType === 'pro').length !== 0 && (
        <>
          <IconButton
            data-testid="validate-comment"
            onClick={() => {
              setDiffTypes(diffTypes.filter(d => d !== 'resolved'));
              toggleValidatedThread?.(lastComment);
            }}
          >
            {lastComment.validated ? (
              <CheckCircle className="text-success" />
            ) : (
              <CheckCircleOutline />
            )}
          </IconButton>
        </>
      )}
      <IconButton onClick={onClose}>
        <CrossSymbol />
      </IconButton>
    </div>
  );
};
