import React, { useMemo } from 'react';

import {
  DevisComment,
  DevisComments,
  DevisContractor,
  DevisCustomization,
  DevisLocation,
  Lot,
  SenderType,
  TextItem,
} from '@travauxlib/shared/src/types';
import { switchToLotsByLocations } from '@travauxlib/shared/src/utils/switchToLotsByLocations';

import { DevisDisplayContextWrapper } from './components/DevisDisplayContext';
import { Header } from './components/Header';
import { Item } from './components/Item';
import { LotComponent } from './components/Lot';
import { generateDevisComparison } from './utils/generateDevisComparison';

import { EURCurrency } from '../../components/EURCurrency';
import { getPrixTotalFromLots } from '../../utils/facture/getPrixTotalHTFromLots';

type Props = {
  showOnlyFacturable?: boolean;
  showTotalTravaux?: boolean;
  isHemeaEmployee?: boolean;
  isAuthor?: boolean;
  addComment?: (payload: { text: string; targetUuid: string; senderType: SenderType }) => void;
  updateComment?: (payload: {
    uuid: string;
    text: string;
    targetUuid: string;
    senderType: SenderType;
  }) => void;
  toggleValidatedThread?: (comment: DevisComment) => void;
  deleteComment?: (comment: DevisComment) => void;
  dealOwnerName?: string;
  proCompanyName?: string;
  customerName: string;
  comments?: DevisComments;
  lots: Lot[];
  headerText?: TextItem;
  locations?: DevisLocation[];
  hidePrices?: boolean;
  isViewByLocations?: boolean;
  showFournitures?: boolean;
  customization?: DevisCustomization;
  isProPreview?: boolean;
  isRGE?: boolean;
  contractors?: DevisContractor[];
  isReviewed?: boolean;
  token: string;
  previousDevis?: { lots: Lot[]; locations: DevisLocation[] };
};

export const DevisDisplay: React.FC<Props> = ({
  showOnlyFacturable = false,
  dealOwnerName,
  isHemeaEmployee = false,
  isAuthor,
  addComment,
  updateComment,
  toggleValidatedThread,
  deleteComment,
  locations = [],
  hidePrices = false,
  isViewByLocations = false,
  showFournitures = true,
  customization,
  isProPreview = false,
  comments,
  proCompanyName,
  customerName,
  headerText,
  isRGE,
  lots,
  showTotalTravaux,
  contractors,
  isReviewed,
  token,
  previousDevis,
}) => {
  const lotsToDisplay = useMemo(() => {
    if (previousDevis) {
      return generateDevisComparison(previousDevis, { lots, locations }, { matchThread: true }).map(
        lotCompare => {
          const refLot = lotCompare.status === 'added' ? lotCompare.compare : lotCompare.base;
          return {
            ...refLot,
            items: lotCompare.lignes.map(ligneCompare => {
              const ligneRef =
                ligneCompare.status === 'missing' ? ligneCompare.base : ligneCompare.compare;
              return {
                ...ligneRef,
                compare: ligneCompare.status === 'modified' ? ligneCompare.base : undefined,
                comparisonStatus: ligneCompare.status,
              };
            }),
          };
        },
      );
    }
    if (isViewByLocations) {
      return switchToLotsByLocations(lots, locations);
    }
    return lots;
  }, [lots, locations, previousDevis, isViewByLocations]);

  return (
    <DevisDisplayContextWrapper
      {...{
        customization,
        dealOwnerName,
        proCompanyName,
        customerName,
        devisLocations: locations,
        hidePrices,
        isHemeaEmployee,
        isAuthor,
        isProPreview,
        showFournitures,
        comments,
        addComment,
        updateComment,
        toggleValidatedThread,
        deleteComment,
        isRGE,
        isReviewed,
        token,
        lots,
      }}
    >
      <div data-testid="prestation">
        <Header customization={customization} />
        {headerText && <Item lotIndex={-1} itemIndex={-1} item={headerText} />}
        {lotsToDisplay.map((lot, index) => (
          <LotComponent
            showOnlyFacturable={showOnlyFacturable}
            key={lot.uuid}
            lotIndex={index}
            lot={lot}
            contractors={contractors}
          />
        ))}
        {showTotalTravaux && (
          <div className="flex flex-row items-center justify-between font-bold">
            <div>Total travaux</div>
            <EURCurrency amount={getPrixTotalFromLots(lotsToDisplay)} suffix="HT" />
          </div>
        )}
      </div>
    </DevisDisplayContextWrapper>
  );
};
