import React, { ComponentType } from 'react';

import classNames from 'classnames';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

import { SuiviChantierCircleProgress } from './SuiviChantierCircleProgress';

type Props = {
  onClick: () => void;
  Icon?: ComponentType<{ className: string }>;
  bigIcon?: JSX.Element;
  title: string;
  subTitle: string;
  pourcentageAvancement: number;
  className?: string;
  shouldDisplayInfoColor?: boolean;
};

export const Header: React.FC<Props> = ({
  onClick,
  Icon,
  title,
  subTitle,
  pourcentageAvancement,
  className,
  shouldDisplayInfoColor,
  bigIcon,
}) => (
  <div
    className={classNames(
      'bg-neutral-0 flex items-center px-md py-xs justify-between shadow-ds-xs gap-xxs sticky top-0 z-20',
      className,
    )}
  >
    <div className="flex items-center overflow-auto">
      <IconButton className="mr-xs shrink-0" onClick={onClick} aria-label="back-button">
        <ChevronLeft />
      </IconButton>
      <div className="flex items-center overflow-auto">
        {Icon && <Icon className="w-[2.5rem] h-[2.5rem] mr-xxs shrink-0" />}
        {bigIcon && (
          <span className="w-[2.5rem] h-[2.5rem] mr-xxs shrink-0 flex items-center">{bigIcon}</span>
        )}
        <div className="overflow-auto">
          <div className="text-ds-b2 text-neutral-700 truncate">{title}</div>
          <div className="text-ds-b1 font-bold truncate">{subTitle}</div>
        </div>
      </div>
    </div>
    <div>
      <SuiviChantierCircleProgress
        value={pourcentageAvancement}
        variant={shouldDisplayInfoColor ? 'info' : undefined}
      />
    </div>
  </div>
);
