import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AppFeatures,
  EspaceClientEventProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class AvancementChantierTrackingClass extends Tracker<AppFeatures> {
  onAvancementChantierValidated = (properties: {
    [EspaceClientEventProperties.ProjectUuid]?: string;
    [EspaceClientEventProperties.ProjectName]?: string;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Validated,
      properties,
    });
  };
}

export const AvancementChantierTracking = new AvancementChantierTrackingClass(
  AppFeatures.AvancementChantier,
);
