import React from 'react';

import _sum from 'lodash/sum';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { BonCommandeLot } from '@travauxlib/shared/src/types';

type Props = {
  title?: string;
  lots: BonCommandeLot[];
};

export const BonCommandeLotsSommaire: React.FC<Props> = ({ lots, title = 'Sommaire des lots' }) => (
  <div className="flex flex-col overflow-hidden p-sm w-full overflow-auto !bg-white rounded">
    <div className="text-ds-h6 font-bold mb-md">{title}</div>
    <div className="max-h-[300px] relative overflow-auto">
      {lots
        .filter(lot => lot.lignes.some(ligne => ligne.selected))
        .map((lot, lotIndex) => {
          const lotTrueAmount = _sum(
            lot.lignes.flatMap(ligne => {
              if (ligne.selected) {
                return ligne.prixUnitaireHT * ligne.quantite;
              } else {
                return 0;
              }
            }),
          );
          return (
            <div key={lot.uuid} className="flex justify-between pb-sm">
              <div className="flex">
                <div className="flex text-gray-600 !text-ds-sm pt-xxs pr-xs">{`${
                  lotIndex + 1
                }.0`}</div>
                <div className="flex justify-end"> {lot.label}</div>
              </div>
              <div className="pl-md whitespace-nowrap">
                <EURCurrency amount={lotTrueAmount} />
              </div>
            </div>
          );
        })}
    </div>
  </div>
);
