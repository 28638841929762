import React from 'react';

import capitalize from 'lodash/capitalize';
import { useNavigate } from 'react-router-dom';

import { useDownloadAsZip } from '@travauxlib/shared/src/api/useDownloadAsZip';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import DocumentOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentOutline.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

import { getPvDateLabel } from './utils/getPvDateLabel';
import { getPvStatusTag } from './utils/getPvStatusTag';

import { Chantier } from '../../types';

type Props = {
  chantier: Chantier;
};

const PvRecap: React.FC<{ pv: ChantierPvWithDocuments }> = ({ pv }) => {
  const { downloadAsZip } = useDownloadAsZip();
  const navigate = useNavigate();
  return (
    <div>
      <ListItem
        size={pv.rejectedAt || pv.validatedAt ? 'sm' : undefined}
        shouldTruncate={false}
        className="!rounded-md items-start"
        onChange={() => {
          if (pv.rejectedAt || pv.validatedAt) {
            downloadAsZip({
              zipName: slugify(PvDocumentName[pv.pvType]),
              urls: pv.documents.map(d => d.downloadUrl),
            });
          } else {
            navigate(`pvs/${pv.pvType}`);
          }
        }}
        left={{
          type: 'iconBackground',
          icon: <DocumentOutline />,
          size: 'lg',
          variant: 'beige',
          active: !pv.rejectedAt && !pv.validatedAt,
        }}
        middle={{
          title: capitalize(PvDocumentName[pv.pvType]),
          tags: [getPvStatusTag(pv)],
        }}
        right={
          pv.rejectedAt || pv.validatedAt
            ? { type: 'icon', icon: <Download /> }
            : { type: 'icon', icon: <ChevronRight /> }
        }
      >
        <div className="flex justify-between text-ds-b2 -mt-xxs">
          <div className="text-neutral-600">{getPvDateLabel(pv)}</div>
        </div>
      </ListItem>
    </div>
  );
};

export const FinDeChantier: React.FC<Props> = ({ chantier }) => {
  const { pv } = chantier;

  const receptionChantier = pv.receptionChantier;
  const leveeReserves = pv.leveeReserves;

  if (!receptionChantier && !leveeReserves) {
    return null;
  }

  return (
    <div>
      <div className="text-ds-h5 py-sm mx-md sm-desktop:mx-0 font-bold">
        Documents de fin de chantier
      </div>
      <div className="flex flex-col gap-sm mx-md sm-desktop:mx-0">
        {leveeReserves && <PvRecap pv={leveeReserves} />}
        {receptionChantier && <PvRecap pv={receptionChantier} />}
      </div>
    </div>
  );
};
