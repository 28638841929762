import React from 'react';

import { Navigate, useSearchParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useProjects } from 'features/EspaceClient3/api/useProjects';

export const RedirectToLatestProject: React.FC = () => {
  const { projects, isLoading } = useProjects();
  const [searchParams] = useSearchParams();

  if (isLoading) {
    return <LoaderWrapper />;
  }

  return (
    <Navigate
      to={{ pathname: `projets/${projects[0].uuid}`, search: searchParams.toString() }}
      replace
    />
  );
};
