import React, { useContext } from 'react';

import {
  DevisLocation,
  DevisComment,
  DevisComments,
  DevisCustomization,
  SenderType,
  SenderNames,
  Lot,
} from '@travauxlib/shared/src/types';

import { getSenderType } from './Comments/utils/getSenderType';

type DevisDisplayContextProps = {
  isHemeaEmployee: boolean;
  isAuthor?: boolean;
  addComment?: (payload: {
    text: string;
    targetUuid: string;
    senderType: SenderType;
    threadUuid?: string;
  }) => void;
  updateComment?: (payload: {
    uuid: string;
    text: string;
    targetUuid: string;
    senderType: SenderType;
    resolved?: boolean;
  }) => void;
  toggleValidatedThread?: (comment: DevisComment) => void;
  deleteComment?: (comment: DevisComment) => void;
  dealOwnerName?: string;
  proCompanyName?: string;
  customerName: string;
  senderNames: SenderNames;
  senderType: SenderType;
  comments?: DevisComments;
  devisLocations: DevisLocation[];
  hidePrices: boolean;
  showFournitures: boolean;
  customization?: DevisCustomization;
  isProPreview?: boolean;
  isRGE?: boolean;
  isReviewed?: boolean;
  token: string;
  lots: Lot[];
};

const DevisDisplayContext = React.createContext<DevisDisplayContextProps>({
  isHemeaEmployee: false,
  customerName: '',
  dealOwnerName: '',
  proCompanyName: '',
  hidePrices: false,
  showFournitures: false,
  devisLocations: [],
  isRGE: false,
  token: '',
  lots: [],
  senderNames: {} as SenderNames,
  senderType: 'client',
});

type DevisDisplayProviderProps = Omit<DevisDisplayContextProps, 'senderNames' | 'senderType'> & {
  dealOwnerName?: string;
  proCompanyName?: string;
  customerName: string;
};

export const DevisDisplayContextWrapper: React.FC<
  React.PropsWithChildren<DevisDisplayProviderProps>
> = ({ children, ...props }) => {
  const { isHemeaEmployee, isAuthor, proCompanyName, dealOwnerName, customerName } = props;

  const senderType = getSenderType({ isHemeaEmployee, isAuthor });
  const senderNames = {
    pro: proCompanyName || 'Pro',
    'deal-owner': dealOwnerName || 'hemea',
    client: customerName,
  };
  return (
    <DevisDisplayContext.Provider value={{ ...props, senderNames, senderType }}>
      {children}
    </DevisDisplayContext.Provider>
  );
};

export const useDevisDisplayContext = (): DevisDisplayContextProps => {
  const contextValue = useContext(DevisDisplayContext);
  if (!contextValue) {
    throw new Error(
      "DevisDisplayContext not found, you're using this hook not in a children of the Context (maybe a modal ?)",
    );
  }
  return contextValue;
};
