import React from 'react';

import classNames from 'classnames';
import Currency from 'react-currency-formatter';

function getCurrency(value?: number, forceFullPattern?: boolean): JSX.Element {
  if (value || value === 0) {
    const isInt = value % 1 === 0;
    return isInt && !forceFullPattern ? (
      <Currency locale="fr_FR" quantity={value} pattern="###&nbsp;€" />
    ) : (
      <Currency
        locale="fr_FR"
        quantity={Math.round(value * 100) / 100} // preventive rounding, otherwise 0.0000002 fails with NaN
        currency="EUR"
      />
    );
  }
  return <Currency locale="fr_FR" quantity={0} currency="EUR" />;
}

type Props = {
  amount?: number;
  crossedOut?: boolean;
  prefix?: string;
  suffix?: string;
  className?: string;
  forceFullPattern?: boolean;
  ['data-testid']?: string;
  isIndeterminate?: boolean;
};

export const EURCurrency: React.FC<Props> = ({
  amount = 0,
  crossedOut,
  prefix,
  suffix,
  className,
  forceFullPattern,
  'data-testid': dataTestId,
  isIndeterminate,
}) => (
  <span
    className={classNames('whitespace-nowrap', { 'line-through': crossedOut }, className)}
    data-testid={dataTestId || 'eur-currency'}
    data-value={Number.isNaN(amount) ? undefined : amount}
  >
    {prefix}
    {isIndeterminate ? <>-&nbsp;€</> : getCurrency(amount, forceFullPattern)}
    {suffix}
  </span>
);
