import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { request } from '@travauxlib/shared/src/utils/request';

import { useIsAuthor } from 'hooks/useIsAuthor';
import { Devis, ModalitesHemea } from 'types';

export const DEVIS_KEY = 'DEVIS';

const fetchDevisPro = ({
  queryKey: [, { token, isAuthor }],
}: QueryFunctionContext<[string, { token: string; isAuthor: boolean }]>): Promise<
  Devis & { modalitesHemea: ModalitesHemea }
> =>
  request(`${APP_CONFIG.apiURL}/devis-pro/${token}`, {
    params: {
      isAuthor,
    },
  });

export const useDevisPro = (token?: string): { isLoading: boolean; devis?: Devis } => {
  const isAuthor = useIsAuthor();
  const { isLoading, fetchStatus, data } = useQuery<Devis & { modalitesHemea: ModalitesHemea }>({
    queryKey: [DEVIS_KEY, { token, isAuthor }],
    queryFn: fetchDevisPro,
    staleTime: Infinity,
    retry: (failureCount, error: AxiosError) => failureCount < 2 && error?.response?.status !== 404,
    enabled: !!token,
  });

  return {
    isLoading: isLoading && fetchStatus !== 'idle',
    devis: data ? { ...data, ...data.modalitesHemea } : undefined,
  };
};
