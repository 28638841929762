import React from 'react';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { espaceClientUrl } from 'utils/urls';

export const ConnectedAsHemeaEmployeeAlertMessage: React.FC = () => (
  <AlertMessage
    className="absolute top-0 left-1/2 -translate-x-1/2 max-w-[800px] z-40"
    level="error"
    title="Attention"
  >
    Vous êtes actuellement connecté à un compte client. En poursuivant l'onboarding, vous allez
    créer un nouveau projet pour le client.{' '}
    <Link to={espaceClientUrl} target="_blank" inline>
      Veuillez-vous déconnecter du compte client avant de poursuivre.
    </Link>
  </AlertMessage>
);
