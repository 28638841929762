import { truncate } from './format';

import { WithTooltip } from '../components/DesignSystem/components/WithTooltip';

export const truncateWordsWithTooltip = (value: string | number, max?: number): JSX.Element =>
  value.toString().length > (max ?? Number.POSITIVE_INFINITY) ? (
    <WithTooltip
      showDelay={200}
      trigger={<span>{max ? truncate(value.toString(), max) : value}</span>}
      position="top"
    >
      <span>{value}</span>
    </WithTooltip>
  ) : (
    <>{value}</>
  );
