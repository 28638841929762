import React from 'react';

import { StructureCard } from 'components/Card';

export const ReassuranceSection: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <StructureCard className="sm-desktop:mb-md -mx-md sm-desktop:mx-0">
    <div className="grid grid-cols-1 sm-desktop:grid-cols-3 justify-around grow bg-transparent flex justify-between">
      {children}
    </div>
  </StructureCard>
);
