import { DevisComment, ProContext } from '@travauxlib/shared/src/types';

type Result = {
  [K in DevisComment['senderType']]: string;
};

export const getSenderNamesFromDevis = (proContext: ProContext): Result => {
  const {
    company: { name: proCompanyName },
    deal: { customerName, dealOwnerFullname },
  } = proContext;

  const senderNames: Result = {
    pro: proCompanyName,
    'deal-owner': dealOwnerFullname || 'hemea',
    client: customerName,
  };

  return senderNames;
};
