import React from 'react';

import CrossCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircleOutline.svg?react';
import EditCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/EditCircleOutline.svg?react';
import PlusCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircleOutline.svg?react';
import { Ligne } from '@travauxlib/shared/src/types';

type Props = {
  ligne: Ligne;
  lotIndex: number;
  itemIndex: number;
};

const Content: React.FC<Props> = ({ ligne, lotIndex, itemIndex }) => {
  if (ligne.comparisonStatus === 'added') {
    return <PlusCircleOutline data-testid="added" className="w-lg h-lg text-success-800" />;
  }
  if (ligne.comparisonStatus === 'missing') {
    return <CrossCircleOutline data-testid="missing" className="w-lg h-lg text-error-800" />;
  }
  if (ligne.comparisonStatus === 'modified') {
    return <EditCircleOutline data-testid="modified" className="w-lg h-lg text-info-800" />;
  }
  return (
    <span data-testid="unchanged">
      {lotIndex + 1}.{itemIndex + 1}
    </span>
  );
};

export const LeftIndicator: React.FC<Props> = props => (
  <span className="text-neutral-600 grow-0 shrink-0 w-xl mr-xs">
    <Content {...props} />
  </span>
);
