import React from 'react';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

type Props = {
  title: string;
};

export const Title: React.FC<Props> = ({ title }) => (
  <div className="flex justify-between">
    <h1 className="mb-lg">{title}</h1>
    <IconButtonLink to="../result">
      <CrossSymbol />
    </IconButtonLink>
  </div>
);
