import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  montantAvancement: number;
  initialMontantAvancement: number;
  rejectedLigneCount: number;
  proCompanyName: string;
};

export const PrestationsRecap: React.FC<Props> = ({
  montantAvancement,
  initialMontantAvancement,
  rejectedLigneCount,
  proCompanyName,
}) => {
  if (rejectedLigneCount === 0) {
    return (
      <div>
        Vous avez validé tous les avancements renseignés par <b>{proCompanyName}</b> sur votre
        chantier.
      </div>
    );
  }

  const pluralSuffix = rejectedLigneCount > 1 ? 's' : '';

  return (
    <div>
      {montantAvancement <= 0 ? (
        <>
          Vous avez ajusté l’avancement sur <b>{rejectedLigneCount}</b> prestation{pluralSuffix}. Le
          montant d’avancement total recalculé est maintenant inférieur au montant déjà libéré.
        </>
      ) : (
        <>
          Vous avez ajusté l’avancement sur <b>{rejectedLigneCount}</b> prestation{pluralSuffix}.
          L'avancement recalculé correspond à une libération de fonds de{' '}
          <EURCurrency className="font-bold" amount={montantAvancement} /> au lieu de{' '}
          <EURCurrency className="font-bold" amount={initialMontantAvancement} /> initialement
          demandés par <b>{proCompanyName}</b>.
        </>
      )}
    </div>
  );
};
