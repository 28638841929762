import React from 'react';

import classNames from 'classnames';
import _groupBy from 'lodash/groupBy';

import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Popover } from '@travauxlib/shared/src/components/DesignSystem/components/Popover';
import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';
import { MontantsTVA } from '@travauxlib/shared/src/features/LDDToolbar/components/TotalTVA/MontantsTVA';
import { useFeatureFlag } from '@travauxlib/shared/src/hooks/useFeatureFlag';
import { useScrollStatus } from '@travauxlib/shared/src/hooks/useScrollStatus';

import { SuiviChantierComputed, SuiviLot } from '../types';
import { getAvancementForLots, getMontantHTByAvancementForLots } from '../utils';

type Props = {
  lots: SuiviLot[];
  computed: SuiviChantierComputed;
};

export const SuiviAmounts: React.FC<Props> = ({ lots, computed }) => {
  const groupedLots = _groupBy(lots, 'label');
  const montantAvancementGlobalHT = getMontantHTByAvancementForLots(lots);
  const avancementGlobal = getAvancementForLots(lots);
  const { montantsTVA, totalTVA, montantAvancementGlobalTTC, montantRemise, montantDejaPaye } =
    computed;
  const scrollableRef = React.useRef(null);
  const { isScrollTop, isScrollBottom } = useScrollStatus(scrollableRef);
  const isDebug = useFeatureFlag('debug');

  return (
    <div className="flex flex-col gap-xs border-b pb-xs mb-xs text-ds-b2">
      <div className="flex flex-col gap-xxs">
        <div>
          <SimpleTotal
            className="font-bold"
            sectionName="Montant global d'avancement HT"
            total={montantAvancementGlobalHT}
            label={
              <div className="font-normal">
                Montant global d'avancement{' '}
                <span className="text-ds-sm font-medium text-neutral-600">HT</span>
              </div>
            }
          />
          <div className="text-ds-sm text-neutral-600">({avancementGlobal}% d'avancement)</div>
        </div>
        {isDebug && (
          <div
            className={classNames(
              'max-h-[20vh] sm-desktop:max-h-[90px] overflow-x-auto flex flex-col gap-xxs text-neutral-700',
              {
                'border-t': !isScrollTop,
                'border-b': !isScrollBottom,
              },
            )}
            ref={scrollableRef}
          >
            {Object.entries(groupedLots).map(([lotLabel, lots]) => {
              const montantHTByAvancementForLots = getMontantHTByAvancementForLots(lots);

              if (!montantHTByAvancementForLots) {
                return null;
              }

              return (
                <SimpleTotal
                  key={lotLabel}
                  sectionName={lotLabel}
                  className="text-ds-sm"
                  total={montantHTByAvancementForLots}
                  label={<div className="text-neutral-700">{lotLabel}</div>}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-xxs">
        {montantRemise > 0 && (
          <SimpleTotal className="text-success-600" total={-montantRemise} label="Remise" />
        )}
        <SimpleTotal
          sectionName="Total TVA"
          total={totalTVA}
          label={
            <div className="flex items-center">
              Total TVA
              <Popover
                width={320}
                content={
                  <MontantsTVA
                    darkTheme
                    montantsTVA={
                      montantsTVA.length > 0
                        ? montantsTVA
                        : [
                            {
                              taux: 0,
                              base: 0,
                              montant: 0,
                            },
                          ]
                    }
                  />
                }
                trigger={(onClose, referenceProps) => (
                  <IconButton
                    {...referenceProps}
                    onClick={onClose}
                    className="ml-xxs"
                    size="xs"
                    variant="light"
                  >
                    <HelpCircleOutline />
                  </IconButton>
                )}
              />
            </div>
          }
        />
      </div>
      <div>
        <SimpleTotal
          className="font-bold"
          sectionName="Montant global d'avancement TTC"
          total={montantAvancementGlobalTTC}
          label={
            <div className="font-normal">
              Montant global d'avancement{' '}
              <span className="text-ds-sm font-medium text-neutral-600">TTC</span>
            </div>
          }
        />
        <div className="text-ds-sm text-neutral-600">({avancementGlobal}% d'avancement)</div>
      </div>
      <div>
        <SimpleTotal
          className="font-bold text-info-600"
          sectionName="Montant déjà libéré TTC"
          total={-montantDejaPaye}
          label={
            <div className="font-normal text-neutral-800">
              Montant déjà libéré{' '}
              <span className="text-ds-sm font-medium text-neutral-600">TTC</span>
            </div>
          }
        />
        <div className="text-neutral-600 text-ds-sm">(Hors prestations hemea)</div>
      </div>
    </div>
  );
};
