import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { DevisContractor } from '@travauxlib/shared/src/types';

export const RGEContractorDetails: React.FC<{ contractors: DevisContractor[] }> = ({
  contractors,
}) => (
  <DevisFactureCard title="Sous-traitants RGE" className="grow sm-desktop:max-w-1/2">
    {contractors.map((contractor, index) => (
      <div className="mb-xs" key={`${index}-${contractor.uuid}`}>
        <div className="font-bold text-neutral-600">{contractor.name}</div>
        <div className="text-ds-b2 text-neutral-600">
          <div>{contractor.billingFormattedAddress}</div>
          <div>{contractor.phoneNumber}</div>
          {contractor.siret && <div>Siret : {contractor.siret}</div>}
        </div>
        <div>
          {contractor.certificationsRGE?.map((certification, index) => (
            <div
              key={`${index}-${certification.uuid}`}
              className="flex items-center gap-md text-ds-b2 text-neutral-600"
            >
              <Link href={certification.downloadUrl} target="_blank">
                Voir la certification
              </Link>
            </div>
          ))}
        </div>
      </div>
    ))}
  </DevisFactureCard>
);
