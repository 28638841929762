import React, { useCallback } from 'react';

import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { Badges, NavigationItem } from '../types';
import { getNavigationItems } from '../utils';

type Props = { projectUuid: string; badges: Badges };

const TapBarItem: React.FC<NavigationItem & { isSmallBadge: boolean }> = ({
  Icon,
  label,
  path,
  isActive,
  badgeCount,
  isSmallBadge,
}) => (
  <Link
    className={classNames(
      'flex flex-col items-center p-xxs text-neutral-600 !no-underline rounded-xs flex-1',
      'hover:bg-neutral-100 hover:text-neutral-600 active:bg-neutral-200',
      {
        '!text-primary-400': isActive,
      },
    )}
    to={path}
  >
    <div className="relative">
      <Icon className="w-lg h-lg" />
      {badgeCount > 0 && (
        <Badge
          className={classNames(
            'absolute',
            isSmallBadge ? 'top-0 right-0' : 'top-[-0.2rem] right-[-0.4rem]',
          )}
          size={isSmallBadge ? 'xs' : 'sm'}
          variant="error"
          value={badgeCount}
        />
      )}
    </div>
    <span className="text-ds-sm ">{label}</span>
  </Link>
);

export const TapBar: React.FC<Props> = ({ projectUuid, badges }) => {
  const location = useLocation();
  const isTabletOrMobile = useIsTabletOrMobile();
  const navigationItems = useCallback(
    () => getNavigationItems(projectUuid, location.pathname, badges),
    [location.pathname, badges],
  );

  return (
    /* Fixed to handle smaller page content */
    <div className="sm-desktop:hidden flex bg-neutral-0 w-full border-t p-xxs fixed bottom-0 overflow-x-auto z-20">
      {navigationItems().map(navigationItem => (
        <TapBarItem
          key={navigationItem.label}
          {...navigationItem}
          isSmallBadge={isTabletOrMobile && navigationItem.label !== 'Accueil'}
        />
      ))}
    </div>
  );
};
