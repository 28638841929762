import React from 'react';

import Autre from '@travauxlib/shared/src/components/DesignSystem/assets/AutreDuoTone.svg?react';
import Basedeprix from '@travauxlib/shared/src/components/DesignSystem/assets/Basedeprix.svg?react';
import Batiprix from '@travauxlib/shared/src/components/DesignSystem/assets/Batiprix.svg?react';
import Menuiseries from '@travauxlib/shared/src/components/DesignSystem/assets/Carpentry.svg?react';
import Cone from '@travauxlib/shared/src/components/DesignSystem/assets/Cone.svg?react';
import Draw from '@travauxlib/shared/src/components/DesignSystem/assets/DrawDuoTone.svg?react';
import Electricity from '@travauxlib/shared/src/components/DesignSystem/assets/Electricity.svg?react';
import Facade from '@travauxlib/shared/src/components/DesignSystem/assets/Facade.svg?react';
import Fan from '@travauxlib/shared/src/components/DesignSystem/assets/Fan.svg?react';
import Favoris from '@travauxlib/shared/src/components/DesignSystem/assets/Favoris.svg?react';
import Floor from '@travauxlib/shared/src/components/DesignSystem/assets/Floor.svg?react';
import Cuisine from '@travauxlib/shared/src/components/DesignSystem/assets/KitchenDuoTone.svg?react';
import Maconnerie from '@travauxlib/shared/src/components/DesignSystem/assets/Masonry.svg?react';
import Peinture from '@travauxlib/shared/src/components/DesignSystem/assets/Painting.svg?react';
import Platrerie from '@travauxlib/shared/src/components/DesignSystem/assets/Platrerie.svg?react';
import Plomberie from '@travauxlib/shared/src/components/DesignSystem/assets/Plumbing.svg?react';
import Vrd from '@travauxlib/shared/src/components/DesignSystem/assets/Vrd.svg?react';
import Window from '@travauxlib/shared/src/components/DesignSystem/assets/Window.svg?react';
import WoodenFloor from '@travauxlib/shared/src/components/DesignSystem/assets/WoodenFloor.svg?react';
import Demolition from '@travauxlib/shared/src/components/DesignSystem/assets/Workman.svg?react';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

export const allIcons = {
  amenagements_exterieurs: Vrd,
  autres: Autre,
  base_de_prix: Basedeprix,
  batiprix: Batiprix,
  carrelage_faience: Floor,
  chauffage_ventilation_climatisation: Fan,
  couverture_etancheite_zinguerie: Facade,
  cuisine: Cuisine,
  demolition: Demolition,
  demolition_depose: Demolition,
  electricite: Electricity,
  etudes_conception: Draw,
  facade: Facade,
  facade_couverture_ite: Facade,
  favoris: Favoris,
  gros_oeuvre_structure: Maconnerie,
  installation: Cone,
  installation_de_chantier_nettoyage: Cone,
  maconnerie_gros_oeuvre_structure: Maconnerie,
  menuiseries: Menuiseries,
  menuiseries_interieures: Menuiseries,
  menuiseries_exterieures: Window,
  menuiseries_interieures_agencement: Menuiseries,
  peinture: Peinture,
  revetement_mur_plafond: Peinture,
  phase_chantier: Draw,
  phase_etudes: Draw,
  platrerie_iti: Platrerie,
  platrerie_cloisonnement: Platrerie,
  plomberie: Plomberie,
  plomberie_cvc: Plomberie,
  revetements_de_sols: WoodenFloor,
  revetement_sol: WoodenFloor,
  terrassement_reseaux: Vrd,
  vrd_exterieurs: Vrd,
};

export type Props = {
  lotLabel: string;
  className?: string;
};

export const LotIcon: React.FC<Props> = ({ lotLabel, className }) => {
  const slugifiedLabel = slugify(lotLabel, '_');
  const Icon = allIcons.hasOwnProperty(slugifiedLabel)
    ? allIcons[slugifiedLabel as keyof typeof allIcons]
    : Cone;

  return <Icon className={className} />;
};
