import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import HandshakeOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandshakeOutline.svg?react';
import LockOutline from '@travauxlib/shared/src/components/DesignSystem/assets/LockOutline.svg?react';

import { Props as ReassuranceItemProps } from 'features/EspaceClient3/components/ReassuranceCard/ReassuranceItem';

export const paymentReassurance: ReassuranceItemProps[] = [
  {
    icon: <LockOutline />,
    title: 'Compte sécurisé',
    description:
      "Vous déposez les fonds sur ce compte et vous seul pouvez libérer les fonds au profit de l'entrepreneur",
  },
  {
    icon: <HandshakeOutline />,
    title: "Paiement à l'avancement",
    description:
      "Vous évaluez l'avancement de chantier et vous ne libérez que les fonds nécessaires",
  },
  {
    icon: <Euro />,
    title: 'Paiements fluides',
    description:
      "Les fonds débloqués arrivent sur le compte de l'entrepreneur le lendemain sans induire de retard sur le chantier",
  },
];
