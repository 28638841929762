import React from 'react';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { PaginationType } from '@travauxlib/shared/src/hooks/usePagination';

export function Pagination<T extends object>({
  totalElements,
  nextPage,
  previousPage,
  canGoPrevious,
  canGoNext,
  firstItemIndex,
  lastItemIndex,
}: PaginationType<T>): JSX.Element {
  return (
    <div className="flex items-center">
      {firstItemIndex} - {lastItemIndex} sur {totalElements}
      <IconButton size="lg" disabled={!canGoPrevious} onClick={previousPage} className="ml-md">
        <ChevronLeft data-testid="previous-page" />
      </IconButton>
      <IconButton size="lg" disabled={!canGoNext} onClick={nextPage}>
        <ChevronRight data-testid="next-page" />
      </IconButton>
    </div>
  );
}
