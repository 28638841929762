import React from 'react';

import { Avis } from './Avis';
import { MainContentLayout } from './MainContentLayout';
import { RightSpaceLayout } from './RightSpaceLayout';

type Props = {
  avisImageUrl: string;
  avisText: string;
  avisAuteur: string;
  rightContent?: React.ReactNode;
  children?: React.ReactNode;
};
export const ContentLayout: React.FC<Props> = ({
  rightContent,
  avisImageUrl,
  avisText,
  avisAuteur,
  children,
}) => (
  <>
    <Avis imageUrl={avisImageUrl} avis={avisText} auteur={avisAuteur} />
    <MainContentLayout>{children}</MainContentLayout>
    <RightSpaceLayout>{rightContent}</RightSpaceLayout>
  </>
);
