import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Project } from 'features/EspaceClient3/types';

export const PROJECTS_KEY = 'PROJECTS';

export const useProjects = (): { isLoading: boolean; projects: Project[] } => {
  const { isLoading, data } = useQuery<{ data: Project[] }>({
    queryKey: [PROJECTS_KEY],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/project`),
  });

  return { isLoading, projects: data?.data || [] };
};
