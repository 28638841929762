import React from 'react';

import { Navigate, useLocation, useParams } from 'react-router-dom';

import { ProjectQuestion } from '../types';

type Props = { children: JSX.Element };

export const RedirectToFirstStep: React.FC<Props> = ({ children }) => {
  const { stepId } = useParams();
  const { search } = useLocation();
  const isValidStep = Object.values<string>(ProjectQuestion).includes(stepId!);

  return isValidStep ? children : <Navigate to={{ pathname: '', search: search }} replace />;
};
