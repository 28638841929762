import React from 'react';

import classNames from 'classnames';

import NewComment from '@travauxlib/shared/src/components/DesignSystem/assets/NewComment.svg?react';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { isDeletedThread } from '@travauxlib/shared/src/features/DevisDisplay/utils/isDeletedThread';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useRevisionStore } from '../../hooks/useRevisionStore';
import { useDevisDisplayContext } from '../DevisDisplayContext';

type Props = {
  targetUuid: string;
  threadUuid?: string;
};

export const FloatingTooltip: React.FC<Props> = ({ targetUuid, threadUuid }) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const { comments, lots, senderNames } = useDevisDisplayContext();
  const itemComments = comments?.[targetUuid] || comments?.[threadUuid!];
  const hasComments = !!itemComments?.length;
  const { isRevising, openThread } = useRevisionStore();

  const { activeThread } = useRevisionStore();

  const isDeleted = isDeletedThread(lots, threadUuid);
  const toReview =
    hasComments &&
    itemComments[itemComments.length - 1].senderType === 'pro' &&
    !itemComments[itemComments.length - 1].validated &&
    !isDeleted;

  const isActive =
    activeThread?.ligneUuid === targetUuid ||
    (threadUuid && activeThread?.threadUuid === threadUuid);

  const isValidated = !!itemComments?.[0]?.validated;
  const onOpen = (): void => openThread({ ligneUuid: targetUuid, threadUuid });
  return (
    <div
      onClick={e => e.stopPropagation()}
      className={classNames(
        'add-comment-icon hidden shadow-ds-xs w-xl h-xl rounded-md rounded-bl-none rotate-45 absolute left-full ml-xs top-1/2  bg-white -translate-y-1/2 text-ds-b2',
        {
          '!flex': hasComments && isRevising && (isActive || !isValidated),
          'p-xxs': !isTabletOrMobile && !hasComments,
          '!left-0 !ml-xxs !-top-[1rem] !-rotate-45': isTabletOrMobile,
        },
      )}
    >
      <div
        className={classNames('h-full w-full -rotate-45', {
          '!rotate-45': isTabletOrMobile,
        })}
      >
        <div
          onClick={onOpen}
          className={classNames(
            'h-full rounded-md flex',
            (!isTabletOrMobile || hasComments) && '!hidden',
          )}
        >
          <div className="h-full p-xxs bg-white shadow-ds-xl rounded-md whitespace-nowrap flex">
            <div className="whitespace-nowrap flex flex-grow items-center bg-info-50 rounded-md px-xxs text-info-400">
              <div className="mr-xxs">
                <NewComment className="shrink-0 w-md h-md" />
              </div>
              <div className="shrink-0 font-bold">Ajouter un commentaire</div>
            </div>
          </div>
        </div>

        {hasComments ? (
          <div
            onClick={onOpen}
            className="relative cursor-pointer flex items-center justify-center h-full w-full"
          >
            <Avatar
              size="sm"
              text={getInitials(senderNames[itemComments[itemComments.length - 1].senderType])}
            />

            {toReview && (
              <div className="absolute bottom-0 right-0 w-xs h-xs bg-error-800 rounded-full" />
            )}
          </div>
        ) : (
          <IconButton
            className={isTabletOrMobile && !hasComments ? 'hidden' : 'inline'}
            onClick={onOpen}
            size={hasComments ? 'md' : 'sm'}
            variant="light"
          >
            <NewComment size={16} className="text-info-400" />
          </IconButton>
        )}
      </div>
    </div>
  );
};
