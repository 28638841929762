import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { CustomerSatisfaction, Props } from './index';

type CustomerSatisfactionFieldProps = {
  name: string;
  id: string;
} & Omit<Props, 'onChange' | 'value'> &
  UseFieldConfig<number>;

const CustomerSatisfactionFieldWrapper: React.FC<
  FieldRenderProps<number> & Omit<Props, 'onChange' | 'value'>
> = ({ input: { onChange, value }, ...rest }) => (
  <CustomerSatisfaction value={value} onChange={onChange} {...rest} />
);

export const CustomerSatisfactionField: React.FC<CustomerSatisfactionFieldProps> = props => (
  <Field component={CustomerSatisfactionFieldWrapper} {...props} />
);
