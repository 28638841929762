import React from 'react';

import classNames from 'classnames';

import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';

interface Props {
  children: React.ReactNode;
  left?: boolean;
  smallWidth?: number;
  width?: number;
  height?: string;
}

export const DesktopColumn: React.FC<Props> = ({ children, left, smallWidth, width, height }) => {
  const small = useIsBelowBreakpoint('lg-desktop');

  return (
    <div
      className={classNames(
        'hidden sm-desktop:flex flex-shrink-0 flex-col bg-white',
        left ? 'border-r' : 'border-l',
      )}
      style={{
        width: small ? `${smallWidth ?? 18}rem` : `${width ?? 24}rem`,
        height: height,
      }}
    >
      {children}
    </div>
  );
};
