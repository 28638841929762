import { Level, Config } from './types';

import AlertCircle from '../../assets/AlertCircle.svg?react';
import Announcement from '../../assets/Announcement.svg?react';
import CheckCircle from '../../assets/CheckCircle.svg?react';
import InfoCircle from '../../assets/InfoCircle.svg?react';
import Warning from '../../assets/Warning.svg?react';
export const config: { [key in Level]: Config } = {
  info: {
    DefaultIcon: InfoCircle,
    containerClassnames: 'border-info-800 bg-info-50',
    iconClassnames: 'text-info-800',
  },
  success: {
    DefaultIcon: CheckCircle,
    containerClassnames: 'border-success-800 bg-success-50 ',
    iconClassnames: 'text-success-800',
  },
  warning: {
    DefaultIcon: AlertCircle,
    containerClassnames: 'border-warning-800 bg-warning-50',
    iconClassnames: 'text-warning-800',
  },
  error: {
    DefaultIcon: Warning,
    containerClassnames: 'border-error-800 bg-error-50',
    iconClassnames: 'text-error-800',
  },
  primary: {
    DefaultIcon: Announcement,
    containerClassnames: 'border-primary-400 bg-primary-400',
    iconClassnames: 'text-neutral-0',
  },
  beige: {
    DefaultIcon: Announcement,
    containerClassnames: 'border-primary-400 bg-beige-50',
    iconClassnames: 'text-primary-400',
  },
};
