import dayjs from 'dayjs';

import {
  FormValues,
  LocalisationAnswer,
  MultiSelectStepConfig,
  OnboardingSelectOption,
  QuestionType,
  SelectStepConfig,
  StepConfig,
} from '../types';

const getSelectAnswer = (step: SelectStepConfig, answer: string): string => {
  const options = typeof step.options === 'function' ? step.options(dayjs()) : step.options;
  const optionsAndSubOptions = options.reduce<OnboardingSelectOption[]>(
    (acc, option) =>
      option.subOptions ? [...acc, ...option.subOptions, option] : [...acc, option],
    [],
  );
  const selectedOption = optionsAndSubOptions.find(option => option.value === answer);

  return selectedOption ? selectedOption.formattedLabel || selectedOption.label : answer;
};

const getMultiSelectAnswer = (step: MultiSelectStepConfig, answer: string[]): string => {
  const formattedSelectedOptions = answer.map(answer => {
    const selectedOption = step.options.find(step => step.value === answer);

    return selectedOption ? selectedOption.formattedLabel || selectedOption.label : answer;
  });

  return formattedSelectedOptions.join(' / ');
};

export const getFormattedAnswer = (
  step: StepConfig,
  answer: FormValues[keyof FormValues],
): string => {
  switch (step.type) {
    case QuestionType.Select:
      return getSelectAnswer(step, answer as string);
    case QuestionType.MultiSelect:
      return getMultiSelectAnswer(step, answer as string[]);
    case QuestionType.Localisation:
      return (answer as LocalisationAnswer).value.formatted_address;
    case QuestionType.Input:
    case QuestionType.TextArea:
      return (answer as string) || 'Pas de détails supplémentaires';
    case QuestionType.NumberInput:
      return `${answer} ${step.suffix}`;
  }
};

export const getFormattedAnswers = (
  steps: StepConfig[],
  formValues: FormValues,
  currentStepId: string,
): [StepConfig[], string[]] => {
  const answeredSteps = steps.filter(
    step => Object.keys(formValues).includes(step.id) && step.id !== currentStepId,
  );
  return [
    answeredSteps,
    answeredSteps.map((answeredStep: StepConfig) =>
      getFormattedAnswer(answeredStep, formValues[answeredStep.id]),
    ),
  ];
};
