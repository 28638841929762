import React from 'react';

import classNames from 'classnames';

type Props = {
  disabled?: boolean;
  className?: string;
  small?: boolean;
  children?: React.ReactNode;
};

export const ActionListIconContainer: React.FC<Props> = ({
  disabled,
  children,
  className,
  small,
}) => (
  <span
    className={classNames('min-w-lg min-h-lg w-lg flex items-center', className, {
      '!text-neutral-300': disabled,
      'min-w-xmd min-h-xmd w-xmd': small,
    })}
  >
    {children}
  </span>
);
