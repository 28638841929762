import React from 'react';

import _groupBy from 'lodash/groupBy';
import { useFormState } from 'react-final-form';

import { SuiviClientByLots } from '@travauxlib/shared/src/features/SuiviChantier/components/ClientDisplay/SuiviClientByLots';
import { SuiviClientReadOnlyByLots } from '@travauxlib/shared/src/features/SuiviChantier/components/ClientDisplay/SuiviClientReadOnlyByLots';
import { FormData, SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { parseFormDataToGetLots } from '@travauxlib/shared/src/features/SuiviChantier/utils';

type Props = { isReadOnly?: boolean };

export const MainSuivi: React.FC<Props> = ({ isReadOnly = false }) => {
  const { values } = useFormState<FormData>();
  const lots = parseFormDataToGetLots(values);
  const groupedLots: Record<string, SuiviLot[]> = _groupBy(lots, 'label');

  const lotsWithChange = Object.values(groupedLots).flatMap(lots => {
    const changedLignes = lots.flatMap(lot => lot.lignes).filter(ligne => ligne.hasChanged);

    return changedLignes.length > 0 ? [{ ...lots[0], lignes: changedLignes }] : [];
  });

  return isReadOnly ? (
    <SuiviClientReadOnlyByLots suiviLots={lotsWithChange} />
  ) : (
    <SuiviClientByLots suiviLots={lotsWithChange} />
  );
};
