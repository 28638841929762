import { DevisLocation } from '@travauxlib/shared/src/types';

import { ItemProps } from '../types';

export const piecePrincipale = 'Pièce principale';
export const incompatibleLocationsForStudio = ['Chambre', 'Salon', 'Bureau', 'Salle à manger'];

const getDisabledReasonForPiecePrincipale = (locations: DevisLocation[]): string | undefined => {
  if (locations.some(({ typeLocation }) => typeLocation === piecePrincipale)) {
    return "Vous ne pouvez pas ajouter plus d'une pièce principale.";
  }
  if (locations.some(({ typeLocation }) => incompatibleLocationsForStudio.includes(typeLocation))) {
    return 'Vous ne pouvez pas ajouter de pièce principale car une chambre, un séjour, un bureau ou une salle à manger a déjà été sélectionnée.';
  }
  return undefined;
};

const disabledIfStudio = (locations: DevisLocation[]): string | undefined => {
  const isStudio = locations.some(({ typeLocation }) => typeLocation === piecePrincipale);
  return isStudio
    ? 'Vous ne pouvez pas ajouter cette pièce car une pièce principale a déjà été sélectionnée.'
    : undefined;
};

export const locationTypes: ItemProps[] = [
  { label: 'Autre' },
  { label: 'Bureau', getDisabledReason: disabledIfStudio },
  { label: 'Chambre', getDisabledReason: disabledIfStudio },
  { label: 'Cuisine' },
  { label: 'Dégagement' },
  { label: 'Dressing' },
  { label: 'Entrée' },
  {
    label: 'Pièce principale (studio)',
    type: 'Pièce principale',
    getDisabledReason: getDisabledReasonForPiecePrincipale,
  },
  { label: 'Salle à manger', getDisabledReason: disabledIfStudio },
  { label: "Salle d'eau" },
  { label: 'Salle de bain' },
  { label: 'Salon', getDisabledReason: disabledIfStudio },
  { label: 'WC' },
];
