import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';

import {
  ModalContextWrapper,
  ModalRoot,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal/ModalRoot';
import { ErrorFallback } from '@travauxlib/shared/src/components/ErrorFallback';
import { Helmet } from '@travauxlib/shared/src/components/Helmet';
import { ToastContainer } from '@travauxlib/shared/src/components/Notifications';
import { useConsents } from '@travauxlib/shared/src/utils/consents/ConsentsContext';
import { initAcquisitionCookies } from '@travauxlib/shared/src/utils/cookies/initAcquisitionCookies';

import { useAccount } from './api/useAccount';
import { MainRoutes } from './routes';

export const App: React.FC = () => {
  const { account, isLoading } = useAccount();
  const {
    grantedConsents: { isAdConsentGranted },
  } = useConsents();

  useEffect(() => {
    const match = window.location.pathname.match(/print\/pro\/devis/);
    const isPrintUrl = match && match.length > 0;

    if (!isPrintUrl) {
      const gtmConf = APP_CONFIG.gtm;

      TagManager.initialize({
        gtmId: 'GTM-MB6MFZL',
        auth: gtmConf.auth,
        preview: gtmConf.preview,
      });
    }
  }, []);

  useEffect(() => {
    if (account) {
      Sentry.setUser({
        username: `${account.firstName} ${account.lastName}`,
        email: account.email,
        id: account.uuid,
        comments: account.hemeaEmployee ? 'Action réalisée par un employé hemea' : undefined,
      });
    }
  }, [account?.uuid]);

  useEffect(() => {
    initAcquisitionCookies({ isAdConsentGranted });
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ModalContextWrapper>
        <Helmet>
          <meta
            name="google-site-verification"
            content="kkbnJemerlrMfnYy1c46MXyQ-np5UF8wkdPCeZiRY4U"
          />
        </Helmet>
        <main className="w-full h-full">
          <ToastContainer />
          <ModalRoot />
          <MainRoutes isLoading={isLoading} account={account} />
        </main>
      </ModalContextWrapper>
    </ErrorBoundary>
  );
};
