import React, { useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { useAccount } from 'api/useAccount';

export const DeleteAccount: React.FC = () => {
  const [deleting, setDeleting] = useState(false);
  const { deleteAccount } = useAccount();

  return (
    <>
      <div className="text-danger mb-md">Attention : la suppression du compte est définitive.</div>
      <div className="mb-xs">
        <Button onClick={() => setDeleting(deleting => !deleting)}>
          {deleting ? 'Annuler' : 'Supprimer mon compte'}
        </Button>
      </div>
      {deleting && (
        <Button onClick={() => deleteAccount()}>Confirmer la suppression du compte</Button>
      )}
    </>
  );
};
