import React from 'react';

import { useLoadScript } from '@react-google-maps/api';

import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { GooglemapsAddress, GooglemapsTypes } from '@travauxlib/shared/src/types';

import { LocationSearch } from './LocationSearch';

import { DenseSizes } from '../DesignSystem/components/Input/types';

export type Props = {
  onSubmit?: () => void;
  onChange: (address?: GooglemapsAddress) => void;
  disabled?: boolean;
  googleApiKey: string;
  types?: GooglemapsTypes[];
  initialInputValue?: string;
  onDisplayResults?: () => void;
  errorElement?: JSX.Element | null;
  resetOnBlur?: boolean;
  autoFocus?: boolean;
  autoSubmit?: boolean;
  floatingResults?: boolean;
  inputName?: string;
  id: string;
  label: string;
  error?: string;
  onlyAllowFrance?: boolean;
  onlyAllowNumber?: boolean;
  placeholder?: string;
  className?: string;
  dense?: DenseSizes;
};

const libraries = ['places'] as ['places'];

export const PlacesAutocomplete: React.FC<Props> = ({
  googleApiKey,
  types = ['geocode'],
  disabled,
  initialInputValue = '',
  id = 'address',
  floatingResults,
  error,
  dense,
  ...rest
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    language: 'fr',
    libraries,
  });

  return isLoaded ? (
    <LocationSearch
      id={id}
      data-testid={id}
      types={types}
      initialInputValue={initialInputValue}
      disabled={disabled}
      floatingResults={floatingResults}
      error={error}
      dense={dense}
      {...rest}
    />
  ) : (
    <>
      <Input
        id={id}
        data-testid="loading-googlemaps-placeholder"
        label={rest.label || 'Adresse'}
        value={initialInputValue}
        error={error}
        dense={dense}
      />
    </>
  );
};
