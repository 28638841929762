import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

type Props = {
  buildRedirectUrl: (params: any) => string;
};

export const BetterNavigate: React.FC<Props> = ({ buildRedirectUrl }) => {
  const navigate = useNavigate();
  const params = useParams();

  React.useEffect(() => {
    navigate(buildRedirectUrl(params), { replace: true });
  }, [params]);

  return <LoaderWrapper />;
};
