import React from 'react';

import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { SimpleTotal } from './SimpleTotal';

type Props = {
  prixTotalHT: number;
  activatedMarginCalculation: boolean;
  montantMargeTotale?: number;
};

export const Marge: React.FC<Props> = ({
  activatedMarginCalculation,
  prixTotalHT,
  montantMargeTotale,
}) => {
  if (!activatedMarginCalculation || montantMargeTotale === undefined) {
    return null;
  }

  const prixAchatTotal = roundToTwoDecimals(prixTotalHT - montantMargeTotale);

  return (
    <>
      <div className="!mb-xs">
        <SimpleTotal label="Prix d'achat total" total={prixAchatTotal} />
      </div>
      <div className="!mb-xs">
        <div className="!flex !justify-between">
          <div>Pourcentage de marge</div>
          <div className="!text-right">
            {prixAchatTotal ? roundToTwoDecimals((100 * montantMargeTotale) / prixAchatTotal) : 0} %
          </div>
        </div>
      </div>
      <div className="!font-bold !mb-lg">
        <SimpleTotal label="Marge totale" total={montantMargeTotale} />
      </div>
    </>
  );
};
