import { RefObject } from 'react';

export const getFirstPartiallyHiddenChildrenIndex = (
  childrens: Element[],
  rightBound: number,
): number => childrens.findIndex(tab => Math.round(tab.getBoundingClientRect().right) > rightBound);

export const getFirstCompletelyVisibleTabIndex = (tabs: Element[], leftBound: number): number =>
  tabs.findIndex(elem => Math.round(elem.getBoundingClientRect().left) >= leftBound);

export const scrollToNextHiddenChildren =
  (scrollingContainer: RefObject<HTMLElement>, nextButton: RefObject<HTMLElement>) => () => {
    if (!scrollingContainer.current || !nextButton.current) {
      return;
    }
    const containerChildrens: Element[] = Array.from(scrollingContainer.current.children);
    const firstPartiallyHiddenTabIndex = getFirstPartiallyHiddenChildrenIndex(
      containerChildrens,
      nextButton.current.getBoundingClientRect().left,
    );
    if (firstPartiallyHiddenTabIndex < 1) {
      return;
    }
    const lastVisibleTab = containerChildrens[firstPartiallyHiddenTabIndex - 1];
    const scrollAmount =
      lastVisibleTab.getBoundingClientRect().right -
      scrollingContainer.current.getBoundingClientRect().left -
      nextButton.current.getBoundingClientRect().width;
    scrollingContainer.current.scrollBy({ top: 0, left: scrollAmount, behavior: 'smooth' });
  };

export const scrollToPreviousHiddenChildren =
  (scrollingContainer: RefObject<HTMLElement>, nextButton: RefObject<HTMLElement>) => () => {
    if (!scrollingContainer.current || !nextButton.current) {
      return;
    }
    const tabs = Array.from(scrollingContainer.current.children);
    const firstCompletelyVisibleTabIndex = getFirstCompletelyVisibleTabIndex(
      tabs,
      nextButton.current?.getBoundingClientRect().right,
    );
    const scrollAmount =
      scrollingContainer.current.getBoundingClientRect().right -
      tabs[firstCompletelyVisibleTabIndex].getBoundingClientRect().left -
      nextButton.current?.getBoundingClientRect().width;
    scrollingContainer.current.scrollBy({ top: 0, left: -scrollAmount, behavior: 'smooth' });
  };

export const scrollToNthChildren = (
  scrollingContainer: RefObject<HTMLElement>,
  scrollToN: number,
): void => {
  if (!scrollingContainer.current) {
    return;
  }
  const nthElement: Element | undefined = Array.from(scrollingContainer.current.children)[
    scrollToN
  ];
  if (!nthElement) {
    return;
  }
  const nebcr = nthElement.getBoundingClientRect();
  scrollingContainer.current.scrollBy?.({
    top: 0,
    left: nebcr.left - nebcr.width,
    behavior: 'smooth',
  });
};
