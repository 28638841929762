import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { useDelayedRedirect } from 'features/DevisPro/hooks/useDelayedRedirect';
import { Facture } from 'types';

type FacturePaymentProps = {
  facture: Facture;
};

export const FacturePaymentSuccess: React.FC<FacturePaymentProps> = ({ facture }) => {
  useDelayedRedirect(APP_CONFIG.wwwURL, 15000);

  return (
    <div className="shadow-ds-sm bg-neutral-0 rounded flex items-center flex-col max-w-[440px] p-lg text-center">
      <p className="font-bold text-ds-h5 flex">Merci pour votre confiance</p>
      <p className="text-ds-b1">
        Le réglement de votre facture de {facture.prixTotalTTC}€ a été complété avec succès
      </p>
      <p className="text-ds-b1">
        Vous recevrez dans quelques minutes un email récapitulatif du réglement qui vient d'être
        effectué et vous allez être redirigé automatiquement vers notre site, si la redirection ne
        se fait pas d'ici 15 secondes cliquez sur le lien ci-dessous
      </p>
      <Link href={APP_CONFIG.wwwURL}> Revenir sur hemea.com </Link>
    </div>
  );
};
