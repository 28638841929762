import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

type Props<T> = {
  name: string;
  newItem: T;
  label: string;
  deleteLabel: string;
  children: React.FC<{
    name: string;
    index: number;
    deleteButton: JSX.Element;
  }>;
};

export const ItemAdder = <T extends object>({
  name: mainName,
  newItem,
  label,
  deleteLabel,
  children,
}: Props<T>): JSX.Element => (
  <FieldArray<T> name={mainName}>
    {({ fields }) => (
      <>
        <h3 className="mb-md">{label}</h3>
        {fields.map((name, index) => {
          const deleteButton = (
            <IconButton
              onClick={() => fields.remove(index)}
              data-testid={`${mainName}-${index}-delete`}
            >
              <Delete />
            </IconButton>
          );
          return children({ name, index, deleteButton });
        })}
        <Button variant="secondary" onClick={() => fields.push(newItem)}>
          {deleteLabel}
        </Button>
      </>
    )}
  </FieldArray>
);
