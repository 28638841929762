import React from 'react';

import classNames from 'classnames';

import { InputErrorText } from '@travauxlib/shared/src/components/DesignSystem/components/Input/commons';

import { FormControlListProps } from '../FormControl/commons';

import { Chip } from '.';

export type Props = FormControlListProps<string, string>;

export const ChipList: React.FC<Props> = ({
  options,
  value,
  onChange,
  id,
  disabled,
  renderingOptions,
  error,
}) => (
  <>
    <div className={classNames(renderingOptions?.containerClassName)} id={id}>
      {options.map(option => (
        <div key={option.label} className={classNames(renderingOptions?.elementClassName)}>
          <Chip
            data-testid={`chiplist-select-${option.value}`}
            onClick={() => onChange(option.value)}
            label={option.label!}
            disabled={disabled || option.disabled}
            selected={option.value === value}
          />
        </div>
      ))}
    </div>
    {error && <InputErrorText error={error} />}
  </>
);
