import React, { useEffect, useState } from 'react';

import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';

import { GooglemapsAddress, GooglemapsTypes } from '@travauxlib/shared/src/types';

import { InputAndResults } from './InputAndResults';

import { DenseSizes } from '../DesignSystem/components/Input/types';

declare const google: any;

type Props = {
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  types: GooglemapsTypes[];
  onChange: (address?: GooglemapsAddress) => void;
  onSubmit?: () => void;
  initialInputValue: string;
  onDisplayResults?: () => void;
  errorElement?: JSX.Element | null;
  resetOnBlur?: boolean;
  autoFocus?: boolean;
  inputName?: string;
  autoSubmit?: boolean;
  floatingResults?: boolean;
  error?: string;
  onlyAllowFrance?: boolean;
  isNewInput?: boolean;
  label: string;
  onlyAllowNumber?: boolean;
  dense?: DenseSizes;
  className?: string;
};

export const LocationSearch: React.FC<Props> = ({
  types,
  onChange,
  disabled,
  initialInputValue,
  onDisplayResults,
  resetOnBlur,
  autoFocus,
  inputName,
  autoSubmit,
  floatingResults,
  errorElement,
  error,
  onSubmit,
  id,
  onlyAllowFrance = true,
  label,
  onlyAllowNumber,
  placeholder,
  dense,
  className,
}) => {
  const [value, setValue] = useState<string>(initialInputValue);
  const handleChange = (result: GooglemapsAddress): void => {
    setValue(result.formatted_address);
    onChange(result);
  };

  // If google finds a result
  const handleGeocodeSuccess = ([result]: google.maps.GeocoderResult[]): void => {
    handleChange(result as unknown as GooglemapsAddress);

    // Is actual input value equals result from google
    if (onSubmit && value === result.formatted_address) {
      onSubmit();
    }
  };

  useEffect(() => {
    if (autoSubmit && initialInputValue) {
      geocodeByAddress(initialInputValue).then(([result]: google.maps.GeocoderResult[]) =>
        handleChange(result as unknown as GooglemapsAddress),
      );
    }
  }, []);

  useEffect(() => {
    setValue(initialInputValue);
  }, [initialInputValue]);

  // Needed to trigger "Champs obligatoire" in forms
  useEffect(() => {
    if (value.length === 0) {
      onChange();
    }
  }, [value]);

  const handleSelect = (address: string, placeId: string): void => {
    if (placeId) {
      geocodeByPlaceId(placeId).then(handleGeocodeSuccess);
    }
  };

  return (
    <>
      <div data-testid="location-search" className="!w-full">
        <PlacesAutocomplete
          value={value}
          onChange={setValue}
          onSelect={handleSelect}
          highlightFirstSuggestion
          searchOptions={{
            /*
              Ensures results are not polluted with point of interests or precise adresses.
              Should be parameterized if we want to use this component to enter complete
              adresses.
              */
            types,
            bounds: new google.maps.LatLngBounds(
              new google.maps.LatLng(41.1738841489, -5.0864994848),
              new google.maps.LatLng(51.2434209476, 9.9148090027),
            ),
            componentRestrictions: onlyAllowFrance ? { country: 'fr' } : undefined,
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <InputAndResults
              placeholder={placeholder}
              id={id}
              onDisplayResults={onDisplayResults}
              getInputProps={getInputProps}
              suggestions={suggestions}
              getSuggestionItemProps={getSuggestionItemProps}
              disabled={disabled}
              floatingResults={floatingResults}
              inputName={inputName}
              onBlur={resetOnBlur ? () => setValue(initialInputValue) : undefined}
              autoFocus={autoFocus}
              label={label}
              error={error}
              onlyAllowNumber={onlyAllowNumber}
              dense={dense}
              className={className}
            />
          )}
        </PlacesAutocomplete>
      </div>
      {errorElement}
    </>
  );
};
