import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { DetailsCard } from './DetailsCard';

import { getServiceHemeaDetails } from '../utils/constants';

const paragraphs = [
  {
    title: 'Pendant toute la durée du chantier',
    items: ['Accompagnement à distance', "Versement de l'acompte sur un compte nanti sécurisé"],
  },
  {
    title: 'Lors de la livraison du chantier',
    items: [
      "Si l'entreprise ne respecte pas le délai imparti, versement de pénalités de retard journalières",
      'Processus de médiation en cas de litige',
      'Certificat de fin de chantier et procès-verbal de réception pour déclencher le parfait achèvement',
    ],
  },
];

type Props = {
  prixServiceHemeaTTC: number;
  tauxServiceHemea: number;
  montantProtectionHemea: number;
  hasServiceHemeaIncluded: boolean;
};

export const ServicesHemea: React.FC<Props> = ({
  prixServiceHemeaTTC,
  tauxServiceHemea,
  montantProtectionHemea,
  hasServiceHemeaIncluded,
}) => (
  <div className="mb-xl break-inside-avoid" data-testid="services-hemea">
    <div className="text-ds-h4 font-medium mb-md">Services exclusifs hemea</div>
    {hasServiceHemeaIncluded ? (
      <DetailsCard
        isSelected
        title="Service hemea"
        price={<EURCurrency amount={prixServiceHemeaTTC} />}
        subPrice={`(${tauxServiceHemea}% du montant des travaux)`}
        detailRows={getServiceHemeaDetails(montantProtectionHemea)}
        isTS={false}
        hasBeenSigned={false}
      />
    ) : (
      <Card state="outlined">
        {paragraphs.map(({ title, items }) => (
          <div className="mb-sm" key={title}>
            <div className="font-bold mb-xs">{title}</div>
            <ul className="text-neutral-700 text-ds-b2 pl-md mb-0">
              {items.map(item => (
                <li key={item} className="mb-xxs last:mb-0">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Card>
    )}
  </div>
);
