import React from 'react';

import classNames from 'classnames';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { DevisFactureStatuses } from '@travauxlib/shared/src/types';
import { devisFactureStatuses } from '@travauxlib/shared/src/utils/constants';

type Props = {
  status: DevisFactureStatuses;
  onClick?: () => void;
  className?: string;
};

export const DevisFactureTag: React.FC<Props> = ({ status, onClick, className }) => {
  const badge = devisFactureStatuses[status];

  return (
    <Tag
      size="md"
      variant={badge.level}
      label={badge.label}
      onClick={onClick}
      className={classNames(className, '!block text-center text-[1rem]')}
    />
  );
};
