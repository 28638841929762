import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { LigneStatus } from '@travauxlib/shared/src/types';

type Props = {
  status?: LigneStatus;
  prixHT: number;
  showTag?: boolean;
};

export const PrixHTLabel: React.FC<Props> = ({ status, prixHT, showTag }: Props) => {
  const showParens = status && [LigneStatus.Free, LigneStatus.Option].includes(status);

  return (
    <EURCurrency
      amount={prixHT}
      crossedOut={showTag}
      prefix={showParens ? '(' : ''}
      suffix={showParens ? ')' : ''}
    />
  );
};
