import React from 'react';

import isEqual from 'react-fast-compare';
import { FormSpy, useForm, useFormState } from 'react-final-form';
import { useUpdateEffect } from 'react-use';

type Props = {
  debounce?: number;
  onSubmit?: () => void;
  skipValuesCompare?: boolean; // maybe it should be the default
};

export const DEFAULT_DEBOUNCE = 500;

const AutoSubmit: React.FC<Props> = ({
  debounce = DEFAULT_DEBOUNCE,
  onSubmit,
  skipValuesCompare,
}) => {
  const form = useForm();
  const { values, initialValues } = useFormState();

  useUpdateEffect(() => {
    if (skipValuesCompare || !isEqual(values, initialValues)) {
      const timeout = window.setTimeout(onSubmit || form.submit, debounce);
      return () => window.clearTimeout(timeout);
    }
    return undefined;
  }, [values]);

  return null;
};

export const AutoSubmitForm: React.FC<Props> = props => (
  <FormSpy subscription={{ values: true }}>{() => <AutoSubmit {...props} />}</FormSpy>
);
