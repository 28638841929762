import { ActionItem } from '../ActionItem';
import { Divider } from '../Divider';
import { OptionItem } from '../OptionItem';
import { SubMenuItem } from '../SubMenuItem';
import { ActionListItem } from '../type';

export const itemMatcher = (
  item: ActionListItem,
  close: () => void,
  small?: boolean,
): JSX.Element | undefined => {
  switch (item.type) {
    case 'action':
      return <ActionItem small={small} key={item.label} {...item} closeActionList={close} />;
    case 'option':
      return <OptionItem small={small} key={item.label} {...item} closeActionList={close} />;
    case 'divider':
      return <Divider key={item.label} />;
    case 'submenu':
      return <SubMenuItem small={small} key={item.label} {...item} />;
    default:
      return undefined;
  }
};
