import React from 'react';

import { useFormState } from 'react-final-form';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { required } from '@travauxlib/shared/src/utils/form';

import { Chantier } from 'features/EspaceClient3/types';

type Props = {
  chantier: Chantier;
};

export const HasReservesField: React.FC<Props> = ({ chantier }) => {
  const { values } = useFormState();

  const remainingPourcentage = chantier.leveeReservesThreshold
    ? 100 - chantier.leveeReservesThreshold
    : undefined;
  return (
    <div>
      <div className="mb-md text-neutral-700 text-ds-b1">
        Avez-vous renseigné des réserves dans le procès-verbal ?
      </div>
      <div className="mb-md">
        <ChipListField
          renderingOptions={{ containerClassName: 'flex gap-xs' }}
          name="hasReserves"
          validate={required}
          options={[
            {
              label: 'Sans réserves',
              value: false as any,
            },
            {
              label: 'Avec réserves',
              value: true as any,
            },
          ]}
        />
        {values.hasReserves && !!remainingPourcentage && (
          <InlineAlert level="info">
            {remainingPourcentage}% du montant total du chantier restera gelé sur votre compte
            séquestre après validation du document
          </InlineAlert>
        )}
      </div>
    </div>
  );
};
