import React from 'react';

import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';
import { Prestations } from '@travauxlib/shared/src/types';

type Props = {
  totaux: { montantDejaPaye: number; montantRestantAPayer: number };
  facture: Prestations;
  isCloture?: boolean;
};

export const FacturationInfos: React.FC<Props> = ({ totaux, facture, isCloture }) => {
  if (facture.isAvoir) {
    return null;
  }

  const montantDejaPaye = isCloture
    ? totaux.montantDejaPaye + (facture.montantDejaPaye || 0)
    : facture.montantDejaPaye || 0;

  const montantRestantAPayer = isCloture
    ? totaux.montantRestantAPayer
    : facture.montantRestantAPayer;

  if (!montantRestantAPayer && !isCloture) {
    return null;
  }

  return (
    <>
      {!!totaux.montantRestantAPayer && isCloture && (
        <SimpleTotal label="Déjà facturé" total={montantDejaPaye} />
      )}
      <SimpleTotal label="Déjà payé" total={montantDejaPaye} />
      <SimpleTotal label="Montant à payer" total={facture.montantRestantAPayer || 0} />
    </>
  );
};
