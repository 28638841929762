import { SenderType } from '@travauxlib/shared/src/types';

type Payload = {
  isHemeaEmployee: boolean;
  isAuthor?: boolean;
};

export const getSenderType = ({ isHemeaEmployee, isAuthor }: Payload): SenderType => {
  if (isHemeaEmployee) {
    return 'deal-owner';
  }
  if (isAuthor) {
    return 'pro';
  }
  return 'client';
};
