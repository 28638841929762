import React from 'react';

import { BonCommandeLotsSommaire } from '@travauxlib/shared/src/features/BonCommande/components/BonCommandeLotsSommaire';
import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';

import { CompanyInfos } from 'components/DocumentHeader/CompanyInfos';
import { BonCommandeClient } from 'types';

import { ContractorInformations } from './ContractorInformations';

type Props = {
  bonCommande: BonCommandeClient;
};

export const BonCommandeContentHeader: React.FC<Props> = ({ bonCommande }) => (
  <>
    <div className="flex justify-between pb-lg flex-wrap sm-desktop:flex-nowrap">
      {bonCommande.proCompany && <CompanyInfos companyInfo={bonCommande.proCompany} />}
      {bonCommande.contractor ? (
        <div className="inline-block bg-lighter p-md mt-md sm-desktop:mt-0">
          <p>A l'attention de :</p>
          <div className="font-bold">{bonCommande.contractor.name}</div>
          <div className="font-bold">{bonCommande.contractor.fullname}</div>
          <div>
            {bonCommande.contractor.billingFormattedAddress} <br />
          </div>
          <ContractorInformations contractor={bonCommande.contractor} />
        </div>
      ) : null}
    </div>
    <div className="text-ds-h4 pb-lg">{bonCommande.title}</div>
    <div className="flex justify-end">
      <div className="border-1 p-sm sm-desktop:w-2/5">
        <BonCommandeLotsSommaire title="Récapitulatif des lots" lots={bonCommande.lots} />
        <div className="mb-xs text-ds-h5 font-bold">
          <SimpleTotal label="Total HT" total={bonCommande.prixTotalHT} />
        </div>
      </div>
    </div>
  </>
);
