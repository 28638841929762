import React from 'react';

import { useDevisDisplayContext } from '../DevisDisplayContext';

export const PageBreak: React.FC = () => {
  const { isProPreview } = useDevisDisplayContext();

  if (isProPreview) {
    return (
      <div className="p-xs mb-xs relative flex break-inside-avoid rounded-xxs">
        <div className="bg-page-break bg-[length:1rem_1rem] mx-md flex flex-col justify-center overflow-hidden text-center whitespace-nowrap bg-light py-xxs text-dark">
          Saut de page
        </div>
      </div>
    );
  }
  return null;
};
