import { BadgeSizes } from './types';

export const MAX_NOTIF = 99;

export const variantsClassNames = {
  light: 'text-neutral-800 bg-neutral-100 border',
  primary: 'text-neutral-0 bg-primary-400',
  error: 'text-neutral-0 bg-error-800',
};

export const getClassNamesBySize = (value: number): { [K in BadgeSizes]: string } => ({
  md: value > MAX_NOTIF ? 'w-xl h-lg' : 'w-lg h-lg',
  sm: value > MAX_NOTIF ? 'w-[30px] h-md' : 'w-md h-md',
  xs: 'w-xs h-xs',
});
