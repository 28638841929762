import React from 'react';

import Gift from '@travauxlib/shared/src/components/DesignSystem/assets/Gift.svg?react';
import Swap from '@travauxlib/shared/src/components/DesignSystem/assets/Swap.svg?react';
import { Tag, Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { SuiviLigne } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { LigneStatus } from '@travauxlib/shared/src/types';

type Props = {
  ligne: SuiviLigne;
};

type TagTSConfig = { [key in string]: { label: string; icon: JSX.Element; variant: Variant } };

const config: TagTSConfig = {
  modified: { label: 'Modifié', icon: <Swap />, variant: 'info' },
  free: { label: 'Offert', icon: <Gift />, variant: 'success' },
};

const TagTemplate: React.FC<{ ligneStatus: LigneStatus }> = ({ ligneStatus }) => (
  <Tag
    label={config[ligneStatus].label}
    variant={config[ligneStatus].variant}
    size="md"
    icon={config[ligneStatus].icon}
  />
);

export const TagTS: React.FC<Props> = ({ ligne }) => (
  <>
    {ligne.status === LigneStatus.Free && <TagTemplate ligneStatus={LigneStatus.Free} />}
    {ligne.isModifyingAnotherLigne && <TagTemplate ligneStatus={LigneStatus.Modified} />}
  </>
);
