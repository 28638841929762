import jsCookie from 'js-cookie';

import { ConsentPayload } from '@travauxlib/shared/src/types';
import { Cookie, defaultCookieAttributes } from '@travauxlib/shared/src/utils/cookies/constants';

import { ConsentEvent } from './constants';

const updateConsentOnGTM = (consentPayload: ConsentPayload): void =>
  window?.updateConsent?.(consentPayload);
const updateConsentCookie = (consentPayload: ConsentPayload): string | undefined =>
  jsCookie.set(Cookie.Consents, JSON.stringify(consentPayload), defaultCookieAttributes);

export const updateConsents = (consentPayload: ConsentPayload): void => {
  updateConsentOnGTM(consentPayload);
  updateConsentCookie(consentPayload);
};

export const sendConsentsEventToGTM = (action: 'accepted' | 'modal.opened'): number | undefined =>
  window?.dataLayer?.push({
    event: ConsentEvent.Consents,
    action,
  });
