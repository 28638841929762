import React from 'react';

import Phone from '@travauxlib/shared/src/components//DesignSystem/assets/Phone.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

export const FinancialReassuranceBlock: React.FC = () => (
  <div className="mt-md py-md bg-neutral-0 rounded-xxs px-md shadow-ds-xxs tablet:px-lg">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-ds-b2 font-bold mb-0">Besoin d'aide ?</p>
        <p className="text-ds-xs mb-0">Lundi au vendredi - 9h30 à 18h30</p>
      </div>
      <a href="tel:0182285600" className="shrink-0">
        <Button variant="tertiary" leftIcon={<Phone />} size="sm">
          01 82 28 56 00
        </Button>
      </a>
    </div>
    <hr className="border-neutral-300 my-xs" />
    <div className="flex items-center justify-between">
      <div>
        <p className="text-ds-b2 font-bold mb-0">Nos partenaires</p>
        <p className="text-ds-xs mb-0">Ils nous font confiance !</p>
      </div>
      <div className="flex items-center">
        <img
          className="mr-xxs"
          src={`${baseImgixUrl}/static/partenaires/logo-bnp-bw.svg`}
          width={32}
          height={32}
          alt="Logo BNP Paribas"
        />
        <img
          className="mr-xxs"
          src={`${baseImgixUrl}/static/partenaires/logo-yousign-bw.svg`}
          width={32}
          height={32}
          alt="Logo Yousign"
        />
        <img
          src={`${baseImgixUrl}/static/partenaires/logo-lemonway-bw.svg`}
          width={32}
          height={32}
          alt="Logo Lemonway"
        />
      </div>
    </div>
  </div>
);
