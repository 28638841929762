import { roundToOneDecimal, roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { QuantityComputerId, DevisLocation } from '../types';

export type QuantityComputer = (location: DevisLocation) => number;

export const surfaceSolQuantityComputer: QuantityComputer = (location: DevisLocation) =>
  location.surface;

export const longueurMurQuantityComputer: QuantityComputer = (location: DevisLocation) =>
  location.longueurMurs;

export const surfaceMursQuantityComputer: QuantityComputer = (location: DevisLocation) =>
  location.surfaceMurs;

// Je remets le CORRECTION_COEFF suite à une demande de Béné,
// je sais plus pourquoi on m'avait fait le retirer

const CORRECTION_COEFF = 1.1;
export const estimatePerimetre = (surface: number): number =>
  roundToTwoDecimals(Math.sqrt(surface) * 4 * CORRECTION_COEFF);

export const perimetreWithSurfaceQuantityComputer = (surface: number): number =>
  roundToOneDecimal(estimatePerimetre(surface));

export const perimetreQuantityComputer: QuantityComputer = (location: { surface: number }) =>
  perimetreWithSurfaceQuantityComputer(location.surface);

export const estimateSurfaceMurs = (
  location: { surface: number },
  hauteurSousPlafond: number,
): number =>
  roundToOneDecimal(perimetreWithSurfaceQuantityComputer(location.surface) * hauteurSousPlafond);

export const quantityComputersById: { [K in QuantityComputerId]?: QuantityComputer } = {
  sols: surfaceSolQuantityComputer,
  murs: surfaceMursQuantityComputer,
  perimetre: longueurMurQuantityComputer,
};
