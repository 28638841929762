import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Modal } from './Modal';
import { Props } from './types';

type ModalProps = { closable?: boolean } & Omit<Props, 'children'>;

type ModalState = {
  ModalComponent: React.ComponentType<any>;
  isOpen: boolean;
  modalProps: ModalProps;
};

const defaultModalState = {
  modalProps: { title: '' },
  isOpen: false,
  ModalComponent: () => null,
};

const ModalContext = React.createContext<
  ModalState & {
    closeModals: () => void;
    openModal: <P extends object>(
      ModalComponent: React.ComponentType<P>,
      modalProps: ModalProps & Omit<P, 'handleClose'>,
    ) => void;
  }
>({
  ...defaultModalState,
  closeModals: () => {},
  openModal: () => {},
});

export const useOpenModal = (): typeof handler => {
  const { openModal } = useContext(ModalContext);

  const handler = <P extends object>(
    ModalComponent: React.ComponentType<P>,
    modalProps: ModalProps & Omit<P, 'handleClose'>,
  ): void => openModal(ModalComponent, modalProps);

  return handler;
};

export const ModalContextWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>(defaultModalState);

  return (
    <ModalContext.Provider
      value={{
        ...modalState,
        closeModals: () => setModalState(modalState => ({ ...modalState, isOpen: false })),
        openModal: <P extends object>(
          ModalComponent: React.ComponentType<P>,
          modalProps: ModalProps & Omit<P, 'handleClose'>,
        ) => setModalState({ ModalComponent, modalProps, isOpen: true }),
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalRoot: React.FC = () => {
  const { ModalComponent, isOpen, modalProps, closeModals } = useContext(ModalContext);
  const location = useLocation();

  useEffect(() => {
    closeModals();
  }, [location.pathname]);

  const onClose = modalProps?.closable ? closeModals : undefined;

  return (
    <Modal isOpen={isOpen} handleClose={onClose} {...modalProps}>
      <ModalComponent handleClose={closeModals} {...modalProps} />
    </Modal>
  );
};
