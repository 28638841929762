import React, { useEffect } from 'react';

import { BonCommandeContent } from 'features/BonCommande/components/BonCommandeContent';
import { BonCommandeClient } from 'types';

type Props = {
  bonCommande: BonCommandeClient;
};

export const BonCommandePrint: React.FC<Props> = props => {
  const bonCommande = props.bonCommande;

  useEffect(() => {
    window.status = 'ready';
  }, []);

  return <BonCommandeContent bonCommande={bonCommande} />;
};
