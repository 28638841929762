import React, { useCallback } from 'react';

import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';

import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import Logout from '@travauxlib/shared/src/components/DesignSystem/assets/Logout.svg?react';
import Project from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { ClientAccount } from '@travauxlib/shared/src/types';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { useProjects } from 'features/EspaceClient3/api/useProjects';
import { useOpenMyAdviserModal } from 'features/EspaceClient3/hooks/useOpenMyAdviserModal';
import { useOpenSwitchProjectModal } from 'features/EspaceClient3/hooks/useOpenSwitchProjectModal';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { HeaderContainer } from './HeaderContainer';

type Props = { account: ClientAccount; logout: () => void; projectUuid: string };

export const ProjectHeader: React.FC<Props> = ({ account, logout, projectUuid }) => {
  const { projects } = useProjects();
  const navigate = useNavigate();
  const openMyAdviserModal = useOpenMyAdviserModal();
  const openSwitchProjectModal = useOpenSwitchProjectModal();

  const getHelpItems = useCallback<() => ActionListItem[]>(
    () => [
      {
        type: 'action',
        label: "Demander de l'aide",
        onClick: () => {
          EspaceClientTracking.onEspaceClientAskForHelpClicked();
          openMyAdviserModal({ projectUuid });
        },
      },
      { type: 'action', label: 'Consulter nos guides', onClick: () => navigate('guides') },
    ],
    [projectUuid],
  );

  const getAccountItems = useCallback<() => ActionListItem[]>(
    () => [
      ...(projects.length > 1
        ? [
            {
              type: 'action',
              icon: <Project />,
              label: 'Changer de projet',
              onClick: () => openSwitchProjectModal({ projects, currentProjectUuid: projectUuid }),
            } as ActionListItem,
          ]
        : []),
      {
        type: 'action',
        icon: <Logout />,
        label: 'Déconnexion',
        onClick: () => {
          EspaceClientTracking.onEspaceClientLoggedOut();
          logout();
        },
      },
    ],
    [projectUuid, projects],
  );

  return (
    <HeaderContainer>
      <Link to="/" className="flex items-center h-xxl">
        <ImgLazy
          url={`${baseImgixUrl}/static/logo-hemea-black-v2.png?h=32`}
          height={32}
          alt="logo hemea"
          eagerLoading
        />
      </Link>
      <div className="flex items-center">
        <ActionList
          trigger={(toggle, referenceProps, isOpen) => (
            <IconButton
              onClick={toggle}
              {...referenceProps}
              size="lg"
              className={classNames({
                '!bg-neutral-100': isOpen,
              })}
              aria-label="Help"
            >
              <HelpCircleOutline />
            </IconButton>
          )}
          items={getHelpItems()}
        />
        <ActionList
          trigger={(toggle, referenceProps, isOpen) => (
            <div
              {...referenceProps}
              onClick={toggle}
              data-testid="avatar-user-header"
              className={classNames(
                'p-xs rounded-full hover:bg-neutral-100 active:bg-neutral-200',
                {
                  'bg-neutral-100': isOpen,
                },
              )}
              role="button"
            >
              <Avatar firstName={account.firstName} lastName={account.lastName} />
            </div>
          )}
          items={getAccountItems()}
        />
      </div>
    </HeaderContainer>
  );
};
