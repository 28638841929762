import React, { ReactNode } from 'react';

import classNames from 'classnames';
import Linkify from 'react-linkify';
/**
 * Transform a multiline string in an HTML paragraph with <a> tags for detected links.
 * One \n = <br>
 * Two \n = <p>
 */

type Props = {
  text?: string;
  paragraphClassName?: string;
  lineClassName?: string;
  hasLinksOpenedInNewWindow?: boolean;
};

export const MultilineParagraph: React.FC<Props> = ({
  text,
  paragraphClassName,
  lineClassName = '',
  hasLinksOpenedInNewWindow,
}) => {
  const [bundleLoaded, setBundleLoaded] = React.useState(false);

  React.useEffect(() => {
    setBundleLoaded(true);
  }, []);

  if (!text) {
    return null;
  }

  const LinkifyOrOverride = !bundleLoaded
    ? (props: { className: string; children?: ReactNode; properties: any }) => (
        <span className={props.className}>{props.children}</span>
      )
    : Linkify;

  return (
    <>
      {text.split('\n\n').map((paragraph, i) => {
        const lines = paragraph.split('\n');

        const data = lines.map((item, j) => (
          <LinkifyOrOverride
            className={lineClassName}
            properties={{
              rel: 'noreferrer noopener',
              target: hasLinksOpenedInNewWindow ? '_blank' : '_self',
            }}
            key={i + j}
          >
            {lines.length === 1 || (j !== 0 && <br />)}
            {item}
          </LinkifyOrOverride>
        ));

        return (
          <p
            key={i}
            className={classNames(paragraphClassName, '!break-words text-ds-b2 last:mb-0')}
          >
            {data}
          </p>
        );
      })}
    </>
  );
};
