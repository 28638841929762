import React from 'react';

import { Remises } from '@travauxlib/shared/src/features/LDDToolbar/components/Remises';
import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';
import { Prestations } from '@travauxlib/shared/src/types';

type Props = {
  prestations: Prestations;
};

export const Prices: React.FC<Props> = ({ prestations }) => (
  <>
    <SimpleTotal
      label={prestations.montantRemise ? 'Total HT avant remise' : 'Total travaux HT'}
      total={
        prestations.montantRemise ? prestations.prixTotalHTAvantRemise : prestations.prixTotalHT
      }
    />
    {!!prestations.montantRemise && (
      <Remises
        remises={prestations.remises}
        prixTotalHTAvantRemise={prestations.prixTotalHTAvantRemise}
        prixTotalTTCAvantRemise={prestations.prixTotalTTCAvantRemise}
        prixTotalHT={prestations.prixTotalHT}
      />
    )}
    {prestations.showFournitures && !!prestations.prixTotalFournitureHT && (
      <SimpleTotal
        className="text-ds-b2 text-neutral-700"
        label="Dont fournitures HT"
        total={prestations.prixTotalFournitureHT}
      />
    )}
    {prestations.showMainOeuvre && !!prestations.prixTotalMainOeuvreHT && (
      <SimpleTotal
        className="text-ds-b2 text-neutral-700"
        label="Dont main d'œuvre HT"
        total={prestations.prixTotalMainOeuvreHT}
      />
    )}
  </>
);
