import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { EstimateurPublic } from 'features/Estimateur/features/Public/Loadable';

import { Header } from '../Header';

export const EstimateurCreditDuNord: React.FC = () => (
  <>
    <EstimateurPublic
      header={({ toggleRecallModal }) => (
        <Header
          toggleRecallModal={toggleRecallModal}
          logo={
            <ImgLazy
              alt="Crédit du Nord"
              url={`${baseImgixUrl}/static/estimateur/logo-credit-du-nord.png`}
              height={45}
            />
          }
        />
      )}
      affiliateToken={APP_CONFIG.creditDuNordToken}
    />
  </>
);
