import React, { useState, useEffect } from 'react';

export const WaitMessage: React.FC<{ waitInterval: number }> = ({ waitInterval }) => {
  const [waitStep, setWaitStep] = useState(0);
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setWaitStep(step => step + 1);
    }, waitInterval);
    return () => window.clearTimeout(timeout);
  }, [waitStep]);

  if (waitStep === 0) {
    return <div>Veuillez patienter...</div>;
  }
  if (waitStep === 1) {
    return <div>Cela ne devrait plus prendre très longtemps...</div>;
  }

  return <div>Nous y sommes presque...</div>;
};
