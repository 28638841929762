import React from 'react';

import ChecklistPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ChecklistPicto.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

export const EmptyAction: React.FC = () => (
  <div className="text-center">
    <EmptyState
      className="mb-md sm-desktop:mb-xl"
      illustration={<ChecklistPicto />}
      title="Bravo !"
      description="Toutes les tâches sont terminées"
    />
  </div>
);
