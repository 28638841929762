import storePlugins from 'store/plugins/defaults';
import storeEngine from 'store/src/store-engine';
import storeLocalStorage from 'store/storages/localStorage';
import storeMemoryStorage from 'store/storages/memoryStorage';
import storeSessionStorage from 'store/storages/sessionStorage';

const storages = [storeLocalStorage, storeSessionStorage, storeMemoryStorage];

const plugins = [storePlugins];

export const storage = storeEngine.createStore(storages, plugins);
