import _flatMap from 'lodash/flatMap';
import _groupBy from 'lodash/groupBy';
import _sum from 'lodash/sum';

import { LigneStatus, Remises } from '@travauxlib/shared/src/types';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { getMontantAvancementWithThresholdAndMontantGele } from './getMontantAvancementWithThresholdAndMontantGele';

import { SuiviChantierComputed, SuiviLot } from '../types';

import { createMontantsTVA, getMontantHTByAvancementForLots } from '.';

type Props = {
  lots: SuiviLot[];
  remisesMapByDevisToken: Record<string, Remises>;
  montantRemise: number;
  montantDejaPaye: number;
  totalMontantTVA: number;
  threshold?: number;
};

export const computeSuiviChantierAmounts = ({
  lots,
  remisesMapByDevisToken,
  montantRemise,
  montantDejaPaye,
  totalMontantTVA,
  threshold,
}: Props): SuiviChantierComputed => {
  const lotsWithoutFreeLignes = lots.map(lot => ({
    ...lot,
    lignes: lot.lignes.filter(ligne => ligne.status !== LigneStatus.Free),
  }));
  const lotsGroupedByDevis = _groupBy(lotsWithoutFreeLignes, 'devisToken');

  const montantsTVAByDevis = _flatMap(
    Object.entries(lotsGroupedByDevis),
    ([devisToken, lotsWithoutFreeLignes]) =>
      createMontantsTVA(lotsWithoutFreeLignes, remisesMapByDevisToken[devisToken]),
  );

  const montantsTVA = Object.entries(_groupBy(montantsTVAByDevis, 'taux')).map(
    ([taux, montantsTVA]) => ({
      taux: Number(taux),
      base: _sum(montantsTVA.map(montantTVA => montantTVA.base)),
      montant: _sum(montantsTVA.map(montantTVA => montantTVA.montant)),
    }),
  );

  const trueTotalTVA = _sum(montantsTVA.map(tauxTVA => tauxTVA.montant));
  // Safeguard. Whatever rounding error shenanigans, always bill at most what the client signed on devis
  const totalTVA = trueTotalTVA >= totalMontantTVA ? totalMontantTVA : trueTotalTVA;

  const montantAvancementGlobalTTC =
    getMontantHTByAvancementForLots(lotsWithoutFreeLignes) + totalTVA - montantRemise;

  const atLeastOneLineNotFreeHasChanged = checkIfLinesChanged(lotsWithoutFreeLignes);
  const atLeastOneLineHasChanged = checkIfLinesChanged(lots);

  const { montantAvancementWithThreshold, montantGele } =
    getMontantAvancementWithThresholdAndMontantGele({
      montantTotalChantier: _sum(lots.map(lot => _sum(lot.lignes.map(ligne => ligne.prixTTC)))),
      threshold,
      montantAvancementGlobalTTCWithCorrection: montantAvancementGlobalTTC,
      montantDejaPaye,
    });

  const montantALibererTTC = montantAvancementWithThreshold - montantDejaPaye;

  const isSuiviWithoutThreshold = !threshold || threshold === 100;
  const shouldShowRealMontantALiberer =
    atLeastOneLineNotFreeHasChanged && (isSuiviWithoutThreshold || montantALibererTTC > 0);

  return {
    montantsTVA,
    totalTVA: roundToTwoDecimals(totalTVA),
    montantAvancementGlobalTTC: roundToTwoDecimals(montantAvancementGlobalTTC),
    montantALibererTTC: shouldShowRealMontantALiberer ? roundToTwoDecimals(montantALibererTTC) : 0,
    montantRemise: roundToTwoDecimals(montantRemise),
    montantDejaPaye: roundToTwoDecimals(montantDejaPaye),
    montantGele: roundToTwoDecimals(montantGele),
    atLeastOneLineHasChanged,
  };
};

const checkIfLinesChanged = (lots: SuiviLot[]): boolean =>
  lots
    .flatMap(l => l.lignes)
    .some(
      l =>
        l.previousCyclePourcentageAvancement !== l.pourcentageAvancement ||
        l.previousCycleHasFournitures !== l.hasFournitures,
    );
