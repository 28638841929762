import React from 'react';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import PaperSearch from '@travauxlib/shared/src/components/DesignSystem/assets/PaperSearch.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

type Props = {
  onClickEdit: () => void;
};

const getMenuItems = (onClickEdit: () => void): ActionListItem[] => [
  // TODO brancher l'action de consulter quand on fera le ticket bonus qui gère ça
  { type: 'action', icon: <PaperSearch />, label: 'Consulter', onClick: onClickEdit },
  { type: 'action', icon: <Edit />, label: 'Modifier', onClick: onClickEdit },
];

export const DocumentMenu: React.FC<Props> = ({ onClickEdit }) => (
  <ActionList
    items={getMenuItems(onClickEdit)}
    trigger={(toggle, referenceProps) => (
      <IconButton
        onClick={toggle}
        {...referenceProps}
        className="-mr-xxs"
        dataTestId="document-actions"
      >
        <DotsMenu />
      </IconButton>
    )}
  />
);
