import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { UserTracking } from '@travauxlib/shared/src/api/UserTracking';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ClientAccount } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { TrackingIdentify } from '../utils/tracking';

export const AUTH_KEY = 'auth';

const handleLogout = (): Promise<void> => {
  const url = `${APP_CONFIG.apiURL}/client/logout`;
  return request(url, { method: 'POST' });
};

const handleLogin = (body: { email: string; password: string }): Promise<ClientAccount> => {
  const url = `${APP_CONFIG.apiURL}/client/login`;
  return request(url, {
    method: 'POST',
    body,
  });
};

const handleRequestPasswordReset = (body: { email: string }): Promise<void> => {
  const url = `${APP_CONFIG.apiURL}/client/request-password-reset`;
  return request(url, {
    method: 'POST',
    body,
  });
};

const handleResetPassword = (body: {
  email: string;
  token: string;
  password: string;
}): Promise<ClientAccount> => {
  const url = `${APP_CONFIG.apiURL}/client/reset-password`;
  return request(url, {
    method: 'POST',
    body,
  });
};

const handleDeleteAccount = (): Promise<void> => {
  const url = `${APP_CONFIG.apiURL}/client/account`;
  return request(url, {
    method: 'DELETE',
  });
};

const handleMarkHasSeenOnboardingRevision = (): Promise<void> => {
  const url = `${APP_CONFIG.apiURL}/client/mark-onboarding-seen`;
  return request(url, { method: 'POST' });
};

export const useAccount = (): {
  account?: ClientAccount;
  isLoading: boolean;
  login: typeof handleLogin;
  logout: typeof handleLogout;
  resetPassword: typeof handleResetPassword;
  requestPasswordReset: typeof handleRequestPasswordReset;
  isAuthenticated: boolean;
  deleteAccount: typeof handleDeleteAccount;
  markHasSeenOnboardingRevision: typeof handleMarkHasSeenOnboardingRevision;
} => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: account, isLoading } = useQuery<ClientAccount>({
    queryKey: [AUTH_KEY],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.apiURL}/client/account`;
      const account = await request(requestURL);
      TrackingIdentify(account);
      return account;
    },
    staleTime: Infinity,
  });

  const { mutateAsync: logout } = useMutation({
    mutationFn: handleLogout,
    onSuccess: () => {
      queryClient.removeQueries();
      navigate('/');
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la déconnexion');
    },
  });

  const { mutateAsync: login } = useMutation({
    mutationFn: handleLogin,
    onSuccess: (account: ClientAccount) => {
      queryClient.setQueryData([AUTH_KEY], account);
      TrackingIdentify(account);
      UserTracking.people.set('Last login', new Date().toISOString());
    },
  });

  const { mutateAsync: requestPasswordReset } = useMutation({
    mutationFn: handleRequestPasswordReset,
  });

  const { mutateAsync: resetPassword } = useMutation({
    mutationFn: handleResetPassword,
    onSuccess: (account: ClientAccount) => {
      queryClient.setQueryData([AUTH_KEY], account);
    },
  });

  const { mutateAsync: deleteAccount } = useMutation({
    mutationFn: handleDeleteAccount,
    onSuccess: () => {
      queryClient.setQueryData([AUTH_KEY], null);
      toast.success('Votre compte a été supprimé avec succès.');
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du compte, veuillez réessayer ultérieurement.');
    },
  });

  const { mutateAsync: markHasSeenOnboardingRevision } = useMutation({
    mutationFn: handleMarkHasSeenOnboardingRevision,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
    },
  });

  return {
    account,
    isLoading,
    isAuthenticated: !!account,
    logout,
    login,
    requestPasswordReset,
    resetPassword,
    deleteAccount,
    markHasSeenOnboardingRevision,
  };
};
