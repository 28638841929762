import { ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';

export const getPvStatusTag = (
  pv: ChantierPvWithDocuments,
): {
  label: string;
  variant: 'warning' | 'success' | 'error';
} => {
  if (pv.isRejected) {
    return {
      label: 'Refusé',
      variant: 'error',
    };
  }
  if (pv.isValidated) {
    return {
      label: 'Validé',
      variant: 'success',
    };
  }
  return {
    label: 'À valider',
    variant: 'warning',
  };
};
