import React, { useRef } from 'react';

import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';

import { RawModal } from './RawModal';
import { Props } from './types';
import { modalConfigBySize } from './utils';

let modalRoot = document.getElementById('modal-root');
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal-root');
  document.body.appendChild(modalRoot);
}

export const Modal: React.FC<Props & { isOpen: boolean }> = ({ isOpen, ...rest }) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const isBelowDesktopSM = useIsBelowBreakpoint('sm-desktop');
  const config = modalConfigBySize[isBelowDesktopSM ? 'tabletAndMobile' : 'desktop'];

  if (!modalRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    <Transition
      nodeRef={nodeRef}
      in={isOpen}
      timeout={{ enter: 0, exit: config.transitionDuration }}
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <RawModal
          {...rest}
          ref={nodeRef}
          isMounted={state === 'entered'}
          isBelowDesktopSM={isBelowDesktopSM}
        />
      )}
    </Transition>,
    modalRoot,
  );
};
