import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { UnitValue } from '@travauxlib/shared/src/types';
import { units } from '@travauxlib/shared/src/utils/units';

type Props = {
  unite: UnitValue;
  prixUnitaireHT: number;
};

export const PrixUnitaireLabel: React.FC<Props> = ({ unite, prixUnitaireHT }) => {
  const unit = units[unite];
  const uniteLabel = `/${unit.unitaire ? 'u' : unit.label}`;
  return (
    <>
      <EURCurrency amount={prixUnitaireHT} />
      {uniteLabel}
    </>
  );
};
