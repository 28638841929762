import React from 'react';

import { Prime } from '@travauxlib/shared/src/types';

import { SimpleTotal } from './SimpleTotal';

type Props = {
  primes: Prime[];
  withEllipsisOnLabel?: boolean;
};

export const Primes: React.FC<Props> = ({ primes, withEllipsisOnLabel }) => (
  <>
    {primes.map(prime => (
      <SimpleTotal
        key={prime.label}
        data-testid={prime.label}
        label={prime.label}
        total={-prime.amount}
        withEllipsisOnLabel={withEllipsisOnLabel}
      />
    ))}
  </>
);
