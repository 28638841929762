import React from 'react';

import { Devis } from 'types';

import { LegacyServicesOptionnels } from './components/LegacyServicesOptionnels';
import { OfferDetails } from './components/OfferDetails';
import { ServicesHemea } from './components/ServicesHemea';

type Props = {
  devis: Devis;
  isReadOnly: boolean;
};

export const ModalitesHemea: React.FC<Props> = ({ devis, isReadOnly }) => (
  <>
    {devis.prixTotalTTC >= 0 && (
      <ServicesHemea
        prixServiceHemeaTTC={devis.prixServiceHemeaTTC}
        tauxServiceHemea={devis.tauxServiceHemea}
        montantProtectionHemea={devis.montantProtectionHemea}
        hasServiceHemeaIncluded={devis.hasServiceHemeaIncluded}
      />
    )}
    {devis.hasServiceHemeaIncluded ? (
      <OfferDetails
        selectedTypeSuivi={devis.defaultTypeSuivi}
        prixTravauxPlannerTTC={devis.prixTravauxPlannerTTC}
        tauxTravauxPlanner={devis.tauxTravauxPlanner}
        isTS={!!devis.proContext?.isTS}
        hasBeenSigned={!!devis.dateSignature}
      />
    ) : (
      <LegacyServicesOptionnels isReadOnly={isReadOnly} devis={devis} />
    )}
  </>
);
