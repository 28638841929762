import { GooglemapsAddress } from '@travauxlib/shared/src/types';

import { useIsLocationAddressable } from '../api/useIsLocationAddressable';
import { useIsEstimateurPublic, useWhiteLabelPartner } from '../utils/paths';

export const useShouldShowRecallButton = (googleMapsAddress?: GooglemapsAddress): boolean => {
  const isEstimateurPublic = useIsEstimateurPublic();
  const isEstimateurWhiteLabelPartner = !!useWhiteLabelPartner();

  const isLocationAddressable = !!useIsLocationAddressable(googleMapsAddress);

  return isLocationAddressable && (isEstimateurPublic || isEstimateurWhiteLabelPartner);
};
