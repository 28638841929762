import React from 'react';

import classNames, { Argument } from 'classnames';

export type FormControlProps<T> = {
  value?: T;
  onChange?: (isChecked: T) => void;
  label?: string | JSX.Element;
  indeterminate?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

export type FormControlListOption<T> = {
  value: T;
  label?: string;
  disabled?: boolean;
  default?: boolean;
  tooltip?: string;
};

export type FormControlListProps<GlobalValue, ItemValue = GlobalValue> = {
  className?: string;
  optionsClassName?: string;
  inline?: boolean;
  options: FormControlListOption<ItemValue>[];
  id?: string;
  disabled?: boolean;
  onChange: (value: GlobalValue) => void;
  value: GlobalValue;
  renderingOptions?: {
    containerClassName?: string;
    elementClassName?: string;
  };
  error?: string;
};

export type FormControlListOptionVerticalChip<T> = FormControlListOption<T> & {
  centerIcon: JSX.Element;
};

export type FormControlListVerticalChipProps<GlobalValue, ItemValue = GlobalValue> = Omit<
  FormControlListProps<GlobalValue, ItemValue>,
  'options'
> & {
  options: FormControlListOptionVerticalChip<ItemValue>[];
};

export type FormControlLabelProps = {
  label?: string | JSX.Element;
};

export const FormControlLabel: React.FC<FormControlLabelProps> = ({
  label,
}: FormControlLabelProps) => (label ? <span className="ml-xs">{label}</span> : null);

export const commonFormControlContainerClasses = (
  checked?: boolean,
  disabled?: boolean,
): Argument[] => [
  'border-solid border m-0 text-neutral-0 peer-focus-visible:outline peer-focus-visible:outline-blue-500',
  { 'border-primary-400': !disabled && checked },
  { 'group-hover:border-neutral-900 group-active:border-primary-400': !disabled && !checked },
];

export const commonFormControlCheckmarkClasses = (
  checked?: boolean,
  disabled?: boolean,
  indeterminate?: boolean,
): Argument[] => [
  { 'bg-neutral-100': disabled && !checked },
  { 'bg-neutral-300': disabled && checked },
  {
    'bg-primary-400 group-hover:bg-primary-500 group-hover:border-primary-500 group-active:bg-primary-600 group-active:border-primary-600':
      !disabled && (checked || indeterminate),
  },
];

export const hiddenInputClassNames = 'peer cursor-pointer absolute top-0 left-0 opacity-0';
export const containerLabelClassnames = (disabled?: boolean): Argument[] => [
  ' text-ds-b1 relative mb-0 min-h-lg inline-flex cursor-pointer',
  { 'pointer-events-none': disabled },
];

type Props = FormControlProps<any>;
export const GenericFormControlContainer: React.FC<Props> = ({
  className,
  label,
  children,
  ...rest
}) => (
  <label className={classNames(className, containerLabelClassnames(rest.disabled))}>
    <div className="inline-flex items-center px-[2px] group">
      <input className={hiddenInputClassNames} {...rest} />
      {children}
      <FormControlLabel label={label} />
    </div>
  </label>
);
