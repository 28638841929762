import React from 'react';

import { Form } from 'react-final-form';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxListField } from '@travauxlib/shared/src/components/DesignSystem/components/CheckboxList/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { required } from '@travauxlib/shared/src/utils/form';

import { QuestionProps } from 'features/Estimateur/types';

import { Title } from '../QuestionTitle';

export const Peinture = ({
  locations,
  submitAnswer,
  initialValues,
}: QuestionProps): JSX.Element => (
  <Form onSubmit={submitAnswer} initialValues={initialValues}>
    {({ handleSubmit, valid, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="mb-xl">
          <Title title="Peinture" />
          <div className="mb-md">
            <label className="font-bold" htmlFor="surfaceToPaint">
              Quelles surfaces sont à peindre ?
            </label>
            <CheckboxListField
              id="surfaceToPaint"
              name="surfaceToPaint"
              validate={required}
              options={[
                {
                  value: 'murs',
                  label: 'Murs',
                },
                {
                  value: 'plafonds',
                  label: 'Plafonds',
                },
              ]}
            />
          </div>
          <div className="mb-md">
            <label className="font-bold" htmlFor="locations">
              Dans quelles pièces ?
            </label>
            <CheckboxListField
              validate={required}
              id="locations"
              name="locations"
              checkAllLabel="Toutes les pièces"
              options={locations.map(({ uuid, label }) => ({
                label,
                value: uuid,
              }))}
            />
          </div>
          <div className="mb-md">
            <FormLabel label="Dans quel état sont les murs et les plafonds ?" />
            <ButtonGroupField
              validate={required}
              name="etatMursEtPlafonds"
              options={[
                { label: 'Dégradés', value: 'degrades' },
                { label: 'Sains', value: 'sains' },
              ]}
            />
          </div>
        </div>
        <div className="text-center">
          <Button type="submit" disabled={!valid || submitting} loading={submitting}>
            Ajouter
          </Button>
        </div>
      </form>
    )}
  </Form>
);
