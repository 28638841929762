import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';

import { toast } from '@travauxlib/shared/src/components/Notifications';

import { usePayFactureByCard } from './usePayFactureByCard';

export type CardCheckoutFormValues = {
  email: string;
  name: string;
  acceptConditions: boolean;
  token: string;
};

export const useSubmitFactureCheckout = (
  onSuccess: () => void,
): {
  handleSubmit: (payload: CardCheckoutFormValues) => void;
} => {
  const stripe = useStripe();
  const elements = useElements();
  const { payFactureByCard } = usePayFactureByCard(onSuccess);
  const handleSubmit = async ({
    token,
    email,
    name,
  }: CardCheckoutFormValues): Promise<PaymentIntent | undefined> => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (cardElement) {
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email,
        },
      });

      if (paymentMethod.error) {
        toast.error("Votre moyen de paiement n'est pas valable");
        throw Error('Error while creating the payment method');
      }

      return payFactureByCard({
        factureToken: token,
        paymentMethodId: paymentMethod.paymentMethod?.id,
      });
    }

    throw new Error('No StripeElement found for processing payment');
  };

  return { handleSubmit };
};
