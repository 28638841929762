import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import CalculatePicto from '@travauxlib/shared/src/components/DesignSystem/assets/CalculatePicto.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { ReassuranceCard } from 'features/EspaceClient3/components/ReassuranceCard';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { paymentReassurance } from '../utils/constants';

export const EmptyPayment: React.FC = () => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const navigate = useNavigate();

  useEffect(() => {
    EspaceClientTracking.onEspaceClientPaymentEmptyViewed();
  }, []);

  return (
    <div className="m-md sm-desktop:mb-3xl sm-desktop:mx-0">
      <EmptyState
        className="mb-md sm-desktop:mb-xl"
        illustration={<CalculatePicto />}
        iconIllustrationSize="md"
        title="Votre compte séquestre"
        description="À la signature du devis travaux que vous aurez sélectionné, nous allons vous attribuer un compte séquestre qui vous permettra de réaliser les paiements de l'entrepreneur"
        secondaryAction={{
          label: 'Consulter notre guide dédié',
          onClick: () => navigate('../guides/compte-sequestre'),
        }}
        fullWidth={isTabletOrMobile}
      />
      <ReassuranceCard items={paymentReassurance} />
    </div>
  );
};
