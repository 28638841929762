import React, { useEffect } from 'react';

type Props = {
  error: Error;
};
export function setWithExpiry(key: string, value: string, ttl: number): void {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string): string | null {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) {
    return null;
  }

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

const regexes = [
  /Loading chunk (\d)+ failed/,
  /Unexpected token '<'/,
  /error loading dynamically imported module/,
];
export const ErrorFallback: React.FC<Props> = ({ error }) => {
  useEffect(() => {
    if (error?.message && regexes.some(regex => regex.test(error.message))) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);
  return null;
};
