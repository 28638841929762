import React from 'react';

import './Loader.css';
import classNames from 'classnames';

import Brand from '@travauxlib/shared/src/components/DesignSystem/assets/Brand.svg?react';

export type LoaderProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

const circleAttributesBySize = {
  xs: {
    widthHeight: 16,
    strokeWidth: 2,
    offsetSpaceCenter: 0,
  },
  sm: {
    widthHeight: 32,
    strokeWidth: 4,
    offsetSpaceCenter: 0,
  },
  md: {
    widthHeight: 48,
    strokeWidth: 4,
    offsetSpaceCenter: 0,
  },
  lg: {
    widthHeight: 64,
    strokeWidth: 4,
    offsetSpaceCenter: 0,
  },
};

const cssAttributesBySize = {
  xs: {
    mainContainerClass: 'h-md w-md',
    capsuleIconSize: '',
  },
  sm: {
    mainContainerClass: 'h-xl w-xl',
    capsuleIconSize: '',
  },
  md: {
    mainContainerClass: 'h-xxl w-xxl',
    capsuleIconSize: 'h-lg w-lg',
  },
  lg: {
    mainContainerClass: 'h-3xl w-3xl',
    capsuleIconSize: 'h-xl w-xl',
  },
};

export const Loader: React.FC<LoaderProps> = ({ size = 'md' }) => {
  const { widthHeight, offsetSpaceCenter, strokeWidth } = circleAttributesBySize[size];
  const svgContainerWH = widthHeight;
  const circlesPosXY = svgContainerWH / 2;
  const radius = widthHeight / 2 - strokeWidth;
  const halfOffsetSpaceCenter = offsetSpaceCenter / 2;
  const centerCapsuleWidthHeight = widthHeight - strokeWidth * 3 - offsetSpaceCenter;
  const centerCapsulePosXY = strokeWidth * 1.5 + halfOffsetSpaceCenter;

  const { mainContainerClass, capsuleIconSize } = cssAttributesBySize[size];

  return (
    <div className={classNames('text-primary-900', mainContainerClass)} data-testid="loader">
      {(size === 'md' || size === 'lg') && (
        <div className="relative">
          <div
            className={classNames(
              'absolute text-neutral-800 flex items-center justify-center rounded-full',
            )}
            style={{
              top: centerCapsulePosXY,
              left: centerCapsulePosXY,
              height: centerCapsuleWidthHeight,
              width: centerCapsuleWidthHeight,
            }}
          >
            <span
              className={classNames('text-primary transform -translate-y-[10%]', capsuleIconSize)}
            >
              <Brand />
            </span>
          </div>
        </div>
      )}
      <svg
        className="loader__svg"
        width={svgContainerWH}
        height={svgContainerWH}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          fill="none"
          className="text-neutral-200"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx={circlesPosXY}
          cy={circlesPosXY}
          r={radius}
        />
        <circle
          className={`text-primary animatedCircle animatedCircle--${size}`}
          stroke="currentColor"
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx={circlesPosXY}
          cy={circlesPosXY}
          r={radius}
        />
      </svg>
    </div>
  );
};
