import React, { MouseEventHandler } from 'react';

import { Level } from './types';

import CrossSymbol from '../../assets/CrossSymbol.svg?react';
import { IconButton } from '../Buttons/IconButton';

type Props = {
  level: Level;
  onClickClose?: MouseEventHandler<HTMLButtonElement>;
};

export const CloseButton: React.FC<Props> = ({ onClickClose, level }) => (
  <IconButton className="shrink-0 ml-xxs -mr-xxs" onClick={onClickClose} variant={level}>
    <CrossSymbol data-testid="close-notif" />
  </IconButton>
);
