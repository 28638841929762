import { baseImgixUrl, stringifyParams } from '@travauxlib/shared/src/utils/urls';

import { Devis } from 'types';

export const getPrintDevisUrl = (token: string, isViewByLocations = false): string => {
  const queryString = stringifyParams({ isViewByLocations });
  return queryString
    ? `${APP_CONFIG.apiURL}/devis-pro/${token}/pdf?${queryString}`
    : `${APP_CONFIG.apiURL}/devis-pro/${token}/pdf`;
};

export const getSignedDevisPdf = ({ proContext, token }: Devis): string | undefined =>
  proContext?.hasSignedDevisPdf
    ? `${APP_CONFIG.apiURL}/devis-pro/${token}/signed-devis-pdf`
    : undefined;

export const getSignedPdfOrPrintDevisUrl = (devis: Devis, isViewByLocations = false): string =>
  getSignedDevisPdf(devis) || getPrintDevisUrl(devis.token, isViewByLocations);

export const getPrintFactureUrl = (token: string): string =>
  `${APP_CONFIG.apiURL}/client/factures/${token}/pdf`;

export const espaceClientUrl = '/espace-client';
export const deleteAccountLegacyUrl = '/dashboard/delete-account';

export const loginUrl = '/login';

export const imgixPath = `${baseImgixUrl}/static/devis/`;

export const partenairesPremiumDevisUrl = [
  `${imgixPath}/Premium_Atlantic.svg`,
  `${imgixPath}/Premium_Schneider.svg`,
  `${imgixPath}/Officiel_Howdens.svg`,
];
