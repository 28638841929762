import React from 'react';

import classNames from 'classnames';

import { BadgeSizes, BadgeVariant } from './types';
import { getClassNamesBySize, MAX_NOTIF, variantsClassNames } from './utils';

type Props = {
  className?: string;
  variant?: BadgeVariant;
  size?: BadgeSizes;
  value: number;
};

export const Badge: React.FC<Props> = ({ className, size = 'md', variant = 'light', value }) => {
  if (!value) {
    return null;
  }

  return (
    <div
      data-testid="badge"
      className={classNames(
        className,
        'rounded-full flex items-center justify-center shrink-0',
        variantsClassNames[variant],
        getClassNamesBySize(value)[size],
      )}
    >
      {size !== 'xs' ? (
        <div
          className={classNames('font-medium', {
            'text-ds-b2': size === 'md',
            'text-ds-sm': size === 'sm',
          })}
        >
          {value > MAX_NOTIF ? '99+' : value}
        </div>
      ) : null}
    </div>
  );
};
