import React from 'react';

import { useEstimateurSource } from 'features/Estimateur/utils/paths';

import { QuestionRoutes } from './routes';

import { Answer, Estimation } from '../../types';

type Props = {
  submitEstimation: (estimation: Estimation) => Promise<unknown>;
  organizationName?: string;
  estimation: Estimation;
  getPrintEstimationUrl?: (uuid: string) => string;
  isClientEstimateur: boolean;
  affiliateToken?: string;
};

export const EstimateurQuestions: React.FC<Props> = ({
  estimation,
  organizationName,
  submitEstimation,
  getPrintEstimationUrl,
  isClientEstimateur,
  affiliateToken,
}) => {
  const source = useEstimateurSource();

  const submitAnswer = async (answer: Answer, index?: number): Promise<unknown> => {
    const existingAnswers = estimation.answers || [];
    const newAnswers =
      index !== undefined && existingAnswers[index]
        ? existingAnswers.map((a, i) => (i === index ? answer : a))
        : [...existingAnswers, answer];
    const newEstimation = {
      ...estimation,
      source,
      context: {
        ...estimation.context,
        deletedLinesIdentifiers: estimation.answers?.length
          ? estimation.context.deletedLinesIdentifiers
          : [],
        defaultGamme: estimation.context.defaultGamme || 'confort',
      },
      answers: newAnswers,
    };
    return submitEstimation(newEstimation);
  };

  const deleteAnswer = async (answerIndex: number): Promise<unknown> => {
    const existingAnswers = estimation.answers || [];
    const newAnswers = [
      ...existingAnswers.slice(0, answerIndex),
      ...existingAnswers.slice(answerIndex + 1),
    ];
    const newEstimation = {
      ...estimation,
      context: {
        ...estimation.context,
        deletedLinesIdentifiers: estimation.answers?.length
          ? estimation.context.deletedLinesIdentifiers
          : [],
      },
      answers: newAnswers,
    };
    return submitEstimation(newEstimation);
  };

  return (
    <QuestionRoutes
      estimation={estimation}
      organizationName={organizationName}
      affiliateToken={affiliateToken}
      submitAnswer={submitAnswer}
      submitEstimation={submitEstimation}
      deleteAnswer={deleteAnswer}
      printUrl={getPrintEstimationUrl?.(estimation.uuid)}
      isClientEstimateur={isClientEstimateur}
      locations={estimation.context.locations}
    />
  );
};
