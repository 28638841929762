import React from 'react';

import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

export const NotFound: React.FC = () => (
  <div className="!pt-xl !flex !justify-center">
    <div className="!text-center">
      <h1>Oops!</h1>
      <h2>Cette page est introuvable</h2>
      <div className="!mb-lg">
        La page à laquelle vous essayez d&apos;accéder n&apos;existe pas.
      </div>
      <div>
        <ButtonLink to="/">Revenir à l&apos;accueil</ButtonLink>
      </div>
    </div>
  </div>
);
