import React from 'react';

import { useForm } from 'react-final-form';

import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import {
  SuiviChantierComputed,
  SuiviLot,
} from '@travauxlib/shared/src/features/SuiviChantier/types';

import { LiberationDeFondsRecap } from './LiberationDeFondsRecap';
import { PrestationsRecap } from './PrestationsRecap';
import { ThresholdAndFrozenMoneyRecap } from './ThresholdAndFrozenMoneyRecap';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  lots: SuiviLot[];
  proCompanyName: string;
  currentThreshold?: number;
  computed: SuiviChantierComputed;
  initialComputed: SuiviChantierComputed;
};

export const ConfirmationSuiviChantierModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  lots,
  proCompanyName,
  currentThreshold,
  computed: { montantALibererTTC, montantGele },
  initialComputed: {
    montantALibererTTC: initialMontantALibererTTC,
    montantGele: initialMontantGele,
  },
}) => {
  const form = useForm();
  const { submitting } = form.getState();

  const changedLigne = lots.flatMap(lot => lot.lignes).filter(ligne => ligne.hasChanged);
  const rejectedLigneCount = changedLigne.filter(
    ligne =>
      ligne.pourcentageAvancement !== ligne.previousStepPourcentageAvancement ||
      ligne.hasFournitures !== ligne.previousStepHasFournitures,
  ).length;

  const isMontantALibererNegative = montantALibererTTC <= 0;

  return (
    <SimpleModal
      title={
        isMontantALibererNegative
          ? 'Voulez-vous confirmer ces modifications ?'
          : 'Voulez-vous confirmer la libération des fonds ?'
      }
      isOpen={isOpen}
      handleClose={handleClose}
      validateAction={{
        label: isMontantALibererNegative
          ? "Confirmer l'état d'avancement"
          : 'Confirmer la libération',
        onClick: () => form.submit(),
        disabled: submitting,
        loading: submitting,
      }}
      cancelAction={{ label: 'Annuler', onClick: handleClose }}
    >
      <div
        data-testid="recap-content"
        className="flex flex-col gap-xxs text-ds-b2 text-neutral-600"
      >
        <PrestationsRecap
          montantAvancement={montantALibererTTC + montantGele}
          initialMontantAvancement={initialMontantALibererTTC + initialMontantGele}
          rejectedLigneCount={rejectedLigneCount}
          proCompanyName={proCompanyName}
        />
        <ThresholdAndFrozenMoneyRecap
          montantALibererTTC={montantALibererTTC}
          montantGele={montantGele}
          currentThreshold={currentThreshold}
        />
        <LiberationDeFondsRecap
          montantALibererTTC={montantALibererTTC}
          proCompanyName={proCompanyName}
        />
      </div>
    </SimpleModal>
  );
};
