import { danger, info, gray600 } from '@travauxlib/shared/src/utils/style/colors';

export const stripeCardComponentOptions = {
  classes: { base: 'bg-white border h-[3rem] px-md w-full py-md' },
  style: {
    base: {
      fontSize: '14px',
      color: info,
      letterSpacing: '0.025em',
      '::placeholder': {
        color: gray600,
      },
    },
    invalid: {
      color: danger,
    },
  },
};
