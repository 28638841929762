export enum Estimateur {
  Public = 'public',
  Cdn = 'cdn',
  Hosman = 'hosman',
  Client = 'client',
  Partner = 'partner',
}

export enum EstimateurEvent {
  Estimateur = 'estimateur',
}

export enum EstimateurEventAction {
  Started = 'started',
  RecallStarted = 'recall.started',
  RecallFinished = 'recall.finished',
  Result = 'result',
  PdfDownloaded = 'pdf.downloaded',
}
